import { __assign, __rest } from "tslib";
import * as React from 'react';
import classNames from 'classnames';
import './sub-area-card.scss';
export var CnCardSubAreaCard = function (_a) {
    var title = _a.title, className = _a.className, action = _a.action, children = _a.children, otherProps = __rest(_a, ["title", "className", "action", "children"]);
    return (React.createElement("div", __assign({ "data-name": "CnCardSubAreaCard", className: classNames(['cn-ui-card-sub-area-card', className]) }, otherProps),
        (title || action) && (React.createElement("div", { className: "cn-ui-card-sub-area-card-header" },
            React.createElement("div", { className: "cn-ui-card-sub-area-card-title" },
                React.createElement("div", { className: "cn-ui-card-sub-area-card-title-header" }, title),
                React.createElement("div", { className: "cn-ui-card-sub-area-card-title-footer" }, action)))),
        children && (React.createElement("div", { className: "cn-ui-card-sub-area-card-footer" }, children))));
};
CnCardSubAreaCard.displayName = 'CnCardSubAreaCard';
