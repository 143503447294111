import * as React from 'react';
import isNil from 'lodash/isNil';
import classNames from 'classnames';
import { CnEllipsis } from '@/components/cn-ellipsis';
import './format-ellipsis.scss';
export default (function (column) {
    return {
        cell: function (val) {
            var classes = classNames({
                'cn-table-format-align-left': !(column === null || column === void 0 ? void 0 : column.align) || (column === null || column === void 0 ? void 0 : column.align) === 'left',
                'cn-table-format-align-center': (column === null || column === void 0 ? void 0 : column.align) === 'center',
                'cn-table-format-align-right': (column === null || column === void 0 ? void 0 : column.align) === 'right',
            });
            var isEmptyValue = isNil(val) || val === '';
            var text = isEmptyValue ? '- -' : val;
            var style = (column === null || column === void 0 ? void 0 : column.width)
                ? { width: parseInt(column.width) - 30 }
                : {};
            return React.createElement(CnEllipsis, { className: classes, style: style }, text);
        },
    };
});
