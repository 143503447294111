import { __spreadArray } from "tslib";
import { useCallback } from 'react';
import { fileToObject } from '../../../utils';
export var useOnStart = function (props) {
    var setValue = props.setValue;
    return useCallback(function (_file) {
        var file = fileToObject(_file, { status: 'idle' });
        setValue(function (prev) { return __spreadArray(__spreadArray([], prev, true), [file], false); });
    }, [setValue]);
};
