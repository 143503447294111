import { __assign, __rest } from "tslib";
import * as React from 'react';
import cx from 'classnames';
import { Breadcrumb } from '@/components/fusion';
export var CnBreadcrumb = function (props) {
    var _a;
    var children = props.children, dataSource = props.dataSource, className = props.className, otherProps = __rest(props, ["children", "dataSource", "className"]);
    var childNodes = [];
    if (dataSource) {
        dataSource.forEach(function (item, idx) {
            var itemChildren = item.children, otherItemProps = __rest(item, ["children"]);
            childNodes.push(React.createElement(Breadcrumb.Item, __assign({ key: idx }, otherItemProps), item.children));
        });
    }
    var classes = cx((_a = {
            'cn-ui-breadcrumb': true
        },
        _a[className] = !!className,
        _a));
    return (React.createElement(Breadcrumb, __assign({ "data-name": "CnBreadcrumb", separator: "/", className: classes }, otherProps), children || childNodes));
};
export var CnBreadcrumbItem = Breadcrumb.Item;
export { Breadcrumb };
