import { isEmpty } from './helper';
var getKey = function (storageKey) {
    var defaultKey = "FILTER_".concat("".concat(global.location.origin, "_").concat(global.location.pathname), "|cachesearch");
    if (isEmpty(storageKey))
        return defaultKey;
    if (typeof storageKey === 'function') {
        var key = storageKey();
        return "".concat(defaultKey, "|").concat(key);
    }
    return "".concat(defaultKey, "|").concat(storageKey);
};
export var getCache = function (storageKey) {
    var FILTER_SAVE_KEY = getKey(storageKey);
    var saveDataStr = sessionStorage.getItem(FILTER_SAVE_KEY) || '';
    var saveData = null;
    if (saveDataStr) {
        try {
            saveData = JSON.parse(saveDataStr);
        }
        catch (e) {
            console.error(e);
        }
    }
    return saveData;
};
export var setCache = function (saveData, storageKey, cacheSearch) {
    if (cacheSearch === void 0) { cacheSearch = false; }
    if (!cacheSearch)
        return;
    var FILTER_SAVE_KEY = getKey(storageKey);
    var saveDataStr = '';
    try {
        saveDataStr = JSON.stringify(saveData);
    }
    catch (e) {
        console.error(e);
    }
    if (saveDataStr) {
        sessionStorage.setItem(FILTER_SAVE_KEY, saveDataStr);
    }
};
export var removeCache = function (storageKey) {
    var FILTER_SAVE_KEY = getKey(storageKey);
    sessionStorage.removeItem(FILTER_SAVE_KEY);
};
