import { __assign } from "tslib";
import * as React from 'react';
import cx from 'classnames';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnIcon } from '@/components/cn-icon';
import ResizeObserver from 'resize-observer-polyfill';
export var CnTextEllipsis = function (_a) {
    var _b;
    var children = _a.children, style = _a.style, line = _a.line, outerClassName = _a.outerClassName, className = _a.className, hasTooltip = _a.hasTooltip, showFolder = _a.showFolder, toolTipProps = _a.toolTipProps;
    var textRef = React.useRef(null);
    var _c = React.useState(false), isOverflow = _c[0], setIsOverflow = _c[1];
    var _d = React.useState(false), showAll = _d[0], setShowAll = _d[1];
    React.useEffect(function () {
        if (!(textRef === null || textRef === void 0 ? void 0 : textRef.current))
            return;
        var resizeObserver = new ResizeObserver(function () {
            var _a, _b;
            if (((_a = textRef === null || textRef === void 0 ? void 0 : textRef.current) === null || _a === void 0 ? void 0 : _a.scrollHeight) > ((_b = textRef === null || textRef === void 0 ? void 0 : textRef.current) === null || _b === void 0 ? void 0 : _b.offsetHeight)) {
                setIsOverflow(true);
            }
        });
        resizeObserver.observe(textRef.current);
        return function () {
            resizeObserver.disconnect();
        };
    }, []);
    var handleToggleFold = function () {
        setShowAll(!showAll);
    };
    var content = (React.createElement("div", { "data-name": "CnEllipsis", className: cx(outerClassName, 'cn-ui-text-clip', {
            fold: showFolder && isOverflow,
        }) },
        React.createElement("div", { ref: textRef, className: cx([
                className,
                {
                    'cn-ui-text-clip-inner': !showAll,
                },
            ]), style: __assign(__assign({}, style), { WebkitLineClamp: line }) }, children),
        showFolder && isOverflow ? (React.createElement("span", { className: "cn-ui-text-clip-fold", onClick: handleToggleFold },
            React.createElement(CnIcon, { type: showAll ? 'triangle-up-fill' : 'triangle-down-fill' }))) : null));
    if (hasTooltip && isOverflow) {
        return (React.createElement(CnTooltip, __assign({ className: cx((_b = {
                    'cn-ui-text-ellipsis-tooltip': true
                },
                _b[toolTipProps === null || toolTipProps === void 0 ? void 0 : toolTipProps.className] = !!(toolTipProps === null || toolTipProps === void 0 ? void 0 : toolTipProps.className),
                _b)), trigger: content }, toolTipProps), children));
    }
    return content;
};
CnTextEllipsis.defaultProps = {
    line: 2,
    hasTooltip: true,
    showFolder: false,
};
CnTextEllipsis.displayName = 'CnTextEllipsis';
