import { __assign } from "tslib";
import isEmpty from 'lodash/isEmpty';
export function handleMerge(props, columns) {
    var remote = props.remote;
    if ('columns' in props && !isEmpty(props.columns)) {
        return columns;
    }
    var coverColumns = remote === null || remote === void 0 ? void 0 : remote.columns;
    if (Array.isArray(coverColumns)) {
        coverColumns.forEach(function (item) {
            var key = (item === null || item === void 0 ? void 0 : item.dataIndex) || (item === null || item === void 0 ? void 0 : item.key);
            columns.forEach(function (colItem, index) {
                var colKey = (colItem === null || colItem === void 0 ? void 0 : colItem.dataIndex) || (colItem === null || colItem === void 0 ? void 0 : colItem.key);
                if (key === colKey) {
                    // @ts-ignore
                    columns[index] = __assign(__assign({}, (colItem || {})), (item || {}));
                }
            });
        });
    }
    return columns;
}
