import { __assign, __rest } from "tslib";
import './index.scss';
import * as React from 'react';
import classNames from 'classnames';
import { usePageFooterHeight } from './hooks/use-page-footer-height';
import { useImproveMargin } from './hooks/use-improve-margin';
export var CnPageFooter = function (props) {
    var className = props.className, leftSlot = props.leftSlot, middleSlot = props.middleSlot, middleSecondarySlot = props.middleSecondarySlot, rightSlot = props.rightSlot, _a = props.mode, mode = _a === void 0 ? 'page' : _a, restProps = __rest(props, ["className", "leftSlot", "middleSlot", "middleSecondarySlot", "rightSlot", "mode"]);
    var ref = React.useRef(null);
    usePageFooterHeight({ disabled: mode !== 'page', ref: ref });
    useImproveMargin({ disabled: mode !== 'page', ref: ref });
    return (React.createElement("div", __assign({ ref: ref, className: classNames('cn-ui-page-footer', className, {
            // 区块场景样式类名
            'cn-ui-page-footer-mode-block': mode === 'block',
            // 页面场景样式类名
            'cn-ui-page-footer-mode-page': mode === 'page',
        }) }, restProps),
        React.createElement("div", { className: "cn-ui-page-footer-header" }, leftSlot),
        React.createElement("div", { className: "cn-ui-page-footer-sub-header" }, middleSecondarySlot),
        React.createElement("div", { className: "cn-ui-page-footer-body" }, middleSlot),
        React.createElement("div", { className: "cn-ui-page-footer-footer" }, rightSlot)));
};
CnPageFooter.displayName = 'CnPageFooter';
