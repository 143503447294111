export function getFormUploadProps(params) {
    var fileListRef = params.fileListRef, field = params.field, props = params.props, uploadErrMsg = params.uploadErrMsg;
    /** 校验正在上传中的文件 */
    var uploadingValidator = function () {
        // 当包含未上传完成的文件时，返回错误信息
        if (fileListRef.current.length) {
            return [uploadErrMsg];
        }
        return [];
    };
    // 定义结果对象，包含'onProgress'，'onError'，'onRemove'三个方法
    var result = {
        // 文件上传进度的处理方法
        onProgress: function (file) {
            var _a;
            if (file.status === 'done') {
                // 如果文件已完成上传，从文件列表中移除
                fileListRef.current = fileListRef.current.filter(function (item) {
                    return item.originFile !== file.originFile;
                });
            }
            else {
                // 查找文件在文件列表中的位置
                var fileIndex = fileListRef.current.findIndex(function (item) {
                    if (item.key)
                        return item.key === file.key;
                    return item.originFile === file.originFile;
                });
                if (fileIndex >= 0) {
                    // 当文件已存在，执行替换文件
                    fileListRef.current[fileIndex] = file;
                }
                else {
                    // 当文件不存在，添加文件
                    fileListRef.current.push(file);
                }
            }
            field.setSelfErrors(uploadingValidator());
            (_a = props.onProgress) === null || _a === void 0 ? void 0 : _a.call(props, file);
        },
        // 文件上传错误的处理方法
        onError: function (err, file) {
            var _a;
            // 从文件列表中移除出错的文件
            fileListRef.current = fileListRef.current.filter(function (item) {
                return item.originFile !== file.originFile;
            });
            field.setSelfErrors(uploadingValidator());
            (_a = props.onError) === null || _a === void 0 ? void 0 : _a.call(props, err, file);
        },
        // 文件移除的处理方法
        onRemove: function (file) {
            var _a;
            // 从文件列表中移除被删除的文件
            fileListRef.current = fileListRef.current.filter(function (item) {
                return item.originFile !== file.originFile;
            });
            field.setSelfErrors(uploadingValidator());
            (_a = props.onRemove) === null || _a === void 0 ? void 0 : _a.call(props, file);
        },
    };
    return result;
}
