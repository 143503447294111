import { __assign } from "tslib";
import React, { useContext } from 'react';
import $i18n from 'panda-i18n';
import { Checkbox } from '@/components/fusion';
import { CnScrollBar } from '@/components/cn-scroll-bar';
import { leftFixMaxNum, rightFixMaxNum } from './const';
import { CnDialog } from '@/components/cn-dialog';
import { ColumnItem } from './column-item';
import { ColumnsStateContext } from './context';
import ColumnsFixLeft from './columns-fix-left';
import ColumnsFixRight from './columns-fix-right';
var LeftColumns = function () {
    var fixLeftColumns = useContext(ColumnsStateContext).fixLeftColumns;
    if (fixLeftColumns.length > 0) {
        return (React.createElement("div", { className: "cn-table-column-setting-dialog__fix__left" },
            React.createElement(ColumnsFixLeft, null)));
    }
    return (React.createElement("div", { className: "cn-table-column-setting-dialog__fix__left" },
        React.createElement("div", { className: "cn-table-column-setting-wrapper-title" }, $i18n.get({
            id: 'FixedOnTheLeftUpTo_236572884',
            dm: '固定在左侧(最多固定{leftFixMaxNum}个)',
            ns: 'CnTable',
        }, { leftFixMaxNum: leftFixMaxNum })),
        React.createElement("div", { className: "cn-table-column-setting-wrapper-null" }, $i18n.get({
            id: 'ThereIsNoFixedItemFor_2234810',
            dm: '暂无固定项，请从「不固定」池选择固定',
            ns: 'CnTable',
        }))));
};
var RightColumns = function () {
    var fixRightColumns = useContext(ColumnsStateContext).fixRightColumns;
    if ((fixRightColumns === null || fixRightColumns === void 0 ? void 0 : fixRightColumns.length) > 0) {
        return (React.createElement("div", { className: "cn-table-column-setting-dialog__fix__right" },
            React.createElement(ColumnsFixRight, null)));
    }
    return (React.createElement("div", { className: "cn-table-column-setting-dialog__fix__right" },
        React.createElement("div", { className: "cn-table-column-setting-wrapper-title" }, $i18n.get({
            id: 'FixedOnTheRightUpTo_937691714',
            dm: '固定在右侧(最多固定{rightFixMaxNum}个)',
            ns: 'CnTable',
        }, { rightFixMaxNum: rightFixMaxNum })),
        React.createElement("div", { className: "cn-table-column-setting-wrapper-null" }, $i18n.get({
            id: 'ThereIsNoFixedItemFor_2234810',
            dm: '暂无固定项，请从「不固定」池选择固定',
            ns: 'CnTable',
        }))));
};
var MiddleColumns = function () {
    var noFixColumns = useContext(ColumnsStateContext).noFixColumns;
    return (React.createElement("div", { className: "cn-table-column-setting-dialog__no_fix" },
        React.createElement(CnScrollBar, null, noFixColumns.length ? (React.createElement("div", { className: "cn-table-column-setting" }, noFixColumns.map(function (dataItem, index) { return (React.createElement(ColumnItem, { showIndex: true, position: "center", dataItem: dataItem, key: dataItem.dataIndex, index: index })); }))) : null)));
};
export function ColumnsSettingDialog(props) {
    var visible = props.visible, setVisible = props.setVisible;
    var _a = useContext(ColumnsStateContext), checkedColumnKeys = _a.checkedColumnKeys, checkedAll = _a.checkedAll, setColumns = _a.setColumns, updateColumns = _a.updateColumns, cancelUpdateColumns = _a.cancelUpdateColumns;
    var cancel = function () {
        cancelUpdateColumns();
        setVisible(false);
    };
    return (React.createElement(CnDialog, { visible: visible, size: "large", className: "cn-table-column-setting-dialog", title: $i18n.get({ id: 'ColumnSettings', dm: '列设置', ns: 'CnTable' }), footer: React.createElement("div", { className: "cn-table-column-setting-dialog-checkall" },
            React.createElement(Checkbox, { checked: checkedAll, onChange: function (checked) {
                    setColumns(function (prev) {
                        return prev.map(function (item) { return (__assign(__assign({}, item), { hidden: !checked })); });
                    });
                }, indeterminate: checkedColumnKeys.length && !checkedAll }),
            $i18n.get({ id: 'AllSelection', dm: '全选', ns: 'CnTable' })), onClose: cancel, onCancel: cancel, onOk: function () {
            updateColumns();
            setVisible(false);
        }, okProps: {
            children: $i18n.get({ id: 'Confirmation', dm: '确认', ns: 'CnTable' }),
        } },
        React.createElement("div", { className: "cn-table-column-setting-dialog" },
            React.createElement("div", { className: "cn-table-column-setting-dialog__fix" },
                React.createElement(LeftColumns, null),
                React.createElement(RightColumns, null)),
            React.createElement(MiddleColumns, null))));
}
