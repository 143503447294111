import { __awaiter, __generator } from "tslib";
import { model, markRaw, action, toJS, observable, observe, define, } from '@formily/reactive';
export var createCnFormStepInstance = function (defaultCurrent) {
    if (defaultCurrent === void 0) { defaultCurrent = 0; }
    var env = define({
        form: null,
        field: null,
        steps: [],
    }, {
        form: observable.ref,
        field: observable.ref,
        steps: observable.shallow,
    });
    var dispose = null;
    var currentChangeCallbacks = [];
    var _nextLoading = observable.ref(false);
    var setDisplay = action.bound(function (target) {
        var currentStep = env.steps[target];
        env.steps.forEach(function (_a) {
            var name = _a.name;
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            env.form.query("".concat(env.field.address, ".").concat(name)).take(function (field) {
                if (name === currentStep.name) {
                    field.setDisplay('visible');
                }
                else {
                    field.setDisplay('hidden');
                }
            });
        });
    });
    var next = function () {
        if (formStep.allowNext) {
            setDisplay(formStep.current + 1);
            formStep.setCurrent(formStep.current + 1);
        }
    };
    var back = function () {
        if (formStep.allowBack) {
            setDisplay(formStep.current - 1);
            formStep.setCurrent(formStep.current - 1);
        }
    };
    var formStep = model({
        connect: function (steps, field) {
            env.steps = steps;
            env.form = field === null || field === void 0 ? void 0 : field.form;
            env.field = field;
        },
        current: defaultCurrent,
        setCurrent: function (key) {
            formStep.current = key;
            setDisplay(key);
        },
        onCurrentChange: function (cb) {
            if (typeof cb === 'function') {
                currentChangeCallbacks.push(cb);
            }
            return dispose || (function () { });
        },
        get allowNext() {
            return formStep.current < env.steps.length - 1;
        },
        get nextLoading() {
            return _nextLoading.value;
        },
        set nextLoading(value) {
            _nextLoading.value = value;
        },
        get allowBack() {
            return formStep.current > 0;
        },
        next: function (fnc) {
            return __awaiter(this, void 0, void 0, function () {
                var values, _next, e_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            _nextLoading.value = true;
                            return [4 /*yield*/, env.form.validate()];
                        case 1:
                            _a.sent();
                            if (fnc) {
                                values = toJS(env.form.values);
                                _next = function () {
                                    _nextLoading.value = false;
                                    next();
                                };
                                fnc(values, _next);
                                return [2 /*return*/];
                            }
                            next();
                            return [3 /*break*/, 3];
                        case 2:
                            e_1 = _a.sent();
                            _nextLoading.value = false;
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        back: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    back();
                    return [2 /*return*/];
                });
            });
        },
        submit: function (onSubmit) {
            var _a, _b;
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_c) {
                    return [2 /*return*/, (_b = (_a = env.form) === null || _a === void 0 ? void 0 : _a.submit) === null || _b === void 0 ? void 0 : _b.call(_a, onSubmit)];
                });
            });
        },
    });
    var rawFormStep = markRaw(formStep);
    dispose = observe(rawFormStep, function (v) {
        if ((v === null || v === void 0 ? void 0 : v.key) === 'current') {
            currentChangeCallbacks.map(function (cb) {
                return cb(v);
            });
        }
    });
    return rawFormStep;
};
