import { __assign } from "tslib";
import { ActionTypes } from '../actions/save-selected';
export var Reducers = function (state, action) {
    var type = action.type, payload = action.payload;
    switch (type) {
        case ActionTypes.SetOptions:
            return __assign(__assign({}, state), { options: payload });
        case ActionTypes.SetSelected:
            return __assign(__assign({}, state), { selected: payload });
        case ActionTypes.SetOverlayVisible:
            return __assign(__assign({}, state), { overlayVisible: payload });
        default:
            return __assign({}, state);
    }
};
