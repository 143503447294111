import { mergeCellProps, makeRecursiveMapper } from '../../utils';
import isFunction from 'lodash/isFunction';
export function cellPropsPipeline(cellProps) {
    // 返回一个处理函数
    return function (pipeline) {
        // 获取源列
        var sourceColumns = pipeline.getColumns();
        // 初始化列索引
        var colIndex = 0;
        // 判断 cellProps 是否为函数
        if (isFunction(cellProps)) {
            // 创建递归映射处理器
            var traverseHandler = makeRecursiveMapper(function (col, _a) {
                var isLeaf = _a.isLeaf;
                // 如果列不存在，直接返回
                if (!col) {
                    return col;
                }
                // 保存当前列索引
                var nowColIndex = colIndex;
                // 如果是叶子节点
                if (isLeaf) {
                    // 获取源单元格属性
                    var sourceGetCellProps_1 = col.getCellProps;
                    // 重写 getCellProps 方法
                    col.getCellProps = function (value, rowData, rowIndex) {
                        // 获取源单元格属性
                        var sourceCellProps = isFunction(sourceGetCellProps_1)
                            ? sourceGetCellProps_1(value, rowData, rowIndex)
                            : {};
                        // 获取通用单元格属性
                        var commonCellProps = cellProps(rowIndex, nowColIndex, col.code, rowData);
                        // 合并单元格属性，优先级 cellProps > getCellProps > pipeline内部getCellProps
                        return mergeCellProps(sourceCellProps, commonCellProps);
                    };
                    // 更新列索引
                    colIndex += 1;
                }
                // 返回处理后的列
                return col;
            });
            // 使用处理器遍历源列
            traverseHandler(sourceColumns);
        }
        // 返回处理后的列
        return pipeline.columns(sourceColumns);
    };
}
