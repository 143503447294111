import { __assign } from "tslib";
import $i18n from 'panda-i18n';
import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import useJobsPolling from '@/components/cn-async-jobs-pro/hooks/use-jobs-polling';
import { downloadFile } from '@cainiaofe/cn-ui-utils';
import { events } from '@/components/cn-utils';
import { CnButton } from '@/components/cn-button';
import { CnScrollBar } from '@/components/cn-scroll-bar';
import { CnResult } from '@/components/cn-result';
import { CnMessage } from '@/components/cn-message';
import { cssPrefix, MARK_UNREAD } from '../../constant';
import JobItem from '../job-item';
import JobMessage from '../job-message';
import useStartPolling from './hooks/use-start-polling';
import useLauncherLoading from './hooks/use-launcher-loading';
import './index.scss';
export default function JobPanel(props) {
    var selected = props.selected, visible = props.visible, type = props.type, pollingRequestConfigProps = props.pollingRequestConfig, onError = props.onError, onSuccess = props.onSuccess, className = props.className, _a = props.style, _style = _a === void 0 ? {} : _a, autoDownload = props.autoDownload, viewMore = props.viewMore, pollingService = props.pollingService;
    var pollingRequestConfig = pollingRequestConfigProps || pollingService;
    var _b = useJobsPolling({ pollingRequestConfig: pollingRequestConfig, onError: onError, onSuccess: onSuccess }), loading = _b.loading, jobResult = _b.jobResult, startTaskLoop = _b.startTaskLoop, hasUnreadJob = _b.hasUnreadJob, processedJobs = _b.processedJobs;
    useEffect(function () {
        if (visible)
            return;
        if (hasUnreadJob)
            events.emit(MARK_UNREAD);
    }, [visible, hasUnreadJob]);
    useStartPolling({ visible: visible, type: type, startTaskLoop: startTaskLoop });
    useLauncherLoading({ type: type, loading: loading });
    useEffect(function () {
        for (var _i = 0, processedJobs_1 = processedJobs; _i < processedJobs_1.length; _i++) {
            var job = processedJobs_1[_i];
            var status = job.status, downloadUrl = job.downloadUrl, jobName = job.jobName;
            var config = {
                content: React.createElement(JobMessage, { type: type, data: job }),
                duration: 3000,
            };
            if (status === 'FAILED') {
                CnMessage.error(config);
            }
            else if (status === 'SOME') {
                if (autoDownload)
                    downloadFile(downloadUrl, jobName);
                CnMessage.warning(config);
            }
            else if (status === 'SUCCEEDED') {
                if (autoDownload)
                    downloadFile(downloadUrl, jobName);
                CnMessage.success(config);
            }
        }
    }, [autoDownload, processedJobs, type]);
    var style = useMemo(function () {
        var temp = __assign(__assign({}, _style), { height: viewMore ? 'calc(100% - 56px)' : '100%' });
        if (!selected)
            temp.display = 'none';
        return temp;
    }, [viewMore, selected, _style]);
    if (!jobResult.length) {
        return (React.createElement(CnResult, { type: "empty", style: style, title: $i18n.get({
                id: 'NoTaskYet',
                dm: '暂无任务',
                ns: 'CnAsyncJobsPro',
            }) }));
    }
    return (React.createElement("div", { className: classNames("".concat(cssPrefix, "panel"), className), style: style },
        React.createElement(CnScrollBar, null, jobResult.map(function (data, i) {
            var _a;
            return (React.createElement(JobItem, { className: classNames((_a = {}, _a["".concat(cssPrefix, "item-first")] = i === 0, _a)), key: data.jobId, type: type, data: data }));
        })),
        viewMore ? (React.createElement("div", { className: classNames("".concat(cssPrefix, "panel-view-more")) },
            React.createElement(CnButton, { onClick: viewMore.onClick, text: true, size: "medium" }, viewMore.text ||
                $i18n.get({
                    id: 'SeeMore',
                    dm: '查看更多',
                    ns: 'CnAsyncJobsPro',
                })))) : null));
}
