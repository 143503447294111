export default {
    // 默认
    custom: 'https://img.alicdn.com/imgextra/i3/O1CN01F0yFjd1CofNXeRRvH_!!6000000000128-55-tps-26-19.svg',
    // 万事顺
    maestro: 'https://img.alicdn.com/imgextra/i1/O1CN01TmAJtL1C8WwSHBGBF_!!6000000000036-55-tps-26-19.svg',
    // 万事达
    mastercard: 'https://img.alicdn.com/imgextra/i2/O1CN015KRI5Z1usduO7V20R_!!6000000006093-55-tps-26-19.svg',
    visa: 'https://img.alicdn.com/imgextra/i2/O1CN01M7L0Qj28IpYxuNFg4_!!6000000007910-55-tps-26-19.svg',
    // 农业银行
    abc: 'https://img.alicdn.com/imgextra/i4/O1CN01U85bz01Cp7m4t3XNk_!!6000000000129-55-tps-26-26.svg',
    // 中国工商银行
    icbc: 'https://img.alicdn.com/imgextra/i3/O1CN01NCiBsa1Pl5HsPFRvw_!!6000000001880-55-tps-26-26.svg',
    // 平安银行
    spabank: 'https://img.alicdn.com/imgextra/i1/O1CN01OueN021cA4HQTSNm8_!!6000000003559-55-tps-26-23.svg',
    // 广发银行
    gdb: 'https://img.alicdn.com/imgextra/i1/O1CN017WL7NS1qKyKY3UsGT_!!6000000005478-55-tps-26-23.svg',
    // 中国银行
    boc: 'https://img.alicdn.com/imgextra/i4/O1CN01LX7rSz1b5eqoZqE4A_!!6000000003414-55-tps-26-26.svg',
    // 美国运通绿卡
    amex: 'https://img.alicdn.com/imgextra/i4/O1CN016wjViY1dKQrJK8s3O_!!6000000003717-55-tps-25-18.svg',
    // 人民银行
    pboc: 'https://img.alicdn.com/imgextra/i4/O1CN01BaJMl91K28KsIMSxZ_!!6000000001105-55-tps-26-22.svg',
    // 交通银行
    comm: 'https://img.alicdn.com/imgextra/i2/O1CN01z7Kk1e1T13wxYzYeg_!!6000000002321-55-tps-26-29.svg',
    // 民生银行
    cmbc: 'https://img.alicdn.com/imgextra/i2/O1CN01VPvAuV1KxqEoNNk7q_!!6000000001231-55-tps-26-20.svg',
    // 浦发
    spdb: 'https://img.alicdn.com/imgextra/i3/O1CN01yuHZIY1qjFK1KRtbo_!!6000000005531-55-tps-26-21.svg',
    // 招商银行
    cmb: 'https://img.alicdn.com/imgextra/i1/O1CN01mgEDt01NatEwgp38n_!!6000000001587-55-tps-26-26.svg',
    // 银联卡
    unionpay: 'https://img.alicdn.com/imgextra/i4/O1CN01XTnDZt1UUfAJF91sj_!!6000000002521-55-tps-26-19.svg',
    // 中国邮政
    psbc: 'https://img.alicdn.com/imgextra/i3/O1CN01mSm41w1ygVsuNpx6w_!!6000000006608-55-tps-26-25.svg',
    // 中国建设银行
    ccb: 'https://img.alicdn.com/imgextra/i3/O1CN01U5rIRf1ZDmdy0F0Jn_!!6000000003161-55-tps-25-26.svg',
    // 中信银行
    citic: 'https://img.alicdn.com/imgextra/i2/O1CN01WV1CzB1J3DeoIPYoj_!!6000000000972-55-tps-26-26.svg',
    // 上海银行
    shbank: 'https://img.alicdn.com/imgextra/i2/O1CN01EMJYBL1lJ4brK1Ltf_!!6000000004797-55-tps-26-26.svg',
};
