/* eslint-disable react/react-in-jsx-scope */
import { belongTo } from './utils';
/* eslint-disable import/no-mutable-exports */
export var rowDetailSymbol = Symbol('row-detail');
export var rowSelectionSymbol = Symbol('row-selection');
// 序号列唯一 id
export var rowIndex = Symbol('row-index');
export var treeMetaSymbol = Symbol('treeMetaSymbol');
export var footerSymbol = Symbol('footerSymbol');
export var globalAsyncDragCol = false;
export var globalHeaderCopy = false;
export var globalSplit = '｜';
export var globalOpreateMoreIconSize = 12;
export var sysFixedColSign = [rowSelectionSymbol, rowIndex];
export var isSysFixedCol = belongTo(sysFixedColSign);
export function setGlobalAsyncDragCol(val) {
    globalAsyncDragCol = val;
}
export function setGlobalHeaderCopy(val) {
    globalHeaderCopy = val;
}
export function setOperateColumnSplit(val) {
    globalSplit = val;
}
