import { __spreadArray } from "tslib";
var allNeedCalChar = __spreadArray(__spreadArray(__spreadArray([], 'abcdefghijklmnopqrstuvwxyz'.split(''), true), 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split(''), true), [
    '`',
    '~',
    '@',
    '#',
    '$',
    '%',
    '-',
    '+',
    '=',
    '!',
    '"',
    '&',
    '',
    '*',
    '(',
    ')',
    '_',
    '\\',
    '|',
    "'",
    ';',
    ':',
    '?',
    '/',
    '.',
    '>',
    '<',
    '¥',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '0',
    ' ',
], false);
export default allNeedCalChar;
