import { __spreadArray } from "tslib";
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useMemo, useState, useRef, useCallback } from 'react';
import { isFunction, getValueByPath } from './util';
export function useSecond(callback, deps) {
    var hasBeSecond = useRef(false);
    useMemo(function () {
        if (hasBeSecond.current) {
            callback instanceof Function && callback();
        }
        else {
            hasBeSecond.current = true;
        }
    }, __spreadArray([], deps, true));
}
export function useUnitValue(props, opts) {
    var _a;
    var _b = opts.defaultValuePropName, defaultValuePropName = _b === void 0 ? [] : _b, _c = opts.valuePropName, valuePropName = _c === void 0 ? 'value' : _c;
    var _d = useState((_a = props[valuePropName]) !== null && _a !== void 0 ? _a : defaultValuePropName), data = _d[0], setData = _d[1];
    useSecond(function () {
        setData(props[valuePropName]);
    }, [props[valuePropName]]);
    return [data, setData];
}
export function useControlValue(props, opts) {
    var _a, _b;
    var _c = opts.defaultValuePropName, defaultValuePropName = _c === void 0 ? 'defaultValue' : _c, _d = opts.valuePropName, valuePropName = _d === void 0 ? 'value' : _d, _e = opts.changePropName, changePropName = _e === void 0 ? 'onChange' : _e, defaultValue = opts.defaultValue;
    var _f = useState((_b = (_a = props === null || props === void 0 ? void 0 : props[valuePropName]) !== null && _a !== void 0 ? _a : props === null || props === void 0 ? void 0 : props[defaultValuePropName]) !== null && _b !== void 0 ? _b : defaultValue), data = _f[0], setData = _f[1];
    var setHandler = useCallback(function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var finalChange = getValueByPath(props, changePropName.split('.'), null);
        if (isFunction(finalChange)) {
            // @ts-ignore
            finalChange.apply(void 0, args);
        }
        // @ts-ignore
        !(valuePropName in (props || {})) && setData.apply(void 0, args);
    }, [props, valuePropName, changePropName]);
    useSecond(function () {
        setData(props[valuePropName]);
    }, [props === null || props === void 0 ? void 0 : props[valuePropName]]);
    // @ts-ignore
    return [data, setHandler];
}
export var useUpdate = function () {
    var _a = useState({}), setState = _a[1];
    return useCallback(function () { return setState({}); }, []);
};
