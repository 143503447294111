import $i18n from 'panda-i18n';
import isString from 'lodash/isString';
export default (function (value) {
    if (isString(value) &&
        !!value.match(/[\w\u4E00-\u9FA5]+([-+.][\w\u4E00-\u9FA5]+)*@[\w\u4E00-\u9FA5]+([-.][\w\u4E00-\u9FA5]+)*\.[\w\u4E00-\u9FA5]+([-.][\w\u4E00-\u9FA5]+)*/) &&
        value.length < 255) {
        return true;
    }
    return $i18n.get({
        id: 'NotALegalEmailAddress',
        dm: '不是合法的email地址',
        ns: 'CnForm',
    });
});
