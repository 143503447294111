/**
 * 根据文件大小文件上传进度补偿
 * 由于获取上传 token 属于文件上传的一部分，所以当 token 凭证获取成功时预设文件上传
 *
 * @param fileSize 文件大小，单位 bytes
 */
export function getFilePercentOffset(fileSize) {
    var fileSizeMB = fileSize / 1024 / 1024;
    if (fileSizeMB < 0.5)
        return 15;
    if (fileSizeMB < 1)
        return 10;
    if (fileSizeMB < 2)
        return 6;
    if (fileSizeMB < 5)
        return 4;
    if (fileSizeMB < 10)
        return 2;
    return 1;
}
