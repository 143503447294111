import { useI18n } from 'panda-i18n';
/**
 * 当 labelAlign 为 auto 的时候，根据是否是中文简繁自动生成上下左右布局
 */
export var useFormLabelAlign = function (labelAlign) {
    var i18n = useI18n();
    var lang = i18n.getLang();
    if (labelAlign === 'auto') {
        // 简繁 使用左右布局
        if (['zh-CN', 'zh-TW'].includes(lang))
            return 'left';
        // 非简繁 使用上下布局
        return 'top';
    }
    // 非 auto 模式使用用户设置的值
    return labelAlign;
};
