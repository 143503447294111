import * as React from 'react';
import { registerFormat } from '../../register';
import './index.scss';
registerFormat('CnInputTextArea', function (value) {
    if (!value) {
        return null;
    }
    return (React.createElement("div", null, value.split('\n').map(function (data) {
        return React.createElement("p", { className: "cn-format-cn-input-text-area" }, data);
    })));
});
