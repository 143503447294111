import isFunction from 'lodash/isFunction';
/**
 * 后序遍历树
 * @param {Iitem[]} list - 树形结构数据
 * @param {Function} handlerCallback - 回调函数
 * @param {number} level - 当前层级
 */
export default function dfsHouxu(list, handlerCallback, level) {
    if (level === void 0) { level = 1; }
    if (Array.isArray(list)) {
        list.forEach(function (item) {
            var _a;
            if (((_a = item === null || item === void 0 ? void 0 : item.children) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                dfsHouxu(item === null || item === void 0 ? void 0 : item.children, handlerCallback, level + 1);
            }
            if (isFunction(handlerCallback)) {
                handlerCallback(item, level);
            }
        });
    }
}
