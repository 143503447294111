/**
 * 将固定宽度映射表转换为字符到宽度的映射表
 * @param {string[]} allNeedCalChar - 所有需要计算宽度的字符
 * @returns {function} - 返回一个函数，该函数接受一个 HTMLElement 作为参数，返回字符到宽度的映射表
 */
export default function fixedWidthMapToCharMap(allNeedCalChar) {
    var hasCaled = false;
    var charToWMap = {};
    return function (measureEl) {
        if (hasCaled) {
            return charToWMap;
        }
        hasCaled = true;
        allNeedCalChar.forEach(function (char) {
            measureEl.textContent = char;
            charToWMap[char] = measureEl.offsetWidth;
        });
        return charToWMap;
    };
}
