import { __assign, __rest } from "tslib";
import * as React from 'react';
import { Radio as NextRadio } from '@fusion/lib';
import $i18n, { withI18n } from 'panda-i18n';
import isFunction from 'lodash/isFunction';
import { CnReadOnly } from '@/components/cn-read-only';
import { HocBaseComponents, Plugin, pluginManager, } from '@/components/cn-utils';
import { useCnRequest } from '@cainiaofe/cn-ui-common';
import locale from '@/locales';
var renderPreview = function (value, readOnlyProps) {
    var showValue = value;
    if (typeof value === 'boolean') {
        showValue = value
            ? $i18n.get({ id: 'Yes', dm: '是', ns: 'CnRadio' })
            : $i18n.get({ id: '31255237809541120.CNTM', dm: '否', ns: 'CnRadio' });
    }
    return React.createElement(CnReadOnly, __assign({ value: showValue, type: "enum" }, readOnlyProps));
};
var CnRadioGroupPlugin = function (props, context) {
    var readOnly = props.readOnly, readOnlyProps = props.readOnlyProps, _a = props.requestConfig, requestConfig = _a === void 0 ? {} : _a, otherProps = __rest(props, ["readOnly", "readOnlyProps", "requestConfig"]);
    var insertProps = {};
    var isRemoteDataSource = React.useMemo(function () {
        return !!((requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.url) || (requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.service));
    }, [requestConfig]);
    if (!requestConfig.resultFormatter && !requestConfig.formatResult) {
        requestConfig.formatResult = function (res) {
            var _a;
            if (Array.isArray(res)) {
                return res;
            }
            else if (Array.isArray(res === null || res === void 0 ? void 0 : res.data)) {
                return res.data;
            }
            else if (Array.isArray((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.dataSource)) {
                return res.data.dataSource;
            }
            return [];
        };
    }
    var cnRequestConfig = __assign({ ready: isRemoteDataSource }, requestConfig);
    // 兼容自定义service逻辑（通用hook不处理非cn-request的service）
    if (isFunction(requestConfig.service)) {
        cnRequestConfig.service = function (searchConfig) {
            var _a;
            return (_a = requestConfig.service(searchConfig)) === null || _a === void 0 ? void 0 : _a.then(function (data) {
                var dataSource = requestConfig.formatResult(data);
                return Array.isArray(dataSource) ? dataSource : [];
            });
        };
    }
    var _b = useCnRequest(cnRequestConfig).data, data = _b === void 0 ? [] : _b;
    if (isRemoteDataSource) {
        insertProps.dataSource = data;
    }
    return {
        props: __assign(__assign({ 'data-name': 'CnRadio', isPreview: readOnly, renderPreview: function (values) { return renderPreview(values, readOnlyProps); } }, insertProps), otherProps),
        context: context,
    };
};
export var CnRadioGroup = withI18n(React.forwardRef(function (props, ref) {
    var plugin = React.useMemo(function () {
        var plugin = new Plugin('CnRadio', pluginManager);
        plugin.setBasicsPlugin([CnRadioGroupPlugin]);
        plugin.setLocalPlugin(props === null || props === void 0 ? void 0 : props.usePlugin);
        plugin.setGlobalPlugin(pluginManager
            .getPluginsByComponentName('CnRadioGroup')
            .map(function (item) { return item.method; }));
        return plugin;
    }, []);
    var plugins = plugin.getPlugin();
    if (plugins.length === 0) {
        return React.createElement(NextRadio.Group, __assign({}, props, { ref: ref }));
    }
    return HocBaseComponents(NextRadio.Group, { props: props, plugins: plugins, ref: ref });
}), {
    locale: locale,
    componentName: 'CnRadio',
});
CnRadioGroup.displayName = 'CnRadioGroup';
CnRadioGroup.defaultProps = {
    readOnlyProps: {
        type: 'enum',
    },
};
