import { __awaiter, __generator } from "tslib";
/**
 * 动态加载js文件
 */
function loadJS(url) {
    return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        document.body.appendChild(script);
        script.onload = function () { return resolve(true); };
        script.onerror = function (err) { return reject(err); };
    });
}
var hasLoaded = false;
export var loadWebOfficeSDK = function () { return __awaiter(void 0, void 0, void 0, function () {
    var main;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (hasLoaded)
                    return [2 /*return*/];
                main = function () { return __awaiter(void 0, void 0, void 0, function () {
                    var sdkVersion, url;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                sdkVersion = '1.1.16';
                                url = "https://g.alicdn.com/IMM/office-js/".concat(sdkVersion, "/aliyun-web-office-sdk.min.js");
                                return [4 /*yield*/, loadJS(url)];
                            case 1:
                                hasLoaded = _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); };
                return [4 /*yield*/, main()];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
