import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Dropdown, Menu } from '@/components/fusion';
import { CnButton } from '@/components/cn-button';
import { CnIcon } from '@/components/cn-icon';
var OptBtns = function (props) {
    var _a = props.maxShowNum, maxShowNum = _a === void 0 ? 1 : _a, _b = props.dataSource, dataSource = _b === void 0 ? [] : _b, size = props.size;
    var visibleBtns = useMemo(function () {
        return dataSource.slice(0, maxShowNum - 1);
    }, [dataSource, maxShowNum]);
    var moreBtns = useMemo(function () {
        return dataSource.slice(maxShowNum - 1, dataSource.length);
    }, [dataSource, maxShowNum]);
    var renderMoreOpts = function (btns) {
        return (React.createElement(Dropdown, { v2: true, triggerType: "click", 
            // closable={false}
            // popupClassName='cn-ui-filter-btns-dropdown'
            // popupContainer={() => document.querySelector('.c-content-container')}
            // popupStyle={{ width: 150 }}
            trigger: React.createElement(CnButton, { iconSize: "medium", size: size, className: classNames('cn-ui-filter-icon-btn', "cn-ui-filter-icon-btn-".concat(size)) },
                React.createElement(CnIcon, { type: "ellipsis-more" })), align: "br" },
            React.createElement(Menu, null, btns.map(function (item, index) {
                var title = item.title, _a = item.onClick, onClick = _a === void 0 ? function () { } : _a, icon = item.icon, ref = item.ref;
                return (React.createElement(Menu.Item, { key: index, className: "opt-btn", ref: ref, 
                    // iconSize={'small'}
                    onClick: function () { return onClick(item); } },
                    React.createElement(CnIcon, { type: icon, size: "small" }),
                    " ",
                    title));
            }))));
    };
    var renderMoreBtns = function (btns) {
        var _a, _b, _c, _d, _e;
        if (btns.length > 1)
            return renderMoreOpts(btns);
        if (btns.length === 1) {
            return (React.createElement(CnButton, { className: "opt-btn", ref: (_a = btns[0]) === null || _a === void 0 ? void 0 : _a.ref, size: (_b = btns[0]) === null || _b === void 0 ? void 0 : _b.size, onClick: function () { var _a; return (_a = btns[0]) === null || _a === void 0 ? void 0 : _a.onClick(btns[0]); } }, ((_c = btns[0]) === null || _c === void 0 ? void 0 : _c.useIcon) ? React.createElement(CnIcon, { type: (_d = btns[0]) === null || _d === void 0 ? void 0 : _d.icon }) : (_e = btns[0]) === null || _e === void 0 ? void 0 : _e.title));
        }
        return null;
    };
    return (React.createElement(React.Fragment, null,
        renderMoreBtns(moreBtns),
        visibleBtns.map(function (item, index) {
            var icon = item.icon, ref = item.ref, _a = item.onClick, onClick = _a === void 0 ? function () { } : _a;
            return (React.createElement(CnButton, { ref: ref, size: size, className: "opt-btn", onClick: function () { return onClick(item); }, key: index }, item.useIcon ? React.createElement(CnIcon, { type: icon }) : item.title));
        })));
};
export default OptBtns;
