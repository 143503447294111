import { __spreadArray } from "tslib";
import { useMemo, useRef } from 'react';
import useUpdate from 'ahooks/lib/useUpdate';
import { useWillUpdate } from './use-will-update';
/**
 * 用于缓存函数，避免重复渲染
 * @param fn
 * @returns
 */
function usePersistFn(fn) {
    var fnRef = useRef(fn);
    fnRef.current = fn;
    var persistFn = useRef(function () { });
    if (!persistFn.current) {
        persistFn.current = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return fnRef.current.apply(this, args);
        };
    }
    return persistFn.current;
}
/**
 * 用于处理受控组件的值
 * @param props controlProps 控制组件的props
 * @param opt controlOpt 控制组件的选项
 * @returns 返回一个数组，第一个元素是当前的值，第二个元素是设置值的函数
 */
export function useControllableValue(props, opt) {
    // 处理选项
    props = props || {};
    var _a = (opt || {}), fallbackDefaultValue = _a.defaultValue, // 默认值
    _b = _a.valuePropName, // 默认值
    valuePropName = _b === void 0 ? 'value' : _b, // 值的属性名
    _c = _a.defaultValuePropName, // 值的属性名
    defaultValuePropName = _c === void 0 ? 'defaultValue' : _c, // 默认值的属性名
    _d = _a.triggerName, // 默认值的属性名
    triggerName = _d === void 0 ? 'onChange' : _d;
    // 获取值和默认值
    var value = props[valuePropName];
    var defaultValue = props[defaultValuePropName];
    // 初始化值
    var initialValue = useMemo(function () {
        var _a;
        return (_a = value !== null && value !== void 0 ? value : defaultValue) !== null && _a !== void 0 ? _a : fallbackDefaultValue;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // 强制更新
    var forceUpdate = useUpdate();
    // 值的引用
    var valueRef = useRef(initialValue);
    // 设置值的函数
    var setState = usePersistFn(function (val) {
        var rest = [];
        for (var _a = 1; _a < arguments.length; _a++) {
            rest[_a - 1] = arguments[_a];
        }
        var onChange = props[triggerName];
        if (!(valuePropName in props)) {
            valueRef.current = val;
            forceUpdate();
        }
        onChange instanceof Function && onChange.apply(void 0, __spreadArray([val], rest, false));
    });
    // 监听值的变化
    useWillUpdate(function () {
        valueRef.current = value;
    }, [value]);
    return [valueRef.current, setState];
}
