import React from 'react';
export function Colgroup(_a) {
    var descriptors = _a.descriptors;
    return (React.createElement("colgroup", null, descriptors.map(function (descriptor) {
        if (descriptor.type === 'blank') {
            return (React.createElement("col", { key: descriptor.blankSide, style: { width: descriptor.width } }));
        }
        return (React.createElement("col", { key: descriptor.colIndex, style: { width: descriptor.col.width } }));
    })));
}
