import { __assign, __rest } from "tslib";
import * as React from 'react';
import { Collapse as UiCollapse } from '@fusion/lib';
import cx from 'classnames';
import './index.scss';
export var CnCollapse = React.forwardRef(function (props, ref) {
    var _a;
    var className = props.className, otherProps = __rest(props, ["className"]);
    return (React.createElement(UiCollapse, __assign({ className: cx((_a = {
                'cn-ui-collapse': true
            },
            _a[className] = true,
            _a)), "data-name": "CnCollapse", ref: ref }, otherProps)));
});
CnCollapse.displayName = 'CnCollapse';
export var CnCollapsePanel = UiCollapse.Panel;
CnCollapsePanel.displayName = 'CnCollapsePanel';
/**
 * @deprecated
 * 推荐使用 CnCollapsePanel
 */
CnCollapse.Panel = CnCollapsePanel;
