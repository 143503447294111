import { withI18n } from 'panda-i18n';
import locale from '@/locales';
import { CnUploadView } from './components/cn-upload';
import { CnMiniUploadView, } from './components/cn-mini-upload';
import { CnDraggerUploadView, } from './components/cn-dragger-upload';
import './index.scss';
/**
 * @deprecated 组件已弃用，请使用 CnOSSUpload
 */
export var CnUpload = withI18n(CnUploadView, {
    locale: locale,
    componentName: 'CnUpload',
});
/**
 * @deprecated 组件已弃用，请使用 CnOSSUpload
 */
export var CnMiniUpload = withI18n(CnMiniUploadView, {
    locale: locale,
    componentName: 'CnUpload',
});
/**
 * @deprecated 组件已弃用，请使用 CnOSSUpload
 */
export var CnDraggerUpload = withI18n(CnDraggerUploadView, {
    locale: locale,
    componentName: 'CnUpload',
});
