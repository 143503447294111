import { __assign } from "tslib";
import * as React from 'react';
import { observer, useField } from '@formily/react';
import { CnOSSUpload as OSSUpload, } from '@/components/cn-oss-upload';
import { getFormUploadProps } from '@cainiaofe/cn-ui-common';
export var CnOSSUpload = observer(function (props) {
    var fileListRef = React.useRef([]);
    var field = useField();
    return (React.createElement(OSSUpload, __assign({}, props, getFormUploadProps({
        fileListRef: fileListRef,
        field: field,
        props: props,
        uploadErrMsg: '请等待上传结束',
    }))));
});
