// 计算文本宽度
export default function calcTextWidth(str, // 待计算的字符串
map, // 字符到宽度的映射表
baseFontSize, // 基础字体大小
radix) {
    if (radix === void 0) { radix = 1; }
    if (typeof str !== 'string') {
        str = String(str);
    }
    var strArr = str.split('');
    var count = 0;
    strArr.forEach(function (char) {
        var chineseRE = /[^ -~]/; // 匹配中文字符的正则表达式
        if (map[char]) {
            // 如果字符在映射表中有对应的宽度
            count += map[char];
        }
        else if (chineseRE.test(char)) {
            // 如果是中文字符
            count += baseFontSize;
        }
        else {
            // 其他字符
            count += 0.5 * baseFontSize;
        }
    });
    return count * radix; // 返回计算结果
}
