import { __spreadArray } from "tslib";
import { useEffect, useMemo } from 'react';
import { events } from '@/components/cn-utils';
import { setBreadcrumbDataSource } from '../utils/breadcrumb-data-source';
/**
 * 通过菜单数据计算出面包屑数据源
 */
export var useBreadcrumbDataSource = function (props) {
    var selectedKey = props.selectedKey, headerMenu = props.headerMenu, sideBarMenu = props.sideBarMenu;
    // 把菜单打平成 Map 结构
    var menuDataMap = useMemo(function () {
        var temp = {};
        var calcSidebarMenuDataMap = function (list, parent) {
            if (list === void 0) { list = []; }
            for (var _i = 0, list_1 = list; _i < list_1.length; _i++) {
                var item = list_1[_i];
                var children = item.children, key = item.key;
                temp[key] = {
                    parentKey: (parent === null || parent === void 0 ? void 0 : parent.key) || item.parentKey,
                    key: key,
                    text: item.text,
                    path: item.path,
                    type: item.type,
                };
                calcSidebarMenuDataMap(children, item);
            }
        };
        var calcHeaderMenuDataMap = function (list, parent) {
            if (list === void 0) { list = []; }
            for (var _i = 0, list_2 = list; _i < list_2.length; _i++) {
                var item = list_2[_i];
                var children = item.children, key = item.key;
                temp[key] = {
                    parentKey: (parent === null || parent === void 0 ? void 0 : parent.key) || item.parentKey,
                    key: key,
                    text: item.text,
                    path: item.path,
                    type: item.type,
                };
                calcHeaderMenuDataMap(children, item);
            }
        };
        try {
            calcSidebarMenuDataMap(sideBarMenu);
            calcHeaderMenuDataMap(headerMenu);
        }
        catch (_a) { }
        return temp;
    }, [headerMenu, sideBarMenu]);
    // 通过菜单数据计算出面包屑数据源
    var breadcrumbDataSource = useMemo(function () {
        if (!selectedKey)
            return [];
        var findMenu = function (dataSource) {
            var parentKey = dataSource[0].parentKey;
            var findTemp = menuDataMap[parentKey];
            if (!findTemp)
                return dataSource;
            return findMenu(__spreadArray([findTemp], dataSource, true));
        };
        try {
            var findTemp = menuDataMap[selectedKey];
            if (!findTemp)
                return [];
            var tempMenu = findMenu([findTemp]);
            var dataSource = tempMenu
                .filter(function (item) { return item.type !== 'blank'; })
                .map(function (item) { return ({
                link: item.path,
                children: item.text,
            }); });
            return dataSource;
        }
        catch (_a) { }
        return [];
    }, [menuDataMap, selectedKey]);
    useEffect(function () {
        setBreadcrumbDataSource(breadcrumbDataSource);
        events.emit('CnShell.menuSelected', breadcrumbDataSource);
        // 因为 面包屑 接收 CnShell.menuSelected，在组件发送之后，补充发送一次
        events.on('CnBreadcrumb.ready', function () {
            events.emit('CnShell.menuSelected', breadcrumbDataSource);
        });
    }, [breadcrumbDataSource]);
    return breadcrumbDataSource;
};
