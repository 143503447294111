// 将值转换为文本
function transformValueToString(val, // 需要转换的值
transform) {
    if (transform instanceof Function) { // 如果有转换函数
        return transform(val); // 调用转换函数
    }
    if (typeof val === 'string') { // 如果是字符串
        return val; // 直接返回
    }
    if (typeof val === 'number') { // 如果是数字
        return String(val); // 转换为字符串并返回
    }
    if (Array.isArray(val)) { // 如果是数组
        return val.map(function (item) { return transformValueToString(item, null); }).join('\n'); // 递归调用 handlerValToText 处理每个元素，并用换行符连接
    }
    return ''; // 其他情况返回空字符串
}
export default transformValueToString;
