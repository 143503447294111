import calcTextWidth from './calc-text-width';
import defaultAllNeedCalcChar from './ascii-width';
import fixedWidthMapToCharMap from './fixed-width-map-to-char-map';
import { getValueOrExecuteFunction } from './utils';
// 原则上，基于本地环境测试各个字符大小的操作只做一次，其他情况，全部按照比例来算
/**
 * 根据字符到宽度的映射表和字体大小，生成一个比较函数，用于计算文本宽度和附加宽度的和
 * @param {expendObj} charToWMap - 字符到宽度的映射表
 * @param {number} cellFontSize - 字体大小
 * @returns {function} - 返回一个比较函数，该函数接受一个字符串和附加宽度作为参数，返回文本宽度和附加宽度的和
 */
function getCompareCal(charToWMap, cellFontSize) {
    return function (text, additionWidth) {
        if (additionWidth === void 0) { additionWidth = 0; }
        return calcTextWidth(text, charToWMap, cellFontSize) + additionWidth;
    };
}
export function calcColumnWidths(columns, dataSource, opts) {
    var _a = Object.assign({
        headerFontSize: 12,
        cellFontSize: 12,
        sampleRate: 1,
        additionalWidth: 18,
        maxWidth: 460,
        getCustomWidth: function (w) { return w; },
    }, opts), headerFontSize = _a.headerFontSize, cellFontSize = _a.cellFontSize, sampleRate = _a.sampleRate, additionalWidth = _a.additionalWidth, maxWidth = _a.maxWidth, getCustomWidth = _a.getCustomWidth, allNeedCalChar = _a.allNeedCalChar;
    var widths = {};
    var maxLengthMap = {};
    var maxWidthMap = {};
    var keys = [];
    var cellTextCalRulesMap = {};
    var cellAdditionWidthCalFunctions = {}; // 配置保存
    var cellAdditionalWidths = {};
    // 隐藏 dom 仅用于计算
    var measureEl = document.createElement('span');
    measureEl.style.width = 'fit-content';
    measureEl.style.cssText = "\n  position: fixed;\n  top: -9999px;\n  left: -9999px;\n  z-index: -100;\n  white-space: pre-wrap;\n  font-weight: bold;\n";
    measureEl.style.fontSize = "".concat(headerFontSize, "px");
    document.body.appendChild(measureEl);
    var charToWMap = fixedWidthMapToCharMap(allNeedCalChar || defaultAllNeedCalcChar)(measureEl);
    // eslint-disable-next-line no-unused-expressions
    columns === null || columns === void 0 ? void 0 : columns.forEach(function (item) {
        var _a;
        var key = item.key, code = item.code, name = item.name, dataIndex = item.dataIndex, title = item.title, contentWidthRule = item.contentWidthRule, itemMaxWidth = item.maxWidth;
        var _b = (contentWidthRule === null || contentWidthRule === void 0 ? void 0 : contentWidthRule.header) || {}, headerCalText = _b.text, headerAdditionWidth = _b.additionWidth;
        var cellRule = (_a = contentWidthRule === null || contentWidthRule === void 0 ? void 0 : contentWidthRule.cell) !== null && _a !== void 0 ? _a : contentWidthRule;
        var _c = cellRule || {}, calText = _c.text, cellAdditionWidth = _c.additionWidth;
        // 存储主键优先级 key > code > dataIndex
        var usedKey = (key || code || dataIndex);
        // 添加header复杂计算方式contentWidthRule
        measureEl.textContent =
            headerCalText instanceof Function ? headerCalText(item) : name !== null && name !== void 0 ? name : title;
        widths[usedKey] =
            measureEl.offsetWidth +
                (parseInt(getValueOrExecuteFunction(headerAdditionWidth, [])) || 0);
        keys.push(usedKey);
        // 具备关闭能力，itemMaxWidth === false 则表示不存在最大宽度， 也可以自定义最大宽度 itemMaxWidth === number
        if (itemMaxWidth === false) {
            maxWidthMap[usedKey] = Infinity;
        }
        else {
            // 默认最大宽度
            maxWidthMap[usedKey] = itemMaxWidth !== null && itemMaxWidth !== void 0 ? itemMaxWidth : maxWidth;
        }
        if (calText) {
            // 计算函数
            cellTextCalRulesMap[usedKey] = calText;
        }
        if (parseInt(cellAdditionWidth) > 0) {
            cellAdditionalWidths[usedKey] = parseInt(cellAdditionWidth);
        }
        else if (cellAdditionWidth instanceof Function) {
            cellAdditionWidthCalFunctions[usedKey] = cellAdditionWidth;
        }
    });
    var step = Math.ceil(1 / sampleRate);
    var calcCompareWidth = getCompareCal(charToWMap, cellFontSize);
    var _loop_1 = function (i) {
        var item = dataSource[i];
        keys.forEach(function (key) {
            var _a;
            var cellRuleTextFunc = cellTextCalRulesMap[key];
            // additionCache 为function，则计算逻辑，则应该被包含在compare逻辑中
            var additionCalFunction = cellAdditionWidthCalFunctions[key];
            var nowcellAddtionWidth = cellAdditionalWidths[key];
            var val = item[key];
            if (cellRuleTextFunc instanceof Function ||
                additionCalFunction ||
                nowcellAddtionWidth) {
                // 获取需要计算的文本
                var text = cellRuleTextFunc instanceof Function
                    ? cellRuleTextFunc(val, i, item)
                    : val !== null && val !== void 0 ? val : '';
                // 获取需要计算的附加宽度
                var cellAdditionalWidth = additionCalFunction instanceof Function
                    ? getValueOrExecuteFunction(additionCalFunction, [val, i, item])
                    : nowcellAddtionWidth;
                if ((!maxLengthMap[key] && !cellAdditionalWidths[key]) ||
                    // 和之前存的比较，取大的
                    calcCompareWidth(text, cellAdditionalWidth) >
                        calcCompareWidth(maxLengthMap[key], (_a = cellAdditionalWidths[key]) !== null && _a !== void 0 ? _a : 0)) {
                    // 这里的逻辑，是记录目前某列宽度最大行的一些信息，不断对比
                    maxLengthMap[key] = text;
                    cellAdditionalWidths[key] = cellAdditionalWidth;
                }
                return;
            }
            if (val == null)
                return;
            if (Array.isArray(val)) {
                val = val.reduce(function (prev, curr) {
                    return calcTextWidth(curr, charToWMap, cellFontSize) >
                        calcTextWidth(prev, charToWMap, cellFontSize)
                        ? curr
                        : prev;
                }, 0);
            }
            if (!maxLengthMap[key] ||
                calcTextWidth(val, charToWMap, cellFontSize) >
                    calcTextWidth(maxLengthMap[key], charToWMap, cellFontSize)) {
                maxLengthMap[key] = val;
            }
        });
    };
    for (var i = 0; i < (dataSource === null || dataSource === void 0 ? void 0 : dataSource.length); i += step) {
        _loop_1(i);
    }
    measureEl.style.fontSize = "".concat(cellFontSize, "px");
    keys.forEach(function (key) {
        var _a;
        var maxLengthStr = maxLengthMap[key];
        var cellAdditionalWidth = cellAdditionalWidths[key];
        var itemMaxWidth = maxWidthMap[key];
        measureEl.textContent = String(maxLengthStr !== null && maxLengthStr !== void 0 ? maxLengthStr : '');
        var width = Math.min(Math.max(widths[key], // 头部宽度
        measureEl.offsetWidth + (parseInt(cellAdditionalWidth) || 0)) + additionalWidth, itemMaxWidth);
        widths[key] = (_a = getCustomWidth(width, key)) !== null && _a !== void 0 ? _a : width;
    });
    document.body.removeChild(measureEl);
    return widths;
}
