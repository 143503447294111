import { __assign, __awaiter, __generator } from "tslib";
import isEmpty from 'lodash/isEmpty';
import { packRequest } from './pack-request';
import { getStandardData } from './get-standard-data';
export function getRequestService(requestConfig) {
    var _this = this;
    if (!requestConfig)
        return undefined;
    var serviceProps = requestConfig.service, _a = requestConfig.method, method = _a === void 0 ? 'GET' : _a, paramsProps = requestConfig.params, dataProps = requestConfig.data, _b = requestConfig.formatParam, formatParamProps = _b === void 0 ? function (data) { return data; } : _b, formatResultProps = requestConfig.formatResult;
    var formatParam = formatParamProps || requestConfig.paramsFormatter;
    var formatResult = formatResultProps || requestConfig.resultFormatter;
    /**
     * 兼容历史逻辑，该逻辑未来会删除
     */
    var compatibleOldData = function (res) {
        if (isEmpty(res)) {
            return res;
        }
        if (typeof formatResult === 'function') {
            // @ts-ignore 历史兼容代码类型不匹配，忽略校验
            return formatResult(res);
        }
        if (!isEmpty(res === null || res === void 0 ? void 0 : res.data)) {
            return res === null || res === void 0 ? void 0 : res.data;
        }
    };
    var service = function (runParams) { return __awaiter(_this, void 0, void 0, function () {
        var response, responseKeys, params, data, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!serviceProps) return [3 /*break*/, 2];
                    return [4 /*yield*/, serviceProps(runParams)];
                case 1:
                    response = _a.sent();
                    responseKeys = Object.keys(response);
                    if (responseKeys.includes('request') &&
                        responseKeys.includes('status') &&
                        responseKeys.includes('headers') &&
                        responseKeys.includes('config')) {
                        return [2 /*return*/, compatibleOldData(response)];
                    }
                    /** end compatible */
                    return [2 /*return*/, response];
                case 2:
                    params = (method === null || method === void 0 ? void 0 : method.toLocaleUpperCase()) === 'GET' && typeof formatParam === 'function'
                        ? formatParam(__assign(__assign({}, runParams), paramsProps))
                        : paramsProps;
                    data = (method === null || method === void 0 ? void 0 : method.toLocaleUpperCase()) === 'POST' &&
                        typeof formatParam === 'function'
                        ? formatParam(__assign(__assign({}, runParams), dataProps))
                        : dataProps;
                    return [4 /*yield*/, packRequest(__assign(__assign({}, requestConfig), { params: params, data: data }))];
                case 3:
                    res = _a.sent();
                    return [2 /*return*/, getStandardData(res, formatResult)];
            }
        });
    }); };
    return service;
}
