import { request, getRequest } from '@cainiaofe/cn-request';
export function packRequest(options) {
    if (!getRequest()) {
        // eslint-disable-next-line no-console
        console.error('组件使用默认实例中...请先初始化项目cn-request,切勿发布到生产环境');
    }
    return request(options).then(function (result) {
        if ('request' in result && 'config' in result) {
            return result.data;
        }
        return result;
    });
}
