import { __assign } from "tslib";
// import $i18n from 'panda-i18n';
// import { Message } from '@/components/fusion';
import request from './request';
export var getSubDeptByKey = function (params) {
    var mokelay = params.mokelay, _a = params.host, host = _a === void 0 ? '' : _a, deptNo = params.deptNo;
    var result = mokelay
        ? request({
            url: "".concat(host, "/cmdp/hysmab/getSubDepartmentInfo"),
            headers: {
                mokelay: true,
            },
            method: 'post',
            withCredentials: true,
            data: {
                departmentCode: deptNo || '',
                isCommonRequest: true,
            },
        })
        : request({
            url: "".concat(host, "/cn-common-proxy/post"),
            method: 'post',
            withCredentials: true,
            data: {
                path: '/cmdp/hysmab/getSubDepartmentInfo',
                params: { departmentCode: deptNo || '', isCommonRequest: true },
            },
        });
    return result.then(function (res) {
        var _a, _b;
        var resultData = Array.isArray((_a = res.data) === null || _a === void 0 ? void 0 : _a.data)
            ? (_b = res.data) === null || _b === void 0 ? void 0 : _b.data
            : res === null || res === void 0 ? void 0 : res.data;
        return resultData
            .filter(function (item) { return (item === null || item === void 0 ? void 0 : item.enabled) !== false; })
            .map(function (item) { return (__assign(__assign({}, item), { label: item.name, value: item.code, deptName: item.name, deptNo: item.code, departmentFullName: item.fullName || item.name, 
            // fullName: item.fullName || item.name,
            fullName: "".concat(item.fullName || item.name).replace(/(^菜鸟集团-菜鸟-菜鸟-)|(菜鸟集团-菜鸟-菜鸟-$)/g, ''), isLeaf: !item.hasSubDepartment })); });
    });
};
export var getDeptByKey = function (params) {
    var mokelay = params.mokelay, _a = params.host, host = _a === void 0 ? '' : _a;
    var deptNo = '';
    if (Array.isArray(params.deptNo)) {
        deptNo = params.deptNo.join(',');
    }
    else {
        deptNo = params.deptNo;
    }
    var result = mokelay
        ? request({
            url: "".concat(host, "/cmdp/hysmab/getDepartmentInfo"),
            headers: {
                mokelay: true,
            },
            withCredentials: true,
            method: 'POST',
            data: {
                departmentCode: deptNo,
                isCommonRequest: true,
            },
        })
        : request({
            url: "".concat(host, "/cn-common-proxy/post"),
            method: 'post',
            withCredentials: true,
            data: {
                path: '/cmdp/hysmab/getDepartmentInfo',
                params: { departmentCode: deptNo, isCommonRequest: true },
            },
        });
    return result.then(function (res) {
        var _a, _b;
        var resultData = Array.isArray((_a = res.data) === null || _a === void 0 ? void 0 : _a.data)
            ? (_b = res.data) === null || _b === void 0 ? void 0 : _b.data
            : res === null || res === void 0 ? void 0 : res.data;
        if (!Array.isArray(resultData))
            return [];
        return resultData
            .filter(function (item) { return (item === null || item === void 0 ? void 0 : item.enabled) !== false; })
            .map(function (item) { return (__assign(__assign({}, item), { label: item.name, value: item.code, deptName: item.name, deptNo: item.code, departmentFullName: item.fullName || item.name, 
            // fullName: item.fullName || item.name,
            fullName: "".concat(item.fullName || item.name).replace(/(^菜鸟集团-菜鸟-菜鸟-)|(菜鸟集团-菜鸟-菜鸟-$)/g, ''), isLeaf: !item.hasSubDepartment })); });
    });
};
export var getDepartmentsByKeyword = function (params) {
    var _a = params.searchKey, searchKey = _a === void 0 ? '' : _a, mokelay = params.mokelay, _b = params.host, host = _b === void 0 ? '' : _b;
    var result = mokelay
        ? request({
            url: "".concat(host, "/cmdp/hysmab/searchDepartmentInfo"),
            headers: {
                mokelay: true,
            },
            method: 'POST',
            withCredentials: true,
            data: {
                searchKey: searchKey,
                isCommonRequest: true,
            },
        })
        : request({
            url: "".concat(host, "/cn-common-proxy/post"),
            method: 'post',
            withCredentials: true,
            data: {
                path: '/cmdp/hysmab/searchDepartmentInfo',
                params: { searchKey: searchKey, isCommonRequest: true },
            },
        });
    return result.then(function (res) {
        var _a, _b;
        var resultData = Array.isArray((_a = res.data) === null || _a === void 0 ? void 0 : _a.data)
            ? (_b = res.data) === null || _b === void 0 ? void 0 : _b.data
            : res === null || res === void 0 ? void 0 : res.data;
        return resultData
            .filter(function (item) { return (item === null || item === void 0 ? void 0 : item.enabled) !== false; })
            .map(function (item) { return (__assign(__assign({}, item), { label: item.name, value: item.code, deptName: item.name, deptNo: item.code, departmentFullName: item.fullName || item.name, fullName: "".concat(item.fullName || item.name).replace(/(^菜鸟集团-菜鸟-菜鸟-)|(菜鸟集团-菜鸟-菜鸟-$)/g, ''), isLeaf: !item.hasSubDepartment })); });
    });
};
