import { __assign } from "tslib";
import './index.scss';
import $i18n from 'panda-i18n';
import * as React from 'react';
import cls from 'classnames';
import { useField, observer, useFieldSchema, RecursionField, } from '@formily/react';
import { CnCardSubAreaCard, } from '@/components/cn-card';
import { ArrayBase } from '../array-base';
import { usePrefixCls } from '@/form/__builtins__';
import { CnIcon } from '@/components/cn-icon';
import { CnButton } from '@/components/cn-button';
import { CnFormGrid } from '@/components/cn-form-grid';
import pick from 'lodash/pick';
import isFunction from 'lodash/isFunction';
import { CnBox } from '@/components/cn-box';
var isAdditionComponent = function (schema) {
    var _a, _b;
    return ((_b = (_a = schema['x-component']) === null || _a === void 0 ? void 0 : _a.indexOf) === null || _b === void 0 ? void 0 : _b.call(_a, 'Addition')) > -1;
};
var isRemoveComponent = function (schema) {
    var _a, _b;
    return ((_b = (_a = schema['x-component']) === null || _a === void 0 ? void 0 : _a.indexOf) === null || _b === void 0 ? void 0 : _b.call(_a, 'Remove')) > -1;
};
var isCopyComponent = function (schema) {
    var _a, _b;
    return ((_b = (_a = schema['x-component']) === null || _a === void 0 ? void 0 : _a.indexOf) === null || _b === void 0 ? void 0 : _b.call(_a, 'Copy')) > -1;
};
var isMoveUpComponent = function (schema) {
    var _a, _b;
    return ((_b = (_a = schema['x-component']) === null || _a === void 0 ? void 0 : _a.indexOf) === null || _b === void 0 ? void 0 : _b.call(_a, 'MoveUp')) > -1;
};
var isMoveDownComponent = function (schema) {
    var _a, _b;
    return ((_b = (_a = schema['x-component']) === null || _a === void 0 ? void 0 : _a.indexOf) === null || _b === void 0 ? void 0 : _b.call(_a, 'MoveDown')) > -1;
};
var isOperationComponent = function (schema) {
    return (isAdditionComponent(schema) ||
        isRemoveComponent(schema) ||
        isCopyComponent(schema) ||
        isMoveDownComponent(schema) ||
        isMoveUpComponent(schema));
};
export var CnArraySubAreaCard = observer(function (props) {
    var _a;
    var field = useField();
    var schema = useFieldSchema();
    var dataSource = Array.isArray(field.value) ? field.value : [];
    var prefixCls = usePrefixCls('formily-array-sub-area-card', props);
    var readOnly = (_a = field === null || field === void 0 ? void 0 : field.form) === null || _a === void 0 ? void 0 : _a.readOnly;
    var renderItems = function () {
        return dataSource === null || dataSource === void 0 ? void 0 : dataSource.map(function (item, index) {
            var _a;
            var items = Array.isArray(schema.items)
                ? schema.items[index] || schema.items[0]
                : schema.items;
            var title = props.title || field.title;
            if (isFunction(props === null || props === void 0 ? void 0 : props.title)) {
                title = props.title(item, index);
            }
            var onItemClick = function (event) {
                isFunction(props === null || props === void 0 ? void 0 : props.onItemClick) && props.onItemClick(event, item, index);
            };
            var content = (React.createElement(RecursionField, { schema: items, name: index, filterProperties: function (schema) {
                    if (isOperationComponent(schema))
                        return false;
                    return true;
                } }));
            var operations = (React.createElement(RecursionField, { schema: items, name: index, filterProperties: function (schema) {
                    if (!isOperationComponent(schema))
                        return false;
                    return true;
                }, onlyRenderProperties: true }));
            return (React.createElement(ArrayBase.Item, { key: index, index: index, record: function () { var _a; return (_a = field.value) === null || _a === void 0 ? void 0 : _a[index]; } },
                React.createElement(CnCardSubAreaCard, __assign({ action: React.createElement(CnBox, { direction: "row", spacing: 8, align: "center" },
                        isFunction(props.titleSlot) &&
                            props.titleSlot((_a = field.value) === null || _a === void 0 ? void 0 : _a[index], index),
                        !readOnly && props.allowRemove && (React.createElement(CnButton, { text: true, className: "".concat(prefixCls, "-action-remove"), onClick: function (e) {
                                var _a;
                                e.stopPropagation();
                                (_a = field === null || field === void 0 ? void 0 : field.remove) === null || _a === void 0 ? void 0 : _a.call(field, index);
                            } },
                            React.createElement(CnIcon, { type: "delete" }),
                            $i18n.get({
                                id: '31255239516622848.CNTM',
                                dm: '删除',
                                ns: 'CnForm',
                            }))),
                        operations) }, pick(props, [
                    'title',
                    'style',
                    'className',
                    'action',
                    'children',
                ]), { className: cls("".concat(prefixCls, "-item"), props.className), title: title, onClick: onItemClick }), content)));
        });
    };
    var renderAddition = function () {
        return schema.reduceProperties(function (addition, schema, key) {
            if (isAdditionComponent(schema)) {
                return React.createElement(RecursionField, { schema: schema, name: key });
            }
            return addition;
        }, null);
    };
    return (React.createElement(ArrayBase, null,
        React.createElement(CnFormGrid, __assign({}, pick(props, [
            'gridItemStyle',
            'columnGap',
            'rowGap',
            'cols',
            'slot',
            'area',
            'responsive',
            'onColumnChange',
        ])),
            renderItems(),
            React.createElement("div", { colSpan: "full" }, renderAddition()))));
});
CnArraySubAreaCard.displayName = 'CnArraySubAreaCard';
CnArraySubAreaCard.defaultProps = {
    allowRemove: true,
    cols: 1,
};
ArrayBase.mixin(CnArraySubAreaCard);
