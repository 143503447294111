import { __assign, __rest } from "tslib";
import './index.scss';
import React from 'react';
import classNames from 'classnames';
import { useUploadState } from '@cainiaofe/cn-ui-common';
import { UploadListItem } from '../upload-list-item';
export var UploadList = function (props) {
    var _a = useUploadState(), getValue = _a.getValue, rootProps = _a.props;
    var limit = rootProps.limit;
    var showUploadList = props.showUploadList, restProps = __rest(props, ["showUploadList"]);
    if (!showUploadList)
        return null;
    var fileList = getValue();
    if (!fileList.length)
        return null;
    if (limit === 1) {
        return (React.createElement("div", { className: classNames('cn-ui-oss-upload-single', 'cn-ui-oss-upload-list-normal') },
            React.createElement(UploadListItem, { file: fileList[0] })));
    }
    return (React.createElement("div", { className: classNames('cn-ui-oss-upload-list', 'cn-ui-oss-upload-list-normal') }, fileList.map(function (item, index) { return (React.createElement(UploadListItem, __assign({ key: item.key || "".concat(item.name, "_").concat(index) }, restProps, { file: item }))); })));
};
