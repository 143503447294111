import { __assign } from "tslib";
/**
 * 用于修改表格列的单元格属性
 * @param {ICnColumn[]} columns - 表格列
 * @param {Function} modifyCellProps - 修改单元格属性的函数
 * @returns {ICnColumn[]} 修改后的表格列
 */
function modifyColumns(columns, modifyCellProps) {
    return columns.map(function (col) {
        if (col.children) {
            col.children = modifyColumns(col.children, modifyCellProps); // 修改子列
        }
        var sourceCellProps = (col === null || col === void 0 ? void 0 : col.getCellProps) || (function () { return ({}); }); // 获取单元格属性
        col.getCellProps = function (value, record, rowIndex) {
            var prevCellProps = sourceCellProps(value, record, rowIndex); // 获取之前的单元格属性
            return modifyCellProps(value, record, rowIndex, prevCellProps); // 修改单元格属性
        };
        return col;
    });
}
/**
 * 生成一个表格管道函数，用于为表格的奇数行添加斑马纹效果
 * @param {string} zebraColor - 斑马纹颜色
 * @returns {Function} 表格管道函数
 */
export function zebraPipeline(zebraColor) {
    if (zebraColor === void 0) { zebraColor = 'var(--row-zbcolor)'; }
    return function (pipeline) {
        var sourceCols = pipeline.getColumns();
        var modifiedCols = modifyColumns(sourceCols, 
        /**
         * 修改单元格属性的函数
         * @param {string} value - 单元格的值
         * @param {ICnColumn} record - 单元格所在行的数据
         * @param {number} rowIndex - 单元格所在行的索引
         * @param {Record<string, any>} prevCellProps - 单元格之前的属性
         * @returns {Record<string, any>} 修改后的单元格属性
         */
        function (value, record, rowIndex, prevCellProps) {
            if (rowIndex % 2 === 1) {
                return Object.assign({}, prevCellProps, {
                    style: __assign({ '--bgcolor': zebraColor }, ((prevCellProps === null || prevCellProps === void 0 ? void 0 : prevCellProps.style) || {})),
                });
            }
            return prevCellProps;
        });
        return pipeline.columns(modifiedCols);
    };
}
