import { __assign } from "tslib";
import $i18n from 'panda-i18n';
import { mergeCellProps } from '../../utils';
import isFunction from 'lodash/isFunction';
/**
 * 表格数据处理流水线。TablePipeline 提供了表格数据处理过程中的一些上下方与工具方法，包括……
 *
 * 1. ctx：上下文环境对象，step（流水线上的一步）可以对 ctx 中的字段进行读写。
 * ctx 中部分字段名称有特定的含义（例如 primaryKey 表示行的主键），使用自定义的上下文信息时注意避开这些名称。
 *
 * 2. rowPropsGetters：getRowProps 回调队列，step 可以通过 pipeline.appendRowPropsGetter 向队列中追加回调函数，
 *   在调用 pipeline.props() 队列中的所有函数会组合形成最终的 getRowProps
 *
 * 3. 当前流水线的状态，包括 dataSource, columns, rowPropsGetters 三个部分
 *
 * 4. snapshots，调用 pipeline.snapshot(name) 可以记录当前的状态，后续可以通过 name 来读取保存的状态
 * */
var TablePipeline = /** @class */ (function () {
    function TablePipeline(_a) {
        var state = _a.state, setState = _a.setState, doUpdate = _a.doUpdate, ctx = _a.ctx;
        this._snapshots = {};
        this._rowPropsGetters = [];
        this.ctx = {
            components: {},
            indents: TablePipeline.defaultIndents,
        };
        this.state = state;
        this.doUpdate = doUpdate;
        this.setState = setState;
        Object.assign(this.ctx, ctx);
    }
    TablePipeline.prototype.appendRowPropsGetter = function (getter) {
        this._rowPropsGetters.push(getter);
        return this;
    };
    TablePipeline.prototype.getDataSource = function (name) {
        if (name == null) {
            return this._dataSource;
        }
        else {
            return this._snapshots[name].dataSource;
        }
    };
    TablePipeline.prototype.getColumns = function (name) {
        if (name == null) {
            return this._columns || [];
        }
        else {
            return this._snapshots[name].columns;
        }
    };
    TablePipeline.prototype.getStateAtKey = function (stateKey, defaultValue) {
        var _a;
        return (_a = this.state[stateKey]) !== null && _a !== void 0 ? _a : defaultValue;
    };
    /** 将 stateKey 对应的状态设置为 partialState  */
    TablePipeline.prototype.setStateAtKey = function (stateKey, partialState, extraInfo) {
        this.setState(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[stateKey] = partialState, _a)));
        }, stateKey, partialState, extraInfo);
    };
    /** 确保 primaryKey 已被设置，并返回 primaryKey  */
    TablePipeline.prototype.ensurePrimaryKey = function (hint) {
        if (this.ctx.primaryKey == null) {
            throw new Error(hint
                ? $i18n.get({
                    id: 'PrimaryKeyMustBeSetBeforeUsing_1275473647',
                    dm: '使用 {hint} 之前必须先设置 primaryKey',
                    ns: 'CnBaseTable',
                }, { hint: hint })
                : $i18n.get({
                    id: 'PrimaryKeyMustBeSetFirst',
                    dm: '必须先设置 primaryKey',
                    ns: 'CnBaseTable',
                }));
        }
        return this.ctx.primaryKey;
    };
    /** 设置流水线的输入数据 */
    TablePipeline.prototype.input = function (input) {
        if (this._dataSource != null || this._columns != null) {
            throw new Error($i18n.get({
                id: 'InputCannotBeCalledTwice',
                dm: 'input 不能调用两次',
                ns: 'CnBaseTable',
            }));
        }
        this._dataSource = input.dataSource;
        this._columns = input.columns;
        this.snapshot('input');
        return this;
    };
    TablePipeline.prototype.update = function () {
        if (isFunction(this.doUpdate)) {
            this.doUpdate();
        }
    };
    /** 设置 dataSource */
    TablePipeline.prototype.dataSource = function (rows) {
        this._dataSource = rows;
        return this;
    };
    /** 设置 columns */
    TablePipeline.prototype.columns = function (cols) {
        this._columns = cols;
        return this;
    };
    /** 设置主键 */
    TablePipeline.prototype.primaryKey = function (key) {
        this.ctx.primaryKey = key;
        return this;
    };
    // locale(val: Locale) {
    //   this.ctx.locale = val;
    //   return this;
    // }
    // getLocale() {
    //   return this.ctx.locale ?? {};
    // }
    /** 保存快照 */
    TablePipeline.prototype.snapshot = function (name) {
        this._snapshots[name] = {
            dataSource: this._dataSource,
            columns: this._columns,
            rowPropsGetters: this._rowPropsGetters.slice(),
        };
        return this;
    };
    /** 使用 pipeline 功能拓展 */
    TablePipeline.prototype.use = function (step) {
        return step(this);
    };
    /** 转换 dataSource */
    TablePipeline.prototype.mapDataSource = function (mapper) {
        return this.dataSource(mapper(this.getDataSource()));
    };
    /** 转换 columns */
    TablePipeline.prototype.mapColumns = function (mapper) {
        return this.columns(mapper(this.getColumns()));
    };
    /** 获取 BaseTable 的 props，结果中包含 dataSource/columns/primaryKey/getRowProps 四个字段 */
    TablePipeline.prototype.getProps = function () {
        var _this = this;
        var result = {
            dataSource: this._dataSource,
            columns: this._columns,
        };
        if (this.ctx.primaryKey) {
            result.primaryKey = this.ctx.primaryKey;
        }
        if (this._rowPropsGetters.length > 0) {
            result.getRowProps = function (row, rowIndex) {
                return _this._rowPropsGetters.reduce(function (res, get) {
                    return mergeCellProps(res, get(row, rowIndex));
                }, {});
            };
        }
        return result;
    };
    TablePipeline.defaultIndents = {
        iconIndent: -8,
        iconWidth: 16,
        iconGap: 0,
        indentSize: 16,
    };
    return TablePipeline;
}());
export { TablePipeline };
