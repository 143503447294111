import { useCallback } from 'react';
import { fileToObject, getFileIndex } from '../../../utils';
export var useOnSuccess = function (props) {
    var setValue = props.setValue, onChangeRef = props.onChangeRef, formatterRef = props.formatterRef;
    return useCallback(function (response, _file, xhr) {
        var _a, _b;
        var file = fileToObject(_file, { status: 'done', xhr: xhr });
        file.response = ((_a = formatterRef.current) === null || _a === void 0 ? void 0 : _a.call(formatterRef, response, file)) || response;
        if (file.response) {
            file.url = file.response.url;
            file.downloadUrl = file.response.downloadUrl;
        }
        var value = [];
        setValue(function (prev) {
            var fileIndex = getFileIndex(file, prev);
            value = prev.map(function (item, index) {
                return index === fileIndex ? file : item;
            });
            return value;
        });
        (_b = onChangeRef.current) === null || _b === void 0 ? void 0 : _b.call(onChangeRef, value, file);
    }, [formatterRef, setValue, onChangeRef]);
};
