import { __rest } from "tslib";
import React, { useEffect, useState } from 'react';
import { Overlay } from '@fusion/lib';
import { CnIcon } from '@/components/cn-icon';
import isFunction from 'lodash/isFunction';
var Popup = Overlay.Popup;
var DEFAULT_AVATAR = 'https://img.alicdn.com/imgextra/i1/O1CN01JkTVma1u660f1TAPV_!!6000000005987-2-tps-112-112.png';
var User = function (_a) {
    var _b;
    var userSlot = _a.userSlot, others = __rest(_a, ["userSlot"]);
    // api 兼容
    var _c = useState(), user = _c[0], setUser = _c[1];
    // popup 显示状态
    var _d = useState(false), popupVisible = _d[0], setPopupVisible = _d[1];
    // 用户信息由外部传入
    useEffect(function () {
        setUser(others.user);
    }, [others.user]);
    var userInfo = (React.createElement("div", { className: "user-info" },
        React.createElement("img", { alt: "avatar", width: "18", height: "18", src: (_b = user === null || user === void 0 ? void 0 : user.img) !== null && _b !== void 0 ? _b : DEFAULT_AVATAR, className: "avatar" }),
        user && user.displayname && (React.createElement("span", { className: "displayname" },
            user.displayname,
            userSlot && React.createElement(CnIcon, { type: "triangle-down-fill", size: "small" })))));
    return (React.createElement(Popup, { v2: true, visible: popupVisible, onVisibleChange: setPopupVisible, placement: "br", trigger: userInfo, triggerType: "click", offset: [4, 14], className: "shell-user-popup", followTrigger: true },
        React.createElement("div", { className: "user-overlay" },
            React.isValidElement(userSlot) && userSlot,
            isFunction(userSlot) &&
                userSlot(function () {
                    setPopupVisible(false);
                }))));
};
User.defaultProps = {};
export default User;
