import { registerValidateRules } from '@formily/validator';
import isEmail from './is-email';
import maxValidator from './max-validator';
import minValidator from './min-validator';
import exclusiveMaximumValidator from './exclusive-maximum-validator';
import exclusiveMinimumValidator from './exclusive-minimum-validator';
import lenValidator from './len-validator';
import isWhitespace from './is-whitespace';
import isZh from './is-zh';
import isRequired from './is-required';
registerValidateRules({
    email: isEmail,
    max: maxValidator,
    min: minValidator,
    exclusiveMaximum: exclusiveMaximumValidator,
    exclusiveMinimum: exclusiveMinimumValidator,
    len: lenValidator,
    whitespace: isWhitespace,
    zh: isZh,
    required: isRequired,
});
