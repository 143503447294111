import React, { useMemo, useRef, useState } from 'react';
import { useDebounceCallback, useEventListener, useResizeObserver } from './hooks';
import { computeRatio, getGapSize, handleExtractSize, updateScrollElementStyle, updateScrollPosition, } from './utils';
import ThumbBar from './thumb-bar';
var initialSize = {
    CW: 0,
    SW: 0,
    CH: 0,
    SH: 0,
    PT: 0,
    PR: 0,
    PB: 0,
    PL: 0,
};
var initialAction = {
    pinX: false,
    pinY: false,
    lastST: 0,
    lastSL: 0,
    startX: 0,
    startY: 0,
};
export default function useScrollbar(scrollBox, _a) {
    var _b = _a.trackGap, trackGap = _b === void 0 ? 16 : _b, trackStyle = _a.trackStyle, thumbStyle = _a.thumbStyle, minThumbSize = _a.minThumbSize, suppressAutoHide = _a.suppressAutoHide;
    var isGlobal = scrollBox === window;
    var containerRef = useMemo(function () {
        if (isGlobal) {
            return { current: document.documentElement };
        }
        return scrollBox;
    }, [isGlobal, scrollBox]);
    var horizontalRef = useRef(null);
    var verticalRef = useRef(null);
    var _c = useState(initialSize), boxSize = _c[0], updateBoxSize = _c[1];
    var _d = useState(initialAction), action = _d[0], updateAction = _d[1];
    var _e = useState(true), barVisible = _e[0], updateBarVisible = _e[1];
    var hideScrollbar = function () { return !suppressAutoHide && updateBarVisible(false); };
    var delayHideScrollbar = useDebounceCallback(hideScrollbar, { wait: 1000 });
    var CW = boxSize.CW, SW = boxSize.SW, CH = boxSize.CH, SH = boxSize.SH;
    var showHorizontalBar = SW - CW > 0;
    var showVerticalBar = SH - CH > 0;
    var _f = getGapSize(trackGap, showHorizontalBar && showVerticalBar), startX = _f[0], gapX = _f[1], startY = _f[2], gapY = _f[3];
    var updateLayerThrottle = useDebounceCallback(function () {
        updateBarVisible(true);
        delayHideScrollbar();
        updateScrollElementStyle(containerRef.current, horizontalRef.current, verticalRef.current, gapX, gapY, minThumbSize);
    }, { maxWait: 8, leading: true });
    useEventListener('mousemove', function (evt) {
        if (action.pinX) {
            var horizontalRatio = computeRatio(SW, CW, gapX, minThumbSize).ratio;
            updateScrollPosition(containerRef.current, Math.floor((evt.clientX - action.startX) * (1 / horizontalRatio) + action.lastSL), true);
        }
        if (action.pinY) {
            var verticalRatio = computeRatio(SH, CH, gapY, minThumbSize).ratio;
            updateScrollPosition(containerRef.current, Math.floor((evt.clientY - action.startY) * (1 / verticalRatio) + action.lastST));
        }
    });
    useEventListener('mouseup', function () { return updateAction(initialAction); });
    useResizeObserver(containerRef, updateLayerNow);
    function updateLayerNow() {
        if (containerRef.current) {
            updateBoxSize(handleExtractSize(containerRef.current));
            updateLayerThrottle();
        }
    }
    var horizontalBar = showHorizontalBar && (React.createElement(ThumbBar, { visible: barVisible, isGlobal: isGlobal, trackStyle: trackStyle, thumbStyle: thumbStyle, minThumbSize: minThumbSize, start: startX, gap: gapX, horizontal: true, pin: action.pinX, trackRef: horizontalRef, boxSize: boxSize, update: updateAction }));
    var verticalBar = showVerticalBar && (React.createElement(ThumbBar, { visible: barVisible, isGlobal: isGlobal, trackStyle: trackStyle, thumbStyle: thumbStyle, minThumbSize: minThumbSize, start: startY, gap: gapY, pin: action.pinY, trackRef: verticalRef, boxSize: boxSize, update: updateAction }));
    return [horizontalBar, verticalBar, updateLayerNow, updateLayerThrottle, hideScrollbar];
}
