import React, { useContext } from 'react';
import { CnIcon } from '@/components/cn-icon';
import { ShellStateCtx } from '../context';
export var SideBarFooter = function () {
    var _a = useContext(ShellStateCtx), sideBarProps = _a.sideBarProps, isSideBarFold = _a.isSideBarFold, setIsSideBarFold = _a.setIsSideBarFold, isSideBarPreview = _a.isSideBarPreview, setIsSideBarPreview = _a.setIsSideBarPreview;
    var _b = sideBarProps || {}, onCollapseClick = _b.onCollapseClick, onToggleMenuCollapse = _b.onToggleMenuCollapse;
    // 折叠或展开 侧边栏
    var toggleSideBar = function (fold) {
        var _a, _b;
        if (fold) {
            (_a = window === null || window === void 0 ? void 0 : window.coneArmsTrace) === null || _a === void 0 ? void 0 : _a.reportHottag({
                name: 'CnShell左导航收起',
                eventType: 'CLK',
                hottag: 'cn-ui.cn-shell.sideBarCollapse',
            });
        }
        else {
            (_b = window === null || window === void 0 ? void 0 : window.coneArmsTrace) === null || _b === void 0 ? void 0 : _b.reportHottag({
                name: 'CnShell左导航展开',
                eventType: 'CLK',
                hottag: 'cn-ui.cn-shell.sideBarExpand',
            });
        }
        setIsSideBarFold(fold);
        setIsSideBarPreview(false);
        onToggleMenuCollapse === null || onToggleMenuCollapse === void 0 ? void 0 : onToggleMenuCollapse(fold);
    };
    return (React.createElement("div", { className: "cn-ui-sidebar-fold", onMouseEnter: function (e) { return e.stopPropagation(); }, onClick: function () {
            onCollapseClick === null || onCollapseClick === void 0 ? void 0 : onCollapseClick(isSideBarFold, isSideBarPreview);
            toggleSideBar(!isSideBarFold);
        } },
        React.createElement(CnIcon, { type: "collapse-right", size: "xl", className: "cn-ui-sidebar-fold-icon" })));
};
