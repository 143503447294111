import { __assign, __awaiter, __generator } from "tslib";
import React, { useMemo } from 'react';
import $i18n from 'panda-i18n';
import cx from 'classnames';
import { unpackRequest } from '@/components/cn-utils/old-request';
import { CnMessage } from '@/components/cn-message';
import { CnButton } from '@/components/cn-button';
import { getRequestService, withNativeProps } from '@cainiaofe/cn-ui-common';
import { downloadFile } from '@cainiaofe/cn-ui-utils';
export var CnDownload = function (props) {
    var style = props.style, className = props.className, service = props.service, requestConfig = props.requestConfig, _a = props.buttonText, buttonText = _a === void 0 ? $i18n.get({ id: 'Download', dm: '下载', ns: 'CnDownload' }) : _a, buttonProps = props.buttonProps, children = props.children, customRender = props.customRender;
    var requestService = useMemo(function () {
        if (requestConfig)
            return getRequestService(requestConfig);
        return undefined;
    }, [requestConfig]);
    // 通过service拿到文件下载链接
    var fetchFileUrl = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (requestService)
                return [2 /*return*/, requestService()];
            if (typeof service === 'function')
                return [2 /*return*/, service()];
            if (service)
                return [2 /*return*/, unpackRequest(service)];
            return [2 /*return*/, undefined];
        });
    }); };
    var onClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var onError, onSuccess, url, fileName, fileUrl, _a, err_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    onError = props.onError, onSuccess = props.onSuccess, url = props.url, fileName = props.fileName;
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 4, , 5]);
                    _a = url;
                    if (_a) return [3 /*break*/, 3];
                    return [4 /*yield*/, fetchFileUrl()];
                case 2:
                    _a = (_b.sent());
                    _b.label = 3;
                case 3:
                    fileUrl = _a;
                    if (!fileUrl) {
                        throw new Error($i18n.get({
                            id: 'DownloadLinkDoesNotExist',
                            dm: '下载链接不存在',
                            ns: 'CnDownload',
                        }));
                    }
                    downloadFile(fileUrl, fileName);
                    onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(fileUrl);
                    return [3 /*break*/, 5];
                case 4:
                    err_1 = _b.sent();
                    if (onError) {
                        onError(err_1);
                    }
                    else if (err_1.message) {
                        CnMessage.error(err_1.message);
                    }
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    if (customRender)
        return customRender(onClick);
    if (children) {
        return (React.createElement("span", { style: style, className: cx('cn-ui-download-wrapper', className), onClick: onClick }, children));
    }
    return withNativeProps(props, React.createElement(CnButton, __assign({ "data-name": "CnDownload" }, buttonProps, { onClick: onClick }), buttonText));
};
