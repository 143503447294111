import { __assign } from "tslib";
import * as React from 'react';
import { CnTable } from '@/components/cn-table';
import { Field } from '@/components/fusion';
import isPlainObject from 'lodash/isPlainObject';
export function useFtp(url, opts) {
    var _a, _b, _c, _d, _e, _f;
    var instance = CnTable.useRemote();
    var _g = React.useState({}), filterValues = _g[0], setFilterValues = _g[1];
    var filterField = Field.useField({});
    var hasInitOperation = (opts === null || opts === void 0 ? void 0 : opts.initFilterValues) instanceof Function;
    React.useEffect(function () {
        if (hasInitOperation) {
            Promise.resolve(opts === null || opts === void 0 ? void 0 : opts.initFilterValues()).then(function (values) {
                if (isPlainObject(values)) {
                    filterField.setValues(values);
                    setFilterValues(values);
                }
            });
        }
        else if (isPlainObject(opts === null || opts === void 0 ? void 0 : opts.initFilterValues)) {
            filterField.setValues(opts === null || opts === void 0 ? void 0 : opts.initFilterValues);
            setFilterValues(opts === null || opts === void 0 ? void 0 : opts.initFilterValues);
        }
    }, [opts === null || opts === void 0 ? void 0 : opts.initFilterValues]);
    return {
        tableProps: mergeProps((_a = opts === null || opts === void 0 ? void 0 : opts.sourceTableProps) !== null && _a !== void 0 ? _a : {}, {
            showSelect: (_b = opts === null || opts === void 0 ? void 0 : opts.showSelect) !== null && _b !== void 0 ? _b : true,
            paging: (_c = opts === null || opts === void 0 ? void 0 : opts.paging) !== null && _c !== void 0 ? _c : true,
            remote: {
                url: url,
                instance: instance,
                params: filterValues,
                immediate: (_e = (_d = opts === null || opts === void 0 ? void 0 : opts.immediate) !== null && _d !== void 0 ? _d : !hasInitOperation) !== null && _e !== void 0 ? _e : true,
                paramsTransform: opts === null || opts === void 0 ? void 0 : opts.paramsTransform,
                responseTransform: opts === null || opts === void 0 ? void 0 : opts.responseTransform,
                asciiSort: opts === null || opts === void 0 ? void 0 : opts.asciiSort,
                requestOptions: opts === null || opts === void 0 ? void 0 : opts.requestOptions,
                treeCache: opts === null || opts === void 0 ? void 0 : opts.treeCache,
                getChildrenInTree: opts === null || opts === void 0 ? void 0 : opts.getChildrenInTree,
            },
        }),
        filterProps: mergeProps((_f = opts === null || opts === void 0 ? void 0 : opts.sourceFilterProps) !== null && _f !== void 0 ? _f : {}, {
            onSearch: function (val) {
                var validate = opts === null || opts === void 0 ? void 0 : opts.onSearchValidate;
                if (validate instanceof Function) {
                    Promise.resolve(validate(val)).then(function (res) {
                        res &&
                            setFilterValues(__assign({}, val));
                    });
                }
                else {
                    setFilterValues(__assign({}, val));
                }
            },
            field: filterField,
        }),
        load: function () {
            instance.load();
        },
        refresh: function () {
            instance.refresh();
        },
        instance: instance,
        field: filterField,
    };
}
function mergeProps(base, extra) {
    if (base == null) {
        return extra;
    }
    if (extra == null) {
        return base;
    }
    var result = Object.assign({}, base);
    for (var _i = 0, _a = Object.keys(extra); _i < _a.length; _i++) {
        var key = _a[_i];
        var value = extra[key];
        var type = typeof value;
        if (value === null) {
            // value=null 时 覆盖原来的值
            result[key] = null;
        }
        else if (value === undefined) {
            // value=undefined 时 保留原来的值
        }
        else if (type === 'function') {
            var prev = result[key];
            if (prev == null) {
                result[key] = value;
            }
            else {
                result[key] = composeEventHandler(prev, value);
            }
        }
        else if (type === 'object') {
            result[key] = mergeProps(result[key], value);
        }
        else {
            result[key] = value;
        }
        // else `type` is 'bigint' or 'symbol', `value` is an invalid cellProp, ignore it
    }
    return result;
}
export function composeEventHandler(handler1, handler2) {
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        // 先执行原有的事件回调函数
        handler1 instanceof Function && handler1(args);
        handler2 instanceof Function && handler2(args);
        // 事件回调函数没有返回值，故这里不进行 return
    };
}
