// 将输入框的值转换为适合在文本框中显示的格式
export var formatValue = function (value) {
    return value.replace(/(,)+/g, ',').replace(/(\n)+/g, '\n');
};
// 处理输入框的值，将逗号替换为换行符
export var processInputValue = function (inputVal) {
    if (typeof inputVal !== 'string')
        return inputVal;
    return formatValue(inputVal.replace(/,/g, '\n')).replace(/\n$/, '');
};
// 处理文本框的值，将换行符替换为逗号
export var processTextareaValue = function (textareaVal) {
    if (typeof textareaVal !== 'string')
        return textareaVal;
    return formatValue(textareaVal.replace(/\n/g, ',')).replace(/,$/, '');
};
