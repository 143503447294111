import { __spreadArray } from "tslib";
import { useMemo, useRef } from 'react';
/**
 * 自定义hook，用于在组件渲染后的第二次渲染时执行回调函数
 * @param callback 回调函数
 * @param deps 依赖项
 */
export function useSecond(callback, deps) {
    var hasBeSecond = useRef(false); // 用于记录是否已经渲染过一次
    useMemo(function () {
        if (hasBeSecond.current) {
            // 如果已经渲染过一次，则执行回调函数
            callback instanceof Function && callback();
        }
        else {
            // 如果还没有渲染过一次，则将hasBeSecond.current设置为true
            hasBeSecond.current = true;
        }
    }, __spreadArray([], deps, true)); // 依赖项发生变化时执行useMemo
}
