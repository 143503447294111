import { __assign, __rest } from "tslib";
import React from 'react';
import { CnButton } from '@/components/cn-button';
import { useI18n } from 'panda-i18n';
import { getFooterButtonVisible } from './utils/get-footer-button-visible';
export function CnDrawerFooter(props) {
    var footer = props.footer, onOk = props.onOk, onCancel = props.onCancel, okProps = props.okProps, cancelProps = props.cancelProps;
    var $i18n = useI18n();
    // 当 footer 为 false，不渲染抽屉底部
    if (footer === false)
        return null;
    var _a = okProps || {}, _b = _a.children, okChildren = _b === void 0 ? $i18n.get({
        id: 'Confirmation',
        dm: '确认',
        ns: 'CnDrawer',
    }) : _b, _okVisible = _a.visible, restOkProps = __rest(_a, ["children", "visible"]);
    var _c = cancelProps || {}, _d = _c.children, cancelChildren = _d === void 0 ? $i18n.get({
        id: 'Cancel',
        dm: '取消',
        ns: 'CnDrawer',
    }) : _d, _cancelVisible = _c.visible, restCancelProps = __rest(_c, ["children", "visible"]);
    var _e = getFooterButtonVisible(footer, _okVisible, _cancelVisible), okButtonVisible = _e.okButtonVisible, cancelButtonVisible = _e.cancelButtonVisible;
    // 排除 footer 为 true，是因为当 cancelButtonVisible 和 okButtonVisible 也设置为 false，footer 会渲染成空白 div
    var hasDrawerFooter = (footer !== true && footer) || cancelButtonVisible || okButtonVisible;
    // 无抽屉底部渲染为 null，是为了避免出现空白 div
    if (!hasDrawerFooter)
        return null;
    return (React.createElement("div", { className: "cn-ui-drawer-footer" },
        footer,
        cancelButtonVisible ? (React.createElement(CnButton, __assign({ className: "cn-ui-drawer-btn", onClick: onCancel, size: "medium" }, restCancelProps), cancelChildren)) : null,
        okButtonVisible ? (React.createElement(CnButton, __assign({ type: "primary", onClick: onOk, size: "medium" }, restOkProps), okChildren)) : null));
}
