// 第三方依赖
import { useState, useCallback } from 'react';
import isFunction from 'lodash/isFunction';
import { getValueByPath } from '../utils';
import { useSecond } from './use-second';
export function useControlValue(props, opts) {
    var _a;
    var defaultValuePropName = opts.defaultValuePropName, _b = opts.valuePropName, valuePropName = _b === void 0 ? 'value' : _b, _c = opts.changePropName, changePropName = _c === void 0 ? 'onChange' : _c;
    var _d = useState((_a = props === null || props === void 0 ? void 0 : props[valuePropName]) !== null && _a !== void 0 ? _a : defaultValuePropName), data = _d[0], setData = _d[1];
    var setHandler = useCallback(function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var finalChange = getValueByPath(props, changePropName.split('.'), null);
        if (isFunction(finalChange)) {
            // 防止render期间导致 error引发白屏
            try {
                finalChange.apply(void 0, args);
            }
            catch (error) {
                console.error(error);
            }
        }
        !(valuePropName in (props || {})) && setData.apply(void 0, args);
    }, [props, valuePropName, changePropName]);
    useSecond(function () {
        setData(props === null || props === void 0 ? void 0 : props[valuePropName]);
    }, [props === null || props === void 0 ? void 0 : props[valuePropName]]);
    return [data, setHandler];
}
