import './sub-card.scss';
import React from 'react';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
export function CnCardSubCard(_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, _c = _a.subTitle, subTitle = _c === void 0 ? '' : _c, subDesc = _a.subDesc, action = _a.action, children = _a.children;
    var actionDom = action;
    if (isFunction(action)) {
        // @ts-ignore
        actionDom = action();
    }
    return (React.createElement("div", { className: classNames(['cn-ui-card-sub-card', className]) },
        subTitle && (React.createElement("div", { className: "cn-ui-card-sub-card-header" },
            React.createElement("div", { className: "cn-ui-card-sub-card-header-header" }, subTitle),
            React.createElement("div", { className: "cn-ui-card-sub-card-header-footer" }, actionDom))),
        subDesc && (React.createElement("div", { className: "cn-ui-card-subdesc cn-ui-card-desc" }, subDesc)),
        children && (React.createElement("div", { className: "cn-ui-card-sub-card-content" }, children))));
}
CnCardSubCard.displayName = 'CnCardSubCard';
