import { __assign, __rest } from "tslib";
import $i18n from 'panda-i18n';
import React from 'react';
import { CnButton } from '@/components/cn-button';
import { useParentForm, observer } from '@formily/react';
export var Submit = observer(function (_a) {
    var onSubmit = _a.onSubmit, onSubmitFailed = _a.onSubmitFailed, onSubmitSuccess = _a.onSubmitSuccess, props = __rest(_a, ["onSubmit", "onSubmitFailed", "onSubmitSuccess"]);
    var form = useParentForm();
    return (React.createElement(CnButton, __assign({ htmlType: onSubmit ? 'button' : 'submit', type: "primary" }, props, { loading: props.loading !== undefined ? props.loading : form.submitting, onClick: function (e) {
            if (props.onClick) {
                if (props.onClick(e) === false)
                    return;
            }
            if (onSubmit) {
                form.submit(onSubmit).then(onSubmitSuccess).catch(onSubmitFailed);
            }
        } }), props.children ||
        $i18n.get({ id: '31255239613091840.CNTM', dm: '提交', ns: 'CnForm' })));
}, {
    forwardRef: true,
});
