import * as React from 'react';
import ReactDOM from 'react-dom';
import useUpdate from 'ahooks/lib/useUpdate';
import { isDef, isPlainObject } from '../../utils';
import { useWillUpdate } from '../../hooks';
import { ColAbility } from './types';
import { globalAsyncDragCol } from '../../global';
import { operateCode } from '../operate-column-pipeline';
export function useNoWidthRule(widthRef) {
    var buttonWidthRef = React.useRef({});
    var _a = React.useState({}), update = _a[1];
    var calOpereateButtonItemWithNoRule = React.useCallback(function (props) {
        var render = props.render, buttonIndex = props.buttonIndex, record = props.record, index = props.index, splitWidth = props.splitWidth;
        if (buttonWidthRef.current[buttonIndex]) {
            return buttonWidthRef.current[buttonIndex];
        }
        var element = render instanceof Function ? render(record, index) : null;
        if (element) {
            buttonWidthRef.current[buttonIndex] = true;
        }
        var hasMeasureEl = document.querySelector('.cnc-table-measure');
        var measureEl = hasMeasureEl || document.createElement('div');
        if (!hasMeasureEl) {
            measureEl.style.width = 'fit-content';
            measureEl.className = 'cnc-table-measure';
            measureEl.style.cssText = "\n          position: fixed;\n          top: -10000px;\n          left: -10000px;\n          z-index: -100;\n        ";
            document.body.appendChild(measureEl);
        }
        element &&
            ReactDOM.render(element, measureEl, function () {
                var _a;
                buttonWidthRef.current[buttonIndex] =
                    measureEl.offsetWidth + splitWidth;
                var buttonValues = Object.keys(buttonWidthRef.current).map(function (key) { return buttonWidthRef.current[key]; });
                var filterValues = buttonValues.filter(function (val) { return parseInt(val, 10) > 0; });
                if (filterValues.length === buttonValues.length) {
                    if (!widthRef.current) {
                        // @ts-ignore
                        widthRef.current = {};
                    }
                    widthRef.current[operateCode] =
                        // @ts-ignore
                        ((_a = widthRef.current[operateCode]) !== null && _a !== void 0 ? _a : 0) +
                            // @ts-ignore
                            filterValues.reduce(function (ret, val) { return ret + val; }, 0);
                    if (document.querySelector('.cnc-table-measure')) {
                        document
                            .querySelector('.cnc-table-measure')
                            .parentElement.removeChild(document.querySelector('.cnc-table-measure'));
                        // document.body.removeChild(measureEl);
                    }
                    update({});
                }
            });
    }, []);
    return {
        calOpereateButtonItemWithNoRule: calOpereateButtonItemWithNoRule,
    };
}
// 处理dragline能力
export function useAsyncDrag(allowColAsyncDrag, dragLineUpdateRef) {
    React.useEffect(function () {
        if (allowColAsyncDrag) {
            dragLineUpdateRef.current instanceof Function &&
                dragLineUpdateRef.current();
        }
    }, [allowColAsyncDrag]);
}
function useCalAbility(columnResize, dragLineUpdateRef) {
    var ability = ColAbility.justWidth;
    if (columnResize) {
        ability =
            columnResize.async || globalAsyncDragCol
                ? ColAbility.widthAndAsyncResize
                : ColAbility.widthAndResize;
    }
    useAsyncDrag(ability === ColAbility.widthAndAsyncResize, dragLineUpdateRef);
    return ability;
}
/**
 * 生成列宽度调整的相关参数
 * @param {TableProps} props - 表格组件的props
 * @returns {IColumnsResizeOptions | undefined} - 返回列宽度调整的相关参数
 */
export function useColumnsResize(props) {
    var columnResize = props.columnResize, columnsAutoWidth = props.columnsAutoWidth, size = props.size;
    if (!isDef(columnResize) && !columnsAutoWidth) {
        return;
    }
    var update = useUpdate();
    var dragLineUpdateRef = React.useRef();
    var ability = useCalAbility(columnResize, dragLineUpdateRef);
    // @ts-ignore
    var _a = columnResize || {}, widths = _a.widths, _b = _a.defaultWidths, defaultWidths = _b === void 0 ? {} : _b;
    var widthsRef = React.useRef(widths || defaultWidths || {});
    var setWidths = React.useCallback(function (ws) {
        widthsRef.current = ws;
    }, []);
    var setWidth = React.useCallback(function (key, val) {
        if (!isPlainObject(widthsRef.current)) {
            widthsRef.current = {};
        }
        widthsRef.current[key] = val;
    }, []);
    /**
     * 生成没有规则的操作按钮的宽度
     * @returns {void}
     */
    var calOpereateButtonItemWithNoRule = useNoWidthRule(widthsRef).calOpereateButtonItemWithNoRule;
    useWillUpdate(function () {
        setWidths(widths);
    }, [widths]);
    var dragLineRef = React.useRef();
    return {
        widthsRef: widthsRef,
        setWidth: setWidth,
        setWidths: setWidths,
        calOpereateButtonItemWithNoRule: calOpereateButtonItemWithNoRule,
        size: size,
        dragLineRef: dragLineRef,
        columnResize: columnResize,
        columnsAutoWidth: columnsAutoWidth,
        ability: ability,
        update: update,
        dragLineUpdateRef: dragLineUpdateRef,
    };
}
