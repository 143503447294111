import { useState } from 'react';
import useUpdate from 'ahooks/lib/useUpdate';
import { TablePipeline } from './table-pipeline';
export function useTablePipeline(pipeline) {
    var _a = useState({}), state = _a[0], setState = _a[1];
    var doUpdate = useUpdate();
    if (pipeline) {
        return pipeline;
    }
    return new TablePipeline({ state: state, setState: setState, doUpdate: doUpdate });
}
