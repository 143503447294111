import * as React from 'react';
import cx from 'classnames';
import { CnTextEllipsis } from '@/components/cn-ellipsis';
import { CnIcon } from '@/components/cn-icon';
export var AnchorLink = function (props) {
    var label = props.label, htmlId = props.htmlId, isFocus = props.isFocus, level = props.level, icon = props.icon, onSelect = props.onSelect, _a = props.padding, padding = _a === void 0 ? 0 : _a;
    var cls = cx({
        'cn-ui-anchor-item': true,
        // [`cn-ui-anchor-item-level-${level}`]: true,
        'cn-ui-anchor-item-active': !!isFocus,
    });
    return (React.createElement("div", { key: htmlId, className: cls, onClick: function () {
            return onSelect({
                label: label,
                htmlId: htmlId,
                isFocus: isFocus,
                level: level,
                icon: icon,
                padding: padding,
            });
        }, style: {
            paddingLeft: 4 + 8 * level + padding,
        } },
        icon && (React.createElement("div", { className: "cn-ui-anchor-item-icon-wrapper" },
            React.createElement(CnIcon, { type: icon, className: "cn-ui-anchor-item-icon", "data-testid": "anchor-icon" }))),
        React.createElement(CnTextEllipsis, { line: 1 }, label)));
};
