import * as React from 'react';
export var usePageFooterHeight = function (props) {
    var disabled = props.disabled, ref = props.ref;
    var calcHeight = function () {
        var dom = ref.current;
        if (!dom)
            return undefined;
        return dom.getBoundingClientRect().height;
    };
    var _a = React.useState(calcHeight()), height = _a[0], setHeight = _a[1];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(function () {
        if (disabled)
            return;
        setHeight(calcHeight());
    });
    React.useEffect(function () {
        if (disabled)
            return;
        if (!height)
            return;
        var dom = ref.current;
        if (!dom)
            return;
        var pageDom = dom.closest('.cn-ui-page');
        if (!pageDom)
            return;
        var placeholder = pageDom.querySelector('.cn-ui-page-footer-placeholder');
        if (!placeholder)
            return;
        placeholder.style.height = "".concat(height, "px");
    }, [disabled, height, ref]);
};
