import { __assign, __rest, __spreadArray } from "tslib";
import * as React from 'react';
import { CnTree, CnTreeNode } from '@/components/cn-tree';
import { normalizeToArray } from '@/components/cn-tree/view/util';
import { valueToSelectKey } from '@fusion/lib/select/util';
import { flatDataSource } from '@/components/cn-tree-select/tree-select/tree-select';
import { useCnRequest } from '@/components/cn-utils/old-request';
import { findDeep } from '@/components/cn-tree-select/utils';
var Tree = React.forwardRef(function (props, ref) {
    var isControlled = 'value' in props;
    var propValue = props.value, defaultValue = props.defaultValue, children = props.children, dataSource = props.dataSource, checkable = props.checkable, checkedStrategy = props.checkedStrategy, onChange = props.onChange, others = __rest(props, ["value", "defaultValue", "children", "dataSource", "checkable", "checkedStrategy", "onChange"]);
    var _a = React.useState(normalizeToArray(isControlled ? propValue : defaultValue).map(function (v) {
        return valueToSelectKey(v);
    })), value = _a[0], setValue = _a[1];
    var _b = React.useState(1), forceUpdate = _b[1];
    var datasetRef = React.useRef(flatDataSource(props));
    var _c = datasetRef.current, _k2n = _c._k2n, _p2n = _c._p2n, _v2n = _c._v2n;
    var treeProps = {};
    React.useEffect(function () {
        if (isControlled && value !== propValue) {
            setValue(normalizeToArray(propValue).map(function (v) {
                return valueToSelectKey(v);
            }));
        }
    }, [propValue]);
    React.useEffect(function () {
        datasetRef.current = flatDataSource(props);
        forceUpdate(function (s) { return (s + 1) % 32; });
    }, [dataSource, children]);
    var createNodesByData = function (ds) {
        var loop = function (data, prefix) {
            if (prefix === void 0) { prefix = '0'; }
            var retainedNodes = [];
            data.forEach(function (item, index) {
                var _a;
                var children = item.children, others = __rest(item, ["children"]);
                var pos = "".concat(prefix, "-").concat(index);
                var key = (_a = _p2n[pos]) === null || _a === void 0 ? void 0 : _a.key;
                if (key) {
                    retainedNodes.push(React.createElement(CnTreeNode, __assign({}, others, { key: key }), children && children.length ? loop(children, pos) : null));
                }
            });
            return retainedNodes;
        };
        return loop(ds);
    };
    var getKeysByValue = function (value) {
        return value.reduce(function (ret, v) {
            var k = _v2n[v] && _v2n[v].key;
            if (k) {
                ret.push(k);
            }
            return ret;
        }, []);
    };
    var getValueByKeys = function (keys) {
        return keys.map(function (k) { return _k2n[k].value; });
    };
    var getData = function (value) {
        var ret = value.reduce(function (ret, v) {
            var k = _v2n[v] && _v2n[v].key;
            if (k) {
                var _a = _k2n[k], label = _a.label, pos = _a.pos;
                var d = {
                    value: v,
                    label: label,
                    pos: pos,
                };
                d.key = k;
                ret.push(d);
            }
            return ret;
        }, []);
        return ret;
    };
    var handleSelect = function (selectedKeys) {
        var value = getValueByKeys(selectedKeys);
        if (!isControlled) {
            setValue(value);
        }
        var data = getData(value);
        props.multiple ? onChange(value, data) : onChange(value[0], data[0]);
    };
    var handleCheck = function (checkedKeys) {
        var value = getValueByKeys(checkedKeys);
        if (!isControlled) {
            setValue(value);
        }
        onChange(value, getData(value));
    };
    var keys = getKeysByValue(value);
    if (checkable) {
        treeProps.checkable = checkable;
        treeProps.checkedStrategy = props.checkStrictly ? 'all' : checkedStrategy;
        treeProps.checkedKeys = keys;
        treeProps.onCheck = handleCheck;
    }
    else {
        treeProps.selectedKeys = keys;
        treeProps.onSelect = handleSelect;
    }
    return (React.createElement(CnTree, __assign({ ref: ref }, others, treeProps), (dataSource === null || dataSource === void 0 ? void 0 : dataSource.length) ? createNodesByData(dataSource) : children));
});
export var FormTree = React.forwardRef(function (props, ref) {
    var requestConfig = props.requestConfig, enableRemoteLazyLoad = props.enableRemoteLazyLoad, others = __rest(props, ["requestConfig", "enableRemoteLazyLoad"]);
    var _a = React.useState([]), innerDataSource = _a[0], setInnerDataSource = _a[1];
    var remoteLazyLoadItemRef = React.useRef();
    var treeProps = {};
    var isRemoteDataSource = React.useMemo(function () {
        return !!((requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.url) || (requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.service));
    }, [requestConfig]);
    if (!requestConfig.formatResult) {
        requestConfig.formatResult = function (res) {
            var _a;
            if (Array.isArray(res)) {
                return res;
            }
            else if (Array.isArray(res === null || res === void 0 ? void 0 : res.data)) {
                return res.data;
            }
            else if (Array.isArray((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.dataSource)) {
                return res.data.dataSource;
            }
            return [];
        };
    }
    var _b = useCnRequest(__assign({ ready: isRemoteDataSource }, requestConfig)), runAsync = _b.runAsync, data = _b.data, loading = _b.loading;
    React.useEffect(function () {
        if (!isRemoteDataSource)
            return;
        if (loading)
            return;
        setInnerDataSource(function (previousDataSource) {
            var _a;
            if (enableRemoteLazyLoad) {
                if (!((_a = remoteLazyLoadItemRef.current) === null || _a === void 0 ? void 0 : _a.value)) {
                    return data;
                }
                var valueItem = findDeep(previousDataSource, 'value', remoteLazyLoadItemRef.current.value);
                if (valueItem) {
                    valueItem.children = data;
                }
                return __spreadArray([], previousDataSource, true);
            }
            return data;
        });
    }, [data]);
    if (isRemoteDataSource) {
        treeProps.dataSource = innerDataSource;
    }
    if (enableRemoteLazyLoad) {
        treeProps.loadData = function (_a) {
            var _b;
            var item = _a.props;
            remoteLazyLoadItemRef.current = item;
            return runAsync((_b = {},
                _b[requestConfig.remoteLazyLoadKey || 'value'] = item.value,
                _b)).catch(function (err) {
                console.log(err);
            });
        };
    }
    return React.createElement(Tree, __assign({ ref: ref }, others, treeProps));
});
FormTree.defaultProps = {
    requestConfig: {},
};
