import { __assign } from "tslib";
// 第三方依赖
import * as React from 'react';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { useControllableValue } from 'ahooks';
// 其他组件
import { safeCallFunction } from '@/components/cn-utils';
import { globalConfig } from '../config';
export var usePage = function (props) {
    var _a, _b, _c;
    var pageInfo = React.useRef({});
    var pageHasChange = React.useRef(false);
    var defaultPageSize = props.defaultPageSize, defaultCurrentPage = props.defaultCurrentPage, onPageChange = props.onPageChange, _d = props.pageProps, pageProps = _d === void 0 ? {} : _d;
    var pageSizeListValue = React.useMemo(function () {
        if ('pageSizeList' in pageProps)
            return { value: pageProps.pageSizeList };
        if ('pageSizeList' in props)
            return { value: props.pageSizeList };
        return {};
    }, [pageProps === null || pageProps === void 0 ? void 0 : pageProps.pageSizeList, props === null || props === void 0 ? void 0 : props.pageSizeList]);
    var _e = useControllableValue(pick(pageProps, ['total']), {
        valuePropName: 'total',
        defaultValue: (_b = (_a = props === null || props === void 0 ? void 0 : props.dataSource) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0,
    }), total = _e[0], setTotal = _e[1];
    React.useEffect(function () {
        var _a, _b;
        if ('dataSource' in props) {
            var dataSourceLength = (_b = (_a = props === null || props === void 0 ? void 0 : props.dataSource) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0;
            setTotal(dataSourceLength);
        }
    }, [props === null || props === void 0 ? void 0 : props.dataSource]);
    var _f = useControllableValue(__assign(__assign({}, pick(pageProps, ['current'])), { onChange: function (currentPage, type) { return handlePageChange({ currentPage: currentPage, type: type }); } }), {
        defaultValue: defaultCurrentPage !== null && defaultCurrentPage !== void 0 ? defaultCurrentPage : 1,
        valuePropName: 'current',
    }), currentPage = _f[0], setCurrent = _f[1];
    var _g = useControllableValue(__assign(__assign({}, pageSizeListValue), { onChange: function (pageSizeList) { return handlePageChange({ pageSizeList: pageSizeList }); } }), {
        defaultValue: globalConfig.pageSizeList,
    }), pageSizeList = _g[0], setPageSizeList = _g[1];
    var _h = useControllableValue(__assign(__assign({}, pick(pageProps, ['pageSize'])), { onChange: function (pageSize) { return handlePageChange({ pageSize: pageSize }); } }), {
        valuePropName: 'pageSize',
        defaultValue: (_c = defaultPageSize !== null && defaultPageSize !== void 0 ? defaultPageSize : pageSizeList[0]) !== null && _c !== void 0 ? _c : globalConfig.defaultPageSize,
    }), pageSize = _h[0], setPageSize = _h[1];
    var resultPageInfo = {
        pageSizeList: pageSizeList,
        pageSize: pageSize,
        total: total,
        currentPage: currentPage,
    };
    function handlePageChange(params) {
        console.log(params.type);
        if (params.type === 'linkage')
            return;
        delete params.type;
        // 如果pagesize发生了变化把当前页重置成第一页
        if ('pageSize' in params) {
            setCurrent(1, 'linkage');
            Object.assign(params, { currentPage: 1 });
        }
        Object.assign(resultPageInfo, params);
        if (!isEqual(pageInfo.current, resultPageInfo)) {
            pageHasChange.current = true;
            safeCallFunction(onPageChange, resultPageInfo);
        }
    }
    pageInfo.current = __assign({}, resultPageInfo);
    return {
        total: total,
        setTotal: setTotal,
        pageSize: pageSize,
        setPageSize: setPageSize,
        currentPage: currentPage,
        setCurrent: setCurrent,
        pageSizeList: pageSizeList,
        setPageSizeList: setPageSizeList,
        pageHasChange: pageHasChange,
    };
};
