'use strict';

exports.__esModule = true;
var TIME_PICKER_TYPE = exports.TIME_PICKER_TYPE = {
    TIME: 'time',
    RANGE: 'range'
};

var TIME_INPUT_TYPE = exports.TIME_INPUT_TYPE = {
    BEGIN: 0,
    END: 1
};