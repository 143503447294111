import { useCallback, useEffect, useState } from 'react';
import { getMenuKeysWitchHasChildren, getParentKeysBySelected, } from '../side-bar/utils';
export default function useSideBarExpandKeys(props) {
    var defaultExpandAll = props.defaultExpandAll, menu = props.menu, selectedMenuKey = props.selectedMenuKey;
    // 计算侧边栏展开的菜单列表
    var getSideBarExpandKeys = useCallback(function () {
        if (defaultExpandAll)
            return getMenuKeysWitchHasChildren(menu);
        if (selectedMenuKey)
            return getParentKeysBySelected(menu, selectedMenuKey);
        return [];
        // dependencies 不直接依赖 menu 是因为外部传递进来的是一个引用类型的数据，会触发重复渲染，而菜单如果变化通常是长度变化了
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMenuKey, menu.length]);
    var _a = useState(getSideBarExpandKeys()), sideBarExpandKeys = _a[0], setSideBarExpandKeys = _a[1];
    useEffect(function () {
        setSideBarExpandKeys(getSideBarExpandKeys());
    }, [getSideBarExpandKeys]);
    return { sideBarExpandKeys: sideBarExpandKeys, setSideBarExpandKeys: setSideBarExpandKeys };
}
