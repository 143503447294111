import { __assign } from "tslib";
import { useCallback } from 'react';
import { getFileIndex } from '../../../utils';
export var useOnProgress = function (props) {
    var setValue = props.setValue;
    return useCallback(function (event, file) {
        setValue(function (prev) {
            var fileIndex = getFileIndex(file, prev);
            return prev.map(function (item, index) {
                if (index !== fileIndex)
                    return item;
                return __assign(__assign({}, item), { percent: event.percent, status: 'uploading' });
            });
        });
    }, [setValue]);
};
