import { __assign } from "tslib";
import $i18n from 'panda-i18n';
import React, { forwardRef, useEffect, useImperativeHandle, useState, } from 'react';
import { events, friendlyTips } from '@/components/cn-utils';
import { CnDrawer } from '@/components/cn-drawer';
import { cssPrefix as _cssPrefix, MARK_READ } from '../../constant';
import JobPanel from '../job-panel';
import classNames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import './index.scss';
import { CnResult } from '@/components/cn-result';
var cssPrefix = "".concat(_cssPrefix, "container");
export var JobContainer = forwardRef(function (props, ref) {
    var _a, _b;
    var visible = props.visible, setVisible = props.setVisible, importProps = props.importProps, exportProps = props.exportProps, className = props.className;
    var _c = useState(function () {
        return exportProps ? 'export' : 'import';
    }), tab = _c[0], setTab = _c[1];
    useImperativeHandle(ref, function () { return ({ setTab: setTab }); });
    useEffect(function () {
        if (visible)
            events.emit(MARK_READ);
    }, [visible]);
    if (!importProps && !exportProps) {
        friendlyTips({
            message: $i18n.get({
                id: 'PleaseCheckWhetherTheComponentConfiguration_802152393',
                dm: '请检查组件配置是否正确',
                ns: 'CnAsyncJobsPro',
            }),
            component: 'CnAsyncJobsPro',
        });
    }
    return (React.createElement(CnDrawer, { visible: visible, onClose: function () { return setVisible(false); }, className: classNames(cssPrefix, className), hasMask: false, canCloseByOutSideClick: true, canCloseByEsc: false, embeddable: true },
        React.createElement("div", { className: "".concat(cssPrefix, "-close"), onClick: function () { return setVisible(false); } },
            React.createElement(CnIcon, { type: "close", size: 16 })),
        !importProps && !exportProps ? (React.createElement(CnResult, { type: "empty", title: $i18n.get({
                id: 'NoTaskYet',
                dm: '暂无任务',
                ns: 'CnAsyncJobsPro',
            }) })) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "".concat(cssPrefix, "-header") },
                exportProps ? (React.createElement("div", { className: classNames("".concat(cssPrefix, "-header-tab"), (_a = {},
                        _a["".concat(cssPrefix, "-header-tab-selected")] = tab === 'export',
                        _a)), onClick: function () { return setTab('export'); } }, exportProps.title ||
                    $i18n.get({
                        id: 'ExportTask',
                        dm: '导出任务',
                        ns: 'CnAsyncJobsPro',
                    }))) : null,
                importProps ? (React.createElement("div", { className: classNames("".concat(cssPrefix, "-header-tab"), (_b = {},
                        _b["".concat(cssPrefix, "-header-tab-selected")] = tab === 'import',
                        _b)), onClick: function () { return setTab('import'); } }, importProps.title ||
                    $i18n.get({
                        id: 'ImportTask',
                        dm: '导入任务',
                        ns: 'CnAsyncJobsPro',
                    }))) : null),
            React.createElement("div", { className: "".concat(cssPrefix, "-content") },
                exportProps ? (React.createElement(JobPanel, __assign({ selected: tab === 'export', type: "export" }, exportProps, { visible: visible }))) : null,
                importProps ? (React.createElement(JobPanel, __assign({ selected: tab === 'import', type: "import" }, importProps, { visible: visible }))) : null)))));
});
JobContainer.displayName = 'JobContainer';
