import { __assign } from "tslib";
// 第三方依赖
import * as React from 'react';
import isPlainObject from 'lodash/isPlainObject';
import isNil from 'lodash/isNil';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
// 其他组件
import { CnTooltip } from '@/components/cn-tooltip';
import { CnOSSUpload } from '@/components/cn-oss-upload';
import { CnAsyncSelect } from '@/components/cn-async-select';
import { domStyleMap } from '@/components/cn-utils';
import { CnProgress } from '@/components/cn-progress';
import { CnFormat } from '@/components/cn-format';
import { CnImageViewer } from '@/components/cn-image-viewer';
// 当前目录的相对依赖
import { formatCardNumber, isNumberStr, renderValueHandleEmpty } from './utils';
import formatTag from './format-tag';
import formatEllipsis from './format-ellipsis';
import formatNumber from './format-number';
import './index.scss';
var CnTableCellFormat = {
    tag: formatTag,
    number: formatNumber,
    currency: {
        align: 'right',
        cell: function (val) {
            return getNumFromStr(val);
        },
    },
    'currency-with-unit': function () {
        return {
            align: 'right',
            cell: function (val) {
                if (isPlainObject(val)) {
                    var amount = val === null || val === void 0 ? void 0 : val.amount;
                    var currency = val === null || val === void 0 ? void 0 : val.currency;
                    return "".concat(getNumFromStr(amount), "  ").concat(currency);
                }
                else if (val !== undefined &&
                    val !== null &&
                    (typeof val === 'string' || typeof val === 'number')) {
                    return getNumFromStr(val);
                }
            },
        };
    },
    percent: {
        align: 'right',
        cell: function (val) {
            return isNil(val) ? renderValueHandleEmpty(val) : "".concat(val, "%");
            // return `${val}%`;
        },
    },
    user: {
        cell: function (value) {
            var result;
            function renderUserOne(value) {
                if (isPlainObject(value)) {
                    var nameDom = void 0;
                    var nickNameDom = void 0;
                    var workNoDom = void 0;
                    var name = value.name, nickName = value.nickName, workNo = value.workNo;
                    if (name) {
                        nameDom = name;
                    }
                    if (nickName) {
                        nickNameDom = "(".concat(nickName, ")");
                    }
                    if (workNo) {
                        workNoDom = workNo;
                    }
                    var dom = (React.createElement("div", { className: "table-format-user-content" },
                        React.createElement("img", { src: "https://work.alibaba-inc.com/photo/".concat(workNo, ".25x25.jpg"), className: 'table-format-user-avatar' }),
                        nameDom,
                        nickNameDom));
                    if (workNoDom) {
                        return (React.createElement(CnTooltip, { align: 't', trigger: dom }, workNoDom));
                    }
                    else {
                        return dom;
                    }
                }
            }
            if (Array.isArray(value)) {
                result = value.map(function (item) {
                    return renderUserOne(item);
                });
            }
            else if (isPlainObject(value)) {
                result = renderUserOne(value);
            }
            return React.createElement("div", { className: "table-format-user-wrap" }, result);
        },
    },
    'graphics-percent': function (column) {
        return {
            align: 'left',
            cell: function (val) {
                return React.createElement(CnProgress, __assign({}, column.percentProps, { percent: val }));
            },
        };
    },
    progress: function () {
        return {
            align: 'left',
            cell: function (val) {
                if (typeof val !== 'object') {
                    val = { value: val };
                }
                return Number.isNaN(Number(val === null || val === void 0 ? void 0 : val.value)) ? ('') : (React.createElement(CnProgress, { className: "cn-table-cell-format-progress", percent: Number(val === null || val === void 0 ? void 0 : val.value), state: val === null || val === void 0 ? void 0 : val.state, textRender: function (percent) {
                        return Number.isNaN(Number(percent)) ? '-%' : "".concat(percent, "%");
                    } }));
            },
        };
    },
    'multiple-text': function () {
        return {
            align: 'left',
            cell: function (val) {
                return (React.createElement("div", { className: 'cn-table-cell-format-multiple-text' }, Array.isArray(val) ? (val.map(function (item) {
                    return renderLine(item);
                })) : (React.createElement(CnFormat, { format: "CnInputTextArea", value: val }))));
            },
        };
    },
    'image-text': function (c) {
        return {
            align: 'left',
            cell: function (val) {
                var previewImg = function (url) {
                    CnImageViewer.open({ src: url });
                };
                if (isPlainObject(val)) {
                    var _a = val.url, url_1 = _a === void 0 ? '' : _a, _b = val.title, title = _b === void 0 ? '' : _b, _c = val.content, content = _c === void 0 ? '' : _c;
                    return (React.createElement("div", { className: 'cn-table-cell-format-image-text' },
                        React.createElement("img", { className: 'ctfit-img', src: url_1, onClick: function () { previewImg(url_1); } }),
                        React.createElement("div", { className: "ctfit-right" },
                            React.createElement("div", { className: "ctfit-title" }, renderLine(title)),
                            React.createElement("div", { className: "ctfit-content" }, renderLine(content, 2)))));
                }
                else if (typeof val === 'string') {
                    return React.createElement("img", { className: 'ctfit-img', src: val, onClick: function () { previewImg(val); } });
                }
                else {
                    return '';
                }
            },
        };
    },
    date: function (column) {
        return {
            cell: function (val) {
                var _a;
                if (val) {
                    return formatDate(val, (_a = column === null || column === void 0 ? void 0 : column.dateInterv) !== null && _a !== void 0 ? _a : '-', column === null || column === void 0 ? void 0 : column.dateFormat);
                    // return formatDate(val, column?.dateInterv ?? '-');
                }
                else {
                    return '- -';
                }
            },
        };
    },
    'date-time': function (column) {
        return {
            cell: function (val) {
                var _a, _b;
                if (val) {
                    return formatDateTime(val, {
                        interv: (_a = column === null || column === void 0 ? void 0 : column.dateInterv) !== null && _a !== void 0 ? _a : '-',
                        hasSecond: (_b = column === null || column === void 0 ? void 0 : column.dateHasSecond) !== null && _b !== void 0 ? _b : false,
                    });
                }
                else {
                    return '- -';
                }
            },
        };
    },
    file: function (column) {
        return {
            cell: function (cell) {
                return React.createElement(CnOSSUpload, __assign({ shape: "mini" }, column === null || column === void 0 ? void 0 : column.cnOssUploadProps, { value: cell, readOnly: true }));
            },
        };
    },
    bank: function (column) {
        return {
            cell: function (val) {
                return formatCardNumber("".concat(val), column.cardTypes);
            },
        };
    },
    ellipsis: formatEllipsis,
    micrometer: function () {
        return {
            cell: function (value) {
                var valueNumber = new BigNumber(value);
                var valueTypeVerify = typeof value === 'string' || typeof value === 'number';
                var valueIsLikeNumber = typeof valueNumber.toNumber() === 'number';
                var canMicrometer = valueTypeVerify && valueIsLikeNumber;
                if (canMicrometer) {
                    return valueNumber.toFormat();
                }
                return value;
            },
        };
    },
    enumeration: function (column) {
        var options = column.options;
        return {
            cell: function (value) {
                var _a, _b, _c, _d;
                return (React.createElement(CnAsyncSelect, __assign({}, (options || {}), { filterLocal: true, value: value, requestConfig: __assign({ cacheKey: "".concat((_a = options === null || options === void 0 ? void 0 : options.requestConfig) === null || _a === void 0 ? void 0 : _a.url, "+").concat((_b = options === null || options === void 0 ? void 0 : options.requestConfig) === null || _b === void 0 ? void 0 : _b.method, "+").concat(JSON.stringify((_c = options === null || options === void 0 ? void 0 : options.requestConfig) === null || _c === void 0 ? void 0 : _c.params), "+").concat(JSON.stringify((_d = options === null || options === void 0 ? void 0 : options.requestConfig) === null || _d === void 0 ? void 0 : _d.data)), staleTime: 5000 }, ((options === null || options === void 0 ? void 0 : options.requestConfig) || {})), readOnly: true })));
            },
        };
    },
};
function getNumFromStr(val) {
    return isNumberStr("".concat(val)) ? formatCurrency("".concat(val)) : val;
}
function formatCurrency(val) {
    var _a = val === null || val === void 0 ? void 0 : val.split('.'), pre = _a[0], suff = _a[1];
    return "".concat(Number(pre).toLocaleString('zh-CN')).concat(isNil(suff) ? '' : ".".concat(suff));
    // return Number(val).toLocaleString('zh-CN');
    // const split = ',';
    // const [pre, suff] = `${parseFloat(val)}`.split('.');
    // const reverseArr = pre
    //   .split('')
    //   .reverse()
    //   .join('')
    //   .replace(/([0-9]{3})/g, `$1${split}`)
    //   .split('')
    //   .reverse();
    // if (reverseArr[0] === split) {
    //   reverseArr.splice(0, 1);
    // }
    // return `${reverseArr.join('')}${isFunction(suff?.slice) ? `.${suff?.slice?.(0, 2)}` : ''
    //   }`;
}
function formatDate(val, interv, dateFormat) {
    if (val === void 0) { val = '0'; }
    if (interv === void 0) { interv = '-'; }
    if (dateFormat === void 0) { dateFormat = 'YYYY-MM-DD'; }
    if (interv !== '-') {
        var date = new Date(val !== null && val !== void 0 ? val : 0);
        return "".concat(date.getFullYear()).concat(interv).concat(date.getMonth() + 1).concat(interv).concat(date.getDate());
    }
    return dayjs(val).format(dateFormat);
}
function formatDateTime(val, _a) {
    var _b = _a.interv, interv = _b === void 0 ? '-' : _b, _c = _a.hasSecond, hasSecond = _c === void 0 ? true : _c;
    var date = new Date(val !== null && val !== void 0 ? val : 0);
    return "".concat(formatDate(val, interv), " ").concat(getTwoNumber(date.getHours()), ":").concat(getTwoNumber(date.getMinutes())).concat(hasSecond ? ":".concat(getTwoNumber(date.getSeconds())) : '');
}
function getTwoNumber(val) {
    var num = parseInt(String(val), 10);
    return num < 10 && num >= 0 ? "0".concat(num) : num;
}
function renderText(value) {
    var _a;
    if (isPlainObject(value)) {
        // 对象传入，目前支持ToolTips,link
        var linkStyle = (value === null || value === void 0 ? void 0 : value.link) ? { color: '#284cc0' } : {}; // 是否带超链
        var styleAttribute = Object.keys(value).reduce(function (pre, cur) {
            if (cur in domStyleMap) {
                pre[cur] = value[cur];
            }
            return pre;
        }, {});
        var textDom = (React.createElement("span", { style: __assign(__assign({}, linkStyle), styleAttribute), onClick: function () { return (value === null || value === void 0 ? void 0 : value.link) && window.open(value === null || value === void 0 ? void 0 : value.link); } }, renderValueHandleEmpty((_a = value.value) !== null && _a !== void 0 ? _a : value.text)));
        if (value === null || value === void 0 ? void 0 : value.toolTips) {
            // toolsTips
            var renderContent = function (data) {
                return (React.createElement("div", null, data.map(function (item) {
                    return React.createElement("div", null, item.desc);
                })));
            };
            if (Array.isArray(value === null || value === void 0 ? void 0 : value.toolTips)) {
                return (React.createElement(CnTooltip, { trigger: textDom, triggerType: "hover" }, renderContent(value.toolTips)));
            }
            else {
                return (React.createElement(CnTooltip, { trigger: textDom, triggerType: "hover" }, renderValueHandleEmpty(value.toolTips)));
            }
        }
        return textDom;
    }
    return renderValueHandleEmpty(value);
}
function renderLine(value, lineNumber) {
    var text = renderText(value);
    // let text;
    // if (isPlainObject(value)) {
    //   if (typeof value?.value === 'string') {
    //     text = value.value;
    //   }
    // } else if (typeof value === 'string') {
    //   text = value;
    // } else {
    //   try {
    //     text = JSON.stringify(value);
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }
    return (React.createElement("div", { className: "ctmt-line ctmt-line-".concat(lineNumber || 1) },
        React.createElement(CnTooltip, { v2: true, delay: 100, align: 't', trigger: React.createElement("span", null, text) }, text)));
}
export default CnTableCellFormat;
