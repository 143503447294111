import { __assign } from "tslib";
/**
 * 保存查询习惯
 */
import { useEffect, useRef, } from 'react';
import storage from '../storage';
import { useAsyncReducer, SaveSelectedReducers, SaveSelectedActionTypes } from '../store';
import isEqual from 'lodash/isEqual';
export var useSaveSelected = function (_a) {
    var enableSaveSelected = _a.enableSaveSelected, values = _a.values, storageKey = _a.storageKey, onGetStorage = _a.onGetStorage, onSetStorage = _a.onSetStorage, filterContext = _a.filterContext;
    var isInitStorageRef = useRef(true);
    var currentSelectedValuesRef = filterContext.currentSelectedValuesRef;
    var storageRef = useRef(storage({
        storageKey: storageKey,
        type: 'selected',
        getData: onGetStorage,
        setData: onSetStorage,
    }));
    var _b = useAsyncReducer(SaveSelectedReducers, {
        options: [],
        selected: undefined,
        overlayVisible: false,
    }), state = _b[0], dispatch = _b[1];
    var options = state.options;
    useEffect(function () {
        if (!enableSaveSelected)
            return;
        storageRef.current
            .getData()
            .then(function (data) {
            isInitStorageRef.current = false;
            if (data) {
                dispatch({
                    type: SaveSelectedActionTypes.SetOptions,
                    payload: data,
                });
            }
        })
            .catch(function () { });
    }, [enableSaveSelected]);
    useEffect(function () {
        if (!enableSaveSelected)
            return;
        if (isInitStorageRef.current)
            return;
        storageRef.current.setData(options).catch(function () { });
    }, [options, enableSaveSelected]);
    useEffect(function () {
        if (!enableSaveSelected)
            return;
        if (currentSelectedValuesRef.current &&
            Object.keys(__assign(__assign({}, currentSelectedValuesRef.current), values)).some(function (key) {
                var _a;
                return !isEqual((_a = currentSelectedValuesRef.current) === null || _a === void 0 ? void 0 : _a[key], values === null || values === void 0 ? void 0 : values[key]);
            })) {
            currentSelectedValuesRef.current = null;
            dispatch({
                type: SaveSelectedActionTypes.SetSelected,
                payload: undefined,
            });
        }
    }, [values, enableSaveSelected]);
    return [state, dispatch];
};
