import { getValueByPath } from './common';
// 根据尺寸类型计算单元格内边距宽度
export function safeCellPaddingWidth(size) {
    return size === 'large' ? 26 : 18;
}
// 渲染表头
export function safeRenderHeader(column) {
    var _a;
    return (_a = column.title) !== null && _a !== void 0 ? _a : column.name;
}
// 获取单元格的值
export function safeGetValue(column, record, rowIndex) {
    var _a;
    if (column.getValue) {
        return column.getValue(record, rowIndex);
    }
    return getValueByPath(record, "".concat((_a = column.code) !== null && _a !== void 0 ? _a : '').split('.'), undefined);
}
// 获取行键
export function safeGetRowKey(primaryKey, // primaryKey: 行键的属性名或者计算函数
record, // record: 当前行的数据
rowIndex) {
    // 返回值: 当前行的键
    var key; // 初始化键
    if (typeof primaryKey === 'string') {
        // 如果行键是属性名
        key = record[primaryKey]; // 获取行键
    }
    else if (typeof primaryKey === 'function') {
        // 如果行键是计算函数
        key = primaryKey(record); // 计算行键
    }
    if (key == null) {
        // 如果行键不存在
        key = String(rowIndex); // 使用行索引作为行键
    }
    return key; // 返回行键
}
// 定义渲染前缀
export var renderPre = Symbol('render-pre');
// 获取渲染键
export function safeGetRenderKey(primaryKey, record, rowIndex) {
    var _a;
    var key = '';
    var pre = (_a = record[renderPre]) !== null && _a !== void 0 ? _a : '';
    if (typeof primaryKey === 'string') {
        key = record[primaryKey];
    }
    else if (typeof primaryKey === 'function') {
        key = primaryKey(record);
    }
    if (key == null) {
        key = String(rowIndex);
    }
    return "".concat(pre).concat(key);
}
// 获取单元格属性
export function safeGetCellProps(column, record, rowIndex) {
    if (column.getCellProps) {
        var value = safeGetValue(column, record, rowIndex);
        return column.getCellProps(value, record, rowIndex) || {};
    }
    return {};
}
// 渲染单元格
export function safeRender(column, record, rowIndex) {
    var value = safeGetValue(column, record, rowIndex);
    if (column.render) {
        return column.render(value, record, rowIndex);
    }
    return value;
}
