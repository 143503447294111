/**
 * 如果链接是相对协议URL，则自动补全 Protocol
 */
export var attachUrlProtocol = function (url) {
    if (url.startsWith('//')) {
        return "".concat(location.protocol).concat(url);
    }
    return url;
};
/**
 * 获取链接中的文件名
 * 如果获取失败，使用链接自身作为兜底
 */
export var getBasename = function (url) {
    try {
        var pathname = new URL(attachUrlProtocol(url)).pathname;
        var basename = pathname.split('/').pop();
        if (basename)
            return basename;
    }
    catch (_a) { }
};
/** 获取文件格式，不包含. */
export var getExtname = function (url) {
    var _a;
    var basename = getBasename(url);
    return (_a = basename === null || basename === void 0 ? void 0 : basename.split('.').pop()) === null || _a === void 0 ? void 0 : _a.toLowerCase();
};
