export default (function (file, accepted) {
    if (file && accepted) {
        var acceptedList = Array.isArray(accepted)
            ? accepted
            : accepted.split(',');
        var fileName_1 = file.name || '';
        var mimeType_1 = file.type || '';
        var baseMimeType_1 = mimeType_1.replace(/\/.*$/, '');
        return acceptedList.some(function (type) {
            var validType = type.trim();
            // This is something like */*,*  allow all files
            if (/^\*(\/\*)?$/.test(type)) {
                return true;
            }
            // like .jpg, .png
            if (validType.charAt(0) === '.') {
                var lowerFileName_1 = fileName_1.toLowerCase();
                var lowerType = validType.toLowerCase();
                var affixList = [lowerType];
                if (lowerType === '.jpg' || lowerType === '.jpeg') {
                    affixList = ['.jpg', '.jpeg'];
                }
                return affixList.some(function (affix) { return lowerFileName_1.endsWith(affix); });
            }
            // This is something like a image/* mime type
            if (/\/\*$/.test(validType)) {
                return baseMimeType_1 === validType.replace(/\/.*$/, '');
            }
            // Full match
            if (mimeType_1 === validType) {
                return true;
            }
            // Invalidate type should skip
            if (/^\w+$/.test(validType)) {
                return true;
            }
            return false;
        });
    }
    return true;
});
