import * as React from 'react';
import { CnBox } from '@/components/cn-box';
import { CnStatusTag } from '@/components/cn-status-tag';
/**
 * 根据传入的参数生成对应的标签
 * @param val - 传入的参数，可以是字符串或者对象
 * @returns 生成的标签
 */
function getTag(val) {
    if (typeof val === 'string') {
        // 如果传入的是字符串
        return React.createElement(CnStatusTag, null, val);
    }
    if (typeof (val === null || val === void 0 ? void 0 : val.color) === 'string' && (val === null || val === void 0 ? void 0 : val.color)) {
        // 如果传入的是对象，且有颜色属性
        return (React.createElement(CnStatusTag, { style: { backgroundColor: val === null || val === void 0 ? void 0 : val.color, color: '#ffffff' } }, (val === null || val === void 0 ? void 0 : val.value) || ''));
    }
    else {
        // 如果传入的是对象，但没有颜色属性
        var displayStatus = void 0;
        if ((val === null || val === void 0 ? void 0 : val.state) === 'notice') {
            displayStatus = 'info';
        }
        else if ((val === null || val === void 0 ? void 0 : val.state) === 'normal') {
            displayStatus = 'invalid';
        }
        else {
            displayStatus = (val === null || val === void 0 ? void 0 : val.state) || 'info';
        }
        return React.createElement(CnStatusTag, { status: displayStatus }, (val === null || val === void 0 ? void 0 : val.value) || '');
    }
}
export default {
    cell: function (val) {
        if (Array.isArray(val)) {
            return (React.createElement(CnBox, { spacing: 5, wrap: true, direction: "row", align: "center" }, val.map(function (v) { return getTag(v); })));
        }
        return getTag(val);
    },
};
