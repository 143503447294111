import { componentName } from './const';
import { withI18n } from 'panda-i18n';
import locale from '@/locales';
import { EmployeeSelect as PureEmployeeSelect, } from './view/employee-select';
import './index.scss';
export * from './view/types';
export var CnEmployeeSelect = withI18n(PureEmployeeSelect, {
    componentName: 'CnEmployeeSelect',
    locale: locale,
});
CnEmployeeSelect.displayName = componentName;
