import { __assign } from "tslib";
import { useEffect, useRef } from 'react';
import isFunction from 'lodash/isFunction';
import isPlainObject from 'lodash/isPlainObject';
import isObject from 'lodash/isObject';
// 其他组件
import { safeCallFunction } from '@/components/cn-utils';
import { useLatest } from 'ahooks';
import { getTransform, shouldGetDefaultParams, setInnerInfo, } from '../../utils';
// 当前目录的相对依赖
import fetch from './fetch';
import { globalConfig } from '../../config';
function setRemoteInstance(instance, fetchData) {
    var _a;
    // @ts-ignore
    if (isPlainObject((_a = instance === null || instance === void 0 ? void 0 : instance.remoteOperation) === null || _a === void 0 ? void 0 : _a.current)) {
        // @ts-ignore
        instance.remoteOperation.current.fetchData = fetchData;
    }
}
export function useRemote(state, callback) {
    var _a;
    var pageRenderInfo = state.pageRenderInfo, setLoading = state.setLoading, remote = state.remote, innerParams = state.innerParams, innerInfo = state.innerInfo;
    var fetchCallRef = useLatest(fetchData);
    var isInitFetch = useRef(false);
    var lockRef = useRef(false);
    var immediateRef = useRef((_a = remote === null || remote === void 0 ? void 0 : remote.immediate) !== null && _a !== void 0 ? _a : true);
    setRemoteInstance(remote === null || remote === void 0 ? void 0 : remote.instance, fetchData);
    setInnerInfo(remote === null || remote === void 0 ? void 0 : remote.instance, innerInfo);
    useEffect(function () {
        // 是否首次render禁止请求
        if (!immediateRef.current) {
            return;
        }
        // 如果是第一次请求需要保存defaultCurrentPage等参数
        if (isInitFetch.current === false) {
            safeCallFunction(fetchCallRef.current('load'));
        }
        else {
            // 如果不是在第一页点查询就算参数没变也需要回归到第一页
            safeCallFunction(fetchCallRef.current('refresh'));
        }
        isInitFetch.current = true;
        // isFunction(fetchCallRef.current) && fetchCallRef.current(true);
    }, [remote === null || remote === void 0 ? void 0 : remote.url, remote === null || remote === void 0 ? void 0 : remote.params]);
    // 该锁顺序必须在 上面两个Effect之后
    useEffect(function () {
        immediateRef.current = true;
    }, []);
    return fetchData;
    /**
     * 请求远程数据
     * @param params
     * @returns
     */
    function fetchData(params) {
        var _a;
        var _b, _c;
        // shouldClearData
        var clearDataType;
        if (isObject(params)) {
            if ('shouldClearData' in params && params.shouldClearData) {
                clearDataType = 'refresh';
            }
            else {
                clearDataType = 'load';
            }
        }
        else {
            clearDataType = params;
        }
        var paramsData = typeof params === 'object' ? params : {};
        // 前置判断
        if (!(remote === null || remote === void 0 ? void 0 : remote.url) && !isFunction(remote === null || remote === void 0 ? void 0 : remote.fetch)) {
            return Promise.reject();
        }
        if (lockRef.current) {
            lockRef.current = false;
            return Promise.reject();
        }
        // 设置请求锁
        setLoading(true);
        lockRef.current = true;
        var paramsTransform = getTransform((_b = remote === null || remote === void 0 ? void 0 : remote.paramsTransform) !== null && _b !== void 0 ? _b : globalConfig === null || globalConfig === void 0 ? void 0 : globalConfig.defaultParamsTransform);
        // 获取参数
        var finalQuery = paramsTransform(__assign(__assign(__assign(__assign({}, shouldGetDefaultParams(__assign({ currentPage: pageRenderInfo === null || pageRenderInfo === void 0 ? void 0 : pageRenderInfo.currentPage }, ((innerParams === null || innerParams === void 0 ? void 0 : innerParams.current) || {})), clearDataType)), { pageSize: pageRenderInfo === null || pageRenderInfo === void 0 ? void 0 : pageRenderInfo.pageSize }), ((remote === null || remote === void 0 ? void 0 : remote.params) || {})), paramsData));
        var method = (_c = remote === null || remote === void 0 ? void 0 : remote.method) !== null && _c !== void 0 ? _c : 'post';
        var doFetch = (remote === null || remote === void 0 ? void 0 : remote.fetch) instanceof Function
            ? remote === null || remote === void 0 ? void 0 : remote.fetch(finalQuery)
            : fetch(__assign(__assign({ url: remote === null || remote === void 0 ? void 0 : remote.url, method: method }, ((remote === null || remote === void 0 ? void 0 : remote.requestOptions) || {})), (_a = {}, _a[(method === null || method === void 0 ? void 0 : method.toLocaleLowerCase()) === 'post' ? 'data' : 'params'] = finalQuery, _a)));
        return ((doFetch === null || doFetch === void 0 ? void 0 : doFetch.then) instanceof Function &&
            doFetch
                .then(function (res) {
                function handleResponseTransform() {
                    if (isFunction(remote === null || remote === void 0 ? void 0 : remote.responseTransform)) {
                        return remote.responseTransform;
                    }
                    if (isFunction(globalConfig.responseTransform)) {
                        return globalConfig.responseTransform;
                    }
                    return globalConfig.responseTransform;
                }
                var responseTransform = handleResponseTransform();
                // @ts-ignore 支持处理responseTransform 返回格式为Promise
                Promise.resolve(responseTransform((res === null || res === void 0 ? void 0 : res.data) || res, res)).then(function (finalRes) {
                    setLoading(false);
                    lockRef.current = false;
                    callback &&
                        isFunction(callback) &&
                        callback(finalRes, clearDataType);
                }, errorHandler);
            })
                .catch(errorHandler)
                .finally(function () {
                isFunction(remote === null || remote === void 0 ? void 0 : remote.onFinally) && (remote === null || remote === void 0 ? void 0 : remote.onFinally());
            }));
    }
    function errorHandler() {
        var _a, _b, _c;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        setLoading(false);
        lockRef.current = false;
        isFunction(remote === null || remote === void 0 ? void 0 : remote.failCallback) && (remote === null || remote === void 0 ? void 0 : remote.failCallback.apply(remote, args));
        (_a = innerInfo === null || innerInfo === void 0 ? void 0 : innerInfo.setDataSource) === null || _a === void 0 ? void 0 : _a.call(innerInfo, []);
        (_c = (_b = innerInfo === null || innerInfo === void 0 ? void 0 : innerInfo.pageInfo) === null || _b === void 0 ? void 0 : _b.setTotal) === null || _c === void 0 ? void 0 : _c.call(_b, 0);
    }
}
