import { __awaiter, __generator } from "tslib";
import $i18n from 'panda-i18n';
import { cnQuickDialogForm } from '@/form';
export function renameQuickDialog(props) {
    return __awaiter(this, void 0, void 0, function () {
        var reanmeDefaultJobName, renameTitle;
        var _this = this;
        return __generator(this, function (_a) {
            reanmeDefaultJobName = props === null || props === void 0 ? void 0 : props.defaultJobName;
            renameTitle = (props === null || props === void 0 ? void 0 : props.title) ||
                $i18n.get({ id: 'ExportFileNaming', dm: '导出文件命名', ns: 'CnExport' });
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    cnQuickDialogForm({ title: renameTitle }, {
                        type: 'object',
                        properties: {
                            jobName: {
                                'x-decorator': 'CnFormItem',
                                default: reanmeDefaultJobName,
                                'x-component': 'CnInput',
                                'x-component-props': {
                                    trim: true,
                                    autoFocus: true,
                                    hasClear: true,
                                    required: true,
                                },
                            },
                        },
                    })
                        .forConfirm(function (form, next) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            try {
                                resolve(form.values.jobName);
                            }
                            catch (err) {
                                reject(err);
                            }
                            next();
                            return [2 /*return*/];
                        });
                    }); })
                        .open();
                })];
        });
    });
}
