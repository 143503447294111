import { __assign } from "tslib";
/**
 * 附加参数数据到CnRequestConfig中
 * 目前只处理了get和post请求
 */
export function requestAttachData(config, extraData) {
    var _a;
    var method = ((_a = config.method) === null || _a === void 0 ? void 0 : _a.toLowerCase()) || 'get';
    if (method === 'get') {
        var params = __assign(__assign({}, config.params), extraData);
        return __assign(__assign({}, config), { params: params });
    }
    if (method === 'post') {
        var data = __assign(__assign({}, config.data), extraData);
        return __assign(__assign({}, config), { data: data });
    }
    return config;
}
