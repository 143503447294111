import { __assign } from "tslib";
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import pick from 'lodash/pick';
import { domStyleMap } from '@/components/cn-utils';
export function headerCellPropsMergeStylePipeline() {
    return function (pipeline) {
        var columns = pipeline.getColumns();
        var mergeHeaderCellPropsColumns = columns.map(function (column) {
            column = headerCellPropsStyleFilter(column);
            return column;
        });
        return pipeline.columns(mergeHeaderCellPropsColumns);
    };
}
function headerCellPropsStyleFilter(column) {
    var headerCellProps = column.headerCellProps;
    if (isEmpty(column) || isNil(headerCellProps)) {
        return column;
    }
    if (Array.isArray(column.children)) {
        column.children = column.children.map(function (element) {
            return headerCellPropsStyleFilter(element);
        });
    }
    var styleAttribute = pick(headerCellProps, Object.keys(domStyleMap));
    // const styleAttribute = Object.keys(headerCellProps).reduce((pre, cur) => {
    //   if (cur in domStyleMap) {
    //     pre[cur] = headerCellProps[cur];
    //   }
    //   return pre;
    // }, {});
    if (headerCellProps.style) {
        headerCellProps.style = __assign(__assign({}, headerCellProps.style), styleAttribute);
    }
    else {
        headerCellProps.style = styleAttribute;
    }
    column.headerCellProps = headerCellProps;
    return column;
}
