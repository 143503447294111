import { __assign, __spreadArray } from "tslib";
// 第三方依赖
import * as React from 'react';
import isNil from 'lodash/isNil';
import debounce from 'lodash/debounce';
export function useColumnsResize(props, columns, setColumns) {
    var columnResizeProps = props.columnResize;
    var columnResize = {};
    var onChange = React.useCallback(debounce(function (info, dataIndex, width) {
        var columnsWidth = columns.map(function (el) {
            if (el.dataIndex === dataIndex) {
                return __assign(__assign({}, el), { width: width });
            }
            return el;
        });
        setColumns(columnsWidth, 'user');
    }, 550), [columnResizeProps === null || columnResizeProps === void 0 ? void 0 : columnResizeProps.onChange, columns]);
    if (isNil(columnResizeProps) || columnResizeProps == false) {
        return columnResizeProps;
    }
    if (columnResizeProps === undefined)
        return columnResizeProps;
    columnResize.onChange = function () {
        var _a;
        var res = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            res[_i] = arguments[_i];
        }
        (_a = columnResizeProps === null || columnResizeProps === void 0 ? void 0 : columnResizeProps.onChange) === null || _a === void 0 ? void 0 : _a.call.apply(_a, __spreadArray([columnResizeProps], res, false));
        onChange.apply(void 0, res);
    };
    return __assign(__assign({}, (typeof columnResizeProps === 'object' ? columnResizeProps : {})), columnResize);
}
