import { __assign, __rest } from "tslib";
import { useI18n } from 'panda-i18n';
import * as React from 'react';
import { CnIcon } from '@/components/cn-icon';
import { sendLog } from '@/components/cn-utils';
import DragModal from '../drag-modal';
import { SaveConfigActionTypes } from '../../store';
import { CnButton } from '@/components/cn-button';
export var ConfigButton = function (_a) {
    var enableConfig = _a.enableConfig, store = _a.store, dispatch = _a.dispatch, props = __rest(_a, ["enableConfig", "store", "dispatch"]);
    var overlayVisible = store.overlayVisible;
    if (!enableConfig)
        return null;
    return (React.createElement(CnButton, __assign({}, props, { className: "cn-ui-filter-setting-btn", onClick: function () {
            sendLog({
                id: 'cn-ui.cn-filter.clickSaveConfigButton',
            });
            dispatch({
                type: SaveConfigActionTypes.SetOverlayVisible,
                payload: !overlayVisible,
            });
        } }),
        React.createElement("span", null,
            React.createElement(CnIcon, { type: "setting" }))));
};
export var ConfigModal = function (_a) {
    var enableConfig = _a.enableConfig, enableSaveSelected = _a.enableSaveSelected, onConfigValueChange = _a.onConfigValueChange, propConfigValue = _a.configValue, store = _a.store, dispatch = _a.dispatch, gridProps = _a.gridProps, saveSelectSpan = _a.saveSelectSpan;
    var dataSource = store.dataSource, overlayVisible = store.overlayVisible, configInited = store.configInited, configValue = store.configValue;
    var $i18n = useI18n();
    if (!enableConfig)
        return null;
    // 内部状态改变更新配置 meta
    var updateConfigValue = function (nextConfigValue) {
        if (enableConfig) {
            onConfigValueChange && onConfigValueChange(nextConfigValue);
            if (propConfigValue === undefined) {
                dispatch({
                    type: SaveConfigActionTypes.SetConfigValue,
                    payload: nextConfigValue,
                });
            }
        }
    };
    var updateDataSource = function (nextDataSource) {
        updateConfigValue(nextDataSource.map(function (nextDataSourceItem) {
            var visible = nextDataSourceItem.visible, value = nextDataSourceItem.value;
            return {
                visible: visible,
                name: value,
            };
        }));
    };
    var setOverlayVisible = function (payload) {
        dispatch({
            type: SaveConfigActionTypes.SetOverlayVisible,
            payload: payload,
        });
    };
    return (React.createElement(DragModal, __assign({}, {
        enableSaveSelected: enableSaveSelected,
        dataSource: dataSource,
        updateDataSource: updateDataSource,
        setOverlayVisible: setOverlayVisible,
        configInited: configInited,
        configValue: configValue,
        overlayVisible: overlayVisible,
        gridProps: gridProps,
        saveSelectSpan: saveSelectSpan,
        $i18n: $i18n,
    })));
};
