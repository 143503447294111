import { __assign, __rest } from "tslib";
import * as React from 'react';
import classNames from 'classnames';
export function FilterContainer(props) {
    var children = props.children, className = props.className, footer = props.footer, rest = __rest(props, ["children", "className", "footer"]);
    var classes = classNames(className, 'cn-table-filter-container');
    return (React.createElement("div", __assign({ "data-testid": "CnBaseTableFilterContainer", className: classes }, rest),
        children,
        footer));
}
