import { withI18n } from 'panda-i18n';
import { events } from '@/components/cn-utils';
import locale from '@/locales';
import { HIDE_PANEL, SHOW_PANEL } from './constant';
import { CnAsyncJobs as CnAsyncJobsView } from './async-jobs-pro';
import './index.scss';
export var CnAsyncJobsPro = withI18n(CnAsyncJobsView, {
    locale: locale,
    componentName: 'CnAsyncJobsPro',
});
CnAsyncJobsPro.showPanel = function (options) {
    return events.emit(SHOW_PANEL, options);
};
CnAsyncJobsPro.hidePanel = function () { return events.emit(HIDE_PANEL); };
