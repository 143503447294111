import { connect, mapProps } from '@formily/react';
import { CnTimePicker2 as UiCnTimePicker2, CnRangeTimePicker2 as UiCnRangeTimePicker2, CnTimePickerPro as UiCnTimePickerPro, CnRangeTimePickerPro as UiCnRangeTimePickerPro, } from '@/components/cn-time-picker-pro';
import { mapSize, mapStatus } from '@/form/__builtins__';
/**
 * @deprecated 请使用 CnTimePickerPro
 */
export var CnTimePicker2 = connect(UiCnTimePicker2, mapProps(mapSize, mapStatus));
/**
 * @deprecated 请使用 CnRangeTimePickerPro
 */
export var CnRangeTimePicker2 = connect(UiCnRangeTimePicker2, mapProps(mapSize, mapStatus));
export var CnTimePickerPro = connect(UiCnTimePickerPro, mapProps(mapSize, mapStatus));
export var CnRangeTimePickerPro = connect(UiCnRangeTimePickerPro, mapProps(mapSize, mapStatus));
