import { __spreadArray } from "tslib";
import { genId } from '../../utils';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
export var ActionTypes = {
    SetOptions: 'SetOptions',
    SetSelected: 'SetSelected',
    SetOverlayVisible: 'SetOverlayVisible',
    SetOverlayRef: 'SetOverlayRef',
};
export var Actions = {
    saveSetOptions: function (state, params) {
        var options = state.options;
        var data = params.data, name = params.name;
        return function (dispatch) {
            var value = genId(options.map(function (opt) { return opt.value; }));
            dispatch({
                type: ActionTypes.SetSelected,
                payload: value,
            });
            var newOptions = filter(options, function (i) { return i.label !== name; });
            var saveData = __spreadArray([
                {
                    label: name,
                    value: value,
                    data: data,
                }
            ], newOptions, true);
            dispatch({
                type: ActionTypes.SetOptions,
                payload: saveData,
            });
            return saveData;
        };
    },
    clearSelected: function (state, params) {
        var options = state.options, selected = state.selected;
        var values = params.values;
        return function (dispatch) {
            var findSelected = options.find(function (i) { return i.value === selected; });
            if (!findSelected || !findSelected.data)
                return;
            if (!isEqual(findSelected.data, values)) {
                dispatch({
                    type: ActionTypes.SetSelected,
                    payload: undefined,
                });
            }
        };
    },
};
