import { __assign } from "tslib";
// @ts-nocheck
/**
 * usage:
 *
 * 1、链式调用 string ｜ object
 * CnMessage.error("错误信息");
 * CnMessage.show({
    type: 'error',
    duration: 200000,
    title: '长度过长',
    content: 'this is content',
    afterClose: () => console.log('Closed the toast'),
  });
 *
 * 2、组件调用
 * <CnMessage title="异常信息" type="error" iconType="exception-manage-fill">
        <Box direction="col" align="center">
          重要提示信息：务必要先check候选人意向再发起操作！
          <CnButton type="primary" size="small">
            面试通过
          </CnButton>
        </Box>
      </CnMessage>
 */
import * as React from 'react';
import Message from './message';
import './index.scss';
// 用户透出信息的上报 fengyue 2022-08-25
var cnMessageShowToUserReport = function (props, type) {
    var _a;
    try {
        // 获取第一个文本的前100字符
        var getFirstText_1 = function (rcNode) {
            if (!React.isValidElement(rcNode)) {
                return "".concat(rcNode);
            }
            return getFirstText_1(React.Children.toArray(rcNode.props.children)[0]).slice(0, 100);
        };
        // 链式调用props?.content
        // 组件调用props?.children
        var hottagName = (typeof props === 'string') ? props.slice(0, 100) : getFirstText_1((props === null || props === void 0 ? void 0 : props.children) || (props === null || props === void 0 ? void 0 : props.content));
        // console.log('cnMessage/type/hottagName', type, hottagName);
        return (_a = window === null || window === void 0 ? void 0 : window.coneArmsTrace) === null || _a === void 0 ? void 0 : _a.reportHottag({
            name: hottagName,
            eventType: 'showToUser',
            hottag: "cn-ui.cn-message.show-".concat(type),
        });
    }
    catch (error) {
        console.log(error);
    }
};
export var CnMessage = function (props) {
    React.useEffect(function () {
        cnMessageShowToUserReport(props, props === null || props === void 0 ? void 0 : props.type);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Message, __assign({ "data-testid": "CnMessage", "data-name": "CnMessage" }, props))));
};
CnMessage.success = function (props) {
    // cnMessageShowToUserReport(props, 'success');
    if (typeof props === 'string') {
        return Message.success({
            content: props,
        });
    }
    return Message.success(__assign({}, props));
};
CnMessage.warning = function (props) {
    cnMessageShowToUserReport(props, 'warning');
    if (typeof props === 'string') {
        return Message.warning({
            content: props,
        });
    }
    return Message.warning(__assign({}, props));
};
CnMessage.error = function (props) {
    cnMessageShowToUserReport(props, 'error');
    if (typeof props === 'string') {
        return Message.error({
            content: props,
        });
    }
    return Message.error(__assign({}, props));
};
CnMessage.help = function (props) {
    // cnMessageShowToUserReport(props, 'help');
    if (typeof props === 'string') {
        return Message.help({
            content: props,
        });
    }
    return Message.help(__assign({}, props));
};
CnMessage.loading = function (props) {
    // cnMessageShowToUserReport(props, 'loading');
    if (typeof props === 'string') {
        return Message.loading({
            content: props,
        });
    }
    return Message.loading(__assign({}, props));
};
CnMessage.notice = function (props) {
    // cnMessageShowToUserReport(props, 'notice');
    if (typeof props === 'string') {
        return Message.notice({
            content: props,
        });
    }
    return Message.notice(__assign({}, props));
};
CnMessage.show = function (props) {
    if (typeof props === 'string') {
        return Message.show({
            content: props,
        });
    }
    return Message.show(__assign({}, props));
};
CnMessage.config = function (props) {
    if (typeof props === 'string') {
        return Message.config(props);
    }
    return Message.config(__assign({}, props));
};
CnMessage.destory = function () {
    return Message.destory();
};
CnMessage.hide = function () {
    return Message.hide();
};
CnMessage.displayName = 'CnMessage';
