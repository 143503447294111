import { useRef, useEffect, useCallback } from 'react';
import { updateRef } from './utils';
export function useInitial(callback) {
    var current = useRef({ sign: false, fn: undefined }).current;
    if (!current.sign) {
        current.sign = true;
        current.fn = callback();
    }
    return current.fn;
}
export function useLatest(something) {
    var ref = useRef(something);
    ref.current = something;
    return ref;
}
export function useEventListener(type, fn, options) {
    var latest = useLatest(fn);
    useEffect(function () {
        function wrapper(evt) {
            latest.current(evt);
        }
        if (type) {
            window.addEventListener(type, wrapper, options);
        }
        return function () {
            if (type) {
                window.removeEventListener(type, wrapper);
            }
        };
    }, [type]);
}
export function useResizeObserver(scrollBoxRef, callback) {
    var throttleCallback = useDebounceCallback(callback, { maxWait: 8, leading: true });
    useEffect(function () {
        var resizeObserver = new ResizeObserver(function () {
            throttleCallback();
        });
        if (scrollBoxRef.current) {
            if (scrollBoxRef.current === document.documentElement) {
                resizeObserver.observe(document.body);
            }
            else {
                resizeObserver.observe(scrollBoxRef.current);
                Array.from(scrollBoxRef.current.children).forEach(function (child) {
                    resizeObserver.observe(child);
                });
            }
        }
        return function () {
            resizeObserver.disconnect();
        };
    }, [scrollBoxRef]);
}
export function useDebounceCallback(callback, _a) {
    var _b = _a.leading, leading = _b === void 0 ? false : _b, maxWait = _a.maxWait, _c = _a.wait, wait = _c === void 0 ? maxWait || 0 : _c;
    var callbackRef = useLatest(callback);
    var prev = useRef(0);
    var trailingTimeout = useRef();
    var clearTrailing = function () { return trailingTimeout.current && clearTimeout(trailingTimeout.current); };
    useEffect(function () { return function () {
        prev.current = 0;
        clearTrailing();
    }; }, [wait, maxWait, leading]);
    return useCallback(function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var now = Date.now();
        function call() {
            prev.current = now;
            clearTrailing();
            callbackRef.current.apply(null, args);
        }
        var last = prev.current;
        var offset = now - last;
        // leading
        if (last === 0) {
            if (leading) {
                call();
                return;
            }
            prev.current = now;
        }
        // body
        if (maxWait !== undefined) {
            if (offset > maxWait) {
                call();
                return;
            }
        }
        else if (offset < wait) {
            prev.current = now;
        }
        // trailing
        clearTrailing();
        trailingTimeout.current = setTimeout(function () {
            call();
            prev.current = 0;
        }, wait);
    }, [wait, maxWait, leading]);
}
export function useSyncRef(innerRef, scrollBoxRef) {
    useEffect(function () {
        updateRef(innerRef, scrollBoxRef.current);
        return function () {
            updateRef(innerRef, null);
        };
    }, [innerRef, scrollBoxRef]);
}
