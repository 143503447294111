import $i18n from 'panda-i18n';
import { useMemo } from 'react';
export var useStatusMap = function () {
    return useMemo(function () {
        return {
            EXECUTING: $i18n.get({
                id: 'InProgress',
                dm: '进行中',
                ns: 'CnAsyncJobsPro',
            }),
            SUCCEEDED: $i18n.get({
                id: 'Completed',
                dm: '已完成',
                ns: 'CnAsyncJobsPro',
            }),
            FAILED: $i18n.get({ id: 'Failure', dm: '失败', ns: 'CnAsyncJobsPro' }),
            SOME: $i18n.get({
                id: 'PartialCompletion',
                dm: '部分完成',
                ns: 'CnAsyncJobsPro',
            }),
        };
    }, []);
};
