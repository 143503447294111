import { findTarget } from './utils';
export function getInitNextWidth(e, leafColumns) {
    var _a;
    var tableDom = findTarget(e.target, function (el) { return el.tagName === 'TABLE'; });
    if (((_a = tableDom === null || tableDom === void 0 ? void 0 : tableDom.children) === null || _a === void 0 ? void 0 : _a.length) > 0) {
        var nextWidth_1 = {};
        // @ts-ignore
        Array.from(tableDom === null || tableDom === void 0 ? void 0 : tableDom.children).forEach(function (_dom) {
            var _a;
            if ((_dom === null || _dom === void 0 ? void 0 : _dom.tagName) === 'COLGROUP' && ((_a = _dom === null || _dom === void 0 ? void 0 : _dom.children) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                Array.from(_dom === null || _dom === void 0 ? void 0 : _dom.children).forEach(function (colDom, index) {
                    var _a, _b, _c;
                    if (colDom) {
                        var matchColum = leafColumns[index];
                        if (matchColum) {
                            var colCode = (_a = matchColum === null || matchColum === void 0 ? void 0 : matchColum.code) !== null && _a !== void 0 ? _a : (_b = matchColum === null || matchColum === void 0 ? void 0 : matchColum.range) === null || _b === void 0 ? void 0 : _b.startIndex;
                            nextWidth_1[colCode] =
                                colDom.clientWidth > 0
                                    ? colDom.clientWidth
                                    : // chrome 浏览器有的版本
                                        parseInt((_c = colDom === null || colDom === void 0 ? void 0 : colDom.style) === null || _c === void 0 ? void 0 : _c.width, 10);
                        }
                    }
                });
            }
        });
        return nextWidth_1;
    }
    return {};
}
