export var useShowUploadPreviewIcon = function (params) {
    var file = params.file, onPreview = params.onPreview;
    if (typeof params.showPreview === 'function') {
        return params.showPreview(file);
    }
    if (!params.showPreview)
        return false;
    // 当 status 存在的时候，代表文件还在表单待提交态
    if (file.status) {
        var isUploading = ['idle', 'uploading'].includes(file.status);
        if (isUploading)
            return false;
    }
    return Boolean(onPreview) || Boolean(file.url);
};
