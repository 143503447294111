import $i18n from 'panda-i18n';
import isArrayLike from 'lodash/isArrayLike';
export default (function (value, rule) {
    if (isArrayLike(value) && value.length === rule.len) {
        return true;
    }
    return ($i18n.get({
        id: 'LengthOrNumberOfEntriesMust_1835290357',
        dm: '长度或条目数必须为',
        ns: 'CnForm',
    }) + rule.len);
});
