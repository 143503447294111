import { __assign } from "tslib";
import * as React from 'react';
import { connect, mapProps } from '@formily/react';
import { CnCard as UiCnCard, CnCardSubCard as UiCnCardSubCard, CnCardSubAreaCard as UiCnCardSubAreaCard, } from '@/components/cn-card';
import { mapSize, mapStatus } from '@/form/__builtins__';
var mapTitle = function (props, field) {
    var _a;
    return __assign(__assign({}, props), { title: props.title ? (React.createElement("div", { id: (_a = field === null || field === void 0 ? void 0 : field.address) === null || _a === void 0 ? void 0 : _a.toString().replace(/\./g, '_'), className: "cn-ui-form-card-title" }, props.title)) : null });
};
var mapSubTitle = function (props, field) {
    var _a;
    return __assign(__assign({}, props), { subTitle: props.subTitle ? (React.createElement("div", { id: (_a = field === null || field === void 0 ? void 0 : field.address) === null || _a === void 0 ? void 0 : _a.toString().replace(/\./g, '_'), className: "cn-ui-form-card-title" }, props.subTitle)) : null });
};
export var CnCard = connect(UiCnCard, mapProps(mapSize, mapStatus, mapTitle));
export var CnCardSubCard = connect(UiCnCardSubCard, mapProps(mapSize, mapStatus, mapSubTitle));
export var CnCardSubAreaCard = connect(UiCnCardSubAreaCard, mapProps(mapSize, mapStatus, mapTitle));
