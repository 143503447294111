export function isEnableScrollbar() {
    return typeof navigator !== 'undefined';
}
export function updateRef(innerRef, scrollBoxElement) {
    if (!innerRef) {
        return;
    }
    if (typeof innerRef === 'function') {
        innerRef(scrollBoxElement);
        return;
    }
    // @ts-ignore
    innerRef.current = scrollBoxElement;
}
export function getGapSize(trackGap, showBothBar) {
    if (Array.isArray(trackGap)) {
        var startX = trackGap[0], endX = trackGap[1], startY = trackGap[2], endY = trackGap[3];
        return [startX, startX + endX, startY, startY + endY];
    }
    var endGap = showBothBar ? trackGap : 0;
    return [0, endGap, 0, endGap];
}
export function handleExtractSize(target) {
    var clientWidth = target.clientWidth, scrollWidth = target.scrollWidth, clientHeight = target.clientHeight, scrollHeight = target.scrollHeight;
    var _a = window.getComputedStyle(target), paddingTop = _a.paddingTop, paddingRight = _a.paddingRight, paddingBottom = _a.paddingBottom, paddingLeft = _a.paddingLeft;
    return {
        CW: clientWidth,
        SW: scrollWidth,
        CH: clientHeight,
        SH: scrollHeight,
        PT: parseInt(paddingTop, 10),
        PR: parseInt(paddingRight, 10),
        PB: parseInt(paddingBottom, 10),
        PL: parseInt(paddingLeft, 10),
    };
}
export function isEnableStyle(disabled) {
    return disabled ? 'hidden' : 'auto';
}
export function updateElementStyle(element, obj) {
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (var key in obj) {
        element.style[key] = "".concat(obj[key], "px");
    }
}
export function computeRatio(scrollSize, clientSize, gapSize, minThumbSize) {
    if (minThumbSize === void 0) { minThumbSize = 20; }
    var boxSize = clientSize - gapSize;
    var realThumbSize = (boxSize / scrollSize) * boxSize;
    var thumbSize = Math.max(minThumbSize, realThumbSize);
    var remaining = boxSize - thumbSize;
    var distance = scrollSize - clientSize;
    return {
        thumbSize: thumbSize,
        ratio: remaining / distance,
    };
}
export function updateScrollPosition(element, position, horizontal) {
    if (!element) {
        return;
    }
    if (horizontal) {
        element.scrollLeft = position;
        return;
    }
    element.scrollTop = position;
}
export function updateScrollElementStyle(containerElement, horizontalElement, verticalElement, gapX, gapY, minThumbSize) {
    if (!containerElement) {
        return;
    }
    var scrollTop = containerElement.scrollTop, scrollLeft = containerElement.scrollLeft, scrollWidth = containerElement.scrollWidth, scrollHeight = containerElement.scrollHeight, clientWidth = containerElement.clientWidth, clientHeight = containerElement.clientHeight;
    if (horizontalElement) {
        updateElementStyle(horizontalElement.firstChild, {
            left: scrollLeft * computeRatio(scrollWidth, clientWidth, gapX, minThumbSize).ratio,
        });
    }
    if (verticalElement) {
        updateElementStyle(verticalElement.firstChild, {
            top: scrollTop * computeRatio(scrollHeight, clientHeight, gapY, minThumbSize).ratio,
        });
    }
}
