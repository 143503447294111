import { __assign } from "tslib";
import { isLeafNode, makeRecursiveMapper, mergeCellProps, } from '../../../view/utils';
export function crossLinePipeline(crosslineConfig) {
    if (crosslineConfig === void 0) { crosslineConfig = {}; }
    var stateKey = 'columnHover';
    return function (pipeline) {
        var _a, _b, _c, _d;
        var hoverColor = (_a = crosslineConfig.hoverColor) !== null && _a !== void 0 ? _a : 'var(--hover-bgcolor)';
        var hoverColIndex = (_d = (_c = (_b = crosslineConfig.hoverColIndex) !== null && _b !== void 0 ? _b : pipeline.getStateAtKey(stateKey)) !== null && _c !== void 0 ? _c : crosslineConfig.defaultHoverColIndex) !== null && _d !== void 0 ? _d : -1;
        var onChangeHoverColIndex = function (nextColIndex) {
            var _a;
            pipeline.setStateAtKey(stateKey, nextColIndex);
            (_a = crosslineConfig.onChangeHoverColIndex) === null || _a === void 0 ? void 0 : _a.call(crosslineConfig, nextColIndex);
        };
        return pipeline.mapColumns(makeRecursiveMapper(function (col, _a) {
            var startIndex = _a.startIndex, endIndex = _a.endIndex;
            var range = { start: startIndex, end: endIndex };
            if (!isLeafNode(col)) {
                return col;
            }
            var colIndexMatched = range.start <= hoverColIndex && hoverColIndex < range.end;
            var prevGetCellProps = col.getCellProps;
            return __assign(__assign({}, col), { getCellProps: function (value, record, rowIndex) {
                    var prevCellProps = prevGetCellProps === null || prevGetCellProps === void 0 ? void 0 : prevGetCellProps(value, record, rowIndex);
                    // @ts-ignore
                    return mergeCellProps(prevCellProps, {
                        // 默认cell 的 background 都是 bgcolor，所以只需要改变当前的 bgcolor 就ok了。
                        style: {
                            '--bgcolor': colIndexMatched ? hoverColor : undefined,
                            '--row-bgcolor': colIndexMatched ? hoverColor : undefined, // 兼容老版本
                        },
                        onMouseEnter: function () {
                            onChangeHoverColIndex(range.start);
                        },
                        onMouseLeave: function () {
                            onChangeHoverColIndex(-1);
                        },
                    });
                } });
        }));
    };
}
