import { __assign } from "tslib";
import * as React from 'react';
import $i18n from 'panda-i18n';
import classnames from 'classnames';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';
import { CnButton } from '@/components/cn-button';
import { tablefilterArmsReport } from '@/components/cn-table/view/arms';
import { FilterIcon, DropDown2 } from '../../../dep';
import CnListFilter from './cn-list-filter';
import { FilterContainer } from './filter-container';
import { isDef, isFunction, isPlainObject, } from '../../../utils';
function FilterComponent(props) {
    var list = props.list, labelKey = props.labelKey, valueKey = props.valueKey, onChange = props.onChange, popContainerStyle = props.popContainerStyle, columns = props.columns;
    // const allValue = list.map((item) => item && item[labelKey]);
    // const handleEmptyList=list.filter((item,index)=>);
    var handleEmptyList = list.reduce(function (pre, cur) {
        if (cur[labelKey]) {
            pre.handleEmptyList.push(cur);
        }
        else if (isEmpty(cur[labelKey]) && pre.first) {
            pre.handleEmptyList.push(cur);
            pre.first = false;
        }
        return pre;
    }, { handleEmptyList: [], first: true }).handleEmptyList;
    var noTreeList = handleEmptyList === null || handleEmptyList === void 0 ? void 0 : handleEmptyList.map(function (item) {
        delete item.children;
        return item;
    });
    var _a = React.useState(props.value), value = _a[0], setValue = _a[1];
    return (React.createElement(CnListFilter, { list: noTreeList, value: value, column: columns, style: __assign({ padding: '12px 2px' }, (popContainerStyle || {})), searchStyle: { margin: '0px 14px', width: 'calc(100% - 28px)' }, onChange: function (val) {
            isFunction(onChange) && onChange(val);
            setValue(val);
        }, labelKey: labelKey, valueKey: valueKey }));
}
export function FilterUI(props) {
    var _a;
    var dataSourceProps = props.dataSource, labelKey = props.labelKey, valueKey = props.valueKey, onChange = props.onChange, keysMap = props.keysMap, primaryKey = props.primaryKey, clear = props.clear, code = props.code, popContainerStyle = props.popContainerStyle, iconSize = props.iconSize, columns = props.columns;
    var selectKeys = React.useRef([]);
    var dataSource = uniqBy(dataSourceProps.map(function (item) {
        var _a;
        if (isPlainObject(item[valueKey])) {
            return __assign(__assign({}, item), (_a = {}, _a[valueKey] = JSON.stringify(item[valueKey]), _a));
        }
        return item;
    }), valueKey);
    if (!isDef(labelKey)) {
        console.warn($i18n.get({
            id: 'FilterMissingLabelConfiguration',
            dm: 'filter， 缺少label配置',
            ns: 'CnBaseTable',
        }));
    }
    var onMidChange = function (val) {
        selectKeys.current = val;
    };
    var confirm = function () {
        isFunction(onChange) && onChange(selectKeys.current);
        DropDown2.hide();
    };
    var reset = function () {
        // isFunction(clear) && clear(selectKeys.current);
        DropDown2.hide();
    };
    // console.log(keysMap?.[code]?.length);
    return (React.createElement(FilterIcon, { size: iconSize, className: classnames('cnc-table-icon', {
            'cnc-table-icon-highlight': ((_a = keysMap === null || keysMap === void 0 ? void 0 : keysMap[code]) === null || _a === void 0 ? void 0 : _a.length) > 0,
        }), onClick: function (event) {
            var _a;
            DropDown2.show(event, React.createElement(FilterComponent, { list: dataSource, value: isPlainObject(keysMap) ? (_a = keysMap[code]) !== null && _a !== void 0 ? _a : [] : [], columns: columns, onChange: onMidChange, labelKey: labelKey, valueKey: valueKey, popContainerStyle: popContainerStyle }), {
                Container: FilterContainer,
                footer: (React.createElement("div", { className: "cnc-table-filter-footer" },
                    React.createElement(CnButton, { type: "primary", onClick: confirm }, $i18n.get({ id: 'Confirm', dm: '确认', ns: 'CnBaseTable' })),
                    React.createElement(CnButton, { onClick: reset }, $i18n.get({ id: 'Cancel', dm: '取消', ns: 'CnBaseTable' })))),
            });
            tablefilterArmsReport();
        } }));
}
