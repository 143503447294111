import { __awaiter, __generator } from "tslib";
import { isEmpty } from './utils';
var createStorage = function (config) {
    var _a = config || {}, storageKey = _a.storageKey, type = _a.type, getData = _a.getData, setData = _a.setData;
    var DEFAULT_FILTER_SAVE_KEY = "FILTER_".concat("".concat(global.location.origin, "_").concat(global.location.pathname));
    var getKey = function (defaultKey) {
        if (isEmpty(storageKey))
            return defaultKey;
        if (typeof storageKey === 'function') {
            var key = storageKey();
            return "".concat(defaultKey, "|").concat(key);
        }
        return "".concat(defaultKey, "|").concat(storageKey);
    };
    var FILTER_SAVE_KEY = getKey([DEFAULT_FILTER_SAVE_KEY, type].filter(Boolean).join('|'));
    var _getData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var saveDataStr, saveData;
        return __generator(this, function (_a) {
            if (getData)
                return [2 /*return*/, getData(FILTER_SAVE_KEY, type)];
            saveDataStr = localStorage.getItem(FILTER_SAVE_KEY) || '';
            saveData = null;
            if (saveDataStr) {
                try {
                    saveData = JSON.parse(saveDataStr);
                }
                catch (e) {
                    console.error(e);
                }
            }
            return [2 /*return*/, Promise.resolve(saveData)];
        });
    }); };
    var _setData = function (saveData) { return __awaiter(void 0, void 0, void 0, function () {
        var saveDataStr;
        return __generator(this, function (_a) {
            if (setData)
                return [2 /*return*/, setData(FILTER_SAVE_KEY, saveData, type)];
            saveDataStr = '';
            try {
                saveDataStr = JSON.stringify(saveData);
            }
            catch (e) {
                console.error(e);
            }
            if (saveDataStr) {
                localStorage.setItem(FILTER_SAVE_KEY, saveDataStr);
            }
            return [2 /*return*/, Promise.resolve()];
        });
    }); };
    return {
        getData: _getData,
        setData: _setData,
    };
};
export default createStorage;
