import { __assign, __rest } from "tslib";
import React from 'react';
import classNames from 'classnames';
import { CnUploader, useUploadState } from '@cainiaofe/cn-ui-common';
import { UploadList } from './upload-list';
import { UploadDropZone } from './upload-drop-zone';
export var OSSUploadDragger = React.forwardRef(function (props, ref) {
    var _a = useUploadState(), rootProps = _a.props, readOnly = _a.readOnly;
    var style = rootProps.style, className = rootProps.className;
    var description = props.description, restProps = __rest(props, ["description"]);
    return (React.createElement("div", { "data-name": "CnOSSUpload", style: style, className: classNames(className, 'cn-ui-oss-upload', 'cn-ui-oss-upload-dragger', {
            'cn-ui-oss-upload-readonly': readOnly,
        }) },
        readOnly ? null : (React.createElement(CnUploader, { dragger: true, ref: ref, className: "cn-ui-oss-upload-action" },
            React.createElement(UploadDropZone, { description: description }))),
        React.createElement(UploadList, __assign({}, restProps))));
});
