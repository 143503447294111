export var useShowDownload = function (params) {
    var file = params.file, onDownload = params.onDownload;
    if (typeof params.showDownload === 'function') {
        return params.showDownload(file);
    }
    if (!params.showDownload)
        return false;
    var isUploading = ['idle', 'uploading'].includes(file.status);
    if (isUploading)
        return false;
    return Boolean(file.downloadUrl || file.url || onDownload);
};
