import * as React from 'react';
var wrapAsync = function (dispatch) {
    return function (action) {
        if (typeof action === 'function') {
            return action(dispatch);
        }
        else {
            return dispatch(action);
        }
    };
};
export function useAsyncReducer(reducer, initializerArg, initializer) {
    var _a = React.useReducer(reducer, initializerArg, initializer), state = _a[0], dispatch = _a[1];
    var wrappedDispatch = React.useMemo(function () { return wrapAsync(dispatch); }, [dispatch]);
    return [state, wrappedDispatch];
}
