import { __assign, __awaiter, __generator } from "tslib";
import useLatest from 'ahooks/lib/useLatest';
import { useCnRequest } from '@cainiaofe/cn-ui-common';
import { useCallback, useMemo, useRef, useState } from 'react';
import useInitPolling from './use-init-polling';
var getProgress = function (list) {
    var countProgress = list.reduce(function (total, item) { return total + (item.progress || 0); }, 0);
    return Math.round(countProgress / list.length);
};
var getJobStatus = function (list) {
    try {
        return list.some(function (item) { return item.status === 'EXECUTING'; })
            ? 'running'
            : 'finished';
    }
    catch (_a) {
        return 'running';
    }
};
/**
 * 计算本次处理结束的任务
 * 本地轮询结果和上次轮询结果进行对比
 */
var getProcessedJobs = function (_prevList, _list) {
    var list = _list.filter(function (item) { return item.status !== 'EXECUTING'; });
    var _prevIdList = _prevList.map(function (item) { return item.jobId; });
    var prevIdList = _prevList
        .filter(function (item) { return item.status === 'EXECUTING'; })
        .map(function (item) { return item.jobId; });
    return list.filter(function (_a) {
        var jobId = _a.jobId, status = _a.status;
        if (!_prevIdList.length)
            return false;
        if (_prevIdList.length && !_prevIdList.includes(jobId))
            return true;
        return status !== 'EXECUTING' && prevIdList.includes(jobId);
    });
};
export default function useJobsPolling(props) {
    var _this = this;
    var onSuccess = props.onSuccess, onError = props.onError, _a = props.pollingInterval, _pollingInterval = _a === void 0 ? 5000 : _a, pollingRequestConfig = props.pollingRequestConfig;
    var pollingInterval = _pollingInterval < 3000 ? 3000 : _pollingInterval;
    var _b = useState([]), jobResult = _b[0], setJobResult = _b[1];
    var jobAbortController = useRef();
    var taskId = useRef();
    var prevJobResult = useRef([]);
    var onErrorRef = useLatest(onError);
    var onSuccessRef = useLatest(onSuccess);
    var pollingRequestConfigRef = useLatest(pollingRequestConfig);
    var stopTaskLoop = useCallback(function () {
        var _a;
        (_a = jobAbortController.current) === null || _a === void 0 ? void 0 : _a.abort();
        if (taskId.current) {
            clearTimeout(taskId.current);
            taskId.current = null;
            prevJobResult.current = [];
        }
    }, []);
    var runAsync = useCnRequest(__assign(__assign({}, pollingRequestConfig), { manual: true })).runAsync;
    var _startTask = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var _pollingService, data, result_1, err_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    jobAbortController.current = new AbortController();
                    _pollingService = pollingRequestConfigRef.current;
                    if (!_pollingService)
                        return [2 /*return*/, undefined];
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, runAsync()];
                case 2:
                    data = _b.sent();
                    result_1 = data.tableData || [];
                    setJobResult(function (prev) {
                        prevJobResult.current = prev;
                        return result_1;
                    });
                    (_a = onSuccessRef.current) === null || _a === void 0 ? void 0 : _a.call(onSuccessRef, data);
                    return [2 /*return*/, result_1];
                case 3:
                    err_1 = _b.sent();
                    if ((err_1 === null || err_1 === void 0 ? void 0 : err_1.message) === 'canceled')
                        return [2 /*return*/, []];
                    setJobResult([]);
                    if (onErrorRef.current) {
                        onErrorRef.current(err_1);
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/, []];
            }
        });
    }); }, [onErrorRef, onSuccessRef, pollingRequestConfigRef, runAsync]);
    var startTaskLoop = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    stopTaskLoop();
                    return [4 /*yield*/, _startTask()];
                case 1:
                    result = _a.sent();
                    if (getJobStatus(result) === 'running') {
                        taskId.current = setTimeout(startTaskLoop, pollingInterval);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [_startTask, stopTaskLoop, pollingInterval]);
    useInitPolling({ stopTaskLoop: stopTaskLoop, startTaskLoop: startTaskLoop });
    var result = useMemo(function () {
        var processedJobs = getProcessedJobs(prevJobResult.current, jobResult);
        return {
            startTaskLoop: startTaskLoop,
            stopTaskLoop: stopTaskLoop,
            jobResult: jobResult,
            progress: getProgress(jobResult),
            hasUnreadJob: Boolean(processedJobs.length),
            loading: jobResult.some(function (job) { return job.status === 'EXECUTING'; }),
            processedJobs: processedJobs,
        };
    }, [jobResult, startTaskLoop, stopTaskLoop]);
    return result;
}
