import { __assign, __rest } from "tslib";
import React, { useState, useMemo, useEffect } from 'react';
import classnames from 'classnames';
import { CnInput } from '@/components/cn-input';
import { formatCardNumber, IMAGES, getCardTypeByValue } from './utils';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
var numberReg = /^[0-9]+$/;
export var CnBankInput = function (props) {
    var _a;
    var value = props.value, defaultValue = props.defaultValue, readOnly = props.readOnly, placeholder = props.placeholder, onChange = props.onChange, readOnlyRender = props.readOnlyRender, cardTypes = props.cardTypes, maxLength = props.maxLength, className = props.className, others = __rest(props, ["value", "defaultValue", "readOnly", "placeholder", "onChange", "readOnlyRender", "cardTypes", "maxLength", "className"]);
    var initValue = typeof defaultValue !== 'undefined' ? defaultValue : value;
    var _b = useState(initValue), curValue = _b[0], setCurValue = _b[1];
    useEffect(function () {
        if (typeof value !== 'undefined') {
            setCurValue(value);
        }
    }, [value]);
    // 获取银行卡类型和格式化之后的字段
    var _c = useMemo(function () {
        var resetValue = String(curValue).replace(/\s/g, '');
        var cType = getCardTypeByValue(resetValue, cardTypes);
        var formattedValue = formatCardNumber(resetValue, cardTypes);
        return [cType.bankCode.toLowerCase(), formattedValue, cType];
    }, [curValue, cardTypes]), bankCode = _c[0], cardNumber = _c[1], cardType = _c[2];
    function handleChange(v, e) {
        var resetValue = String(v).replace(/\s/g, '');
        // 如果存在非数字，直接return
        if (resetValue && !numberReg.test(resetValue)) {
            return;
        }
        // 最大长度
        if (maxLength && resetValue.length > maxLength) {
            return;
        }
        // 受控类型
        if (typeof value === 'undefined') {
            setCurValue(resetValue);
        }
        onChange && onChange(resetValue, e);
    }
    var cls = classnames('cn-ui-bank-input', (_a = {
            'cn-ui-bank-input-readonly': readOnly
        },
        _a[className] = !!className,
        _a));
    if (readOnly) {
        if (readOnlyRender) {
            return readOnlyRender(__assign(__assign({}, props), { cardType: cardType }));
        }
        return (React.createElement("span", { className: cls },
            React.createElement("img", { className: "cn-ui-bank-input-badge", src: IMAGES[bankCode] }),
            React.createElement("span", { className: "cn-ui-bank-input-name" }, cardType === null || cardType === void 0 ? void 0 : cardType.bankName),
            React.createElement("span", { className: "cn-ui-bank-input-number" }, cardNumber)));
    }
    return withNativeProps(props, React.createElement("span", { className: cls, "data-name": "CnBankInput" },
        React.createElement(CnInput, __assign({}, others, { className: "cn-ui-bank-input-input", value: cardNumber, onChange: handleChange, innerBefore: React.createElement("img", { className: "cn-ui-bank-input-badge", src: IMAGES[bankCode] }) }))));
};
CnBankInput.defaultProps = {
    cardTypes: [],
    maxLength: 20,
};
CnBankInput.displayName = 'CnBankInput';
