import { __assign, __extends, __rest } from "tslib";
import * as React from 'react';
import { CnAsyncSelect } from '@/components/cn-async-select';
import { CnEllipsis } from '@/components/cn-ellipsis';
import { highlightRender } from './utils';
import currencyMap from './currency-map';
var CurrencySelect = /** @class */ (function (_super) {
    __extends(CurrencySelect, _super);
    function CurrencySelect() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.currencyItemRender = function (item, searchKey) {
            var currencyDetail = currencyMap[item.value] || {};
            return (React.createElement("div", { className: "cn-ui-currency-select-menu-item" },
                React.createElement("div", { className: "cn-ui-currency-select-menu-item-header" },
                    React.createElement("div", { className: "currency-value" }, highlightRender(currencyDetail.value || item.value, searchKey))),
                React.createElement(CnEllipsis, { className: "cn-ui-currency-select-menu-item-footer" }, currencyDetail.label)));
        };
        _this.currencyValueRender = function (retValue) {
            var currencyDetail = currencyMap[retValue.value] || {};
            return (React.createElement("div", { className: "cn-ui-currency-select-value" },
                React.createElement("div", { className: "currency-value" }, currencyDetail.value)));
        };
        return _this;
    }
    CurrencySelect.prototype.render = function () {
        var _a = this.props, readOnly = _a.readOnly, dataSource = _a.dataSource, otherProps = __rest(_a, ["readOnly", "dataSource"]);
        if (readOnly) {
            return (React.createElement("span", { className: "cn-ui-currency-select-readonly" }, otherProps.value));
        }
        return (React.createElement(CnAsyncSelect, __assign({ "data-name": "CnCurrencySelect", className: "cn-ui-currency-select", popupClassName: "cn-ui-currency-select-popup", itemRender: this.currencyItemRender, valueRender: this.currencyValueRender, dataSource: readOnly ? undefined : dataSource, readOnly: readOnly }, otherProps)));
    };
    return CurrencySelect;
}(React.Component));
export { CurrencySelect };
