import { __assign, __spreadArray } from "tslib";
import React from 'react';
import { CnPageScrollDomSelector } from '@cainiaofe/cn-ui-common';
export var defaultDateFormat = {
    time: 'HH:mm:ss',
    date: 'YYYY-MM-DD',
    month: 'YYYY-MM',
    week: 'GGGG-wo',
    year: 'YYYY',
    quarter: 'YYYY-[Q]Q',
};
export var isEmpty = function (value) {
    if (['boolean', 'number'].includes(typeof value))
        return false;
    return !value;
};
export var normalizeChildren = function (value) {
    return React.Children.toArray(value);
};
export function normalizeArray(value) {
    if (isEmpty(value))
        return [];
    var _value = Array.isArray(value) ? value : [value];
    return _value.filter(function (val) { return !isEmpty(val); });
}
var _childrenToDataSource = function (dataSource, children, targetDisplayName, normalizeFn, ignoreChildren) {
    React.Children.forEach(children, function (child) {
        var _a;
        if (!targetDisplayName ||
            normalizeDisplayName(((_a = child === null || child === void 0 ? void 0 : child.type) === null || _a === void 0 ? void 0 : _a.displayName) || '') === targetDisplayName) {
            dataSource.push(normalizeFn(child));
            if (ignoreChildren)
                return;
        }
        if (child === null || child === void 0 ? void 0 : child.props.children) {
            _childrenToDataSource(dataSource, child === null || child === void 0 ? void 0 : child.props.children, targetDisplayName, normalizeFn);
        }
    });
};
export var childrenToDataSource = function (children, targetDisplayName, normalizeFn, ignoreChildren) {
    var dataSource = [];
    _childrenToDataSource(dataSource, children, normalizeDisplayName(targetDisplayName), normalizeFn, ignoreChildren);
    return dataSource;
};
var _valueToDataSourceItem = function (payload, value, dataSourceNode) {
    if (payload.count === value.length)
        return;
    if (['boolean', 'number', 'string'].includes(typeof dataSourceNode)) {
        var idx = value.indexOf(dataSourceNode);
        if (idx >= 0) {
            payload.items[idx] = {
                value: dataSourceNode,
                label: dataSourceNode,
            };
            payload.count += 1;
            return;
        }
    }
    if (!isEmpty(dataSourceNode.value)) {
        var idx = value.indexOf(dataSourceNode.value);
        if (idx >= 0) {
            payload.items[idx] = dataSourceNode;
            payload.count += 1;
        }
    }
    if (Array.isArray(dataSourceNode.children)) {
        dataSourceNode.children.forEach(function (childNode) {
            _valueToDataSourceItem(payload, value, childNode);
        });
    }
};
export var valueToDataSourceItem = function (value, dataSource) {
    var normalizedValue = normalizeArray(value);
    var payload = {
        count: 0,
        items: normalizedValue.slice(),
    };
    _valueToDataSourceItem(payload, normalizedValue, {
        children: dataSource,
    });
    return payload.items;
};
export var genId = function (ids) {
    return Math.max.apply(Math, __spreadArray(__spreadArray([], (ids || []), false), [0], false)) + 1;
};
export function formatValue(value, formatter) {
    if (Array.isArray(value)) {
        return value.map(formatter);
    }
    return formatter(value);
}
export var normalizeDisplayName = function (displayName) {
    return (displayName || '').replace(/Config\(/g, '').replace(/\)/g, '');
};
export var getNormalizedDisplayName = function (type) {
    return normalizeDisplayName((type === null || type === void 0 ? void 0 : type.displayName) || '');
};
export var getFieldInitName = function (itemProps, childProps, idx) {
    var name = itemProps === null || itemProps === void 0 ? void 0 : itemProps.name;
    var initName = ((childProps === null || childProps === void 0 ? void 0 : childProps['data-meta']) && (childProps === null || childProps === void 0 ? void 0 : childProps.id)) || (childProps === null || childProps === void 0 ? void 0 : childProps.initName);
    if (!initName && idx === 0 && ((childProps === null || childProps === void 0 ? void 0 : childProps.name) || name)) {
        initName = (childProps === null || childProps === void 0 ? void 0 : childProps.name) || name;
    }
    return initName;
};
var componentsWithoutOverlay = [
    'Checkbox',
    'Input',
    'NumberPicker',
    'Radio',
    'Range',
    'Rating',
    'Switch',
    'CnCheckbox',
    'CnInput',
    'CnNumberPicker',
    'CnRangeNumberPicker',
    'CnNumberPickerState',
    'CnRadio',
    'CnRange',
    'CnRating',
    'CnSwitch',
];
export var getItemStyleInjectProps = function (componentName, filterContext, props) {
    var _a;
    if (componentsWithoutOverlay.indexOf(componentName) >= 0) {
        return null;
    }
    // 使用 v2 的组件自己覆盖 container 设置
    var popupContainer = function (e) {
        var _a, _b;
        return (_b = (_a = e === null || e === void 0 ? void 0 : e.closest('.cn-ui-filter')) !== null && _a !== void 0 ? _a : e === null || e === void 0 ? void 0 : e.closest(CnPageScrollDomSelector)) !== null && _b !== void 0 ? _b : document.body;
    };
    return {
        popupContainer: (_a = props === null || props === void 0 ? void 0 : props.popupContainer) !== null && _a !== void 0 ? _a : popupContainer,
        popupProps: __assign({ container: popupContainer }, ((props === null || props === void 0 ? void 0 : props.popupProps) || {})),
    };
};
