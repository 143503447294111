import { CnEllipsis } from '@/components/cn-ellipsis';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { EntryPointsTags } from '../entry-points-tags';
import './index.scss';
export var EntryPointsContent = function (props) {
    var onItemClick = props.onItemClick, dataSource = props.dataSource;
    var hasChildren = useMemo(function () {
        return dataSource === null || dataSource === void 0 ? void 0 : dataSource.some(function (item) { return item.children; });
    }, [dataSource]);
    if (!(dataSource === null || dataSource === void 0 ? void 0 : dataSource.length))
        return null;
    if (!hasChildren) {
        return (React.createElement("div", { className: classNames('cn-entry-points-content', 'cn-entry-points-content-no-children') },
            React.createElement(EntryPointsTags, { onItemClick: onItemClick, dataSource: dataSource })));
    }
    return (React.createElement(React.Fragment, null, dataSource === null || dataSource === void 0 ? void 0 : dataSource.map(function (item) { return (React.createElement("div", { className: "cn-entry-points-content" },
        React.createElement("div", { className: "cn-entry-points-content-title" },
            React.createElement("span", { className: "cn-entry-points-content-title-line" }),
            React.createElement(CnEllipsis, { className: "cn-entry-points-content-title-text" }, item.label)),
        React.createElement(EntryPointsTags, { onItemClick: onItemClick, dataSource: item.children }))); })));
};
