import { __assign, __rest } from "tslib";
import * as React from 'react';
import { CnFormGrid as PureCnFormGrid, } from '@/components/cn-form-grid';
import { observer, useFieldSchema, useField, RecursionField, } from '@formily/react';
export var useGrids = function () {
    var schema = useFieldSchema();
    // useField https://core.formilyjs.org/zh-CN/api/models/field
    var gridField = useField();
    var grids = [];
    schema.mapProperties(function (schemaItem, name) {
        var _a, _b, _c, _d;
        // 获取 子组件 formPath。 formPath 操作 https://core.formilyjs.org/zh-CN/api/entry/form-path
        var childFormPath = gridField.address.concat(name);
        // 查询子组件 field。  query https://core.formilyjs.org/zh-CN/api/models/query#take
        var field = gridField.query(childFormPath).take();
        if ((field === null || field === void 0 ? void 0 : field.display) === 'none' || (field === null || field === void 0 ? void 0 : field.display) === 'hidden')
            return;
        grids.push({
            name: name,
            props: {
                colSpan: (_b = (_a = schemaItem === null || schemaItem === void 0 ? void 0 : schemaItem['x-decorator-props']) === null || _a === void 0 ? void 0 : _a.colSpan) !== null && _b !== void 0 ? _b : schemaItem.colSpan,
                rowSpan: (_d = (_c = schemaItem === null || schemaItem === void 0 ? void 0 : schemaItem['x-decorator-props']) === null || _c === void 0 ? void 0 : _c.rowSpan) !== null && _d !== void 0 ? _d : schemaItem.rowSpan,
            },
            schema: schemaItem,
        });
    });
    return grids;
};
export var CnFormGrid = observer(function (props) {
    var children = props.children, otherProps = __rest(props, ["children"]);
    var grids = useGrids();
    return (React.createElement(PureCnFormGrid, __assign({ "data-name": "CnFormGrid" }, otherProps), grids.map(function (_a, key) {
        var containerProps = _a.props, schema = _a.schema, name = _a.name;
        return (React.createElement("div", __assign({ key: name }, containerProps),
            React.createElement(RecursionField, { schema: schema, name: name })));
    })));
});
