/** panda-disable */
import numbro from 'numbro';
import BigNumber from 'bignumber.js';
/**
 * 格式化数据
 * val: 当前额度值   1234.3212
 * tmpCurrency: 当前货币种类  CNY
 * precision: 精度，可选
 * return  1,234.32
 */
export function handleFormat(val, precision, format) {
    if (format) {
        return format(val);
    }
    if (new BigNumber(val).isZero() || new BigNumber(val).isNaN()) {
        return '0.00';
    }
    if (typeof precision === 'undefined' || isNaN(precision)) {
        return numbro(val).format('(0,0.00)');
    }
    var formatedVal = numbro(val).format({
        thousandSeparated: true,
        mantissa: Number(precision),
    });
    return formatedVal;
}
export function formatInputValue(inputValue, language) {
    var displayNumber = 0;
    var unit = '';
    if (language === 'zh-CN') {
        if (inputValue >= 1000 && inputValue < 10000) {
            displayNumber = Math.floor(inputValue / 1000);
            unit = '千';
        }
        else if (inputValue >= 10000 && inputValue < 100000000) {
            displayNumber = Math.floor(inputValue / 10000);
            unit = '万';
        }
        else if (inputValue >= 100000000 && inputValue < 100000000000) {
            displayNumber = Math.floor(inputValue / 100000000);
            unit = '亿';
        }
        else if (inputValue >= 100000000000) {
            displayNumber = Math.floor(inputValue / 100000000000);
            unit = '千亿';
        }
    }
    else if (inputValue >= 1000 && inputValue < 1000000) {
        displayNumber = Math.floor(inputValue / 1000);
        unit = 'K';
    }
    else if (inputValue >= 1000000 && inputValue < 1000000000) {
        displayNumber = Math.floor(inputValue / 1000000);
        unit = 'M';
    }
    else if (inputValue >= 1000000000) {
        displayNumber = Math.floor(inputValue / 1000000000);
        unit = 'B';
    }
    return displayNumber + unit;
}
