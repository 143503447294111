import { __spreadArray } from "tslib";
import { useMemo, useState, useRef, useCallback } from 'react';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
export function useSecond(callback, deps) {
    var hasBeSecond = useRef(false);
    useMemo(function () {
        if (hasBeSecond.current) {
            callback instanceof Function && callback();
        }
        else {
            hasBeSecond.current = true;
        }
    }, __spreadArray([], deps, true));
}
export function useControlValue(props, opts) {
    var _a;
    var defaultValue = opts.defaultValue, _b = opts.valuePropName, valuePropName = _b === void 0 ? 'value' : _b, _c = opts.changePropName, changePropName = _c === void 0 ? 'onChange' : _c;
    var _d = useState((_a = props === null || props === void 0 ? void 0 : props[valuePropName]) !== null && _a !== void 0 ? _a : defaultValue), data = _d[0], setData = _d[1];
    var setHandler = useCallback(function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var finalChange = get(props, changePropName.split('.'), null);
        if (isFunction(finalChange)) {
            finalChange.apply(void 0, args);
        }
        !(valuePropName in (props || {})) && setData(args[0]);
    }, [props, valuePropName, changePropName]);
    useSecond(function () {
        setData(props === null || props === void 0 ? void 0 : props[valuePropName]);
    }, [props === null || props === void 0 ? void 0 : props[valuePropName]]);
    return [data, setHandler];
}
