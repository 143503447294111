import { __assign, __rest } from "tslib";
/**
 * 组件内埋点能力
 * 埋点参考 https://yuque.antfin-inc.com/cn-xt/arch/ibqesg#T36ta
 * @param id
 * @param eventType
 * @param otherParams
 */
export function sendLog(_a) {
    var id = _a.id, _b = _a.eventType, eventType = _b === void 0 ? 'CLK' : _b, otherParams = __rest(_a, ["id", "eventType"]);
    try {
        var trace = window.coneArmsTrace;
        if (!trace || !trace.reportHottag)
            return;
        trace.reportHottag(__assign({ hottag: id, eventType: eventType }, otherParams));
    }
    catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
    }
}
