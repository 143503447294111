// 第三方依赖
import * as React from 'react';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import isFunction from 'lodash/isFunction';
import { events } from '@cainiaofe/cn-ui-utils';
import Cookies from 'js-cookie';
import { useControllableValue, useCookieState } from 'ahooks';
import $i18n, { pandaEvent } from 'panda-i18n';
import defaultsDeep from 'lodash/defaultsDeep';
// 其他组件
import NEXT_LOCALE from '../../locales/cn-next';
import { isPromise } from './helpers';
/**
 * 用于控制 useValue 能力
 * @param props
 * @param defaultValue
 * @param options
 */
export function useValueState(props, defaultValue, options) {
    var formatter = (options || {}).formatter;
    var isControlled = 'value' in props;
    var initValue = React.useMemo(function () {
        var v = props.defaultValue || defaultValue;
        if (isControlled) {
            v = props.value;
        }
        if (isNil(v)) {
            v = defaultValue;
        }
        if (typeof formatter === 'function') {
            v = formatter(v);
        }
        return v;
    }, []);
    var _a = React.useState(initValue), value = _a[0], setValue = _a[1];
    React.useEffect(function () {
        if (isControlled && value !== props.value) {
            var v = isNil(props.value) ? defaultValue : props.value;
            if (typeof formatter === 'function') {
                v = formatter(v);
            }
            setValue(v);
        }
    }, [props.value]);
    return [value, setValue, isControlled];
}
export function useDerivedValue(props, defaultValue) {
    var isControlled = 'value' in props;
    var initValue = React.useMemo(function () {
        var v = props.defaultValue || defaultValue;
        if (isControlled) {
            v = props.value;
        }
        if (isNil(v)) {
            v = defaultValue;
        }
        return v;
    }, []);
    var _a = React.useState(initValue), value = _a[0], setValue = _a[1];
    var _b = React.useState(null), prevValue = _b[0], setPrevValue = _b[1];
    if (isControlled && !isEqual(prevValue, props.value)) {
        setValue(isNil(props.value) ? defaultValue : props.value);
        setPrevValue(props.value);
    }
    return [value, setValue, isControlled];
}
/** 使用next组件的翻译文案，并支持自动监听语种的变化 */
export var useNextLocale = function (componentName) {
    var _a = React.useState(function () {
        var _lang = $i18n.getLang();
        return NEXT_LOCALE[_lang] && NEXT_LOCALE[_lang][componentName]
            ? NEXT_LOCALE[_lang][componentName]
            : null;
    }), locale = _a[0], setLocale = _a[1];
    pandaEvent.on('CHANGE_LANG', function (_lang) {
        setLocale(NEXT_LOCALE[_lang] && NEXT_LOCALE[_lang][componentName]
            ? NEXT_LOCALE[_lang][componentName]
            : null);
    });
    return locale;
};
export function useSizeChange(props) {
    var SIZE_CHANGE = 'SIZECHANGE';
    var cookieSize = useCookieState('CNUICOOKIESIZE')[0];
    var _a = useControllableValue(props, {
        valuePropName: 'size',
        defaultValue: cookieSize || 'medium',
        defaultValuePropName: 'defaultSize',
        trigger: 'sizeChange',
    }), size = _a[0], setSize = _a[1];
    React.useEffect(function () {
        events.on(SIZE_CHANGE, setSize);
        return function () {
            events.off(SIZE_CHANGE, setSize);
        };
    }, []);
    return {
        size: size,
        setSize: setSize,
    };
}
export function setGlobalSize(size, options) {
    if (options === void 0) { options = {}; }
    defaultsDeep(options, { cookieKey: 'CNUICOOKIESIZE' });
    // Cookies.set(options.cookieKey, size, omit(options, ['cookieKey']));
    // const [cookieSize, SetCookieSize] = useCookieState(
    //   options.cookieKey,
    //   options,
    // );
    if (['small', 'medium', 'large'].includes(size)) {
        Cookies.set(options.cookieKey, size, omit(options, ['cookieKey']));
        events.emit('SIZECHANGE', size);
    }
}
export function useAsyncLoading(props) {
    var _a = React.useState(false), loading = _a[0], setLoading = _a[1];
    if ((props === null || props === void 0 ? void 0 : props.asyncLoading) && isFunction(props === null || props === void 0 ? void 0 : props.onClick)) {
        var wrapOnClick = new Proxy(props.onClick, {
            apply: function (arget, object, args) {
                setLoading(true);
                var result = arget.apply(void 0, args);
                if (isPromise(result)) {
                    result.finally(function () {
                        setLoading(false);
                    });
                }
                else {
                    setLoading(false);
                }
                return result;
            },
        });
        return {
            onClick: wrapOnClick,
            loading: loading,
        };
    }
    return { props: props };
}
