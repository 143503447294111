import { __assign, __spreadArray } from "tslib";
export var ActionTypes = {
    SetConfigValue: 'SetConfigValue',
    SetConfigInited: 'SetConfigInited',
    SetItemsMeta: 'SetItemsMeta',
    SetDataSource: 'SetDataSource',
    SetArrangedChildren: 'SetArrangedChildren',
    SetOverlayVisible: 'SetOverlayVisible',
};
var getFilterItemMeta = function (property) {
    var _a, _b;
    // todo _cnfilter_ismorefilter
    var name = property.key, value = property.value, _c = property._cnfilter_ismorefilter, _cnfilter_ismorefilter = _c === void 0 ? false : _c;
    var enableConfig = value.enableConfig, label = value.title;
    var span = (_b = (_a = value === null || value === void 0 ? void 0 : value['x-decorator-props']) === null || _a === void 0 ? void 0 : _a.colSpan) !== null && _b !== void 0 ? _b : value.colSpan;
    if (enableConfig === false)
        return null;
    if (typeof enableConfig === 'string') {
        return {
            label: enableConfig,
            value: enableConfig,
            _cnfilter_ismorefilter: _cnfilter_ismorefilter,
            span: span,
        };
    }
    if (typeof enableConfig === 'object') {
        return __assign(__assign({}, enableConfig), { _cnfilter_ismorefilter: _cnfilter_ismorefilter, span: span });
    }
    if (name) {
        return {
            label: label,
            value: name,
            _cnfilter_ismorefilter: _cnfilter_ismorefilter,
            span: span,
        };
    }
    return null;
};
export var Actions = {
    updateItemsMeta: function (state, params) {
        var enableConfig = params.enableConfig, children = params.children;
        return function (dispatch) {
            var rst;
            if (!enableConfig) {
                rst = [];
            }
            else {
                rst = children.map(function (child) { return getFilterItemMeta(child); }).filter(Boolean);
            }
            dispatch({
                type: ActionTypes.SetItemsMeta,
                payload: rst,
            });
        };
    },
    updateDataSource: function (state, params) {
        var configValue = state.configValue, itemsMeta = state.itemsMeta;
        var enableConfig = params.enableConfig;
        return function (dispatch) {
            var rst;
            if (!enableConfig) {
                rst = [];
            }
            else if (!configValue) {
                rst = itemsMeta.map(function (meta) {
                    return (__assign(__assign({}, meta), { visible: !meta._cnfilter_ismorefilter }));
                });
            }
            else {
                var metaInConfig_1 = [];
                var metaNotInConfig_1 = [];
                itemsMeta.forEach(function (meta) {
                    var idx = configValue.findIndex(function (val) { return val.name === (meta === null || meta === void 0 ? void 0 : meta.value); });
                    if (idx < 0) {
                        metaNotInConfig_1.push(__assign(__assign({}, meta), { visible: false }));
                        return;
                    }
                    metaInConfig_1[idx] = __assign(__assign({}, meta), { visible: configValue[idx].visible });
                });
                rst = __spreadArray(__spreadArray([], metaInConfig_1.filter(Boolean), true), metaNotInConfig_1, true);
            }
            dispatch({
                type: ActionTypes.SetDataSource,
                payload: rst,
            });
        };
    },
    updateArrangedChildren: function (store, params) {
        var dataSource = store.dataSource;
        var enableConfig = params.enableConfig, children = params.children;
        return function (dispatch) {
            var rst;
            if (!enableConfig) {
                rst = children;
            }
            else {
                var lastIdx_1 = dataSource.length;
                var elementOrderList = children.map(function (child) {
                    var _a;
                    var meta = getFilterItemMeta(child);
                    var idx = !meta
                        ? -1
                        : dataSource.findIndex(function (dataSourceItem) { return dataSourceItem.value === (meta === null || meta === void 0 ? void 0 : meta.value); });
                    if (idx < 0) {
                        idx = lastIdx_1++;
                    }
                    return {
                        idx: idx,
                        element: __assign(__assign({}, child), { value: __assign(__assign({}, child.value), { 'x-visible': !!((_a = dataSource[idx]) === null || _a === void 0 ? void 0 : _a.visible) }) }),
                    };
                });
                elementOrderList.sort(function (a, b) { return a.idx - b.idx; });
                rst = elementOrderList.map(function (item) { return item.element; });
            }
            dispatch({
                type: ActionTypes.SetArrangedChildren,
                payload: rst,
            });
        };
    },
};
