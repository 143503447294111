import { __assign, __rest } from "tslib";
import React, { Fragment, useEffect, useState } from 'react';
import { Pagination } from '@fusion/lib';
import { PaginationContext } from './pagination-context';
import isFunction from 'lodash/isFunction';
export var CnArrayTablePagination = function (_a) {
    var children = _a.children, dataSource = _a.dataSource, props = __rest(_a, ["children", "dataSource"]);
    var _b = useState(1), current = _b[0], setCurrent = _b[1];
    var prefixCls = 'cn-ui-array-table';
    var pageSize = props.pageSize || 10;
    var size = props.size || 'medium';
    var sources = dataSource || [];
    var startIndex = (current - 1) * pageSize;
    var endIndex = startIndex + pageSize - 1;
    var total = (sources === null || sources === void 0 ? void 0 : sources.length) || 0;
    var totalPage = Math.ceil(total / pageSize);
    var handleChange = function (newCurrent) {
        setCurrent(newCurrent);
        if (isFunction(props.onChange)) {
            props.onChange(newCurrent);
        }
    };
    useEffect(function () {
        if (totalPage > 0 && totalPage < current) {
            handleChange(totalPage);
        }
    }, [totalPage, current]);
    var renderPagination = function () {
        if (totalPage <= 1)
            return;
        return (React.createElement("div", { className: "".concat(prefixCls, "-pagination") },
            React.createElement(Pagination, __assign({}, props, { pageSize: pageSize, current: current, total: dataSource.length, size: size, pageSizeSelector: false, onChange: handleChange }))));
    };
    return (React.createElement(Fragment, null,
        React.createElement(PaginationContext.Provider, { value: { totalPage: totalPage, pageSize: pageSize, changePage: handleChange } }, children === null || children === void 0 ? void 0 : children(dataSource === null || dataSource === void 0 ? void 0 : dataSource.slice(startIndex, endIndex + 1), renderPagination()))));
};
