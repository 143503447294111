import { __assign, __rest } from "tslib";
// @ts-nocheck
import * as React from 'react';
import { CnTree } from '@/components/cn-tree';
import { safeCallFunction } from '@/components/cn-utils/helpers';
import { normalizeToArray } from '@/components/cn-tree/view/util';
import { valueToSelectKey } from '@fusion/lib/select/util';
import { flatDataSource } from '@/components/cn-tree-select/tree-select/tree-select';
export var Tree = React.forwardRef(function (props, ref) {
    var isControlled = 'value' in props;
    var propValue = props.value, defaultValue = props.defaultValue, treeArea = props.treeArea, children = props.children, dataSource = props.dataSource, checkable = props.checkable, checkedStrategy = props.checkedStrategy, _a = props.onChange, onChange = _a === void 0 ? function () { } : _a, others = __rest(props, ["value", "defaultValue", "treeArea", "children", "dataSource", "checkable", "checkedStrategy", "onChange"]);
    var _b = React.useState(normalizeToArray(isControlled ? propValue : defaultValue).map(function (v) {
        return valueToSelectKey(v);
    })), value = _b[0], setValue = _b[1];
    var _c = React.useState(1), forceUpdate = _c[1];
    var datasetRef = React.useRef(flatDataSource(props));
    var treeRef = React.useRef(null);
    var _d = datasetRef.current, _k2n = _d._k2n, _p2n = _d._p2n, _v2n = _d._v2n;
    var treeProps = {};
    React.useEffect(function () {
        if (isControlled && value !== propValue) {
            setValue(normalizeToArray(propValue).map(function (v) {
                return valueToSelectKey(v);
            }));
        }
    }, [propValue]);
    React.useEffect(function () {
        datasetRef.current = flatDataSource(props);
        forceUpdate(function (s) { return (s + 1) % 32; });
    }, [dataSource, children]);
    var getKeysByValue = function (value) {
        var _v2n = datasetRef.current._v2n;
        return value.reduce(function (ret, v) {
            var k = _v2n[v] && _v2n[v].key;
            if (k) {
                ret.push(k);
            }
            return ret;
        }, []);
    };
    var getValueByKeys = function (keys) {
        var _k2n = datasetRef.current._k2n;
        return keys.map(function (k) { var _a; return (_a = _k2n[k]) === null || _a === void 0 ? void 0 : _a.value; });
    };
    var getData = function (value) {
        var ret = value.reduce(function (ret, v) {
            var _a;
            var k = _v2n[v] && _v2n[v].key;
            if (k) {
                // console.log(_k2n[k]);
                // const { label, pos } = _k2n[k];
                var d = __assign({ value: v }, ((_a = _k2n[k]) !== null && _a !== void 0 ? _a : {}));
                d.key = k;
                ret.push(d);
            }
            return ret;
        }, []);
        return ret;
    };
    React.useImperativeHandle(ref, function () {
        var _a;
        return __assign(__assign({}, ((_a = treeRef.current) !== null && _a !== void 0 ? _a : {})), { getKeysByValue: getKeysByValue, getValueByKeys: getValueByKeys, datasetRef: datasetRef });
    }, [treeRef]);
    var handleSelect = function (selectedKeys, extra) {
        if (!(extra === null || extra === void 0 ? void 0 : extra.selected)) {
            return;
        }
        var value = getValueByKeys(selectedKeys);
        if (!isControlled) {
            setValue(value);
        }
        var data = getData(value);
        if (props.multiple) {
            safeCallFunction(onChange, value, data);
        }
        else {
            safeCallFunction(onChange, value[0], data[0]);
        }
        // props.multiple ? onChange(value, data) : onChange(value[0], data[0]);
    };
    var handleCheck = function (checkedKeys) {
        var value = getValueByKeys(checkedKeys);
        if (!isControlled) {
            setValue(value);
        }
        safeCallFunction(onChange, value, getData(value));
        // onChange(value, getData(value));
    };
    if (treeArea) {
        treeProps.isLabelBlock = true;
        treeProps.labelRender = function (node) {
            var _a, _b, _c;
            var selfValue = getValueByKeys([node === null || node === void 0 ? void 0 : node.key]);
            node.value = Array.isArray(selfValue) ? selfValue[0] : selfValue;
            return (React.createElement("div", { className: "cn-async-tree-label", style: {
                    display: 'flex',
                    flexGrow: 1,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    position: 'relative',
                } },
                React.createElement("div", null, node === null || node === void 0 ? void 0 : node.label),
                ((_a = value === null || value === void 0 ? void 0 : value.toString) === null || _a === void 0 ? void 0 : _a.call(value)) == ((_c = (_b = node === null || node === void 0 ? void 0 : node.value) === null || _b === void 0 ? void 0 : _b.toString) === null || _c === void 0 ? void 0 : _c.call(_b)) && (React.createElement("div", { className: "cn-async-tree-label-area", style: {
                        position: 'absolute',
                        right: 0,
                        backgroundColor: '#fff',
                    } }, treeArea(node === null || node === void 0 ? void 0 : node.key, node)))));
        };
    }
    var keys = getKeysByValue(value);
    if (checkable) {
        treeProps.checkable = checkable;
        treeProps.checkedStrategy = props.checkStrictly ? 'all' : checkedStrategy;
        treeProps.checkedKeys = keys;
        treeProps.onCheck = handleCheck;
    }
    else {
        treeProps.selectedKeys = keys;
        treeProps.onSelect = handleSelect;
    }
    return (React.createElement(CnTree, __assign({ ref: treeRef }, others, treeProps, { dataSource: dataSource })));
});
Tree.displayName = 'Tree';
