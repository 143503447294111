import { __assign, __rest } from "tslib";
import * as React from 'react';
import { Field, useField, observer } from '@formily/react';
import { CnFormItem } from '@/form/cn-form-item';
import { mapSize, mapStatus } from '@/form/__builtins__';
export var CnFormField = function (props) {
    var children = props.children, otherProps = __rest(props, ["children"]);
    var decoratorProps = {
        style: otherProps.style,
    };
    if (children) {
        return (React.createElement(Field, __assign({ decorator: [CnFormItem, decoratorProps] }, otherProps, { component: [
                observer(function (childProps) {
                    var childrenProps = (childProps === null || childProps === void 0 ? void 0 : childProps.props) || children.props || {};
                    var field = useField();
                    var state = mapStatus(childrenProps, field).state;
                    var size = mapSize(childrenProps).size;
                    return React.cloneElement(children, __assign(__assign({}, childProps), { field: field, state: state, size: size }));
                }),
            ] })));
    }
    return React.createElement(Field, __assign({ decorator: [CnFormItem, decoratorProps] }, otherProps));
};
