import { createContext, useContext } from 'react';
export var FilterRefsContext = createContext({
    update: function () { return undefined; },
    itemCollection: {},
    filterRef: null,
    overlayRef: null,
    currentSelectedValuesRef: null,
});
export var FilterPropsContext = createContext({});
export var useFilterRefsContext = function () { return useContext(FilterRefsContext); };
export var useFilterPropsContext = function () { return useContext(FilterPropsContext); };
