import './index.scss';
import { withI18n } from 'panda-i18n';
import { OSSUpload } from './oss-upload';
import locale from '@/locales';
export var CnOSSUpload = withI18n(OSSUpload, {
    locale: locale,
    // 此处不要删除，特意为了国际化使用了小写的 Oss
    componentName: 'CnOssUpload',
});
CnOSSUpload.displayName = 'CnOSSUpload';
