import isEmpty from 'lodash/isEmpty';
/**
 * 将菜鸟标准数据结构中的data取出来
 * @example
 * 原始数据
 * {
 *   data: {
 *     k1: 'v1',
 *     k2: 'v2',
 *     k3: 'v3'
 *   },
 *   success: true,
 *   errorCode: '0000',
 *   errorMsg: ''
 * }
 * 格式化后取出来的数据
 * {
 *   k1: 'v1',
 *   k2: 'v2',
 *   k3: 'v3'
 * }
 */
export function getStandardData(res, formatResult) {
    // 此处历史遗留兼容代码，暂时保留，后期增加埋点，确认该代码是否有用
    if (isEmpty(res)) {
        return res;
    }
    // 如果存在用户格式化函数，数据处理权交给用户
    if (typeof formatResult === 'function') {
        return formatResult(res);
    }
    // 如果是标准数据结构的数据，取出data，且在没有format函数时，默认判断属于能被组件消费的 IFormatResult 类型
    if (!isEmpty(res === null || res === void 0 ? void 0 : res.data)) {
        return res === null || res === void 0 ? void 0 : res.data;
    }
    // 如果非标准数据架构，返回原始数据，同样此处历史遗留兼容代码，暂时保留，后期增加埋点，确认该代码是否有用
    return res;
}
