import * as React from 'react';
import { makeRecursiveMapper } from '../../utils/tree';
import { TooltipTitle } from './tooltip-title';
export function columnsTitlePipeline() {
    return function (pipeline) {
        var columns = pipeline.getColumns();
        var recursiveMapper = makeRecursiveMapper(function (column) {
            // 仅在存在 tip 时生效
            if (column.tip) {
                column.title = React.createElement(TooltipTitle, { title: column.title, tip: column.tip, align: column.align });
            }
            return column;
        });
        var newColumns = recursiveMapper(columns);
        return pipeline.columns(newColumns);
    };
}
