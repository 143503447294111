import React, { useContext } from 'react';
import classNames from 'classnames';
import { ShellStateCtx } from './context';
export default function Content(props) {
    var children = props.children;
    var _a = useContext(ShellStateCtx), sideBarVisible = _a.sideBarVisible, isSideBarFold = _a.isSideBarFold;
    return (React.createElement("div", { className: classNames('cn-ui-shell-content', {
            stack: sideBarVisible && isSideBarFold,
        }) }, children));
}
