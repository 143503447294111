import { getCnEnv } from '@cainiaofe/cn-ui-utils';
var showRecord = {};
var env = getCnEnv();
/**
 * 开发提示
 */
export function friendlyTips(_options) {
    var options = typeof _options === 'string' ? { message: _options } : _options;
    var message = options.message, component = options.component, _a = options.once, once = _a === void 0 ? true : _a, _b = options.showEnv, showEnv = _b === void 0 ? ['daily', 'pre'] : _b, _c = options.type, type = _c === void 0 ? 'warn' : _c;
    var msg = component ? "\u3010".concat(component, "\u3011").concat(message) : message;
    if (!showEnv.includes(env))
        return;
    if (once && showRecord[msg])
        return;
    showRecord[msg] = (showRecord[msg] || 0) + 1;
    try {
        // eslint-disable-next-line no-console
        console[type](msg);
        // eslint-disable-next-line no-empty
    }
    catch (_d) { }
}
