import { __assign } from "tslib";
import { ActionTypes } from '../actions/save-config';
export var Reducers = function (state, action) {
    var type = action.type, payload = action.payload;
    switch (type) {
        case ActionTypes.SetConfigValue:
            return __assign(__assign({}, state), { configValue: payload });
        case ActionTypes.SetConfigInited:
            return __assign(__assign({}, state), { configInited: payload });
        case ActionTypes.SetItemsMeta:
            return __assign(__assign({}, state), { itemsMeta: payload });
        case ActionTypes.SetDataSource:
            return __assign(__assign({}, state), { dataSource: payload });
        case ActionTypes.SetArrangedChildren:
            return __assign(__assign({}, state), { arrangedChildren: payload });
        case ActionTypes.SetOverlayVisible:
            return __assign(__assign({}, state), { overlayVisible: payload });
        default:
            return __assign({}, state);
    }
};
