/** 在表格的单元格的渲染过程中，先渲染的单元格的 colSpan/rowSpan 会影响到后续单元格是否被渲染
 * `SpanManager` 会在内部维护一份状态来记录最近渲染单元格的 colSpan/rowSpan，
 * 方便后续的单元格快速判断 "是否需要跳过渲染" */
var SpanManager = /** @class */ (function () {
    function SpanManager() {
        this.rects = [];
    }
    SpanManager.prototype.testSkip = function (rowIndex, colIndex) {
        return this.rects.some(function (_a) {
            var left = _a.left, right = _a.right, top = _a.top, bottom = _a.bottom;
            return left <= colIndex &&
                colIndex < right &&
                top <= rowIndex &&
                rowIndex < bottom;
        });
    };
    SpanManager.prototype.stripUpwards = function (rowIndex) {
        this.rects = this.rects.filter(function (rect) { return rect.bottom > rowIndex; });
    };
    SpanManager.prototype.add = function (rowIndex, colIndex, colSpan, rowSpan) {
        this.rects.push({
            left: colIndex,
            right: colIndex + colSpan,
            top: rowIndex,
            bottom: rowIndex + rowSpan,
        });
    };
    return SpanManager;
}());
export default SpanManager;
