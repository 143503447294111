import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
export default function isEmpty(value) {
    if (isNil(value)) {
        return true;
    }
    if (value === '') {
        return true;
    }
    if (Array.isArray(value) && !value.length) {
        return true;
    }
    // 增加组件 preview 时，组件无值时，兼容有的组件值为空对象（例：CnRadioGroup）
    if (isObject(value) && Object.keys(value).length === 0) {
        return true;
    }
    return false;
}
