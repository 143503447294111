import { __assign } from "tslib";
import { columnsResizePipeline } from './index';
import { useAutoWidth } from './use-auto-width';
import { registerPipeline } from '@/components/cn-base-table/register';
registerPipeline('ColumnsResizePipeline', function (props, pipeline, context) {
    var resize = context.resize;
    useAutoWidth(__assign(__assign({}, props), { allColumns: pipeline.getColumns(), resize: resize }));
    if (resize) {
        pipeline.use(columnsResizePipeline(resize));
    }
});
