import { useEffect } from 'react';
export default function useInitPolling(props) {
    var startTaskLoop = props.startTaskLoop, stopTaskLoop = props.stopTaskLoop;
    useEffect(function () {
        startTaskLoop();
        return function () {
            stopTaskLoop();
        };
    }, [startTaskLoop, stopTaskLoop]);
}
