/**
 * 计算抽屉底部确定和取消按钮是否可见
 */
export var getFooterButtonVisible = function (footer, _okButtonVisible, _cancelButtonVisible) {
    var okButtonVisible = _okButtonVisible || false;
    var cancelButtonVisible = _cancelButtonVisible || false;
    if (footer === false) {
        return {
            okButtonVisible: false,
            cancelButtonVisible: false,
        };
    }
    if (footer === true) {
        // 确定按钮 visible 未配置，则使用 footer true 值
        if (typeof _okButtonVisible !== 'boolean') {
            okButtonVisible = true;
        }
        // 取消按钮 visible 未配置，则使用 footer true 值
        if (typeof _cancelButtonVisible !== 'boolean') {
            cancelButtonVisible = true;
        }
    }
    return {
        okButtonVisible: okButtonVisible,
        cancelButtonVisible: cancelButtonVisible,
    };
};
