import $i18n from 'panda-i18n';
export default (function (value, rule) {
    if (rule.zh) {
        if (/^[\u4e00-\u9fa5]+$/.test(value)) {
            return true;
        }
        else {
            return $i18n.get({
                id: 'ThisFieldIsNotAValid_420398750',
                dm: '该字段不是合法的中文字符串',
                ns: 'CnForm',
            });
        }
    }
    return true;
});
