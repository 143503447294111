import $i18n from 'panda-i18n';
import React from 'react';
import cx from 'classnames';
import { CnIcon } from '@/components/cn-icon';
export var UploadDropZone = function (props) {
    var children = props.children, limitExceeded = props.limitExceeded, disabled = props.disabled;
    var _a = React.useState(false), isDropOver = _a[0], setIsDropOver = _a[1];
    var title = $i18n.get({
        id: 'ClickUploadFileOrDragFile_62060765',
        dm: '点击上传文件或拖拽文件到这里',
        ns: 'CnUpload',
    });
    if (limitExceeded) {
        title = $i18n.get({
            id: 'ExceededQuantityLimit',
            dm: '超出数量限制',
            ns: 'CnUpload',
        });
    }
    else if (isDropOver) {
        title = $i18n.get({
            id: 'LetGoAndStartUploading',
            dm: '松手开始上传',
            ns: 'CnUpload',
        });
    }
    var activeZone = disabled ? undefined : function () { return setIsDropOver(true); };
    var inactiveZone = disabled
        ? function (e) { return e.preventDefault(); }
        : function () { return setIsDropOver(false); };
    return (React.createElement("div", { className: cx('cn-ui-upload-drop-zone', {
            disabled: disabled,
            'cn-ui-upload-drop-zone-active': !disabled && isDropOver,
        }), onDragEnter: activeZone, onDragLeave: inactiveZone, onDragOver: inactiveZone, onDrop: inactiveZone },
        React.createElement(CnIcon, { className: "cn-ui-upload-drop-zone-icon", size: "large", type: "upload" }),
        React.createElement("div", { className: "cn-ui-upload-drop-zone-title" }, title),
        React.createElement("div", { className: "cn-ui-upload-drop-zone-description" }, children)));
};
