export default function useGap(size, defaultGap) {
    if (typeof defaultGap === 'function')
        return defaultGap(size);
    if (defaultGap)
        return defaultGap;
    if (size === 'large')
        return 16;
    if (size === 'small')
        return 8;
    return 12;
}
