import { __assign } from "tslib";
import * as React from 'react';
import { createForm } from '@formily/core';
import { useSizeChange } from '@/components/cn-utils';
import { CnFilterProPlugin as Filter } from './filter';
import { getCache } from './cache';
import { withI18n } from 'panda-i18n';
import locale from '@/locales';
export var CnFilterPro = withI18n(React.forwardRef(function (props, ref) {
    var _a = useSizeChange(props), size = _a.size, setSize = _a.setSize;
    return (React.createElement(Filter, __assign({ ref: ref, labelAlign: size === 'small' ? 'left' : 'top', isRightButton: size === 'small' }, props, { size: size })));
}), {
    componentName: 'CnFilter',
    locale: locale,
    forwardRef: true,
});
CnFilterPro.getCache = getCache;
CnFilterPro.createForm = function (options) {
    if (options === void 0) { options = {}; }
    return createForm(__assign(__assign({}, options), { values: __assign(__assign({}, (options.values || {})), (getCache(options.storageKey) || {})) }));
};
export default CnFilterPro;
export { CnFilterProItem } from './components/cn-filter-pro-item';
