import { useI18n } from 'panda-i18n';
/**
 * 当 labelAlign 为 auto 时
 * 通过判断当前语言否是中文简繁自动生成上下左右布局
 */
export var useFormLabelCol = function (params) {
    var labelAlign = params.labelAlign, labelCol = params.labelCol;
    var i18n = useI18n();
    var lang = i18n.getLang();
    if (labelAlign === 'auto') {
        // 简繁 使用左右布局
        if (['zh-CN', 'zh-TW'].includes(lang)) {
            return {
                fixedSpan: 5,
            };
        }
        return labelCol;
    }
    if (labelAlign === 'left' && !labelCol) {
        return {
            fixedSpan: 5,
        };
    }
    // 非 auto 模式使用用户设置的值
    return labelCol;
};
