import { registerValidateRules } from '@formily/validator';
import { checkUploadIsComplete } from './check-upload-is-complete';
/**
 * 注册通用的表单校验逻辑
 */
export function registerCommonValidateRules() {
    registerValidateRules({
        checkUploadIsComplete: checkUploadIsComplete,
    });
}
