import { __extends } from "tslib";
import * as React from 'react';
import './index.scss';
var DragLine = /** @class */ (function (_super) {
    __extends(DragLine, _super);
    function DragLine(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            startClientX: 0,
        };
        return _this;
    }
    DragLine.prototype.setOffset = function (val) {
        this.setState({
            startClientX: val,
        });
    };
    DragLine.prototype.reset = function () {
        this.setState({
            startClientX: 0,
        });
    };
    DragLine.prototype.render = function () {
        var startClientX = this.state.startClientX;
        var tableClientX = this.props.tableClientX;
        return startClientX > 0 ? (React.createElement("div", { className: "cn-ui-table-resize-drag-line-container", style: { transform: "translateX(".concat(startClientX - tableClientX, "px)") } },
            React.createElement("div", { className: "cn-ui-table-resize-drag-line" }))) : null;
    };
    return DragLine;
}(React.Component));
export { DragLine };
