import { connect, mapProps } from '@formily/react';
import { CnInput as UiInput, CnInputTextArea as UiTextArea, } from '@/components/cn-input';
import { mapSize, mapStatus } from '@/form/__builtins__';
export var CnInput = connect(UiInput, mapProps(function (props) {
    if (props.readOnly)
        return {};
    return mapSize(props);
}, function (props, field) {
    if (props.readOnly)
        return {};
    return mapStatus(props, field);
}));
export var CnInputTextArea = connect(UiTextArea, mapProps(mapSize, mapStatus));
