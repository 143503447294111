import { __assign } from "tslib";
import React from 'react';
import { Loading } from '@/components/fusion';
import classNames from 'classnames';
import './index.scss';
export function CnLoading(_a) {
    var _b, _c, _d;
    var children = _a.children, _e = _a.visible, visible = _e === void 0 ? true : _e, _f = _a.tip, tip = _f === void 0 ? '' : _f, tipAlign = _a.tipAlign, _g = _a.className, className = _g === void 0 ? '' : _g, style = _a.style, _h = _a.size, size = _h === void 0 ? 'medium' : _h, fullScreen = _a.fullScreen, _j = _a.isGradient, isGradient = _j === void 0 ? true : _j;
    // const fullWidth = { width: '100%' };
    var cls = classNames((_b = {
            'cn-load': true
        },
        _b["cn-load-".concat(size)] = true,
        _b));
    var svgCls = classNames((_c = {
            'cn-load-svg': true
        },
        _c["cn-load-svg-".concat(size)] = true,
        _c));
    var temporaryIndicator = isGradient ? (React.createElement("svg", { className: svgCls, viewBox: "0 0 36 36", version: "1.1", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", "data-icon": "spin" },
        React.createElement("defs", null,
            React.createElement("linearGradient", { x1: "0%", y1: "100%", x2: "100%", y2: "100%", id: "linearGradient-22" },
                React.createElement("stop", { stopColor: "currentColor", stopOpacity: "0", offset: "0%" }),
                React.createElement("stop", { stopColor: "currentColor", stopOpacity: "0.50", offset: "39.9430698%" }),
                React.createElement("stop", { stopColor: "currentColor", offset: "100%" }))),
        React.createElement("g", { stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("rect", { fillOpacity: "0.01", fill: "#FFFFFF", x: "0", y: "0", width: "36", height: "36" }),
            React.createElement("path", { d: "M34,18 C34,9.163444 26.836556,2 18,2 C11.6597233,2 6.18078805,5.68784135 3.59122325,11.0354951", stroke: "url(#linearGradient-22)", strokeWidth: "4", strokeLinecap: "round" })))) : (React.createElement("div", { className: cls }));
    return (React.createElement(Loading, { "data-testid": "CnLoading", "data-name": "CnLoading", indicator: temporaryIndicator, visible: visible, tip: tip, tipAlign: tipAlign, className: classNames((_d = {
                'cn-ui-loading': true
            },
            _d[className] = !!className,
            _d)), style: __assign({}, style), fullScreen: fullScreen }, children));
}
CnLoading.defaultProps = {
    visible: true,
    tip: '',
    className: '',
};
