import { useCallback } from 'react';
import { getFileIndex } from '../../../utils';
export var useRemoveFile = function (props) {
    var setValue = props.setValue, onChangeRef = props.onChangeRef, onRemoveRef = props.onRemoveRef;
    return useCallback(function (_file) {
        var _a, _b;
        var value = [];
        var file = null;
        setValue(function (prev) {
            var fileIndex = getFileIndex(_file, prev);
            file = prev[fileIndex];
            value = prev.filter(function (_, index) { return fileIndex !== index; });
            return value;
        });
        (_a = onChangeRef.current) === null || _a === void 0 ? void 0 : _a.call(onChangeRef, value, file);
        (_b = onRemoveRef === null || onRemoveRef === void 0 ? void 0 : onRemoveRef.current) === null || _b === void 0 ? void 0 : _b.call(onRemoveRef, file);
    }, [setValue, onRemoveRef, onChangeRef]);
};
