import $i18n from 'panda-i18n';
import cx from 'classnames';
import React from 'react';
import { Colgroup } from './colgroup';
import { Classes } from './styles';
var DefaultEmptyContent = React.memo(function () { return (React.createElement("div", { className: "empty-message" }, $i18n.get({
    id: 'TableDataIsEmpty',
    dm: '表格数据为空',
    ns: 'CnBaseTable',
}))); });
export function EmptyHtmlTable(_a) {
    var descriptors = _a.descriptors, isLoading = _a.isLoading, emptyCellHeight = _a.emptyCellHeight, _b = _a.EmptyContent, EmptyContent = _b === void 0 ? DefaultEmptyContent : _b;
    var show = !isLoading;
    return (React.createElement("table", null,
        React.createElement(Colgroup, { descriptors: descriptors }),
        React.createElement("tbody", null,
            React.createElement("tr", { className: cx(Classes.tableRow, 'first', 'last', 'no-hover'), "data-rowindex": 0 },
                React.createElement("td", { className: cx(Classes.tableCell, 'first', 'last'), colSpan: descriptors.length, style: { height: emptyCellHeight !== null && emptyCellHeight !== void 0 ? emptyCellHeight : 64 } }, show && (React.createElement("div", { className: Classes.emptyWrapper },
                    React.createElement(EmptyContent, null))))))));
}
