import { createSchemaField } from '@formily/react';
import { componentMap } from '@/form/component-map';
export var createScope = function (scope) {
    if (scope) {
        return createSchemaField({
            components: componentMap,
            scope: scope,
        });
    }
    else {
        return createSchemaField({
            components: componentMap,
        });
    }
};
export var SchemaField = createScope();
