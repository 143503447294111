import $i18n from 'panda-i18n';
// 初始化项目的时候填入的变量, 请谨慎修改, 会影响各个地方的使用
export var componentName = 'CnFilterPro';
export var componentItemName = 'CnFilterProItem';
export var libraryName = 'cn-filter-pro';
export var responsiveSizeV = [0, 0, 560, 752, 944, 1136, 1328, 1520];
export var responsiveSizeH = [0, 560, 848, 1136, 1424, 1712];
export var defaultDragZone = [
    {
        key: 'default',
        title: function () { return $i18n.get({ id: 'FilterAdded', dm: '已添加查询项', ns: 'CnFilter' }); },
        zoneStyle: {
            backgroundColor: '#EEF1F7',
        },
    },
    {
        key: 'more',
        title: function () { return $i18n.get({
            id: 'FilterItemNotAdded',
            dm: '未添加查询项',
            ns: 'CnFilter',
        }); },
        zoneStyle: {
            backgroundColor: '#F8F8F8',
        },
    },
];
