import { __assign, __rest } from "tslib";
import React, { forwardRef, useRef, useImperativeHandle, useEffect, } from 'react';
import { Field as FusionField } from '@/components/fusion';
import { componentName, componentItemName } from './const';
import { withI18n } from 'panda-i18n';
import isNil from 'lodash/isNil';
import Filter from './filter';
import { CnFilterItem } from './filter-item';
import FilterContext from './filter-context';
import isEqual from 'lodash/isEqual';
import locale from '@/locales';
import { useCnRequest } from '@cainiaofe/cn-ui-common';
import { useSizeChange } from '@/components/cn-utils';
import { getCache, removeCache } from './cache';
var CnFilter = withI18n(forwardRef(function (props, ref) {
    var filterRef = useRef();
    var filterItemCollectionRef = useRef({
        update: function () { return undefined; },
        itemCollection: {},
        filterRef: filterRef,
    });
    var field = props.field, value = props.value, defaultValue = props.defaultValue, defaultValues = props.defaultValues, requestConfig = props.requestConfig, rest = __rest(props, ["field", "value", "defaultValue", "defaultValues", "requestConfig"]);
    var storageKey = props.storageKey, cacheSearch = props.cacheSearch;
    var _a = useSizeChange(props), size = _a.size, setSize = _a.setSize;
    React.useEffect(function () {
        if (!cacheSearch) {
            // 关闭时 清空一次session值
            removeCache(storageKey);
        }
    }, [cacheSearch]);
    var innerField = FusionField.useField({
        values: defaultValue || value || defaultValues,
    });
    useEffect(function () {
        if (!('value' in props))
            return;
        if (innerField.isOnchange) {
            // 由于 onChange 引起的 value 变化，需要渲染一次之后才能取到实际的field value(联动情形)，重新触发 onChange
            innerField.isOnchange = false;
            if (!isEqual(innerField.stateValues, innerField.getValues())) {
                innerField === null || innerField === void 0 ? void 0 : innerField.filterChange(innerField.getValues(), { field: field });
            }
        }
        else {
            innerField.reset();
            innerField.setValues(value);
        }
    }, [value, innerField]);
    useImperativeHandle(ref, function () { return ({
        getValues: function () { var _a; return (_a = filterRef === null || filterRef === void 0 ? void 0 : filterRef.current) === null || _a === void 0 ? void 0 : _a.getValues(); },
        getField: function () { var _a; return (_a = filterRef === null || filterRef === void 0 ? void 0 : filterRef.current) === null || _a === void 0 ? void 0 : _a.getField(); },
    }); });
    var isInitRemote = React.useMemo(function () {
        return !!(isNil(defaultValue) &&
            isNil(defaultValues) &&
            ((requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.url) || (requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.service)));
    }, [defaultValue, defaultValues, requestConfig]);
    var readyRequestConfig = React.useMemo(function () { return (__assign(__assign({}, requestConfig), { ready: isInitRemote })); }, [requestConfig, isInitRemote]);
    var _b = useCnRequest(readyRequestConfig), data = _b.data, loading = _b.loading;
    React.useEffect(function () {
        if (!isInitRemote)
            return;
        if (loading)
            return;
        innerField.setValues(data || {});
        props.onChange &&
            props.onChange(innerField.getValues(), { field: innerField });
    }, [data]);
    return (React.createElement(FilterContext.Provider, { value: filterItemCollectionRef.current },
        React.createElement(Filter, __assign({ labelAlign: size === 'small' ? 'left' : 'top', isRightButton: size === 'small' }, rest, { size: size, field: field || innerField, ref: filterRef }), props.children)));
}), { componentName: 'CnFilter', locale: locale, forwardRef: true });
CnFilter.displayName = componentName;
CnFilterItem.displayName = componentItemName;
/**
 * @deprecated use CnFilterItem instead
 */
CnFilter.Item = CnFilterItem;
CnFilter.getCache = getCache;
CnFilter.useField = function (options) {
    if (options === void 0) { options = {}; }
    return FusionField.useField(__assign(__assign({}, options), { values: __assign(__assign({}, (options.values || {})), (getCache(options.storageKey) || {})) }));
};
export { CnFilter, CnFilterItem };
