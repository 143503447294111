import { __assign, __spreadArray } from "tslib";
import React, { useContext, useRef, useState } from 'react';
import classNames from 'classnames';
import $i18n from 'panda-i18n';
import { useDrag, useDrop } from 'ahooks';
import { Checkbox } from '@/components/fusion';
import { CnBox } from '@/components/cn-box';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnEllipsis } from '@/components/cn-ellipsis';
import { CnIcon } from '@/components/cn-icon';
import { CnMessage } from '@/components/cn-message';
import { ColumnsStateContext } from '../context';
import { columnsLeftFixedArmsReport, columnsRightFixedArmsReport, columnsDragDropArmsReport } from '../../../arms';
import { leftFixMaxNum, rightFixMaxNum } from '../const';
import { getParent } from '../../../utils';
import DragIcon from './drag-icon';
import './index.scss';
function FixLeftColumn(props) {
    return (React.createElement(CnTooltip, { trigger: React.createElement(CnIcon, { type: "arrow-up-line", onClick: props.onClick, size: "medium" }) }, $i18n.get({
        id: 'FixedOnTheLeftSideOf_867442762',
        dm: '固定在表格左侧',
        ns: 'CnTable',
    })));
}
function CancelColumnFix(props) {
    return (React.createElement(CnTooltip, { trigger: React.createElement(CnIcon, { type: "uturn-right", size: "medium", onClick: props.onClick }) }, $i18n.get({ id: 'Unfixed', dm: '取消固定', ns: 'CnTable' })));
}
function FixRightColumn(props) {
    return (React.createElement(CnTooltip, { trigger: React.createElement(CnIcon, { type: "arrow-down-line", size: "medium", onClick: props.onClick }) }, $i18n.get({
        id: 'FixedToTheRightOfThe_1409668450',
        dm: '固定在表格右侧',
        ns: 'CnTable',
    })));
}
var errorTip = function (num) {
    CnMessage.error($i18n.get({
        id: 'MaximumFixedNum',
        dm: '最多固定{num}个',
        ns: 'CnTable',
    }, { num: num }));
};
export function ColumnItem(props) {
    var itemRightSlot = props.itemRightSlot, showIndex = props.showIndex, position = props.position, dataItem = props.dataItem, index = props.index;
    var dataIndex = dataItem.dataIndex;
    var domRef = useRef();
    var _a = useState(false), isHovering = _a[0], setIsHovering = _a[1];
    var dropPosition = useRef('bottom');
    var _b = useContext(ColumnsStateContext), fixLeftColumns = _b.fixLeftColumns, fixRightColumns = _b.fixRightColumns, checkedColumnKeys = _b.checkedColumnKeys, setColumns = _b.setColumns;
    useDrag(dataIndex, domRef, {
        onDragStart: function (event) {
            event.dataTransfer.setData('text', dataIndex);
        },
        onDragEnd: function () {
            columnsDragDropArmsReport();
        },
    });
    useDrop(domRef, {
        onText: function (dragKey) {
            if (dragKey === dataIndex)
                return;
            setColumns(function (prev) {
                var dragItem = prev.find(function (item) { return item.dataIndex === dragKey; });
                return prev
                    .filter(function (item) { return item.dataIndex !== dragKey; })
                    .reduce(function (result, item) {
                    if (item.dataIndex === dataIndex) {
                        if (dropPosition.current === 'top') {
                            return __spreadArray(__spreadArray([], result, true), [dragItem, item], false);
                        }
                        return __spreadArray(__spreadArray([], result, true), [item, dragItem], false);
                    }
                    return __spreadArray(__spreadArray([], result, true), [item], false);
                }, []);
            });
        },
        onDragEnter: function (event) {
            var itemElement = getParent(event.currentTarget, function (el) {
                return el.className === 'cn-table-column-setting__item';
            });
            if (itemElement === null || itemElement === void 0 ? void 0 : itemElement.getBoundingClientRect) {
                var _a = itemElement.getBoundingClientRect(), top = _a.top, height = _a.height;
                var halfLineTop = top + height / 2;
                setIsHovering(event.clientY > halfLineTop ? 'bottom' : 'top');
                dropPosition.current = event.clientY > halfLineTop ? 'bottom' : 'top';
            }
            else {
                setIsHovering('bottom');
            }
        },
        onDragLeave: function () { return setIsHovering(false); },
        onDrop: function () { return setIsHovering(false); },
    });
    return (React.createElement("div", { ref: domRef, className: classNames('cn-table-column-setting__item', {
            'cn-table-column-setting__item__top': isHovering === 'top',
            'cn-table-column-setting__item__bottom': isHovering === 'bottom',
        }) },
        React.createElement("div", { className: "cn-table-column-setting__item__left" },
            showIndex ? (React.createElement("span", { className: "cn-table-column-setting__item__left__index" },
                index + 1,
                ".")) : null,
            React.createElement(Checkbox, { className: "cn-table-column-setting_checkbox", checked: checkedColumnKeys.includes(dataItem.dataIndex), disabled: dataItem === null || dataItem === void 0 ? void 0 : dataItem.disabled, onChange: function (checked) {
                    setColumns(function (prev) {
                        return prev.map(function (item) {
                            if (item.dataIndex === dataItem.dataIndex) {
                                return __assign(__assign({}, item), { hidden: !checked });
                            }
                            return item;
                        });
                    });
                } }),
            React.createElement("div", { className: "cn-table-column-setting_label" },
                React.createElement(CnEllipsis, null, dataItem.name || dataItem.title))),
        React.createElement(CnBox, { direction: "row", align: "center", spacing: 8, className: "cn-table-column-setting__item__right" },
            itemRightSlot,
            React.createElement("div", { className: "cn-table-column-setting_icon" }, position === 'left' ? (React.createElement(CancelColumnFix, { onClick: function () {
                    setColumns(function (prev) {
                        return prev.map(function (item) {
                            if (item.dataIndex === dataItem.dataIndex) {
                                return __assign(__assign({}, item), { lock: undefined });
                            }
                            return item;
                        });
                    });
                    columnsLeftFixedArmsReport();
                } })) : (React.createElement(FixLeftColumn, { onClick: function () {
                    if (fixLeftColumns.length >= leftFixMaxNum) {
                        errorTip(leftFixMaxNum);
                        return;
                    }
                    setColumns(function (prev) {
                        return prev.map(function (item) {
                            if (item.dataIndex === dataItem.dataIndex) {
                                return __assign(__assign({}, item), { lock: 'left' });
                            }
                            return item;
                        });
                    });
                    columnsLeftFixedArmsReport();
                } }))),
            React.createElement("div", { className: "cn-table-column-setting_icon" }, position === 'right' ? (React.createElement(CancelColumnFix, { onClick: function () {
                    setColumns(function (prev) {
                        return prev.map(function (item) {
                            if (item.dataIndex === dataItem.dataIndex) {
                                return __assign(__assign({}, item), { lock: undefined });
                            }
                            return item;
                        });
                    });
                    columnsRightFixedArmsReport();
                } })) : (React.createElement(FixRightColumn, { onClick: function () {
                    if (fixRightColumns.length >= rightFixMaxNum) {
                        errorTip(rightFixMaxNum);
                        return;
                    }
                    setColumns(function (prev) {
                        return prev.map(function (item) {
                            if (item.dataIndex === dataItem.dataIndex) {
                                return __assign(__assign({}, item), { lock: 'right' });
                            }
                            return item;
                        });
                    });
                    columnsRightFixedArmsReport();
                } }))),
            React.createElement("div", { className: "cn-table-column-setting_icon" },
                React.createElement(DragIcon, null)))));
}
