import { __assign, __spreadArray } from "tslib";
import { useI18n } from 'panda-i18n';
import React, { useEffect, useState, useRef, useMemo, cloneElement, } from 'react';
import DragModal from './drag-modal';
import { normalizeChildren, getFieldInitName } from '../helper';
import storage from '../storage';
import { CnIcon } from '@/components/cn-icon';
import { sendLog } from '@/components/cn-utils';
import { CnButton } from '@/components/cn-button';
// type Locale = typeof zhCN;
var getFilterItemMeta = function (filterItemReactElement) {
    var _a = filterItemReactElement.props, enableConfig = _a.enableConfig, name = _a.name, label = _a.label, children = _a.children, _b = _a._cnfilter_ismorefilter, _cnfilter_ismorefilter = _b === void 0 ? false : _b, span = _a.span;
    if (enableConfig === false)
        return null;
    if (typeof enableConfig === 'string') {
        return {
            label: enableConfig,
            value: enableConfig,
            _cnfilter_ismorefilter: _cnfilter_ismorefilter,
            span: span,
        };
    }
    if (typeof enableConfig === 'object') {
        return __assign(__assign({}, enableConfig), { _cnfilter_ismorefilter: _cnfilter_ismorefilter, span: span });
    }
    if (name) {
        return {
            label: label,
            value: name,
            _cnfilter_ismorefilter: _cnfilter_ismorefilter,
            span: span,
        };
    }
    var meta = null;
    var normalizedChildren = normalizeChildren(children);
    normalizedChildren.some(function (child, idx) {
        var childProps = (child === null || child === void 0 ? void 0 : child.props) || {};
        var initName = getFieldInitName(filterItemReactElement.props, childProps, idx);
        if (initName) {
            meta = {
                label: label,
                value: initName,
                _cnfilter_ismorefilter: _cnfilter_ismorefilter,
                span: span,
            };
            return true;
        }
        return false;
    });
    return meta;
};
export default (function (_a) {
    var enableConfig = _a.enableConfig, defaultConfigValue = _a.defaultConfigValue, configMeta = _a.configMeta, propConfigValue = _a.configValue, onConfigValueChange = _a.onConfigValueChange, storageKey = _a.storageKey, children = _a.children, onGetStorage = _a.onGetStorage, onSetStorage = _a.onSetStorage, 
    // filterPopupContainerRef,
    field = _a.field, saveSelectSpan = _a.saveSelectSpan, gridProps = _a.gridProps, enableSaveSelected = _a.enableSaveSelected, onDragConfigItem = _a.onDragConfigItem;
    var $i18n = useI18n();
    var _b = useState(defaultConfigValue || null), configValue = _b[0], setConfigValue = _b[1];
    var isInitStorageRef = useRef(true);
    var overlayRef = useRef(null);
    var _c = useState(false), overlayVisible = _c[0], setOverlayVisible = _c[1];
    var _d = useState(false), configInited = _d[0], setConfigInited = _d[1];
    useEffect(function () {
        if (propConfigValue !== undefined && propConfigValue !== configValue) {
            setConfigValue(propConfigValue);
        }
    }, [configValue, propConfigValue]);
    var storageRef = useRef(storage({
        storageKey: storageKey,
        type: 'config',
        getData: onGetStorage,
        setData: onSetStorage,
    }));
    useEffect(function () {
        if (!enableConfig)
            return;
        storageRef.current
            .getData()
            .then(function (data) {
            isInitStorageRef.current = false;
            if (data) {
                setConfigValue(data);
            }
        })
            .catch(function () { })
            .finally(function () {
            setConfigInited(true);
        });
    }, [enableConfig]);
    useEffect(function () {
        if (!enableConfig)
            return;
        if (isInitStorageRef.current)
            return;
        storageRef.current.setData(configValue).catch(function () { });
    }, [configValue, enableConfig]);
    var itemsMeta = useMemo(function () {
        if (!enableConfig)
            return [];
        if (configMeta && configMeta.length)
            return configMeta;
        return React.Children.map(children, function (child) {
            return getFilterItemMeta(child);
        }).filter(Boolean);
    }, [enableConfig, children, configMeta]);
    var dataSource = useMemo(function () {
        if (!enableConfig)
            return [];
        if (!configValue) {
            return itemsMeta.map(function (meta) { return (__assign(__assign({}, meta), { visible: !meta._cnfilter_ismorefilter })); });
        }
        var metaInConfig = [];
        var metaNotInConfig = [];
        itemsMeta.forEach(function (meta) {
            var idx = configValue.findIndex(function (val) { return val.name === (meta === null || meta === void 0 ? void 0 : meta.value); });
            if (idx < 0) {
                metaNotInConfig.push(__assign(__assign({}, meta), { visible: false }));
                return;
            }
            metaInConfig[idx] = __assign(__assign({}, meta), { visible: configValue[idx].visible });
        });
        return __spreadArray(__spreadArray([], metaInConfig.filter(Boolean), true), metaNotInConfig, true);
    }, [configValue, itemsMeta, enableConfig]);
    var renderConfigButton = function (props) {
        if (!enableConfig)
            return null;
        return (React.createElement(CnButton, __assign({}, props, { className: "cn-ui-filter-setting-btn", onClick: function () {
                sendLog({
                    id: 'cn-ui.cn-filter.clickSaveConfigButton',
                    name: 'CnFilter查询配置面板展示按钮点击',
                });
                setOverlayVisible(function (v) { return !v; });
            } }),
            React.createElement("span", { ref: overlayRef },
                React.createElement(CnIcon, { type: "setting" }))));
    };
    var renderConfigButtonProps = function (props) {
        if (!enableConfig)
            return null;
        return __assign({ icon: 'setting', title: $i18n.get({ id: 'FilterSettings', dm: '查询设置' }), onClick: function () { return setOverlayVisible(function (v) { return !v; }); }, ref: overlayRef, useIcon: true }, props);
    };
    var renderConfigModal = function () {
        if (!enableConfig)
            return null;
        return (React.createElement(DragModal, __assign({}, {
            onDragConfigItem: onDragConfigItem,
            enableSaveSelected: enableSaveSelected,
            dataSource: dataSource,
            updateDataSource: updateDataSource,
            setOverlayVisible: setOverlayVisible,
            configInited: configInited,
            configValue: configValue,
            field: field,
            overlayVisible: overlayVisible,
            gridProps: gridProps,
            saveSelectSpan: saveSelectSpan,
            $i18n: $i18n,
        })));
    };
    // 内部状态改变更新配置 meta
    var updateConfigValue = function (nextConfigValue) {
        if (enableConfig) {
            onConfigValueChange && onConfigValueChange(nextConfigValue);
            if (propConfigValue === undefined) {
                setConfigValue(nextConfigValue);
            }
        }
    };
    var updateDataSource = function (nextDataSource) {
        updateConfigValue(nextDataSource.map(function (nextDataSourceItem) {
            var visible = nextDataSourceItem.visible, value = nextDataSourceItem.value;
            return {
                visible: visible,
                name: value,
            };
        }));
    };
    var arrangeChildren = function (initChildren) {
        if (!enableConfig)
            return initChildren;
        var lastIdx = dataSource.length;
        var elementOrderList = React.Children.map(initChildren, function (child) {
            var _a;
            var meta = getFilterItemMeta(child);
            var idx = !meta
                ? -1
                : dataSource.findIndex(function (dataSourceItem) { return dataSourceItem.value === (meta === null || meta === void 0 ? void 0 : meta.value); });
            if (idx < 0) {
                idx = lastIdx++;
            }
            return {
                idx: idx,
                element: cloneElement(child, {
                    style: ((_a = dataSource[idx]) === null || _a === void 0 ? void 0 : _a.visible) === false ? { display: 'none' } : {},
                }),
            };
        });
        elementOrderList.sort(function (a, b) { return a.idx - b.idx; });
        return elementOrderList.map(function (item) { return item.element; });
    };
    return {
        renderConfigButton: renderConfigButton,
        renderConfigButtonProps: renderConfigButtonProps,
        renderConfigModal: renderConfigModal,
        arrangeChildren: arrangeChildren,
        children: arrangeChildren(children),
    };
});
