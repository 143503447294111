import { __assign } from "tslib";
import * as React from 'react';
import { calcColumnWidths } from '../../autoWidth';
import { makeRecursiveMapper, safeCellPaddingWidth } from '../../utils';
export function useAutoWidth(props) {
    var columnResize = props.columnResize, columnsAutoWidth = props.columnsAutoWidth, columnsAutoWidthOpt = props.columnsAutoWidthOpt, size = props.size, dataSource = props.dataSource, columns = props.columns, allColumns = props.allColumns, resize = props.resize;
    // @ts-ignore
    var widths = (columnResize || {}).widths;
    React.useMemo(function () {
        // 满足此类条件，则需要自动计算
        if (columnsAutoWidth &&
            !widths &&
            Array.isArray(allColumns) &&
            (allColumns === null || allColumns === void 0 ? void 0 : allColumns.length) > 0 &&
            resize // 开启了width能力
        ) {
            var needCalColumns_1 = [];
            var fixWidths_1 = {};
            // @ts-ignore
            makeRecursiveMapper(function (col, range) {
                var _a;
                if (range === null || range === void 0 ? void 0 : range.isLeaf) {
                    if (col.width > 0 || col.enableAutoWidth === false) {
                        // @ts-ignore
                        fixWidths_1[(_a = col.code) !== null && _a !== void 0 ? _a : range === null || range === void 0 ? void 0 : range.startIndex] = col.width;
                    }
                    else {
                        needCalColumns_1.push(col);
                    }
                }
            })(allColumns);
            var calWidths = calcColumnWidths(needCalColumns_1, dataSource, __assign({ headerFontSize: 12, cellFontSize: 12, additionalWidth: safeCellPaddingWidth(size) }, (columnsAutoWidthOpt || {})));
            resize.setWidths(__assign(__assign({}, calWidths), fixWidths_1));
        }
    }, [
        dataSource,
        columns,
        widths,
        columnsAutoWidth,
        columnsAutoWidthOpt,
        size,
    ]);
}
