import { __assign, __rest } from "tslib";
import * as React from 'react';
import cx from 'classnames';
import { Timeline as NextTimeline } from '@fusion/lib';
import { HocBaseComponents, useCreatePluginInstance } from '@/components/cn-utils';
import "./index.scss";
var NextTimelineItem = NextTimeline.Item;
export var CnTimeline = React.forwardRef(function (props, ref) {
    var _a;
    var className = props.className, others = __rest(props, ["className"]);
    var pluginIns = useCreatePluginInstance('CnTimeline', props === null || props === void 0 ? void 0 : props.usePlugin);
    var plugins = (_a = pluginIns === null || pluginIns === void 0 ? void 0 : pluginIns.getPlugin) === null || _a === void 0 ? void 0 : _a.call(pluginIns);
    if (plugins.length === 0) {
        return (React.createElement(NextTimeline, __assign({ "data-name": "CnTimeline", className: cx('cn-ui-timeline', className), ref: ref }, others)));
    }
    return HocBaseComponents(NextTimeline, {
        props: __assign({ className: cx('cn-ui-timeline', className) }, others),
        ref: ref,
        dataName: "CnTimeline",
        plugins: plugins
    });
});
CnTimeline.displayName = 'CnTimeline';
/**
 * @deprecated
 * 建议使用 CnTimelineItem
 */
CnTimeline.Item = NextTimelineItem;
/**
 * @deprecated
 * 建议使用 CnTimelineItem
 */
export var TimelineItem = NextTimelineItem;
export var CnTimelineItem = NextTimelineItem;
CnTimelineItem.displayName = 'CnTimelineItem';
