import { __assign } from "tslib";
import React from 'react';
import { CnMessage } from '@/components/cn-message';
import { sendLog } from '@/components/cn-utils';
import { CnDragTransfer } from '@/components/cn-drag-transfer';
import { defaultDragZone } from '../const';
import { CnDialog } from '@/components/cn-dialog';
export default (function (_a) {
    var onDragConfigItem = _a.onDragConfigItem, enableSaveSelected = _a.enableSaveSelected, dataSource = _a.dataSource, updateDataSource = _a.updateDataSource, setOverlayVisible = _a.setOverlayVisible, configInited = _a.configInited, configValue = _a.configValue, field = _a.field, overlayVisible = _a.overlayVisible, gridProps = _a.gridProps, saveSelectSpan = _a.saveSelectSpan, $i18n = _a.$i18n;
    var _b = React.useState({}), dragDataSource = _b[0], setDragDataSource = _b[1];
    React.useEffect(function () {
        var newDefault = [];
        var newMore = [];
        if (enableSaveSelected) {
            newDefault.push({
                label: $i18n.get({
                    id: 'QueryCustomaryArea',
                    dm: '查询习惯区',
                    ns: 'CnFilter',
                }),
                span: saveSelectSpan || 1,
                disabled: true,
                className: 'cn-ui-filter-config-modal-query',
            });
        }
        dataSource.forEach(function (i) {
            if (i.visible) {
                newDefault.push(i);
            }
            else {
                newMore.push(i);
            }
        });
        setDragDataSource({
            default: newDefault,
            more: newMore,
        });
    }, [dataSource]);
    var handleConfigOk = function () {
        sendLog({
            id: 'cn-ui.cn-filter.clickConfigOkButton',
            name: 'CnFilter查询配置面板确认点击',
        });
        var newDataSource = [];
        newDataSource = defaultDragZone().reduce(function (p, _a) {
            var _b;
            var k = _a.key;
            return ((_b = dragDataSource === null || dragDataSource === void 0 ? void 0 : dragDataSource[k]) === null || _b === void 0 ? void 0 : _b.length)
                ? p.concat(dragDataSource[k].map(function (i) { return (__assign(__assign({}, i), { visible: k === 'default' })); }))
                : p;
        }, []);
        newDataSource = newDataSource.filter(function (i) { return !i.disabled; });
        updateDataSource(newDataSource);
        setOverlayVisible(false);
    };
    React.useEffect(function () {
        if (!configInited)
            return;
        field.filterChange && field.filterChange(field.getValues(), { field: field });
    }, [configValue]);
    return (React.createElement(CnDialog, { size: "autoLarge", className: "cn-ui-filter-config-modal", visible: overlayVisible, onClose: function () { return setOverlayVisible(false); }, title: $i18n.get({
            id: 'configPanelTitle',
            dm: '查询设置',
        }), closeMode: ['close', 'esc', 'mask'], onOk: handleConfigOk, onCancel: function () { return setOverlayVisible(false); }, footerActions: ['cancel', 'ok'] },
        React.createElement(CnMessage, { type: "notice" }, $i18n.get({
            id: 'OptionsBlocksInTheAreasWhere_84475068',
            dm: '已添加查询项、未添加查询项区域内选项块可以相互拖拽，或通过添加和移除图标按钮操作，未添加查询项将不会出现在查询区内',
            ns: 'CnFilter',
        })),
        React.createElement(CnDragTransfer, { dataSource: dragDataSource, onChange: function (v, actions) {
                sendLog({
                    id: 'cn-ui.cn-filter.changeConfigItem',
                    name: 'CnFilter查询配置项更新事件(拖拽或者点击上下)',
                });
                var newDragDataSource = v;
                if (typeof onDragConfigItem === 'function') {
                    newDragDataSource = onDragConfigItem(v, actions) || newDragDataSource;
                }
                setDragDataSource(newDragDataSource);
            }, gridProps: gridProps, dragZone: defaultDragZone() })));
});
