import { __assign, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
export default function withContext(Radio) {
    var _a;
    return _a = /** @class */ (function (_super) {
            __extends(WrappedComp, _super);
            function WrappedComp() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            WrappedComp.prototype.render = function () {
                return React.createElement(Radio, __assign({}, this.props, { context: this.context }));
            };
            return WrappedComp;
        }(React.Component)),
        _a.displayName = 'Radio',
        _a.contextTypes = {
            onChange: PropTypes.func,
            __group__: PropTypes.bool,
            selectedValue: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.bool,
            ]),
            disabled: PropTypes.bool,
        },
        _a;
}
