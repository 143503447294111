import { __awaiter, __generator, __rest } from "tslib";
import { useI18n } from 'panda-i18n';
import React, { useState } from 'react';
import { observer, useParentForm } from '@formily/react';
import { Select, Input, Overlay, Icon } from '@/components/fusion';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnBalloon } from '@/components/cn-balloon';
import { CnIcon } from '@/components/cn-icon';
import { sendLog } from '@/components/cn-utils';
import { CnFilterProItem } from '../cn-filter-pro-item';
import { useFilterRefsContext, useFilterPropsContext } from '../../context';
import { SaveSelectedActions, SaveSelectedActionTypes } from '../../store';
import { CnButton } from '@/components/cn-button';
export var SaveSelector = observer(function (_a) {
    var props = __rest(_a, []);
    var $i18n = useI18n();
    var filterContext = useFilterRefsContext();
    var _b = useFilterPropsContext(), beforeSetSaveSelected = _b.beforeSetSaveSelected, store = _b.saveSelectedState, dispatch = _b.saveSelectedDispatch, size = _b.size, fullWidth = _b.fullWidth;
    var _c = useState(false), habitSelectVisible = _c[0], setHabitSelectVisible = _c[1];
    var form = useParentForm();
    var selected = store.selected, options = store.options, overlayVisible = store.overlayVisible;
    var currentSelectedValuesRef = filterContext.currentSelectedValuesRef, overlayRef = filterContext.overlayRef;
    var handleRemove = function (item, e) {
        e.stopPropagation();
        dispatch({
            type: SaveSelectedActionTypes.SetOptions,
            payload: options.filter(function (option) { return option.value !== item.value; }),
        });
    };
    var asyncHandleSelect = function (value) { return __awaiter(void 0, void 0, void 0, function () {
        var target, _values;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    dispatch({
                        type: SaveSelectedActionTypes.SetSelected,
                        payload: value || undefined,
                    });
                    target = value && (options || []).find(function (option) { return option.value === value; });
                    if (!target || !target.data) {
                        filterContext.formInstance.filterReset &&
                            filterContext.formInstance.filterReset();
                        currentSelectedValuesRef.current = null;
                        filterContext.formInstance.filterSearch &&
                            filterContext.formInstance.filterSearch();
                        return [2 /*return*/];
                    }
                    _values = target.data;
                    if (!beforeSetSaveSelected) return [3 /*break*/, 2];
                    return [4 /*yield*/, beforeSetSaveSelected(_values)];
                case 1:
                    _values = (_a.sent()) || _values;
                    _a.label = 2;
                case 2:
                    form.reset('*');
                    currentSelectedValuesRef.current = _values;
                    form.setValues(_values);
                    filterContext.formInstance.filterChange &&
                        filterContext.formInstance.filterChange(_values);
                    filterContext.formInstance.filterSearch &&
                        filterContext.formInstance.filterSearch();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleSelect = function (value) {
        asyncHandleSelect(parseInt(value, 10));
    };
    var renderOption = function (item) {
        var removeTriggerRef = React.createRef();
        var handleRemoveClose = function () {
            var _a;
            ((_a = removeTriggerRef.current) === null || _a === void 0 ? void 0 : _a.click) && removeTriggerRef.current.click();
        };
        return (React.createElement("span", { className: "cn-ui-filter-save-option" },
            React.createElement("span", { className: "option-label" }, item.label),
            React.createElement("span", { onClick: function (e) { return e && e.stopPropagation(); } },
                React.createElement(CnBalloon, { v2: true, align: "tl", autoFocus: true, trigger: React.createElement("span", { ref: removeTriggerRef },
                        React.createElement(CnButton, { text: true, size: "large" },
                            React.createElement(CnIcon, { type: "close", className: "option-delete-icon" }))), closable: false, triggerType: "click" },
                    React.createElement("div", { className: "cn-ui-filter-save-text" }, $i18n.get({
                        id: 'ThisFilterCombinationWillBeLost_972042728',
                        dm: '确定要删除当前查询习惯吗？',
                    })),
                    React.createElement("div", { className: "cn-ui-filter-save-btns" },
                        React.createElement(CnButton, { type: "primary", size: "small", onClick: function (e) { return handleRemove(item, e); } }, $i18n.get({ id: 'ok', dm: '确认' })),
                        React.createElement(CnButton, { size: "small", onClick: handleRemoveClose }, $i18n.get({ id: 'cancel', dm: '取消' })))))));
    };
    return (React.createElement(CnFilterProItem, { title: $i18n.get({ id: 'myCriteria', dm: '查询习惯' }), key: "cnfilter-saved-selector", size: size, fullWidth: fullWidth, _shouldInsertProps: false },
        React.createElement(Select, { ref: function (ref) {
                overlayRef.current = ref;
            }, size: size, className: "select cn-ui-filter-habit", value: selected, dataSource: options, onChange: function (value) {
                handleSelect(value);
            }, itemRender: function (item) { return renderOption(item); }, showSearch: true, hasClear: true, visible: habitSelectVisible, onVisibleChange: setHabitSelectVisible, onFocus: function () {
                dispatch({
                    type: SaveSelectedActionTypes.SetOverlayVisible,
                    payload: false,
                });
            }, addonAfter: React.createElement(CnTooltip, { align: "t", delay: 300, trigger: React.createElement(CnButton, { size: size, className: "save", onMouseDown: function (e) {
                        e.stopPropagation();
                        dispatch({
                            type: SaveSelectedActionTypes.SetOverlayVisible,
                            payload: !overlayVisible,
                        });
                        setHabitSelectVisible(false);
                        sendLog({
                            id: 'cn-ui.cn-filter.clickSaveSelected',
                        });
                    }, onClick: function (e) {
                        e.stopPropagation();
                    } },
                    React.createElement(CnIcon, { type: "icon-favorites", size: "medium" })) }, $i18n.get({
                id: 'PreservationHabits',
                dm: '保存习惯',
                ns: 'CnFilter',
            })) })));
});
export var InputSaveOverlay = function (_a) {
    var _b;
    var enableSaveSelected = _a.enableSaveSelected, values = _a.values, beforeGetSaveSelected = _a.beforeGetSaveSelected, store = _a.store, dispatch = _a.dispatch;
    var $i18n = useI18n();
    var _c = useState(''), name = _c[0], setName = _c[1];
    var filterContext = useFilterRefsContext();
    var overlayVisible = store.overlayVisible, options = store.options, selected = store.selected;
    var overlayRef = filterContext.overlayRef;
    var handleClose = function () {
        dispatch({
            type: SaveSelectedActionTypes.SetOverlayVisible,
            payload: false,
        });
    };
    var asyncHandleSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _values;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _values = values;
                    if (!beforeGetSaveSelected) return [3 /*break*/, 2];
                    return [4 /*yield*/, beforeGetSaveSelected(_values)];
                case 1:
                    _values = (_a.sent()) || _values;
                    _a.label = 2;
                case 2:
                    dispatch(SaveSelectedActions.saveSetOptions(store, {
                        data: _values,
                        name: name,
                    }));
                    setName('');
                    handleClose();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleSave = function () {
        sendLog({
            id: 'cn-ui.cn-filter.saveSelectedOk',
        });
        asyncHandleSave();
    };
    var renderSaveValidate = function () {
        var sameItem = options.find(function (i) { return i.label === name; });
        if (sameItem) {
            return (React.createElement("div", { className: "cn-ui-filter-save-panel-msg" },
                React.createElement(Icon, { className: "cn-next-icon-warning", style: { color: '#FFA64E', marginRight: 5 } }),
                $i18n.get({
                    id: 'TheNameIsDuplicatedAndWill_401244060',
                    dm: '名称重复，保存后将覆盖',
                    ns: 'CnFilter',
                })));
        }
        return '';
    };
    if (!enableSaveSelected)
        return null;
    return (React.createElement(Overlay.Popup, { visible: overlayVisible, target: overlayRef.current, safeNode: overlayRef.current, onRequestClose: function () {
            dispatch({
                type: SaveSelectedActionTypes.SetOverlayVisible,
                payload: false,
            });
        }, align: "tc bc", container: function (e) { return e === null || e === void 0 ? void 0 : e.closest('.cn-ui-filter-pro'); } },
        React.createElement("div", { className: "cn-ui-filter-save-panel", style: { width: ((_b = overlayRef === null || overlayRef === void 0 ? void 0 : overlayRef.current) === null || _b === void 0 ? void 0 : _b.offsetWidth) || 200 } },
            React.createElement(Input, { value: name, onChange: setName }),
            renderSaveValidate(),
            React.createElement("div", { className: "cn-ui-filter-save-panel-btns" },
                React.createElement(CnButton, { onClick: function () {
                        dispatch({
                            type: SaveSelectedActionTypes.SetOverlayVisible,
                            payload: false,
                        });
                        sendLog({
                            id: 'cn-ui.cn-filter.saveSelectedCancel',
                        });
                    } }, $i18n.get({ id: 'cancel', dm: '取消', ns: 'CnFilter' })),
                React.createElement(CnButton, { type: "primary", onClick: handleSave }, $i18n.get({ id: 'Save', dm: '保存', ns: 'CnFilter' }))))));
};
