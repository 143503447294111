import React from 'react';
import { CnIcon } from '@/components/cn-icon';
import { CnProgress } from '@/components/cn-progress';
export default function JobItemRight(props) {
    var type = props.type, data = props.data;
    var progress = data.progress, status = data.status, downloadUrl = data.downloadUrl;
    if (status === 'EXECUTING') {
        return (React.createElement(CnProgress, { size: "small", style: { width: 32, height: 32 }, percent: progress, textRender: function (percent) { return React.createElement("span", null, percent); }, shape: "circle" }));
    }
    if (downloadUrl) {
        return (React.createElement(CnIcon, { onClick: function () {
                window.open(downloadUrl);
            }, size: 32, type: "arrow-r-down-fill" }));
    }
    if (type === 'import') {
        if (status === 'SUCCEEDED') {
            return React.createElement(CnIcon, { size: 32, type: "complete-r" });
        }
    }
    return null;
}
