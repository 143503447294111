import { __rest } from "tslib";
import $i18n from 'panda-i18n';
import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import useJobPolling from '@/components/cn-async-jobs-pro/hooks/use-job-polling';
import { downloadFile } from '@cainiaofe/cn-ui-utils';
import { CnButton } from '@/components/cn-button';
import { CnDialog } from '@/components/cn-dialog';
import { getSomeStatusJobDesc } from '@/components/cn-oss-import/util';
import { CnResult } from '@/components/cn-result';
import { CnBox } from '@/components/cn-box';
import { CnProgress } from '@/components/cn-progress';
var cssPrefix = 'cn-ui-sync-dialog';
export default function LoopDialog(props) {
    var _a;
    var setJobId = props.setJobId, showBgProcessBtn = props.showBgProcessBtn, autoDownload = props.autoDownload, visible = props.visible, setVisible = props.setVisible, restProp = __rest(props, ["setJobId", "showBgProcessBtn", "autoDownload", "visible", "setVisible"]);
    var _b = useJobPolling(restProp), jobResult = _b.jobResult, cancel = _b.cancel;
    var close = useCallback(function () {
        setVisible(false);
        cancel();
    }, [setVisible, cancel]);
    useEffect(function () {
        if (!jobResult)
            return;
        var downloadUrl = jobResult.downloadUrl, status = jobResult.status, jobName = jobResult.jobName;
        if (status === 'SUCCEEDED' && autoDownload && downloadUrl) {
            downloadFile(downloadUrl, jobName);
        }
    }, [autoDownload, jobResult]);
    if (!jobResult)
        return null;
    var jobName = jobResult.jobName, downloadUrl = jobResult.downloadUrl, errorDownloadUrl = jobResult.errorDownloadUrl, status = jobResult.status, reason = jobResult.reason;
    var showFooter = showBgProcessBtn && status === 'EXECUTING';
    return (React.createElement(CnDialog, { className: classNames(cssPrefix, (_a = {},
            _a["".concat(cssPrefix, "-show-footer")] = showFooter,
            _a)), size: "small", title: $i18n.get({
            id: '31255238623236096.CNTM',
            dm: '导出',
            ns: 'CnExport',
        }), visible: visible, onCancel: close, onOk: close, okProps: { visible: false }, cancelProps: { visible: false }, onClose: close, afterClose: function () {
            setJobId(undefined);
        }, cache: false, footer: showFooter ? (React.createElement(CnButton, { onClick: function () { return close(); }, size: "medium" }, $i18n.get({
            id: 'BackgroundProcessing',
            dm: '后台处理',
            ns: 'CnExport',
        }))) : (false) },
        status === 'EXECUTING' ? (React.createElement(CnBox, { className: "".concat(cssPrefix, "-job-process"), justify: "center", direction: "column", align: "center", spacing: 12 },
            React.createElement(CnProgress, { className: "".concat(cssPrefix, "-running"), progressive: true, percent: jobResult.progress, shape: "circle" }),
            React.createElement("div", { className: "".concat(cssPrefix, "-running-tip") }, $i18n.get({
                id: 'FileExport',
                dm: '文件导出中...',
                ns: 'CnExport',
            })))) : null,
        status === 'FAILED' ? (React.createElement(CnResult, { size: "mini", type: "error", title: $i18n.get({
                id: 'DataExportFailed',
                dm: '数据导出失败',
                ns: 'CnExport',
            }), subTitle: reason, extra: errorDownloadUrl ? (React.createElement(CnButton, { size: "small", onClick: function () { return downloadFile(errorDownloadUrl); } }, $i18n.get({
                id: 'ViewFailureRecords',
                dm: '查看失败记录',
                ns: 'CnExport',
            }))) : null })) : null,
        status === 'SOME' ? (React.createElement(CnResult, { size: "mini", type: "warning", title: $i18n.get({
                id: 'PartOfTheDataWasExported_1879056932',
                dm: '部分数据导出成功',
                ns: 'CnExport',
            }), subTitle: getSomeStatusJobDesc(jobResult), extra: React.createElement(React.Fragment, null,
                errorDownloadUrl ? (React.createElement(CnButton, { size: "small", onClick: function () { return downloadFile(errorDownloadUrl); } }, $i18n.get({
                    id: 'ViewFailureRecords',
                    dm: '查看失败记录',
                    ns: 'CnExport',
                }))) : null,
                downloadUrl ? (React.createElement(CnButton, { size: "small", onClick: function () { return downloadFile(downloadUrl, jobName); } }, $i18n.get({
                    id: 'ViewSuccessRecords',
                    dm: '查看成功记录',
                    ns: 'CnExport',
                }))) : null) })) : null,
        status === 'SUCCEEDED' ? (React.createElement(CnResult, { size: "mini", type: "success", title: $i18n.get({
                id: 'DataExportSuccessful',
                dm: '数据导出成功',
                ns: 'CnExport',
            }), extra: downloadUrl ? (React.createElement(CnButton, { size: "small", onClick: function () { return downloadFile(downloadUrl, jobName); } }, $i18n.get({ id: 'Download', dm: '下载', ns: 'CnExport' }))) : null })) : null));
}
