import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
/**
 * 根据菜单 key 找根节点
 * @param key 要寻找的那个 key
 * @returns 根节点
 */
export var isKeyParent = function (menu, childKey, parentKey) {
    var keyParentMap = {};
    Array.isArray(menu) &&
        menu.forEach(function (menuItem) {
            var _a;
            keyParentMap[menuItem.key] = [];
            keyParentMap[menuItem.key].push(menuItem.key);
            if ((_a = menuItem.children) === null || _a === void 0 ? void 0 : _a.length) {
                menuItem.children.forEach(function (subItem) {
                    var _a;
                    keyParentMap[subItem.key] = [];
                    keyParentMap[subItem.key].push(subItem.key);
                    keyParentMap[menuItem.key].push(subItem.key);
                    if ((_a = subItem.children) === null || _a === void 0 ? void 0 : _a.length) {
                        subItem.children.forEach(function (thirdLevelItem) {
                            keyParentMap[menuItem.key].push(thirdLevelItem.key);
                            keyParentMap[subItem.key].push(thirdLevelItem.key);
                        });
                    }
                });
            }
        });
    var flag = false;
    Object.keys(keyParentMap).forEach(function (menuKey) {
        if (menuKey === parentKey && keyParentMap[menuKey].includes(childKey)) {
            flag = true;
        }
    });
    return flag;
};
/**
 * 找出当前菜单中匹配指定建值对的 key 与 parentKey
 */
export var findMenuItemByPair = function (menu, _a) {
    var key = _a.key, value = _a.value;
    var findResult = '';
    var flag = false;
    var find = function (list, parent) {
        if (parent === void 0) { parent = ''; }
        if (flag)
            return;
        list.forEach(function (item) {
            var _a;
            if (flag)
                return true;
            if (item[key] === value && !((_a = item === null || item === void 0 ? void 0 : item.children) === null || _a === void 0 ? void 0 : _a.length)) {
                findResult = "".concat(parent, ",").concat(item.key);
                flag = true;
                return;
            }
            if (item.children) {
                find(item.children, "".concat(parent, ",").concat(item.key));
            }
        });
    };
    find(menu);
    var ret = { currentKey: null, parentKeys: null };
    var keys = compact(findResult.split(','));
    if (keys === null || keys === void 0 ? void 0 : keys.length) {
        ret.currentKey = keys.pop();
        ret.parentKeys = keys;
    }
    return ret;
};
/**
 * 获取当前选中的分类和菜单
 */
export var getParentKeysBySelected = function (menu, selectedMenuKey) {
    var _a = findMenuItemByPair(menu, {
        key: 'key',
        value: selectedMenuKey,
    }), currentKey = _a.currentKey, parentKeys = _a.parentKeys;
    if (currentKey && Array.isArray(parentKeys) && parentKeys.length > 0) {
        return uniq(parentKeys);
    }
    return [];
};
export var getMenuKeysWitchHasChildren = function (menu) {
    var rst = [];
    var find = function (m) {
        m.forEach(function (i) {
            var _a;
            if (!((_a = i === null || i === void 0 ? void 0 : i.children) === null || _a === void 0 ? void 0 : _a.length))
                return;
            rst.push(i.key);
            find(i.children);
        });
    };
    find(menu);
    return rst;
};
