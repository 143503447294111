import { __assign } from "tslib";
import dfsHouxu from './dfs-houxu';
/**
 * 获取新节点
 * @param {string} labelKey - 标签键名
 * @returns {Iitem} - 新节点
 */
function getNewNode(labelKey) {
    var _a;
    return _a = {},
        _a[labelKey !== null && labelKey !== void 0 ? labelKey : 'label'] = '',
        _a.value = '',
        _a.children = [],
        _a;
}
/**
 * 判断节点是否包含搜索内容
 * @param {Iitem} item - 节点
 * @param {string} labelKey - 标签键名
 * @param {string} search - 搜索内容
 * @returns {boolean} - 是否包含搜索内容
 */
function isItemIncludesSearch(item, labelKey, search) {
    return (item &&
        item[labelKey !== null && labelKey !== void 0 ? labelKey : 'label'] &&
        String(item[labelKey !== null && labelKey !== void 0 ? labelKey : 'label']).includes(search));
}
// 仅搜索叶子节点
export default function getFilterList(list, search, labelKey) {
    if (labelKey === void 0) { labelKey = 'label'; }
    if (search === '') {
        return list;
    }
    var nowNodes = null;
    var nowNodeLevel = -1;
    var nowTree = null;
    var nowTreeLevel = -1;
    dfsHouxu(list, function (item, level) {
        var _a, _b, _c, _d, _e;
        if (nowNodeLevel === -1) {
            // 初始化
            nowNodeLevel = level;
            nowNodes = getNewNode(labelKey);
        }
        if (level < nowNodeLevel) {
            // 增加了一层，就做一层统计
            if (nowNodes && ((_a = nowNodes === null || nowNodes === void 0 ? void 0 : nowNodes.children) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                // 当前有节点, 则新建
                var newTempNode = __assign(__assign({}, item), { children: nowNodes === null || nowNodes === void 0 ? void 0 : nowNodes.children });
                nowNodes = __assign(__assign({}, getNewNode(labelKey)), { children: [newTempNode] });
            }
            if (level === nowTreeLevel && nowTree && nowNodes) {
                // 重新返回
                nowTree.children = nowTree.children.concat(nowNodes.children);
                nowNodes = nowTree;
            }
        }
        else if (level > nowNodeLevel) {
            // 开始深入下去
            // 记录当前start
            nowTreeLevel = nowNodeLevel;
            nowTree = nowNodes;
            // 记录当前end
            nowNodeLevel = level;
            nowNodes = getNewNode(labelKey);
            if (isItemIncludesSearch(item, labelKey, search)) {
                (_c = (_b = nowNodes === null || nowNodes === void 0 ? void 0 : nowNodes.children) === null || _b === void 0 ? void 0 : _b.push) === null || _c === void 0 ? void 0 : _c.call(_b, item);
            }
        }
        else if (level === nowNodeLevel) {
            // 平层，开始compare
            if (isItemIncludesSearch(item, labelKey, search)) {
                (_e = (_d = nowNodes === null || nowNodes === void 0 ? void 0 : nowNodes.children) === null || _d === void 0 ? void 0 : _d.push) === null || _e === void 0 ? void 0 : _e.call(_d, item);
            }
        }
        // 同步层次
        nowNodeLevel = level;
    });
    // @ts-ignore
    return nowNodes && nowNodes.children ? nowNodes.children : list;
}
