/* eslint-disable no-nested-ternary */

const locale = {
  'zh-cn': {
    name: 'zh-cn',
    weekdays: '星期日_星期一_星期二_星期三_星期四_星期五_星期六'.split('_'),
    weekdaysShort: '周日_周一_周二_周三_周四_周五_周六'.split('_'),
    weekdaysMin: '日_一_二_三_四_五_六'.split('_'),
    months:
      '一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月'.split(
        '_',
      ),
    monthsShort: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split(
      '_',
    ),
    ordinal: (number, period) => {
      switch (period) {
        case 'W':
          return `${number}周`;
        default:
          return `${number}日`;
      }
    },
    weekStart: 1,
    yearStart: 4,
    formats: {
      LT: 'HH:mm',
      LTS: 'HH:mm:ss',
      L: 'YYYY/MM/DD',
      LL: 'YYYY年M月D日',
      LLL: 'YYYY年M月D日Ah点mm分',
      LLLL: 'YYYY年M月D日ddddAh点mm分',
      l: 'YYYY/M/D',
      ll: 'YYYY年M月D日',
      lll: 'YYYY年M月D日 HH:mm',
      llll: 'YYYY年M月D日dddd HH:mm',
    },
    relativeTime: {
      future: '%s内',
      past: '%s前',
      s: '几秒',
      m: '1 分钟',
      mm: '%d 分钟',
      h: '1 小时',
      hh: '%d 小时',
      d: '1 天',
      dd: '%d 天',
      M: '1 个月',
      MM: '%d 个月',
      y: '1 年',
      yy: '%d 年',
    },
    meridiem: (hour, minute) => {
      const hm = hour * 100 + minute;
      if (hm < 600) {
        return '凌晨';
      }
      if (hm < 900) {
        return '早上';
      }
      if (hm < 1100) {
        return '上午';
      }
      if (hm < 1300) {
        return '中午';
      }
      if (hm < 1800) {
        return '下午';
      }
      return '晚上';
    },
  },
  'zh-hk': {
    name: 'zh-hk',
    months:
      '一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月'.split(
        '_',
      ),
    monthsShort: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split(
      '_',
    ),
    weekdays: '星期日_星期一_星期二_星期三_星期四_星期五_星期六'.split('_'),
    weekdaysShort: '週日_週一_週二_週三_週四_週五_週六'.split('_'),
    weekdaysMin: '日_一_二_三_四_五_六'.split('_'),
    ordinal: (number, period) => {
      switch (period) {
        case 'W':
          return `${number}週`;
        default:
          return `${number}日`;
      }
    },
    formats: {
      LT: 'HH:mm',
      LTS: 'HH:mm:ss',
      L: 'YYYY/MM/DD',
      LL: 'YYYY年M月D日',
      LLL: 'YYYY年M月D日 HH:mm',
      LLLL: 'YYYY年M月D日dddd HH:mm',
    },
    relativeTime: {
      future: '%s內',
      past: '%s前',
      s: '幾秒',
      m: '一分鐘',
      mm: '%d 分鐘',
      h: '一小時',
      hh: '%d 小時',
      d: '一天',
      dd: '%d 天',
      M: '一個月',
      MM: '%d 個月',
      y: '一年',
      yy: '%d 年',
    },
  },
  ja: {
    name: 'ja',
    weekdays: '日曜日_月曜日_火曜日_水曜日_木曜日_金曜日_土曜日'.split('_'),
    weekdaysShort: '日_月_火_水_木_金_土'.split('_'),
    weekdaysMin: '日_月_火_水_木_金_土'.split('_'),
    months: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
    monthsShort: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split(
      '_',
    ),
    ordinal: (n) => `${n}日`,
    formats: {
      LT: 'HH:mm',
      LTS: 'HH:mm:ss',
      L: 'YYYY/MM/DD',
      LL: 'YYYY年M月D日',
      LLL: 'YYYY年M月D日 HH:mm',
      LLLL: 'YYYY年M月D日 dddd HH:mm',
      l: 'YYYY/MM/DD',
      ll: 'YYYY年M月D日',
      lll: 'YYYY年M月D日 HH:mm',
      llll: 'YYYY年M月D日(ddd) HH:mm',
    },
    meridiem: (hour) => (hour < 12 ? '午前' : '午後'),
    relativeTime: {
      future: '%s後',
      past: '%s前',
      s: '数秒',
      m: '1分',
      mm: '%d分',
      h: '1時間',
      hh: '%d時間',
      d: '1日',
      dd: '%d日',
      M: '1ヶ月',
      MM: '%dヶ月',
      y: '1年',
      yy: '%d年',
    },
  },
  es: {
    name: 'es',
    monthsShort: 'ene_feb_mar_abr_may_jun_jul_ago_sep_oct_nov_dic'.split('_'),
    weekdays: 'domingo_lunes_martes_miércoles_jueves_viernes_sábado'.split('_'),
    weekdaysShort: 'dom._lun._mar._mié._jue._vie._sáb.'.split('_'),
    weekdaysMin: 'do_lu_ma_mi_ju_vi_sá'.split('_'),
    months:
      'enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre'.split(
        '_',
      ),
    weekStart: 1,
    formats: {
      LT: 'H:mm',
      LTS: 'H:mm:ss',
      L: 'DD/MM/YYYY',
      LL: 'D [de] MMMM [de] YYYY',
      LLL: 'D [de] MMMM [de] YYYY H:mm',
      LLLL: 'dddd, D [de] MMMM [de] YYYY H:mm',
    },
    relativeTime: {
      future: 'en %s',
      past: 'hace %s',
      s: 'unos segundos',
      m: 'un minuto',
      mm: '%d minutos',
      h: 'una hora',
      hh: '%d horas',
      d: 'un día',
      dd: '%d días',
      M: 'un mes',
      MM: '%d meses',
      y: 'un año',
      yy: '%d años',
    },
    ordinal: (n) => `${n}º`,
  },
  ru: () => {
    const monthFormat =
      'января_февраля_марта_апреля_мая_июня_июля_августа_сентября_октября_ноября_декабря'.split(
        '_',
      );
    const monthStandalone =
      'январь_февраль_март_апрель_май_июнь_июль_август_сентябрь_октябрь_ноябрь_декабрь'.split(
        '_',
      );

    const monthShortFormat =
      'янв._февр._мар._апр._мая_июня_июля_авг._сент._окт._нояб._дек.'.split(
        '_',
      );
    const monthShortStandalone =
      'янв._февр._март_апр._май_июнь_июль_авг._сент._окт._нояб._дек.'.split(
        '_',
      );

    const MONTHS_IN_FORMAT = /D[oD]?(\[[^[\]]*\]|\s)+MMMM?/;

    function plural(word, num) {
      const forms = word.split('_');
      return num % 10 === 1 && num % 100 !== 11
        ? forms[0]
        : num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20)
        ? forms[1]
        : forms[2]; // eslint-disable-line
    }
    function relativeTimeWithPlural(number, withoutSuffix, key) {
      const format = {
        mm: withoutSuffix ? 'минута_минуты_минут' : 'минуту_минуты_минут',
        hh: 'час_часа_часов',
        dd: 'день_дня_дней',
        MM: 'месяц_месяца_месяцев',
        yy: 'год_года_лет',
      };
      if (key === 'm') {
        return withoutSuffix ? 'минута' : 'минуту';
      }

      return `${number} ${plural(format[key], +number)}`;
    }
    const months = (dayjsInstance, format) => {
      if (MONTHS_IN_FORMAT.test(format)) {
        return monthFormat[dayjsInstance.month()];
      }
      return monthStandalone[dayjsInstance.month()];
    };
    months.s = monthStandalone;
    months.f = monthFormat;

    const monthsShort = (dayjsInstance, format) => {
      if (MONTHS_IN_FORMAT.test(format)) {
        return monthShortFormat[dayjsInstance.month()];
      }
      return monthShortStandalone[dayjsInstance.month()];
    };
    monthsShort.s = monthShortStandalone;
    monthsShort.f = monthShortFormat;

    return {
      name: 'ru',
      weekdays:
        'воскресенье_понедельник_вторник_среда_четверг_пятница_суббота'.split(
          '_',
        ),
      weekdaysShort: 'вск_пнд_втр_срд_чтв_птн_сбт'.split('_'),
      weekdaysMin: 'вс_пн_вт_ср_чт_пт_сб'.split('_'),
      months,
      monthsShort,
      weekStart: 1,
      yearStart: 4,
      formats: {
        LT: 'H:mm',
        LTS: 'H:mm:ss',
        L: 'DD.MM.YYYY',
        LL: 'D MMMM YYYY г.',
        LLL: 'D MMMM YYYY г., H:mm',
        LLLL: 'dddd, D MMMM YYYY г., H:mm',
      },
      relativeTime: {
        future: 'через %s',
        past: '%s назад',
        s: 'несколько секунд',
        m: relativeTimeWithPlural,
        mm: relativeTimeWithPlural,
        h: 'час',
        hh: relativeTimeWithPlural,
        d: 'день',
        dd: relativeTimeWithPlural,
        M: 'месяц',
        MM: relativeTimeWithPlural,
        y: 'год',
        yy: relativeTimeWithPlural,
      },
      ordinal: (n) => n,
      meridiem: (hour) => {
        if (hour < 4) {
          return 'ночи';
        }
        if (hour < 12) {
          return 'утра';
        }
        if (hour < 17) {
          return 'дня';
        }
        return 'вечера';
      },
    };
  },
  fr: {
    name: 'fr',
    weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
    weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
    weekdaysMin: 'di_lu_ma_me_je_ve_sa'.split('_'),
    months:
      'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split(
        '_',
      ),
    monthsShort:
      'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split(
        '_',
      ),
    weekStart: 1,
    yearStart: 4,
    formats: {
      LT: 'HH:mm',
      LTS: 'HH:mm:ss',
      L: 'DD/MM/YYYY',
      LL: 'D MMMM YYYY',
      LLL: 'D MMMM YYYY HH:mm',
      LLLL: 'dddd D MMMM YYYY HH:mm',
    },
    relativeTime: {
      future: 'dans %s',
      past: 'il y a %s',
      s: 'quelques secondes',
      m: 'une minute',
      mm: '%d minutes',
      h: 'une heure',
      hh: '%d heures',
      d: 'un jour',
      dd: '%d jours',
      M: 'un mois',
      MM: '%d mois',
      y: 'un an',
      yy: '%d ans',
    },
    ordinal: (n) => {
      const o = n === 1 ? 'er' : 'e';
      return `${n}${o}`;
    },
  },
  pt: {
    name: 'pt',
    weekdays:
      'domingo_segunda-feira_terça-feira_quarta-feira_quinta-feira_sexta-feira_sábado'.split(
        '_',
      ),
    weekdaysShort: 'dom_seg_ter_qua_qui_sex_sab'.split('_'),
    weekdaysMin: 'Do_2ª_3ª_4ª_5ª_6ª_Sa'.split('_'),
    months:
      'janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro'.split(
        '_',
      ),
    monthsShort: 'jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez'.split('_'),
    ordinal: (n) => `${n}º`,
    weekStart: 1,
    yearStart: 4,
    formats: {
      LT: 'HH:mm',
      LTS: 'HH:mm:ss',
      L: 'DD/MM/YYYY',
      LL: 'D [de] MMMM [de] YYYY',
      LLL: 'D [de] MMMM [de] YYYY [às] HH:mm',
      LLLL: 'dddd, D [de] MMMM [de] YYYY [às] HH:mm',
    },
    relativeTime: {
      future: 'em %s',
      past: 'há %s',
      s: 'alguns segundos',
      m: 'um minuto',
      mm: '%d minutos',
      h: 'uma hora',
      hh: '%d horas',
      d: 'um dia',
      dd: '%d dias',
      M: 'um mês',
      MM: '%d meses',
      y: 'um ano',
      yy: '%d anos',
    },
  },
  ko: {
    name: 'ko',
    weekdays: '일요일_월요일_화요일_수요일_목요일_금요일_토요일'.split('_'),
    weekdaysShort: '일_월_화_수_목_금_토'.split('_'),
    weekdaysMin: '일_월_화_수_목_금_토'.split('_'),
    months: '1월_2월_3월_4월_5월_6월_7월_8월_9월_10월_11월_12월'.split('_'),
    monthsShort: '1월_2월_3월_4월_5월_6월_7월_8월_9월_10월_11월_12월'.split(
      '_',
    ),
    ordinal(e) {
      return e;
    },
    formats: {
      LT: 'A h:mm',
      LTS: 'A h:mm:ss',
      L: 'YYYY.MM.DD.',
      LL: 'YYYY년 MMMM D일',
      LLL: 'YYYY년 MMMM D일 A h:mm',
      LLLL: 'YYYY년 MMMM D일 dddd A h:mm',
      l: 'YYYY.MM.DD.',
      ll: 'YYYY년 MMMM D일',
      lll: 'YYYY년 MMMM D일 A h:mm',
      llll: 'YYYY년 MMMM D일 dddd A h:mm',
    },
    meridiem(e) {
      return e < 12 ? '오전' : '오후';
    },
    relativeTime: {
      future: '%s 후',
      past: '%s 전',
      s: '몇 초',
      m: '1분',
      mm: '%d분',
      h: '한 시간',
      hh: '%d시간',
      d: '하루',
      dd: '%d일',
      M: '한 달',
      MM: '%d달',
      y: '일 년',
      yy: '%d년',
    },
  },
};

// 扩展dayjs的额外语言
if (window._PANDA_EXUI) {
  Object.keys(window._PANDA_EXUI).forEach((lang) => {
    if (window._PANDA_EXUI[lang].dayjs) {
      locale[
        lang.startsWith('zh-') ? lang.toLocaleLowerCase() : lang.split('-')[0]
      ] = window._PANDA_EXUI[lang].dayjs;
    }
  });
}

export default (dayjs) => {
  Object.values(locale).forEach((langRun) => {
    const _locale = typeof langRun === 'function' ? langRun() : langRun;
    dayjs.locale(_locale, null, true);
  });
};
