import { asyncScheduler, BehaviorSubject, defer, fromEvent, Subscription, } from 'rxjs';
import { map, throttleTime } from 'rxjs/operators';
import * as styledComponents from 'styled-components';
import { Classes } from './styles';
/** styled-components 类库的版本，cn-table 同时支持 v3 和 v5 */
export var STYLED_VERSION = styledComponents.createGlobalStyle != null ? 'v5' : 'v3';
export var STYLED_REF_PROP = STYLED_VERSION === 'v3' ? 'innerRef' : 'ref';
export var OVERSCAN_SIZE = 100;
export var AUTO_VIRTUAL_THRESHOLD = 100;
export function sum(arr) {
    var result = 0;
    arr.forEach(function (x) {
        result += x;
    });
    return result;
}
// 使用 defer 避免过早引用 window，导致在 SSR 场景下报错
export var throttledWindowResize$ = defer(function () {
    return fromEvent(window, 'resize', { passive: true }).pipe(throttleTime(150, asyncScheduler, { leading: true, trailing: true }));
});
/** 获取默认的滚动条大小 */
function getScrollbarSizeImpl() {
    var scrollDiv = document.createElement('div');
    scrollDiv.style.position = 'absolute';
    scrollDiv.style.width = '100px';
    scrollDiv.style.height = '100px';
    scrollDiv.style.overflow = 'scroll';
    scrollDiv.style.top = '-9999px';
    scrollDiv.className = Classes.horizontalScrollContainer;
    document.body.appendChild(scrollDiv);
    var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    var scrollbarHeight = scrollDiv.offsetHeight - scrollDiv.clientHeight;
    document.body.removeChild(scrollDiv);
    return { width: scrollbarWidth, height: scrollbarHeight };
}
var scrollBarSize$;
export function getScrollbarSize() {
    if (scrollBarSize$ == null) {
        scrollBarSize$ = new BehaviorSubject(getScrollbarSizeImpl());
        throttledWindowResize$
            .pipe(map(function () { return getScrollbarSizeImpl(); }))
            .subscribe(scrollBarSize$);
    }
    return scrollBarSize$.value;
}
/** 同步多个元素之间的 scrollLeft, 每当 scrollLeft 发生变化时 callback 会被调用 */
export function syncScrollLeft(elements, callback) {
    var bypassSet = new Set();
    function publishScrollLeft(origin, scrollLeft) {
        bypassSet.clear();
        for (var _i = 0, elements_2 = elements; _i < elements_2.length; _i++) {
            var elem = elements_2[_i];
            if (elem === origin) {
                continue;
            }
            elem.scrollLeft = scrollLeft;
            bypassSet.add(elem);
        }
    }
    var subscription = new Subscription();
    var _loop_1 = function (ele) {
        var listener = function () {
            if (bypassSet.has(ele)) {
                bypassSet.delete(ele);
                return;
            }
            var scrollLeft = ele.scrollLeft;
            publishScrollLeft(ele, scrollLeft);
            callback(scrollLeft);
        };
        ele.addEventListener('scroll', listener, { passive: true });
        subscription.add(function () { return ele.removeEventListener('scroll', listener); });
    };
    for (var _i = 0, elements_1 = elements; _i < elements_1.length; _i++) {
        var ele = elements_1[_i];
        _loop_1(ele);
    }
    return subscription;
}
/**
 * Performs equality by iterating through keys on an object and returning false
 * when any key has values which are not strictly equal between the arguments.
 * Returns true when the values of all keys are strictly equal.
 */
export function shallowEqual(objA, objB) {
    var hasOwnProperty = Object.prototype.hasOwnProperty;
    if (Object.is(objA, objB)) {
        return true;
    }
    if (typeof objA !== 'object' ||
        objA === null ||
        typeof objB !== 'object' ||
        objB === null) {
        return false;
    }
    var keysA = Object.keys(objA);
    var keysB = Object.keys(objB);
    if (keysA.length !== keysB.length) {
        return false;
    }
    // Test for A's keys different from B.
    for (var i = 0; i < keysA.length; i++) {
        if (!hasOwnProperty.call(objB, keysA[i]) ||
            !Object.is(objA[keysA[i]], objB[keysA[i]])) {
            return false;
        }
    }
    return true;
}
