import { __assign, __rest } from "tslib";
import React from 'react';
import classnames from 'classnames';
// 弹出框容器组件
export function PopupContainer(props) {
    // 解构 props
    var children = props.children, className = props.className, rest = __rest(props, ["children", "className"]);
    // 合并类名
    var classNames = classnames(className, 'cn-table-attach-container');
    // 返回组件
    return (React.createElement("div", __assign({ className: classNames }, rest), children));
}
