import * as React from 'react';
export function useScrollRecord(fullScreen) {
    var storeScrollTop = React.useRef(document.documentElement.scrollTop);
    React.useMemo(function () {
        if (fullScreen) {
            storeScrollTop.current = document.documentElement.scrollTop;
            document.documentElement.style.position = 'fixed';
            document.documentElement.style.width = '100%';
        }
        else {
            document.documentElement.style.position = 'unset';
            document.documentElement.scrollTop = storeScrollTop.current;
        }
    }, [fullScreen]);
}
