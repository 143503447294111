import { useCallback } from 'react';
import { useRemoveFile } from './use-remove-file';
export var useOnRemove = function (props) {
    var setValue = props.setValue, onChangeRef = props.onChangeRef, onRemoveRef = props.onRemoveRef, uploadRef = props.uploadRef;
    var removeFile = useRemoveFile({ setValue: setValue, onChangeRef: onChangeRef, onRemoveRef: onRemoveRef });
    return useCallback(function (file) {
        var _a, _b;
        if (file.originFile)
            (_b = (_a = uploadRef.current) === null || _a === void 0 ? void 0 : _a.abort) === null || _b === void 0 ? void 0 : _b.call(_a, file.originFile);
        removeFile(file);
    }, [removeFile, uploadRef]);
};
