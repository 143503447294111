import { __assign, __extends, __rest } from "tslib";
import * as React from 'react';
import { Tab } from '@fusion/lib';
import classnames from 'classnames';
import isString from 'lodash/isString';
import { getByteLen, subCharStr } from '@/components/cn-utils';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnTabItem } from './item';
function CustomTabItem(_a) {
    var itemKey = _a.itemKey, onClose = _a.onClose, title = _a.title, icon = _a.icon, closeable = _a.closeable;
    var onClick = function (e) {
        // 阻止触发 onChange
        e.stopPropagation();
        onClose && onClose(itemKey);
    };
    return (React.createElement("div", { className: "cn-next-tabs-tab-inner" },
        React.createElement("div", { className: "cn-ui-tab-inner-sub cn-ui-tab-inner-sub-left" }),
        React.createElement("div", { className: "cn-ui-tab-inner-sub cn-ui-tab-inner-sub-right" }),
        icon,
        title,
        closeable && (React.createElement("i", { className: "cn-next-icon cn-next-icon-close cn-next-medium cn-next-tabs-tab-close", onClick: onClick }))));
}
function getShape(currentShape, shape) {
    if (shape) {
        return shape;
    }
    if (!currentShape || currentShape === 'pure') {
        return 'wrapped';
    }
    if (currentShape === 'wrapped') {
        return 'text';
    }
    if (currentShape === 'text') {
        return 'capsule';
    }
    return undefined;
}
var TabShapeContext = React.createContext(undefined);
var CnTab = /** @class */ (function (_super) {
    __extends(CnTab, _super);
    function CnTab() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getTabTitle = function (props) {
            if (props === void 0) { props = {}; }
            var propTitle = props.title, maxTitleLength = props.maxTitleLength;
            if (!isString(propTitle)) {
                return propTitle;
            }
            var byteLen = getByteLen(propTitle);
            if (byteLen <= maxTitleLength) {
                return propTitle;
            }
            return (React.createElement(CnTooltip, { v2: true, align: "t", trigger: React.createElement("div", null, subCharStr(propTitle, maxTitleLength)) }, propTitle));
        };
        _this.getChildren = function (smartPadding) {
            var children = [];
            React.Children.forEach(_this.props.children, function (child) {
                if (child === null || child === void 0 ? void 0 : child.props) {
                    children.push(React.cloneElement(child, {
                        title: _this.getTabTitle(child.props),
                        smartPadding: smartPadding,
                    }));
                }
            });
            return children;
        };
        return _this;
    }
    CnTab.prototype.render = function () {
        var _a;
        var _b = this.props, children = _b.children, shape = _b.shape, className = _b.className, onClose = _b.onClose, smartPadding = _b.smartPadding, otherProps = __rest(_b, ["children", "shape", "className", "onClose", "smartPadding"]);
        var shapeContext = this.context;
        var viewShape = getShape(shapeContext, shape);
        return (React.createElement(TabShapeContext.Provider, { value: viewShape },
            React.createElement(Tab, __assign({ "data-name": "CnTab", tabRender: function (key, props) { return (React.createElement(CustomTabItem, __assign({ itemKey: key }, props, { onClose: onClose }))); }, shape: viewShape }, otherProps, { className: classnames('cn-ui-tab', (_a = {},
                    _a[className] = !!className,
                    _a)) }), this.getChildren(smartPadding))));
    };
    CnTab.contextType = TabShapeContext;
    return CnTab;
}(React.Component));
export { CnTab };
CnTab.Item = CnTabItem;
CnTab.displayName = 'CnTab';
