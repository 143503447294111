import { __assign } from "tslib";
import * as React from 'react';
import classnames from 'classnames';
import { Balloon } from '@fusion/lib';
import './index.scss';
export var CnTooltip = function (props) {
    var cls = classnames('cn-balloon-tooltip-style', props.className);
    return (React.createElement(Balloon.Tooltip, __assign({ "data-name": "CnTooltip" }, props, { className: cls }), props.children));
};
CnTooltip.displayName = 'CnTooltip';
