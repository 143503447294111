import { getCardTypeByValue } from './card-types';
export var formatCardNumber = function (cardNumber, cardTypes) {
    if (cardTypes === void 0) { cardTypes = []; }
    var cardType = getCardTypeByValue(cardNumber, cardTypes);
    if (!cardType)
        return (cardNumber.match(/\d+/g) || []).join('');
    var format = cardType === null || cardType === void 0 ? void 0 : cardType.format;
    if (format && format.global) {
        return (cardNumber.match(format) || []).join(' ');
    }
    if (format) {
        var execResult = format.exec(cardNumber.split(' ').join(''));
        if (execResult) {
            return execResult
                .splice(1, 3)
                .filter(function (x) { return x; })
                .join(' ');
        }
    }
    return cardNumber;
};
