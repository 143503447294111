import { Checkbox, CheckboxGroup, DatePicker2, Input, NumberPicker, Radio, RadioGroup, RangeDatePicker, RangeTimePicker, Select, TextArea, TimePicker2, Transfer, Range, } from '@/form/fusion-compatible';
import { Switch } from '@/form/switch';
import { CnIcon } from '@/form/cn-icon';
import { CnBankInput } from '@/form/cn-bank-input';
import { CnNumberPicker, CnRangeNumberPicker, CnPercentInput, } from '@/form/cn-number-picker';
import { FormItem } from '@/form/form-item';
import { CnFormItem } from '@/form/cn-form-item';
import { CnFormLayout } from '@/form/cn-form-layout';
import { CnFormGrid } from '@/form/cn-form-grid';
import { CnSwitch } from '@/form/cn-switch';
import { Submit } from '@/form/submit';
import { Reset } from '@/form/reset';
import { CnFormSingleCol } from '@/form/cn-form-single-col';
import { CnCard, CnCardSubCard, CnCardSubAreaCard } from '@/form/cn-card';
import { CnArrayItems } from '@/form/cn-array-items';
import { CnArraySubAreaCard } from '@/form/cn-array-sub-area-card';
import { CnArrayTable, CnArrayTableColumn, CnArrayTableAddition, CnArrayTableIndex, CnArrayTableRemove, CnArrayTableMoveUp, CnArrayTableMoveDown, CnArrayTableSortHandle, } from '@/form/cn-array-table';
import { CnInput, CnInputTextArea } from '@/form/cn-input';
import { CnBatchInput } from '@/form/cn-batch-input';
import { CnAsyncSelect, CnAsyncSelect as CnSelect, CnAsyncSelectAutoComplete, CnAsyncSelectAutoComplete as CnSelectAutoComplete, } from '@/form/cn-async-select';
import { CnRadio, CnRadioGroup } from '@/form/cn-radio-group';
import { CnCheckbox, CnCheckboxGroup } from '@/form/cn-checkbox';
import { CnComplexRadio, CnComplexRadioGroup } from '@/form/cn-complex-radio';
import { CnComplexCheckbox, CnComplexCheckboxGroup, } from '@/form/cn-complex-checkbox';
import { CnRangeTimePicker2, CnTimePicker2, CnRangeTimePickerPro, CnTimePickerPro, } from '@/form/cn-time-picker-pro';
import { CnDatePicker2, CnMonthPicker2, CnQuarterPicker2, CnRangeDatePicker2, CnWeekPicker2, CnYearPicker2, CnDatePickerPro, CnMonthPickerPro, CnQuarterPickerPro, CnRangeDatePickerPro, CnWeekPickerPro, CnYearPickerPro, } from '@/form/cn-date-picker-pro';
import { CnTreeSelect } from '@/form/cn-tree-select';
import { CnCascaderSelect } from '@/form/cn-cascader-select';
import { CnFormStep, CnFormStepItem } from '@/form/cn-form-step';
import { CnOSSUpload } from '@/form/cn-oss-upload';
import { CnEmployeeSelect } from '@/form/cn-employee-select';
import { CnDepartmentSelect } from '@/form/cn-department-select';
import { CnCurrencySelect } from '@/form/cn-currency-select';
import { CnCurrencyAmountNumberPicker } from '@/form/cn-currency-amount-number-picker';
import { CnTree } from '@/form/cn-tree';
import { CnRating } from '@/form/cn-rating';
import { CnFormTab, CnFormTabItem } from '@/form/cn-form-tab';
import { CnTable } from '@/form/cn-table';
import { CnTransfer } from '@/form/cn-transfer';
import { CnRange } from '@/form/cn-range';
export var componentMap = {
    Input: Input,
    FormItem: FormItem,
    Checkbox: Checkbox,
    CheckboxGroup: CheckboxGroup,
    Radio: Radio,
    RadioGroup: RadioGroup,
    TextArea: TextArea,
    TimePicker2: TimePicker2,
    NumberPicker: NumberPicker,
    RangeTimePicker: RangeTimePicker,
    DatePicker2: DatePicker2,
    RangeDatePicker: RangeDatePicker,
    Switch: Switch,
    Select: Select,
    Range: Range,
    CnRange: CnRange,
    Transfer: Transfer,
    Submit: Submit,
    Reset: Reset,
    // CnArrayTable
    CnArrayItems: CnArrayItems,
    CnArraySubAreaCard: CnArraySubAreaCard,
    CnArrayTable: CnArrayTable,
    CnArrayTableColumn: CnArrayTableColumn,
    CnArrayTableAddition: CnArrayTableAddition,
    CnArrayTableIndex: CnArrayTableIndex,
    CnArrayTableRemove: CnArrayTableRemove,
    CnArrayTableMoveUp: CnArrayTableMoveUp,
    CnArrayTableMoveDown: CnArrayTableMoveDown,
    CnArrayTableSortHandle: CnArrayTableSortHandle,
    // 一般组件
    CnNumberPicker: CnNumberPicker,
    CnRangeNumberPicker: CnRangeNumberPicker,
    CnPercentInput: CnPercentInput,
    CnFormLayout: CnFormLayout,
    CnFormGrid: CnFormGrid,
    CnTreeSelect: CnTreeSelect,
    CnCascaderSelect: CnCascaderSelect,
    CnFormSingleCol: CnFormSingleCol,
    CnFormItem: CnFormItem,
    CnIcon: CnIcon,
    CnBankInput: CnBankInput,
    CnSwitch: CnSwitch,
    CnInput: CnInput,
    CnInputTextArea: CnInputTextArea,
    CnBatchInput: CnBatchInput,
    CnRadio: CnRadio,
    CnRadioGroup: CnRadioGroup,
    CnComplexRadio: CnComplexRadio,
    CnComplexRadioGroup: CnComplexRadioGroup,
    CnTimePicker2: CnTimePicker2,
    CnRangeTimePicker2: CnRangeTimePicker2,
    CnTimePickerPro: CnTimePickerPro,
    CnRangeTimePickerPro: CnRangeTimePickerPro,
    CnSelect: CnSelect,
    CnSelectAutoComplete: CnSelectAutoComplete,
    CnAsyncSelect: CnAsyncSelect,
    CnAsyncSelectAutoComplete: CnAsyncSelectAutoComplete,
    CnCheckbox: CnCheckbox,
    CnCheckboxGroup: CnCheckboxGroup,
    CnComplexCheckbox: CnComplexCheckbox,
    CnComplexCheckboxGroup: CnComplexCheckboxGroup,
    CnDatePicker2: CnDatePicker2,
    CnMonthPicker2: CnMonthPicker2,
    CnYearPicker2: CnYearPicker2,
    CnWeekPicker2: CnWeekPicker2,
    CnQuarterPicker2: CnQuarterPicker2,
    CnRangeDatePicker2: CnRangeDatePicker2,
    CnDatePickerPro: CnDatePickerPro,
    CnMonthPickerPro: CnMonthPickerPro,
    CnYearPickerPro: CnYearPickerPro,
    CnWeekPickerPro: CnWeekPickerPro,
    CnQuarterPickerPro: CnQuarterPickerPro,
    CnRangeDatePickerPro: CnRangeDatePickerPro,
    CnCard: CnCard,
    CnCardSubCard: CnCardSubCard,
    CnCardSubAreaCard: CnCardSubAreaCard,
    CnOSSUpload: CnOSSUpload,
    CnEmployeeSelect: CnEmployeeSelect,
    CnDepartmentSelect: CnDepartmentSelect,
    CnCurrencySelect: CnCurrencySelect,
    CnCurrencyAmountNumberPicker: CnCurrencyAmountNumberPicker,
    CnFormStep: CnFormStep,
    CnFormStepItem: CnFormStepItem,
    CnTree: CnTree,
    CnRating: CnRating,
    CnFormTab: CnFormTab,
    CnFormTabItem: CnFormTabItem,
    CnTable: CnTable,
    CnTransfer: CnTransfer,
};
