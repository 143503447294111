// 导入 select 库
import select from 'select';
/**
 * 根据给定的操作类型执行操作。
 * @param {String} type 操作类型
 * @return {Boolean} 操作是否成功
 */
function command(type) {
    try {
        return document.execCommand(type);
    }
    catch (err) {
        return false;
    }
}
/**
 * 使用给定的值创建一个假的 textarea 元素。
 * @param {String} value textarea 的值
 * @return {HTMLElement} 创建的假 textarea 元素
 */
function createFakeElement(value) {
    var isRTL = document.documentElement.getAttribute('dir') === 'rtl';
    var fakeElement = document.createElement('textarea');
    // 防止在 iOS 上缩放
    fakeElement.style.fontSize = '12pt';
    // 重置盒模型
    fakeElement.style.border = '0';
    fakeElement.style.padding = '0';
    fakeElement.style.margin = '0';
    // 将元素水平移出屏幕
    fakeElement.style.position = 'absolute';
    fakeElement.style[isRTL ? 'right' : 'left'] = '-9999px';
    // 将元素垂直移动到相同位置
    var yPosition = window.pageYOffset || document.documentElement.scrollTop;
    fakeElement.style.top = "".concat(yPosition, "px");
    fakeElement.setAttribute('readonly', '');
    fakeElement.value = value;
    return fakeElement;
}
/**
 * 复制操作包装器。
 * @param {String|HTMLElement} target 要复制的目标，可以是字符串或 HTMLElement
 * @param {Object} options 选项，包括容器
 * @return {String} 被选中的文本
 */
export var ClipboardActionCopy = function (target, options) {
    if (options === void 0) { options = { container: document.body }; }
    var selectedText = '';
    if (typeof target === 'string') {
        var fakeElement = createFakeElement(target);
        options.container.appendChild(fakeElement);
        selectedText = select(fakeElement);
        command('copy');
        fakeElement.remove();
    }
    else {
        selectedText = select(target);
        command('copy');
    }
    return selectedText;
};
