// import NextSelect from '@fusion/lib/select';
import { withI18n } from 'panda-i18n';
// import type { AutoComplete } from '@fusion/types/select';
import { componentName } from './const';
import { AutoComplete as PureAutoComplete } from './view/auto-complete';
import { SelectPlugin as PureSelect } from './view';
import locale from '@/locales';
// TODO ref组件未输出ref类型
export var CnAsyncSelect = withI18n(PureSelect, {
    componentName: componentName,
    locale: locale,
    forwardRef: true,
});
// as unknown as ForwardRefExoticComponent<SelectProps> & {
//   AutoComplete: typeof AutoComplete;
// };
CnAsyncSelect.displayName = componentName;
CnAsyncSelect.AutoComplete = PureAutoComplete;
export * from './types';
