import { __assign } from "tslib";
import React, { useEffect, useRef } from 'react';
import RcUpload from 'rc-upload';
import { useOnSuccess } from './hooks/use-on-success';
import { useOnProgress } from './hooks/use-on-progress';
import { useOnError } from './hooks/use-on-error';
import { useOnStart } from './hooks/use-on-start';
export var UploadCore = React.forwardRef(function (props, ref) {
    var formatter = props.formatter, directory = props.directory, method = props.method, headers = props.headers, beforeUpload = props.beforeUpload, customRequest = props.customRequest, withCredentials = props.withCredentials, openFileDialogOnClick = props.openFileDialogOnClick, _onError = props.onError, _a = props.name, name = _a === void 0 ? 'file' : _a, multiple = props.multiple, action = props.action, data = props.data, accept = props.accept, disabled = props.disabled, onChange = props.onChange, setValue = props.setValue;
    var onChangeRef = useRef(onChange);
    var onErrorRef = useRef(_onError);
    var formatterRef = useRef(formatter);
    useEffect(function () {
        onChangeRef.current = onChange;
        onErrorRef.current = _onError;
        formatterRef.current = formatter;
    }, [onChange, _onError, formatter]);
    var onStart = useOnStart({ setValue: setValue });
    var onProgress = useOnProgress({ setValue: setValue });
    var onSuccess = useOnSuccess({ setValue: setValue, onChangeRef: onChangeRef, formatterRef: formatterRef });
    var onError = useOnError({ setValue: setValue, onChangeRef: onChangeRef, onErrorRef: onErrorRef });
    return (React.createElement(RcUpload, { ref: ref, name: name, action: action, directory: directory, data: data, method: method, headers: __assign(__assign({}, headers), { 'X-Requested-With': (headers === null || headers === void 0 ? void 0 : headers['X-Requested-With']) || null }), multiple: multiple, accept: accept, beforeUpload: beforeUpload, disabled: disabled, customRequest: customRequest, withCredentials: withCredentials, openFileDialogOnClick: openFileDialogOnClick, onStart: onStart, onSuccess: function (res, file, xhr) {
            if ((res === null || res === void 0 ? void 0 : res.success) === false) {
                var err = new Error(res.errorMsg);
                onError(err, res, file);
                return;
            }
            onSuccess(res, file, xhr);
        }, onError: onError, onProgress: onProgress }, props.children));
});
UploadCore.displayName = 'UploadCore';
