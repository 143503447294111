import { __spreadArray } from "tslib";
// 重新排序列函数
export function reOrderColumns(columns) {
    // 定义左侧、普通和右侧列数组
    var left = [];
    var normal = [];
    var right = [];
    // 如果没有列，返回空数组
    if (!columns)
        return [];
    // 遍历列
    columns.forEach(function (col) {
        var lock = col.lock;
        // 如果锁定在左侧或者锁定为true
        if (lock === true || lock === 'left') {
            left.push(col);
        }
        else if (lock === 'right') {
            // 如果锁定在右侧
            right.push(col);
        }
        else {
            // 否则为普通列
            normal.push(col);
        }
    });
    // 返回重新排序后的列数组
    return __spreadArray(__spreadArray(__spreadArray([], left, true), normal, true), right, true);
}
export function reOrderColumnsPipeline(pipeline) {
    return pipeline.columns(reOrderColumns(pipeline.getColumns()));
}
