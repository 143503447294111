import { __assign, __awaiter, __generator, __spreadArray } from "tslib";
import { useI18n } from 'panda-i18n';
import React, { useCallback, useEffect, useState, useRef, useMemo, } from 'react';
import { Select, Menu, Input, Search, Overlay, Icon, } from '@/components/fusion';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnBalloon } from '@/components/cn-balloon';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import { isEmpty, genId } from '../helper';
import storage from '../storage';
import FilterItem from '../filter-item';
import classNames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import { sendLog } from '@/components/cn-utils';
import { IconSave } from '../svg';
import { CnButton } from '@/components/cn-button';
// declare interface Convert {
//   (values: Obj): SavedData;
// }
// declare interface Revert {
//   (data: SavedData): Obj;
// }
export default (function (_a) {
    var enableSaveSelected = _a.enableSaveSelected, values = _a.values, field = _a.field, storageKey = _a.storageKey, beforeGetSaveSelected = _a.beforeGetSaveSelected, beforeSetSaveSelected = _a.beforeSetSaveSelected, onGetStorage = _a.onGetStorage, onSetStorage = _a.onSetStorage, filterPopupContainerRef = _a.filterPopupContainerRef;
    var $i18n = useI18n();
    var _b = useState([]), options = _b[0], setOptions = _b[1];
    var _c = useState(''), name = _c[0], setName = _c[1];
    var _d = useState(), selected = _d[0], setSelected = _d[1];
    var currentSelectedValuesRef = useRef(null);
    var isInitStorageRef = useRef(true);
    var triggerRef = useRef(null);
    var overlayRef = useRef(null);
    var _e = useState(false), overlayVisible = _e[0], setOverlayVisible = _e[1];
    var _f = useState(false), menuVisible = _f[0], setMenuVisible = _f[1];
    var _g = useState(false), habitSelectVisible = _g[0], setHabitSelectVisible = _g[1];
    var clearSelected = function () {
        var findSelected = options.find(function (i) { return i.value === selected; });
        if (!findSelected || !findSelected.data)
            return;
        if (!isEqual(findSelected.data, field.getValues())) {
            setSelected(undefined);
        }
    };
    var storageRef = useRef(storage({
        storageKey: storageKey,
        type: 'selected',
        getData: onGetStorage,
        setData: onSetStorage,
    }));
    useEffect(function () {
        if (!enableSaveSelected)
            return;
        storageRef.current
            .getData()
            .then(function (data) {
            isInitStorageRef.current = false;
            if (data) {
                setOptions(data);
            }
        })
            .catch(function () { });
    }, [enableSaveSelected]);
    useEffect(function () {
        if (!enableSaveSelected)
            return;
        if (isInitStorageRef.current)
            return;
        storageRef.current.setData(options).catch(function () { });
    }, [options, enableSaveSelected]);
    useEffect(function () {
        if (!enableSaveSelected)
            return;
        if (currentSelectedValuesRef.current &&
            Object.keys(__assign(__assign({}, currentSelectedValuesRef.current), values)).some(function (key) {
                var _a;
                return ((_a = currentSelectedValuesRef.current) === null || _a === void 0 ? void 0 : _a[key]) !== (values === null || values === void 0 ? void 0 : values[key]);
            })) {
            currentSelectedValuesRef.current = null;
            setSelected(undefined);
        }
    }, [values, enableSaveSelected]);
    var handleClose = useCallback(function () {
        var _a;
        ((_a = triggerRef.current) === null || _a === void 0 ? void 0 : _a.click) && triggerRef.current.click();
        setOverlayVisible(false);
    }, []);
    var asyncHandleSave = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var _values;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _values = values;
                    if (!beforeGetSaveSelected) return [3 /*break*/, 2];
                    return [4 /*yield*/, beforeGetSaveSelected(_values)];
                case 1:
                    _values = (_a.sent()) || _values;
                    _a.label = 2;
                case 2:
                    setOptions(function (_options) {
                        var value = genId(_options.map(function (opt) { return opt.value; }));
                        setSelected(value);
                        _options = filter(_options, function (i) { return i.label !== name; });
                        // console.log(_options);
                        var saveData = __spreadArray([
                            {
                                label: name,
                                value: value,
                                data: _values,
                            }
                        ], _options, true);
                        return saveData;
                    });
                    setName('');
                    handleClose();
                    return [2 /*return*/];
            }
        });
    }); }, [values, beforeGetSaveSelected, handleClose, name]);
    var renderSaveValidate = function () {
        var sameItem = options.find(function (i) { return i.label === name; });
        if (sameItem) {
            return (React.createElement("div", { className: "cn-ui-filter-save-panel-msg" },
                React.createElement(Icon, { className: "cn-next-icon-warning", style: { color: '#FFA64E', marginRight: 5 } }),
                $i18n.get({
                    id: 'TheNameIsDuplicatedAndWill_401244060',
                    dm: '名称重复，保存后将覆盖',
                    ns: 'CnFilter',
                })));
        }
        return '';
    };
    var handleSave = useCallback(function () {
        sendLog({
            id: 'cn-ui.cn-filter.saveSelectedOk',
            name: 'CnFilter查询条件保存按钮点击',
        });
        asyncHandleSave();
    }, [asyncHandleSave]);
    var handleRemove = useCallback(function (item, e) {
        e.stopPropagation();
        setOptions(function (_options) {
            return _options.filter(function (option) { return option.value !== item.value; });
        });
    }, []);
    var asyncHandleSelect = useCallback(function (value) { return __awaiter(void 0, void 0, void 0, function () {
        var target, _values;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setSelected(value || undefined);
                    target = value && (options || []).find(function (option) { return option.value === value; });
                    if (!target || !target.data) {
                        field.filterReset && field.filterReset();
                        currentSelectedValuesRef.current = null;
                        field.filterSearch && field.filterSearch();
                        return [2 /*return*/];
                    }
                    _values = target.data;
                    if (!beforeSetSaveSelected) return [3 /*break*/, 2];
                    return [4 /*yield*/, beforeSetSaveSelected(_values)];
                case 1:
                    _values = (_a.sent()) || _values;
                    _a.label = 2;
                case 2:
                    field.reset();
                    currentSelectedValuesRef.current = _values;
                    field.setValues(_values);
                    field.filterChange && field.filterChange(_values, { field: field });
                    field.filterSearch && field.filterSearch();
                    return [2 /*return*/];
            }
        });
    }); }, [field, beforeSetSaveSelected, options]);
    var handleSelect = useCallback(function (value) {
        asyncHandleSelect(parseInt(value, 10));
    }, [asyncHandleSelect]);
    var saveEnable = useMemo(function () {
        return (options.length ||
            Object.values(values || {}).some(function (value) {
                return Array.isArray(value)
                    ? value.filter(function (val) { return !isEmpty(val); }).length > 0
                    : !isEmpty(value);
            }));
    }, [values, options]);
    var renderOption = useCallback(function (item) {
        var removeTriggerRef = React.createRef();
        var handleRemoveClose = function () {
            var _a;
            ((_a = removeTriggerRef.current) === null || _a === void 0 ? void 0 : _a.click) && removeTriggerRef.current.click();
        };
        return (React.createElement("span", { className: "cn-ui-filter-save-option" },
            React.createElement("span", { className: "option-label" }, item.label),
            React.createElement("span", { onClick: function (e) { return e && e.stopPropagation(); } },
                React.createElement(CnBalloon, { v2: true, align: "tl", autoFocus: true, trigger: React.createElement("span", { ref: removeTriggerRef },
                        React.createElement(CnButton, { text: true, size: "large" },
                            React.createElement(CnIcon, { type: "close", className: "option-delete-icon" }))), closable: false, triggerType: "click" },
                    React.createElement("div", { className: "cn-ui-filter-save-text" }, $i18n.get({
                        id: 'ThisFilterCombinationWillBeLost_972042728',
                        dm: '确定要删除当前查询习惯吗？',
                    })),
                    React.createElement("div", { className: "cn-ui-filter-save-btns" },
                        React.createElement(CnButton, { type: "primary", size: "small", onClick: function (e) { return handleRemove(item, e); } }, $i18n.get({ id: 'ok', dm: '确认' })),
                        React.createElement(CnButton, { size: "small", onClick: handleRemoveClose }, $i18n.get({ id: 'cancel', dm: '取消' })))))));
    }, [filterPopupContainerRef, handleRemove]);
    var renderSelector = useCallback(function (props) {
        return (React.createElement(FilterItem, __assign({ label: $i18n.get({ id: 'myCriteria', dm: '查询习惯' }) }, props),
            React.createElement(Select, { ref: overlayRef, className: "select cn-ui-filter-habit", value: selected, dataSource: options, onChange: function (value) {
                    handleSelect(value);
                }, itemRender: function (item) { return renderOption(item); }, showSearch: true, hasClear: true, visible: habitSelectVisible, onVisibleChange: setHabitSelectVisible, onFocus: function () {
                    setOverlayVisible(false);
                }, addonAfter: React.createElement(CnTooltip, { align: "t", delay: 300, trigger: React.createElement(CnButton, { size: props.size, className: "save", onMouseDown: function (e) {
                            e.stopPropagation();
                            setOverlayVisible(function (v) { return !v; });
                            setHabitSelectVisible(false);
                            sendLog({
                                id: 'cn-ui.cn-filter.clickSaveSelected',
                                name: 'CnFilter查询条件弹层展示按钮点击',
                            });
                        }, onClick: function (e) {
                            e.stopPropagation();
                        } },
                        React.createElement(CnIcon, { type: "icon-favorites", size: props.size })) }, $i18n.get({
                    id: 'PreservationHabits',
                    dm: '保存习惯',
                    ns: 'CnFilter',
                })) })));
    }, [handleSelect, options, renderOption, selected, habitSelectVisible]);
    // const renderMenu = useCallback(() => {
    //   return options.length ? (
    //     <Menu onSelect={handleSelect} className='diy-menu' selectMode='single'>
    //       {options.map((item) => {
    //         return (
    //           <Menu.Item key={item.value}>
    //             <div className='habit-menu-item'>
    //               {item.label}
    //               <Button
    //                 className='diy-menu-button'
    //                 iconSize={'xs'}
    //                 onClick={(e) => handleRemove(item, e)}
    //                 text
    //               >
    //                 <Icon type='close' />
    //               </Button>
    //             </div>
    //           </Menu.Item>
    //         );
    //       })}
    //     </Menu>
    //   ) : null;
    // }, [handleRemove, handleSelect, options]);
    var renderMenu = function () {
        return options.length ? (React.createElement(Menu, { onSelect: handleSelect, className: "diy-menu", selectMode: "single" }, options.map(function (item) {
            return (React.createElement(Menu.Item, { key: item.value },
                React.createElement("div", { className: "habit-menu-item" },
                    item.label,
                    React.createElement(CnButton, { className: "diy-menu-button", iconSize: 'xs', onClick: function (e) { return handleRemove(item, e); }, text: true },
                        React.createElement(CnIcon, { type: "close" })))));
        }))) : null;
    };
    var renderSaveBtns = useCallback(function (props) {
        if (!enableSaveSelected)
            return null;
        var size = props.size;
        return saveEnable ? (React.createElement(CnBalloon, { v2: true, align: "tr", autoFocus: true, trigger: React.createElement("span", { ref: triggerRef, className: classNames('cn-ui-filter-icon-btn', "cn-ui-filter-icon-btn-".concat(size), 'cn-ui-filter-icon-btn-save-btn'), onClick: function (e) { return !saveEnable && e && e.preventDefault(); } },
                React.createElement(CnButton, { size: size },
                    React.createElement(CnIcon, { type: "save" }))), closable: false, triggerType: "click", popupContainer: function () { return filterPopupContainerRef === null || filterPopupContainerRef === void 0 ? void 0 : filterPopupContainerRef.current; } },
            React.createElement("div", { className: "cn-ui-filter-save-line" },
                React.createElement(Input.Group, { addonAfter: React.createElement(CnButton, { className: "save", disabled: !name, onClick: handleSave },
                        React.createElement(CnIcon, { type: "save" })) },
                    React.createElement(Input, { value: name, onChange: setName }))),
            React.createElement("div", { className: "cn-ui-filter-saved-data" },
                " ",
                renderMenu()))) : (React.createElement(CnButton, { disabled: true, className: classNames('cn-ui-filter-icon-btn', "cn-ui-filter-icon-btn-".concat(size)) },
            React.createElement(CnIcon, { type: "save" })));
    }, [
        handleClose,
        handleSave,
        name,
        saveEnable,
        enableSaveSelected,
        filterPopupContainerRef,
    ]);
    var renderSaveButtonProps = function () {
        return {
            icon: 'save',
            // title: $i18n.get({ id: 'Save', dm: '保存' }),
            onClick: function () {
                setOverlayVisible(function (v) { return !v; });
            },
            // 临时先用title表示
            useIcon: false,
            title: (React.createElement("div", null,
                React.createElement(IconSave, null))),
            ref: overlayRef,
            // children: (
            //   <span ref={overlayRef}>
            //     <Icon type='set' />
            //   </span>
            // ),
        };
    };
    var renderSaveOverlay = useCallback(function () {
        if (!enableSaveSelected)
            return null;
        return (React.createElement(Overlay, { visible: overlayVisible, target: function () { return overlayRef.current; }, safeNode: function () { return overlayRef.current; }, onRequestClose: function () { return setOverlayVisible(false); }, 
            // container={() => filterPopupContainerRef?.current}
            align: "tc bc" },
            React.createElement("div", { className: "save-panel" },
                React.createElement("div", { className: "cn-ui-filter-search" },
                    React.createElement(Search, { style: { width: '100%' }, 
                        // size='small'
                        onVisibleChange: function () { return setMenuVisible(false); }, popupContent: renderMenu(), popupClassName: "cn-ui-filter-habit-list", visible: menuVisible, fillProps: "label", value: name, 
                        // todo
                        // searchText={<CnIcon type='save' size='large' />}
                        searchText: React.createElement("div", null,
                            React.createElement(IconSave, null)), hasIcon: false, onSearch: handleSave, onChange: setName, onFocus: function () { return setMenuVisible(true); } })))));
    }, [
        enableSaveSelected,
        saveEnable,
        overlayVisible,
        name,
        handleSave,
        renderMenu,
        filterPopupContainerRef,
    ]);
    var renderInputSaveOverlay = function () {
        var _a;
        if (!enableSaveSelected)
            return null;
        return (React.createElement(Overlay, { visible: overlayVisible, target: function () { return overlayRef.current; }, safeNode: function () { return overlayRef.current; }, onRequestClose: function () { return setOverlayVisible(false); }, align: "tc bc" },
            React.createElement("div", { className: "cn-ui-filter-save-panel", style: { width: ((_a = overlayRef === null || overlayRef === void 0 ? void 0 : overlayRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth) || 200 } },
                React.createElement(Input, { value: name, onChange: setName }),
                renderSaveValidate(),
                React.createElement("div", { className: "cn-ui-filter-save-panel-btns" },
                    React.createElement(CnButton, { onClick: function () {
                            setOverlayVisible(false);
                            sendLog({
                                id: 'cn-ui.cn-filter.saveSelectedCancel',
                                name: 'CnFilter查询条件取消按钮点击',
                            });
                        } }, $i18n.get({ id: 'cancel', dm: '取消', ns: 'CnFilter' })),
                    React.createElement(CnButton, { type: "primary", onClick: handleSave }, $i18n.get({ id: 'Save', dm: '保存', ns: 'CnFilter' }))))));
    };
    return {
        renderSelector: renderSelector,
        renderSaveBtns: renderSaveBtns,
        renderSaveOverlay: renderSaveOverlay,
        renderSaveButtonProps: renderSaveButtonProps,
        renderInputSaveOverlay: renderInputSaveOverlay,
        clearSelected: clearSelected,
    };
});
