import * as React from 'react';
/**
 * 高亮文字
 */
export var highlightRender = function (itemText, searchKey) {
    if (!searchKey) {
        return itemText;
    }
    if (searchKey && searchKey.length) {
        var key = searchKey.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
        var reg = new RegExp("(".concat(key, ")"), 'ig');
        itemText = itemText.replace(reg, function (x) { return "<span class=\"cn-ui-select-highlight\">".concat(x, "</span>"); });
    }
    return React.createElement("span", { dangerouslySetInnerHTML: { __html: itemText } });
};
