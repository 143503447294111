import { __assign } from "tslib";
import * as React from 'react';
import NextMenuButton from './menu-button/index';
import { useSizeChange } from '@/components/cn-utils';
export var CnMenuButton = React.forwardRef(function (props, ref) {
    var size = useSizeChange(props).size;
    return (React.createElement(NextMenuButton, __assign({ "data-name": "CnMenuButton" }, props, { size: size, ref: ref })));
});
CnMenuButton.displayName = 'CnMenuButton';
/**
 * @deprecated
 * 建议使用 CnMenuButtonItem
 */
CnMenuButton.Item = NextMenuButton.Item;
export var CnMenuButtonItem = NextMenuButton.Item;
CnMenuButtonItem.displayName = 'CnMenuButtonItem';
export var MenuButtonItem = NextMenuButton.Item;
MenuButtonItem.displayName = 'MenuButtonItem';
/**
 * @deprecated
 * 建议使用 CnMenuButtonGroup
 */
CnMenuButton.Group = NextMenuButton.Group;
export var CnMenuButtonGroup = NextMenuButton.Group;
CnMenuButtonGroup.displayName = 'CnMenuButtonGroup';
export var MenuButtonGroup = NextMenuButton.Group;
MenuButtonGroup.displayName = 'MenuButtonGroup';
/**
 * @deprecated
 * 建议使用 CnMenuButtonDivider
 */
CnMenuButton.Divider = NextMenuButton.Divider;
export var CnMenuButtonDivider = NextMenuButton.Divider;
CnMenuButtonDivider.displayName = 'CnMenuButtonDivider';
export var MenuButtonDivider = NextMenuButton.Divider;
MenuButtonDivider.displayName = 'MenuButtonDivider';
