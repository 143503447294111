import { __assign, __spreadArray } from "tslib";
import $i18n from 'panda-i18n'; /* eslint-disable import/no-cycle */
import { collectNodes, isLeafNode } from '../../../view/utils';
import { AUTO_VIRTUAL_THRESHOLD, OVERSCAN_SIZE, sum } from './utils';
function resolveVirtualEnabled(virtualEnum, defaultValue) {
    if (virtualEnum == null || virtualEnum === 'auto') {
        return defaultValue;
    }
    return virtualEnum;
}
var lockColumnNeedSpecifiedWidthWarned = false;
function warnLockColumnNeedSpecifiedWidth(column) {
    if (!lockColumnNeedSpecifiedWidthWarned) {
        lockColumnNeedSpecifiedWidthWarned = true;
        console.warn($i18n.get({
            id: 'CntableTheColumnWithLock_148544281',
            dm: '[cn-table] lock=true 的列需要指定宽度',
            ns: 'CnBaseTable',
        }), column);
    }
}
/** 检查列配置 & 设置默认宽度 */
function processColumns(columns, defaultColumnWidth) {
    if (columns == null || !Array.isArray(columns)) {
        console.warn($i18n.get({
            id: 'CntableBaseTablePropscolumnsNeedTo_845988278',
            dm: '[cn-table] <BaseTable /> props.columns 需要传入一个数组',
            ns: 'CnBaseTable',
        }), columns);
        columns = [];
    }
    function dfs(columns) {
        var _a;
        var result = [];
        for (var _i = 0, columns_1 = columns; _i < columns_1.length; _i++) {
            var column = columns_1[_i];
            if (column.width == null) {
                if (defaultColumnWidth != null) {
                    column = __assign(__assign({}, column), { width: defaultColumnWidth });
                }
                else if (process.env.NODE_ENV !== 'production' &&
                    isLeafNode(column) &&
                    column.lock) {
                    warnLockColumnNeedSpecifiedWidth(column);
                }
            }
            if (isLeafNode(column)) {
                // 被隐藏的列 会在这里被剔除
                if (column.hidden !== true) {
                    result.push(column);
                }
            }
            else {
                var nextChildren = dfs((_a = column.children) !== null && _a !== void 0 ? _a : []);
                // 如果 nextChildren 为空，说明所有的子节点均被隐藏了，在这里隐藏父节点
                if (nextChildren.length > 0 && column.hidden !== true) {
                    result.push(__assign(__assign({}, column), { children: nextChildren }));
                }
            }
        }
        return result;
    }
    return dfs(columns);
}
function getLeftNestedLockCount(columns) {
    var nestedCount = 0;
    for (var _i = 0, columns_2 = columns; _i < columns_2.length; _i++) {
        var col = columns_2[_i];
        if (isLock(col)) {
            nestedCount += 1;
        }
        else {
            break;
        }
    }
    return nestedCount;
    function isLock(col) {
        if (isLeafNode(col) || col.lock) {
            return col.lock;
        }
        return col.children.some(isLock) || undefined;
    }
}
function getHorizontalRenderRange(_a) {
    var offsetX = _a.offsetX, maxRenderWidth = _a.maxRenderWidth, flat = _a.flat, useVirtual = _a.useVirtual;
    if (!useVirtual.horizontal) {
        return {
            leftIndex: 0,
            leftBlank: 0,
            rightIndex: flat.full.length,
            rightBlank: 0,
        };
    }
    var leftIndex = 0;
    var centerCount = 0;
    var leftBlank = 0;
    var centerRenderWidth = 0;
    var overscannedOffsetX = Math.max(0, offsetX - OVERSCAN_SIZE);
    while (leftIndex < flat.center.length) {
        var col = flat.center[leftIndex];
        if (col.width + leftBlank < overscannedOffsetX) {
            leftIndex += 1;
            leftBlank += col.width;
        }
        else {
            break;
        }
    }
    // 考虑 over scan 之后，中间部分的列至少需要渲染的宽度
    var minCenterRenderWidth = maxRenderWidth + (overscannedOffsetX - leftBlank) + 2 * OVERSCAN_SIZE;
    while (leftIndex + centerCount < flat.center.length) {
        var col = flat.center[leftIndex + centerCount];
        if (col.width + centerRenderWidth < minCenterRenderWidth) {
            centerRenderWidth += col.width;
            centerCount += 1;
        }
        else {
            break;
        }
    }
    var rightBlankCount = flat.center.length - leftIndex - centerCount;
    var rightBlank = sum(flat.center
        .slice(flat.center.length - rightBlankCount)
        .map(function (col) { return col.width; }));
    return {
        leftIndex: leftIndex,
        leftBlank: leftBlank,
        rightIndex: leftIndex + centerCount,
        rightBlank: rightBlank,
    };
}
// 一顿计算，将表格本次渲染所需要的数据都给算出来（代码写得有点乱，有较大优化空间）
// todo 可以考虑下将 header 部分的计算逻辑也放到这个文件中，目前应该有一些重复的计算逻辑
export function calculateRenderInfo(table) {
    var _a = table.state, offsetX = _a.offsetX, maxRenderWidth = _a.maxRenderWidth;
    var _b = table.props, useVirtualProp = _b.useVirtual, columnsProp = _b.columns, dataSourceProp = _b.dataSource, defaultColumnWidth = _b.defaultColumnWidth;
    var columns = processColumns(columnsProp, defaultColumnWidth);
    var leftNestedLockCount = getLeftNestedLockCount(columns);
    var fullFlat = collectNodes(columns, 'leaf-only');
    var flat;
    var nested;
    var useVirtual;
    // 全部固定则取消固定
    if (leftNestedLockCount === columns.length) {
        flat = { left: [], right: [], full: fullFlat, center: fullFlat };
        nested = { left: [], right: [], full: columns, center: columns };
        useVirtual = { horizontal: false, vertical: false, header: false };
    }
    else {
        var leftNested = columns.slice(0, leftNestedLockCount);
        var rightNestedLockCount = getLeftNestedLockCount(columns.slice().reverse());
        var centerNested = columns.slice(leftNestedLockCount, columns.length - rightNestedLockCount);
        var rightNested = columns.slice(columns.length - rightNestedLockCount);
        // 横向虚拟滚动
        var shouldEnableHozVirtual = fullFlat.length >= AUTO_VIRTUAL_THRESHOLD &&
            fullFlat.every(function (col) { return col.width != null; });
        // 纵向虚拟滚动
        var shouldEnableVerVirtual = dataSourceProp.length >= AUTO_VIRTUAL_THRESHOLD;
        useVirtual = {
            horizontal: resolveVirtualEnabled(typeof useVirtualProp === 'object'
                ? useVirtualProp.horizontal
                : useVirtualProp, shouldEnableHozVirtual),
            vertical: resolveVirtualEnabled(typeof useVirtualProp === 'object'
                ? useVirtualProp.vertical
                : useVirtualProp, shouldEnableVerVirtual),
            header: resolveVirtualEnabled(typeof useVirtualProp === 'object'
                ? useVirtualProp.header
                : useVirtualProp, false),
        };
        flat = {
            left: collectNodes(leftNested, 'leaf-only'),
            full: fullFlat,
            right: collectNodes(rightNested, 'leaf-only'),
            center: collectNodes(centerNested, 'leaf-only'),
        };
        nested = {
            left: leftNested,
            full: columns,
            right: rightNested,
            center: centerNested,
        };
    }
    var horizontalRenderRange = getHorizontalRenderRange({
        maxRenderWidth: maxRenderWidth,
        offsetX: offsetX,
        useVirtual: useVirtual,
        flat: flat,
    });
    // 计算纵向展示的空白和 数据index
    var verticalRenderRange = table.getVerticalRenderRange(useVirtual);
    var leftBlank = horizontalRenderRange.leftBlank, leftIndex = horizontalRenderRange.leftIndex, rightBlank = horizontalRenderRange.rightBlank, rightIndex = horizontalRenderRange.rightIndex;
    var unfilteredVisibleColumnDescriptors = __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], flat.left.map(function (col, i) { return ({ type: 'normal', col: col, colIndex: i }); }), true), [
        leftBlank > 0 && { type: 'blank', blankSide: 'left', width: leftBlank }
    ], false), flat.center.slice(leftIndex, rightIndex).map(function (col, i) {
        return ({
            type: 'normal',
            col: col,
            colIndex: flat.left.length + leftIndex + i,
        });
    }), true), [
        rightBlank > 0 && { type: 'blank', blankSide: 'right', width: rightBlank }
    ], false), flat.right.map(function (col, i) {
        return ({
            type: 'normal',
            col: col,
            colIndex: flat.full.length - flat.right.length + i,
        });
    }), true);
    // colgroup 的配置信息
    var visibleColumnDescriptors = unfilteredVisibleColumnDescriptors.filter(Boolean);
    var fullFlatCount = flat.full.length;
    var leftFlatCount = flat.left.length;
    var rightFlatCount = flat.right.length;
    var stickyLeftMap = new Map();
    var stickyLeft = 0;
    for (var i = 0; i < leftFlatCount; i++) {
        stickyLeftMap.set(i, stickyLeft);
        stickyLeft += flat.full[i].width;
    }
    var stickyRightMap = new Map();
    var stickyRight = 0;
    for (var i = 0; i < rightFlatCount; i++) {
        stickyRightMap.set(fullFlatCount - 1 - i, stickyRight);
        stickyRight += flat.full[fullFlatCount - 1 - i].width;
    }
    var leftLockTotalWidth = sum(flat.left.map(function (col) { return col.width; }));
    var rightLockTotalWidth = sum(flat.right.map(function (col) { return col.width; }));
    return {
        // leftBlank, leftIndex, rightBlank, rightIndex
        horizontalRenderRange: horizontalRenderRange,
        verticalRenderRange: verticalRenderRange,
        visible: visibleColumnDescriptors,
        flat: flat,
        nested: nested,
        useVirtual: useVirtual,
        // sticky left 各个column具体距离纪录
        stickyLeftMap: stickyLeftMap,
        stickyRightMap: stickyRightMap,
        leftLockTotalWidth: leftLockTotalWidth,
        rightLockTotalWidth: rightLockTotalWidth,
        hasLockColumn: nested.left.length > 0 || nested.right.length > 0,
    };
}
