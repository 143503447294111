export default [
    {
        label: 'FY16',
        value: 'FY16',
    },
    {
        label: 'FY17',
        value: 'FY17',
    },
    {
        label: 'FY18',
        value: 'FY18',
    },
    {
        label: 'FY19',
        value: 'FY19',
    },
    {
        label: 'FY20',
        value: 'FY20',
    },
    {
        label: 'FY21',
        value: 'FY21',
    },
    {
        label: 'FY22',
        value: 'FY22',
    },
    {
        label: 'FY23',
        value: 'FY23',
    },
    {
        label: 'FY24',
        value: 'FY24',
    },
    {
        label: 'FY25',
        value: 'FY25',
    },
    {
        label: 'FY26',
        value: 'FY26',
    },
    {
        label: 'FY27',
        value: 'FY27',
    },
    {
        label: 'FY28',
        value: 'FY28',
    },
    {
        label: 'FY29',
        value: 'FY29',
    },
];
