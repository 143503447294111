/**
 * @description 嵌套的数组对象查找
 * @param o 查找对象
 * @param k key值
 * @param v value值
 * @returns 找到的对象值
 */
export var findDeep = function (o, k, v) {
    for (var i in o) {
        if (Object.prototype.hasOwnProperty.call(o, i)) {
            if (typeof o[i] === 'object') {
                var f = findDeep(o[i], k, v);
                if (f) {
                    return f;
                }
            }
            if (k === i && o[i] === v) {
                return o;
            }
        }
    }
};
