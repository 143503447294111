import { __assign } from "tslib";
import dayjs from 'dayjs';
import moment from 'moment';
import isFunction from 'lodash/isFunction';
import { handlePropRules } from './format';
import { formatValue } from './helper';
var formatConfig = {
    moment: {
        componentNames: [
            'DatePicker',
            'RangePicker',
            'WeekPicker',
            'MonthPicker',
            'YearPicker',
            'TimePicker',
        ],
        getValueFormatter: function (val) {
            return formatValue(val, function (v) {
                return (moment.isMoment(v) ? v.valueOf() : v);
            });
        },
        setValueFormatter: function (val) {
            return formatValue(val, function (v) {
                return (typeof v === 'number' ? moment(v) : v);
            });
        },
    },
    dayjs: {
        componentNames: [
            'DatePicker2',
            'RangePicker2',
            'WeekPicker2',
            'MonthPicker2',
            'QuarterPicker2',
            'YearPicker2',
            'TimePicker2',
        ],
        getValueFormatter: function (val) {
            return formatValue(val, function (v) {
                return (dayjs.isDayjs(v) ? v.valueOf() : v);
            });
        },
        setValueFormatter: function (val) {
            return formatValue(val, function (v) {
                return (typeof v === 'number' ? dayjs(v) : v);
            });
        },
    },
};
export var getValueFormatterInjectProps = function (componentName) {
    var typeName = Object.keys(formatConfig).find(function (key) {
        var _a;
        return (_a = formatConfig[key]) === null || _a === void 0 ? void 0 : _a.componentNames.includes(componentName);
    });
    return typeName
        ? {
            _formattertypename: typeName,
        }
        : null;
};
export var injectFieldInit = function (field, props) {
    if (!field)
        return;
    if (field.init._inject)
        return;
    var fieldInit = field.init;
    field.init = (function (name, fieldOption, rprops) {
        var _a, _b;
        var option = fieldOption;
        var typeName = (_a = fieldOption === null || fieldOption === void 0 ? void 0 : fieldOption.props) === null || _a === void 0 ? void 0 : _a._formattertypename;
        var rules = (_b = fieldOption === null || fieldOption === void 0 ? void 0 : fieldOption.props) === null || _b === void 0 ? void 0 : _b.rules;
        if (typeName) {
            var _c = formatConfig[typeName], getValueFormatter = _c.getValueFormatter, setValueFormatter = _c.setValueFormatter;
            option = __assign({ getValueFormatter: getValueFormatter, setValueFormatter: setValueFormatter }, fieldOption);
        }
        if (rules) {
            option = __assign(__assign({}, fieldOption), { rules: (Array.isArray(fieldOption.rules) ? fieldOption.rules : [fieldOption.rules]).concat(handlePropRules(rules)) });
        }
        if (isFunction(props === null || props === void 0 ? void 0 : props.coverItemId)) {
            option = __assign(__assign({}, option), { id: props.coverItemId(name) });
        }
        return fieldInit.apply(field, [name, option, rprops]);
    });
    field.init._inject = true;
};
