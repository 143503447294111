import { __awaiter, __generator } from "tslib";
import $i18n from 'panda-i18n';
import { getRequestService } from '@cainiaofe/cn-ui-common';
export var preview = function (props) { return __awaiter(void 0, void 0, void 0, function () {
    var key, requestConfig, previewService, previewData, dom, instance;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                key = props.key, requestConfig = props.requestConfig;
                previewService = getRequestService(requestConfig);
                // 防止初始化错误
                // @ts-ignore 通过 js script 加载，暂无类型
                if (!window.aliyun) {
                    throw new Error($i18n.get({ id: 'FailedToLoad', dm: '加载失败', ns: 'CnOssUpload' }));
                }
                return [4 /*yield*/, previewService({ key: key })];
            case 1:
                previewData = _a.sent();
                dom = document.createElement('span');
                dom.style.width = '1px';
                dom.style.height = '1px';
                dom.style.opacity = '0';
                document.body.appendChild(dom);
                instance = window.aliyun.config({
                    mount: dom,
                    mode: 'simple',
                    url: previewData.webofficeURL,
                });
                // 设置 token
                instance.setToken({
                    token: previewData.accessToken,
                    timeout: 30 * 60 * 1000,
                });
                return [4 /*yield*/, instance.ready()];
            case 2:
                _a.sent();
                dom.remove();
                window.open(previewData.webofficeURL);
                return [2 /*return*/];
        }
    });
}); };
