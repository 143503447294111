import $i18n from 'panda-i18n';
import { isArr, isEmpty, isValid } from '@formily/shared';
import locale from '@formily/validator/lib/locale';
import { getValidateLanguage, getValidateLocaleIOSCode } from '@formily/validator';
import isEqual from 'lodash/isEqual';
var isValidateEmpty = function (value) {
    var _a;
    if (isArr(value)) {
        for (var i = 0; i < value.length; i++) {
            if (isValid(value[i]))
                return false;
        }
        return true;
    }
    else {
        // compat to draft-js
        if (value === null || value === void 0 ? void 0 : value.getCurrentContent) {
            /* istanbul ignore next */
            return !((_a = value.getCurrentContent()) === null || _a === void 0 ? void 0 : _a.hasText());
        }
        return isEmpty(value);
    }
};
export default (function (value, rule) {
    if (rule.required === false)
        return '';
    var lang = getValidateLocaleIOSCode(getValidateLanguage());
    var defaultRequiredMessage = locale[lang] ? locale[lang].required : locale['en'].required;
    var isMessageDefault = isEqual(rule.message, defaultRequiredMessage);
    return isValidateEmpty(value)
        ? (isMessageDefault ? $i18n.get({
            id: 'ThisFieldIsRequired',
            dm: '该字段是必填字段',
            ns: 'CnForm',
        }) : rule.message)
        : '';
});
