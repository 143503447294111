import { connect, mapProps } from '@formily/react';
import { CnComplexRadio as UiCnComplexRadio, CnComplexRadioGroup as UiCnComplexRadioGroup, } from '@/components/cn-complex-radio';
import { mapSize } from '@/form/__builtins__';
export var CnComplexRadio = connect(UiCnComplexRadio, mapProps({
    value: 'checked',
    onInput: 'onChange',
}, mapSize));
export var CnComplexRadioGroup = connect(UiCnComplexRadioGroup, mapProps({
    dataSource: true,
}, mapSize));
