/**
 * 用于校验文件是否上传完成
 * 当未完成时拦截提交并抛出异常
 */
export var checkUploadIsComplete = function (value, rule) {
    if (!rule.checkUploadIsComplete)
        return true;
    if (!(value === null || value === void 0 ? void 0 : value.length))
        return true;
    /**
     * 1. 先过滤状态为空的逻辑
     * 2. 再判断状态是否存在非 done 的情况，非 done 代表未上传完成
     */
    var notCompleteList = value.filter(function (item) { return item.status && item.status !== 'done'; });
    if (notCompleteList.length) {
        var fileNameList = notCompleteList
            .map(function (item) { return item.name; })
            .filter(Boolean)
            .join(',');
        return "\u6587\u4EF6".concat(fileNameList, "\u8FD8\u672A\u4E0A\u4F20\u5B8C\u6210\uFF0C\u8BF7\u7B49\u5F85\u6587\u4EF6\u4E0A\u4F20\u5B8C\u6210\u540E\u518D\u63D0\u4EA4");
    }
    return true;
};
