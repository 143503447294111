import { observable } from '@formily/reactive';
export var slot = {
    widgetSlot: observable.shallow({}),
    searchSlot: observable.shallow({}),
};
export var registerWidgetSlot = function (name, comp) {
    slot.widgetSlot[name] = comp;
};
export var registerSearchSlot = function (name, comp) {
    slot.searchSlot[name] = comp;
};
