import { __assign } from "tslib";
import * as React from 'react';
import { Grid as NextGrid } from '@fusion/lib';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
export var Row = function (props) {
    return withNativeProps(props, React.createElement(NextGrid.Row, __assign({ "data-name": "Row" }, props)));
};
export var Col = function (props) {
    return withNativeProps(props, React.createElement(NextGrid.Col, __assign({ "data-name": "Col" }, props)));
};
Col.isNextCol = true;
export var Grid = {
    Row: Row,
    Col: Col,
};
export var CnGrid = {
    CnRow: Row,
    CnCol: Col,
};
