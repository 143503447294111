import { useCallback } from 'react';
import { useRemoveFile } from './use-remove-file';
export var useOnError = function (props) {
    var setValue = props.setValue, onChangeRef = props.onChangeRef, onErrorRef = props.onErrorRef;
    var removeFile = useRemoveFile({ setValue: setValue, onChangeRef: onChangeRef });
    return useCallback(function (error, response, file) {
        removeFile(file);
        if (onErrorRef.current) {
            onErrorRef.current(error, response, file);
        }
    }, [onErrorRef, removeFile]);
};
