import { __assign, __rest } from "tslib";
import * as React from 'react';
import { observer, RecursionField, useField, useFieldSchema, } from '@formily/react';
import { CnTab, CnTabItem, } from '@/components/cn-tab';
import { CnBadge } from '@/components/cn-badge';
import { CnCard } from '@/components/cn-card';
import { model, markRaw } from '@formily/reactive';
import { isValid } from '@formily/shared';
import './index.scss';
var createCnFormTab = function (defaultActiveKey) {
    var formTab = model({
        activeKey: defaultActiveKey,
        setActiveKey: function (key) {
            formTab.activeKey = key;
        },
    });
    return markRaw(formTab);
};
var useTabs = function () {
    var tabs = [];
    var fieldSchema = useFieldSchema();
    var tabsField = useField();
    fieldSchema.mapProperties(function (schema, name) {
        var _a, _b;
        var field = tabsField.query(tabsField.address.concat(name)).take();
        if ((field === null || field === void 0 ? void 0 : field.display) === 'none' || (field === null || field === void 0 ? void 0 : field.display) === 'hidden') {
            return;
        }
        if ((_a = schema['x-component']) === null || _a === void 0 ? void 0 : _a.includes('CnFormTabItem')) {
            tabs.push({
                name: name,
                schema: schema,
                props: __assign({ key: ((_b = schema === null || schema === void 0 ? void 0 : schema['x-component-props']) === null || _b === void 0 ? void 0 : _b.key) || name }, schema === null || schema === void 0 ? void 0 : schema['x-component-props']),
            });
        }
    });
    return tabs;
};
export var CnFormTab = observer(function (props) {
    var tabField = useField();
    var formTab = props.formTab, otherProps = __rest(props, ["formTab"]);
    var _formTab = React.useMemo(function () {
        return formTab || createCnFormTab();
    }, []);
    var tabs = useTabs();
    var activeKey = props.activeKey || _formTab.activeKey;
    var badgedTab = function (key, props) {
        var errors = tabField.form.queryFeedbacks({
            type: 'error',
            address: "".concat(tabField.address.concat(key), ".*"),
        });
        if (errors.length) {
            return (React.createElement(CnBadge, { count: errors.length, className: "cn-ui-form-tab-badge" }, props.title));
        }
        return props.title;
    };
    return (React.createElement(CnTab, __assign({}, otherProps, (isValid(activeKey) && { activeKey: activeKey }), { onChange: function (k) {
            var _a, _b;
            (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, k);
            (_b = formTab === null || formTab === void 0 ? void 0 : formTab.setActiveKey) === null || _b === void 0 ? void 0 : _b.call(formTab, k);
        }, lazyLoad: false }), tabs.map(function (_a, key) {
        var schema = _a.schema, name = _a.name, props = _a.props;
        return (React.createElement(CnTabItem, __assign({ key: key }, props, { title: badgedTab(name, props) }),
            React.createElement(CnCard, null,
                React.createElement(RecursionField, { name: name, schema: schema }))));
    })));
});
CnFormTab.createCnFormTab = createCnFormTab;
export var CnFormTabItem = function (_a) {
    var children = _a.children;
    return React.createElement(React.Fragment, null, children);
};
