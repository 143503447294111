import { __assign } from "tslib";
import './cn-card.scss';
import { useI18n } from 'panda-i18n';
import React from 'react';
import { CnTag } from '@/components/cn-tag';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import { useControllableValue } from 'ahooks';
import { CnIcon } from '@/components/cn-icon';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnCardSubCard } from './sub-card';
import { CnLoading } from '@/components/cn-loading';
import { useSizeChange } from '@/components/cn-utils';
export function CnCard(props) {
    var className = props.className, icon = props.icon, title = props.title, titleTips = props.titleTips, titleTag = props.titleTag, children = props.children, desc = props.desc, action = props.action, foldable = props.foldable, style = props.style, loading = props.loading;
    var $i18n = useI18n();
    var _a = useControllableValue(props, {
        valuePropName: 'fold',
        defaultValuePropName: 'initFold',
        trigger: 'onFoldChange',
    }), fold = _a[0], setFold = _a[1];
    var size = useSizeChange(props).size;
    return (React.createElement("div", { "data-name": "CnCard", className: classNames([
            className,
            'cn-ui-card',
            "cn-ui-card-".concat(size),
            fold ? 'cn-ui-card-fold' : 'cn-ui-card-unfold',
            title ? 'has-title' : 'no-title',
        ]), style: style },
        title && (React.createElement("div", { className: "cn-ui-card-header" },
            React.createElement("div", { className: "cn-ui-card-header-icon-wrapper" },
                React.createElement(CnIcon, { className: "cn-ui-card-header-icon", type: icon || 'service-center' })),
            React.createElement("div", { className: "cn-ui-card-header-title-container" },
                React.createElement("div", { className: "cn-ui-card-header-title" }, title),
                titleTips && (React.createElement("div", { className: "cn-ui-card-header-tips" },
                    React.createElement(CnTooltip, { trigger: React.createElement(CnIcon, { className: "cn-ui-card-tips-icon", type: "help-color" }), triggerType: "hover", align: "t" }, titleTips))),
                titleTag && (React.createElement(CnTag, __assign({ className: "cn-ui-card-header-tag" }, titleTag)))),
            React.createElement("div", { className: "cn-ui-card-header-action" },
                action,
                foldable && (React.createElement("div", { className: "cn-ui-card-header-fold", onClick: function () { return setFold(!fold); } },
                    fold
                        ? $i18n.get({ id: 'Expand', dm: '展开' })
                        : $i18n.get({ id: 'PutItAway', dm: '收起' }),
                    React.createElement(CnIcon, { type: !fold ? 'triangle-up-fill' : 'triangle-down-fill' })))))),
        React.createElement("div", { className: "cn-ui-card-content", style: { display: fold ? 'none' : 'block' } },
            desc && React.createElement("div", { className: "cn-ui-card-desc" }, desc),
            children && (React.createElement("div", { className: "cn-ui-card-container" }, isNil(loading) ? (children) : (React.createElement(CnLoading, { visible: loading, style: { display: 'block' }, size: "medium" }, children)))))));
}
CnCard.displayName = 'CnCard';
CnCard.defaultProps = {
    foldable: false,
    initFold: false,
};
// header, subtitle组件暴露出来供单独使用
CnCard.SubTitle = CnCardSubCard;
