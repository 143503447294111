import { __assign } from "tslib";
import { useState } from 'react';
import isFunction from 'lodash/isFunction';
import { useControllableValue } from '../../../view/hooks';
import SortHeaderCell from './sort-header-cell';
import sortOp, { IColumnSortPipelineOptions } from './sort';
export var useSort = function (sortCustom) {
    var _a = useControllableValue(sortCustom, {
        valuePropName: 'sorts',
        // triggerName: 'onChangeSorts',
    }), sorts = _a[0], setSorts = _a[1];
    var _b = useState(false), keepDataSource = _b[0], setKeepDataSource = _b[1];
    return {
        sorts: sorts,
        keepDataSource: keepDataSource,
        setSorts: setSorts,
        setKeepDataSource: setKeepDataSource,
    };
};
var defaultOrders = ['asc', 'desc', 'none'];
export function columnsSortPipeline(onSort, 
// @ts-ignore
sortStatus, sortCustom) {
    var options = __assign({ SortHeaderCell: SortHeaderCell }, (sortCustom || {}));
    if (isFunction(onSort)) {
        options = __assign(__assign({ mode: 'single', orders: defaultOrders, highlightColumnWhenActive: false, keepDataSource: sortStatus.keepDataSource, SortHeaderCell: SortHeaderCell }, (sortCustom || {})), { 
            // @ts-ignore
            sorts: sortStatus.sorts, onChangeSorts: function (_sorts) {
                var _a;
                var sort = _sorts[0];
                var ret = (_a = onSort(sort === null || sort === void 0 ? void 0 : sort.code, sort === null || sort === void 0 ? void 0 : sort.order, sort)) !== null && _a !== void 0 ? _a : true;
                sortStatus.setKeepDataSource(!ret);
                sortStatus.setSorts(_sorts);
            } });
    }
    // @ts-ignore
    return sortOp(options);
}
export { IColumnSortPipelineOptions };
