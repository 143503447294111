import { __assign } from "tslib";
import React, { useContext } from 'react';
import classNames from 'classnames';
import { Header } from './header';
import { SideBar } from './side-bar';
import Content from './content';
import { ShellStateCtx } from './context';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
export var CnShellContainer = function (props) {
    var _a;
    var className = props.className, children = props.children;
    var _b = useContext(ShellStateCtx), headerVisible = _b.headerVisible, headerProps = _b.headerProps, sideBarVisible = _b.sideBarVisible, theme = _b.theme;
    return withNativeProps(props, React.createElement("div", { "data-name": "CnShell", className: classNames('cn-ui-shell', (_a = {},
            _a["cn-ui-shell-".concat(theme)] = theme,
            _a['hide-sidebar'] = !sideBarVisible,
            _a['hide-top-bar'] = !headerVisible,
            _a[className] = className,
            _a)), "data-testid": "CnShell" },
        headerVisible ? React.createElement(Header, __assign({}, headerProps)) : null,
        React.createElement("div", { className: "cn-ui-shell-body" },
            sideBarVisible ? React.createElement(SideBar, null) : null,
            React.createElement(Content, null, children))));
};
