import { __assign } from "tslib";
import { isLeafNode } from './node';
// 获取一棵树的高度/深度 (0-based)
export function getTreeDepth(nodes) {
    var maxDepth = -1;
    dfs(nodes, 0);
    return maxDepth;
    // 深度优先搜索
    function dfs(columns, depth) {
        for (var _i = 0, columns_1 = columns; _i < columns_1.length; _i++) {
            var column = columns_1[_i];
            if (isLeafNode(column)) {
                maxDepth = Math.max(maxDepth, depth);
            }
            else {
                dfs(column.children, depth + 1);
            }
        }
    }
}
// 创建递归映射器
export function makeRecursiveMapper(fn) {
    return function (tree, level) {
        if (level === void 0) { level = 0; }
        return dfs(tree, level, []).result;
        // 深度优先搜索
        function dfs(nodes, parentStartIndex, path) {
            var flatCount = 0;
            var result = [];
            for (var _i = 0, nodes_1 = nodes; _i < nodes_1.length; _i++) {
                var node = nodes_1[_i];
                path.push(node);
                var startIndex = parentStartIndex + flatCount;
                var subResult = void 0;
                if (isLeafNode(node)) {
                    subResult = fn(node, {
                        startIndex: startIndex,
                        endIndex: startIndex + 1,
                        path: path.slice(),
                        isLeaf: true,
                    });
                    flatCount += 1;
                }
                else {
                    var dfsResult = dfs(node.children, startIndex, path);
                    subResult = fn(__assign(__assign({}, node), { children: dfsResult.result }), {
                        startIndex: startIndex,
                        endIndex: startIndex + dfsResult.flatCount,
                        path: path.slice(),
                        isLeaf: false,
                    });
                    flatCount += dfsResult.flatCount;
                }
                if (Array.isArray(subResult)) {
                    result.push.apply(result, subResult);
                }
                else if (subResult != null) {
                    result.push(subResult);
                }
                path.pop();
            }
            return { result: result, flatCount: flatCount };
        }
    };
}
