import { __assign, __rest } from "tslib";
import React, { useRef } from 'react';
import { useSyncRef } from './hooks';
import useScrollbar from './use-scroll-bar';
import './scroll-bar.scss';
export default function ScrollBar(_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, onScroll = _a.onScroll, onMouseEnter = _a.onMouseEnter, onMouseLeave = _a.onMouseLeave, innerRef = _a.innerRef, children = _a.children, suppressScrollX = _a.suppressScrollX, suppressScrollY = _a.suppressScrollY, suppressAutoHide = _a.suppressAutoHide, _c = _a.skin, skin = _c === void 0 ? 'white' : _c, trackGap = _a.trackGap, trackStyle = _a.trackStyle, thumbStyle = _a.thumbStyle, minThumbSize = _a.minThumbSize, Wrapper = _a.Wrapper, props = __rest(_a, ["className", "onScroll", "onMouseEnter", "onMouseLeave", "innerRef", "children", "suppressScrollX", "suppressScrollY", "suppressAutoHide", "skin", "trackGap", "trackStyle", "thumbStyle", "minThumbSize", "Wrapper"]);
    var scrollBoxRef = useRef(null);
    useSyncRef(innerRef, scrollBoxRef);
    var _d = useScrollbar(scrollBoxRef, {
        trackGap: trackGap,
        trackStyle: trackStyle,
        thumbStyle: thumbStyle,
        minThumbSize: minThumbSize,
        suppressAutoHide: suppressAutoHide,
    }), horizontalBar = _d[0], verticalBar = _d[1], updateLayerNow = _d[2], updateLayerThrottle = _d[3], hideScrollbar = _d[4];
    function handleScroll(evt) {
        if (onScroll) {
            onScroll(evt);
        }
        updateLayerThrottle();
    }
    function handleMouseEnter(evt) {
        if (onMouseEnter) {
            onMouseEnter(evt);
        }
        updateLayerNow();
    }
    function handleMouseLeave(evt) {
        if (onMouseLeave) {
            onMouseLeave(evt);
        }
        hideScrollbar();
    }
    return (React.createElement(Wrapper, __assign({ className: "ms-container".concat(className && " ".concat(className)), ref: scrollBoxRef, onScroll: handleScroll, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave }, props),
        React.createElement("div", { className: "ms-track-box ms-theme-".concat(skin) },
            !suppressScrollX && horizontalBar,
            !suppressScrollY && verticalBar),
        children));
}
