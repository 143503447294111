import BigNumber from 'bignumber.js';
/**
 * 智能比较函数
 * @param x 比较的第一个值
 * @param y 比较的第二个值
 * @returns 如果 x < y，返回 -1；如果 x > y，返回 1；如果 x === y，返回 0
 */
export function smartCompare(x, y) {
    // 将 null 排在最后面
    if (x === undefined && y === undefined) {
        return 0;
    }
    if (x === null && y === null) {
        return 0;
    }
    if (x === null && y === undefined) {
        return 0;
    }
    if (x === undefined && y === null) {
        return 0;
    }
    if (x === undefined || x == null) {
        return 1;
    }
    if (y === undefined || y == null) {
        return -1;
    }
    // 如果都是数据，则返回 x - y
    if (typeof x === 'number' && typeof y === 'number') {
        var result = new BigNumber(x).minus(y).toNumber();
        if (result > 0) {
            return 1;
        }
        else if (result < 0) {
            return -1;
        }
        else {
            return 0;
        }
    }
    // 字符串使用 默认的字典序
    if (typeof x === 'string' && typeof y === 'string') {
        if (x < y) {
            return -1;
        }
        else if (x > y) {
            return 1;
        }
        else {
            return 0;
        }
    }
    if (Array.isArray(x) && Array.isArray(y)) {
        var len = Math.min(x.length, y.length);
        for (var i = 0; i < len; i++) {
            var cmp = smartCompare(x[i], y[i]);
            if (cmp !== 0) {
                return cmp;
            }
        }
        // 数组长度不等时，元素少的字段放在前面
        return x.length - y.length;
    }
    // 对于不认识的数据类型，返回 0
    return 0;
}
