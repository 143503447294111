import { __assign, __rest, __spreadArray } from "tslib";
import React, { useEffect, forwardRef } from 'react';
import classnames from 'classnames';
import { List as NextList } from '@/components/fusion';
import { useControllableValue } from 'ahooks';
import { useNextLocale } from '../cn-utils';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
export var CnList = forwardRef(function (props, ref) {
    var activeKey = props.activeKey, defaultActiveKey = props.defaultActiveKey, selectMode = props.selectMode, onChange = props.onChange, propsRenderItem = props.renderItem, shape = props.shape, className = props.className, size = props.size, dataSource = props.dataSource, otherProps = __rest(props, ["activeKey", "defaultActiveKey", "selectMode", "onChange", "renderItem", "shape", "className", "size", "dataSource"]);
    var listLocale = useNextLocale('List');
    var _a = useControllableValue(props, {
        defaultValue: defaultActiveKey,
        defaultValuePropName: 'defaultActiveKey',
        valuePropName: 'activeKey',
        trigger: 'onChange',
    }), selectedKeysState = _a[0], setSelectedKeysState = _a[1];
    useEffect(function () {
        if (defaultActiveKey && dataSource) {
            setSelectedKeysState(defaultActiveKey, dataSource.find(function (item) { return item.key === defaultActiveKey; }));
        }
    }, [defaultActiveKey, dataSource]);
    var cacheSelectedRecord = React.useRef(function () {
        return selectMode === 'single' ? {} : [];
    });
    var cacheSelectMode = React.useRef(selectMode);
    var checkSelected = function (current) {
        if (!(current === null || current === void 0 ? void 0 : current.key)) {
            return false;
        }
        if (selectMode === 'single') {
            return selectedKeysState === (current === null || current === void 0 ? void 0 : current.key);
        }
        else if (selectMode === 'multiple') {
            return selectedKeysState === null || selectedKeysState === void 0 ? void 0 : selectedKeysState.includes(current === null || current === void 0 ? void 0 : current.key);
        }
    };
    useEffect(function () {
        if (selectMode === 'multiple') {
            cacheSelectedRecord.current = [];
        }
        else {
            cacheSelectedRecord.current = null;
        }
    }, [selectMode]);
    var renderItem = function (current, index) {
        if (propsRenderItem && selectMode) {
            var item_1 = propsRenderItem(current, index);
            return React.cloneElement(item_1, {
                className: classnames(item_1.props.className, {
                    'cn-list-item-card': shape === 'card',
                    'cn-list-item-selected': checkSelected(current),
                }),
                onClick: function (event) {
                    var _a, _b;
                    if (selectMode === 'single') {
                        cacheSelectedRecord.current = current;
                        setSelectedKeysState(current === null || current === void 0 ? void 0 : current.key, current);
                    }
                    else if (selectMode === 'multiple') {
                        if (Array.isArray(selectedKeysState) &&
                            (selectedKeysState === null || selectedKeysState === void 0 ? void 0 : selectedKeysState.includes(current === null || current === void 0 ? void 0 : current.key))) {
                            cacheSelectedRecord.current = cacheSelectedRecord.current.filter(function (item) { return (item === null || item === void 0 ? void 0 : item.key) !== (current === null || current === void 0 ? void 0 : current.key); });
                            setSelectedKeysState(selectedKeysState.filter(function (item) { return item !== (current === null || current === void 0 ? void 0 : current.key); }), cacheSelectedRecord.current);
                        }
                        else {
                            if (selectedKeysState === undefined) {
                                cacheSelectedRecord.current = [current];
                                setSelectedKeysState([current === null || current === void 0 ? void 0 : current.key], cacheSelectedRecord.current);
                            }
                            else {
                                var currentSelectedKeysState = selectedKeysState;
                                if (cacheSelectMode.current !== selectMode) {
                                    currentSelectedKeysState = [];
                                    cacheSelectMode.current = selectMode;
                                }
                                cacheSelectedRecord.current.push(current);
                                setSelectedKeysState(__spreadArray(__spreadArray([], currentSelectedKeysState, true), [current === null || current === void 0 ? void 0 : current.key], false), cacheSelectedRecord.current);
                            }
                        }
                    }
                    (_b = (_a = item_1.props) === null || _a === void 0 ? void 0 : _a.onClick) === null || _b === void 0 ? void 0 : _b.call(_a, event);
                },
            });
        }
        return propsRenderItem ? propsRenderItem(current, index) : null;
    };
    return withNativeProps(props, React.createElement(NextList, __assign({ ref: ref, className: classnames(className, "cn-list-".concat(size)), renderItem: renderItem, size: size, dataSource: dataSource }, otherProps, { locale: listLocale, "data-testid": "cn-list" })));
});
CnList.defaultProps = {
    shape: 'default',
};
