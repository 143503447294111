import { __assign, __spreadArray } from "tslib";
import { useEffect, useMemo, useState } from 'react';
import { events } from '@/components/cn-utils';
import { getBreadcrumbDataSource } from '@/components/cn-shell/utils/breadcrumb-data-source';
/**
 * 计算面包屑数据源
 */
export var useBreadcrumbDataSource = function (props) {
    var cnBreadcrumbProps = props.cnBreadcrumbProps, cnBreadcrumbDataSource = props.cnBreadcrumbDataSource;
    var _a = cnBreadcrumbProps.useStandard, useStandard = _a === void 0 ? false : _a, dataSourceProps = cnBreadcrumbProps.dataSource;
    var _b = useState(getBreadcrumbDataSource()), dataSource = _b[0], setDataSource = _b[1];
    useEffect(function () {
        if (useStandard) {
            events.on('CnShell.menuSelected', function (data) {
                setDataSource(data);
            });
            // 因为 shell 发送 CnShell.menuSelected，在组件监听之后，增加 ready 事件
            events.emit('CnBreadcrumb.ready', true);
        }
        // Unsubscribe from events when component unmounts
        return function () {
            events.off('CnShell.menuSelected');
            events.off('CnBreadcrumb.ready');
        };
    }, [useStandard]);
    var tempResult = useMemo(function () {
        if (useStandard && (dataSource === null || dataSource === void 0 ? void 0 : dataSource.length)) {
            return dataSource.filter(function (item) { return Boolean(item.link); });
        }
        else if (dataSourceProps === null || dataSourceProps === void 0 ? void 0 : dataSourceProps.length) {
            return dataSourceProps;
        }
        else if (cnBreadcrumbDataSource === null || cnBreadcrumbDataSource === void 0 ? void 0 : cnBreadcrumbDataSource.length) {
            return cnBreadcrumbDataSource;
        }
        return [];
    }, [cnBreadcrumbDataSource, dataSource, dataSourceProps, useStandard]);
    return useMemo(function () {
        var temp = __spreadArray([], tempResult, true);
        if (temp.length) {
            temp[temp.length - 1] = __assign(__assign({}, temp[temp.length - 1]), { link: undefined });
        }
        return temp;
    }, [tempResult]);
};
