import { withI18n } from 'panda-i18n';
import locale from '@/locales';
import CnDynamicFormView from './view';
import { componentName } from './const';
var CnDynamicForm = withI18n(CnDynamicFormView, {
    componentName: componentName,
    locale: locale,
});
CnDynamicForm.displayName = componentName;
export { CnDynamicForm };
