import { __assign } from "tslib";
/* 默认的卡号格式正则 */
export var DEFAULT_CARD_FORMAT = /(\d{1,4})/g;
/** 内置的卡片配置，18 + 1 = 19种 */
export var CARD_MAP = {
    psbc: {
        bankName: '中国邮政储蓄银行',
        bankCode: 'PSBC',
        patterns: [
            {
                reg: /^(621096|621098|622150|622151|622181|622188|622199|955100|621095|620062|621285|621798|621799|621797|620529|621622|621599|621674|623218|623219)/,
                cardType: 'DC',
                length: 19,
            },
            {
                reg: /^(62215049|62215050|62215051|62218850|62218851|62218849)/,
                cardType: 'DC',
                length: 19,
            },
            {
                reg: /^(622812|622810|622811|628310|625919)/,
                cardType: 'CC',
                length: 16,
            },
        ],
    },
    spdb: {
        bankName: '浦东发展银行',
        bankCode: 'SPDB',
        patterns: [
            {
                reg: /^(622516|622517|622518|622521|622522|622523|984301|984303|621352|621793|621795|621796|621351|621390|621792|621791)/,
                cardType: 'DC',
                length: 16,
            },
            {
                reg: /^(84301|84336|84373|84385|84390|87000|87010|87030|87040|84380|84361|87050|84342)/,
                cardType: 'DC',
                length: 16,
            },
            {
                reg: /^(356851|356852|404738|404739|456418|498451|515672|356850|517650|525998|622177|622277|628222|622500|628221|622176|622276|622228|625957|625958|625993|625831)/,
                cardType: 'CC',
                length: 16,
            },
            {
                reg: /^(622520|622519)/,
                cardType: 'SCC',
                length: 16,
            },
            {
                reg: /^(620530)/,
                cardType: 'PC',
                length: 19,
            },
        ],
    },
    comm: {
        bankName: '中国交通银行',
        bankCode: 'COMM',
        patterns: [
            {
                reg: /^(622261|622260|622262|621002|621069|621436|621335)/,
                cardType: 'DC',
                length: 19,
            },
            {
                reg: /^(620013)/,
                cardType: 'DC',
                length: 16,
            },
            {
                reg: /^(405512|601428|405512|601428|622258|622259|405512|601428)/,
                cardType: 'DC',
                length: 17,
            },
            {
                reg: /^(49104|53783)/,
                cardType: 'CC',
                length: 16,
            },
            {
                reg: /^(434910|458123|458124|520169|522964|552853|622250|622251|521899|622253|622656|628216|622252|955590|955591|955592|955593|628218|625028|625029)/,
                cardType: 'CC',
                length: 16,
            },
            {
                reg: /^(622254|622255|622256|622257|622284)/,
                cardType: 'SCC',
                length: 16,
            },
            {
                reg: /^(620021|620521)/,
                cardType: 'PC',
                length: 19,
            },
        ],
    },
    shbank: {
        bankName: '上海银行',
        bankCode: 'SHBANK',
        patterns: [
            {
                reg: /^(621050|622172|622985|622987|620522|622267|622278|622279|622468|622892|940021)/,
                cardType: 'DC',
            },
            {
                reg: /^(438600)/,
                cardType: 'DC',
            },
            {
                reg: /^(356827|356828|356830|402673|402674|486466|519498|520131|524031|548838|622148|622149|622268|356829|622300|628230|622269|625099|625953)/,
                cardType: 'CC',
            },
        ],
    },
    icbc: {
        bankName: '中国工商银行',
        bankCode: 'ICBC',
        patterns: [
            {
                reg: /^(620200|620302|620402|620403|620404|620406|620407|620409|620410|620411|620412|620502|620503|620405|620408|620512|620602|620604|620607|620611|620612|620704|620706|620707|620708|620709|620710|620609|620712|620713|620714|620802|620711|620904|620905|621001|620902|621103|621105|621106|621107|621102|621203|621204|621205|621206|621207|621208|621209|621210|621302|621303|621202|621305|621306|621307|621309|621311|621313|621211|621315|621304|621402|621404|621405|621406|621407|621408|621409|621410|621502|621317|621511|621602|621603|621604|621605|621608|621609|621610|621611|621612|621613|621614|621615|621616|621617|621607|621606|621804|621807|621813|621814|621817|621901|621904|621905|621906|621907|621908|621909|621910|621911|621912|621913|621915|622002|621903|622004|622005|622006|622007|622008|622010|622011|622012|621914|622015|622016|622003|622018|622019|622020|622102|622103|622104|622105|622013|622111|622114|622017|622110|622303|622304|622305|622306|622307|622308|622309|622314|622315|622317|622302|622402|622403|622404|622313|622504|622505|622509|622513|622517|622502|622604|622605|622606|622510|622703|622715|622806|622902|622903|622706|623002|623006|623008|623011|623012|622904|623015|623100|623202|623301|623400|623500|623602|623803|623901|623014|624100|624200|624301|624402|623700|624000)/,
                cardType: 'DC',
            },
            {
                reg: /^(622200|622202|622203|622208|621225|620058|621281|900000|621558|621559|621722|621723|620086|621226|621618|620516|621227|621288|621721|900010|623062|621670|621720|621379|621240|621724|621762|621414|621375|622926|622927|622928|622929|622930|622931|621733|621732|621372|621369|621763)/,
                cardType: 'DC',
            },
            {
                reg: /^(402791|427028|427038|548259|621376|621423|621428|621434|621761|621749|621300|621378|622944|622949|621371|621730|621734|621433|621370|621764|621464|621765|621750|621377|621367|621374|621731|621781)/,
                cardType: 'DC',
            },
            {
                reg: /^(9558)/,
                cardType: 'DC',
            },
            {
                reg: /^(370246|370248|370249|370247|370267|374738|374739)/,
                cardType: 'CC',
            },
            {
                reg: /^(427010|427018|427019|427020|427029|427030|427039|438125|438126|451804|451810|451811|458071|489734|489735|489736|510529|427062|524091|427064|530970|530990|558360|524047|525498|622230|622231|622232|622233|622234|622235|622237|622239|622240|622245|622238|451804|451810|451811|458071|628288|628286|622206|526836|513685|543098|458441|622246|544210|548943|356879|356880|356881|356882|528856|625330|625331|625332|622236|524374|550213|625929|625927|625939|625987|625930|625114|622159|625021|625022|625932|622889|625900|625915|625916|622171|625931|625113|625928|625914|625986|625925|625921|625926|625942|622158|625917|625922|625934|625933|625920|625924|625017|625018|625019)/,
                cardType: 'CC',
            },
            {
                reg: /^(45806|53098|45806|53098)/,
                cardType: 'CC',
            },
            {
                reg: /^(622210|622211|622212|622213|622214|622220|622223|622225|622229|622215|622224)/,
                cardType: 'SCC',
            },
            {
                reg: /^(620054|620142|620184|620030|620050|620143|620149|620124|620183|620094|620186|620148|620185)/,
                cardType: 'PC',
            },
            {
                reg: /^(620114|620187|620046)/,
                cardType: 'PC',
            },
        ],
    },
    boc: {
        bankName: '中国银行',
        bankCode: 'BOC',
        patterns: [
            {
                reg: /^(621660|621661|621662|621663|621665|621667|621668|621669|621666|456351|601382|621256|621212|621283|620061|621725|621330|621331|621332|621333|621297|621568|621569|621672|623208|621620|621756|621757|621758|621759|621785|621786|621787|621788|621789|621790|622273|622274|622771|622772|622770|621741|621041)/,
                cardType: 'DC',
            },
            {
                reg: /^(621293|621294|621342|621343|621364|621394|621648|621248|621215|621249|621231|621638|621334|621395|623040|622348)/,
                cardType: 'DC',
            },
            {
                reg: /^(625908|625910|625909|356833|356835|409665|409666|409668|409669|409670|409671|409672|512315|512316|512411|512412|514957|409667|438088|552742|553131|514958|622760|628388|518377|622788|628313|628312|622750|622751|625145|622479|622480|622789|625140|622346|622347)/,
                cardType: 'CC',
            },
            {
                reg: /^(518378|518379|518474|518475|518476|524865|525745|525746|547766|558868|622752|622753|622755|524864|622757|622758|622759|622761|622762|622763|622756|622754|622764|622765|558869|625905|625906|625907|625333)/,
                cardType: 'SCC',
            },
            {
                reg: /^(53591|49102|377677)/,
                cardType: 'SCC',
            },
            {
                reg: /^(620514|620025|620026|620210|620211|620019|620035|620202|620203|620048|620515|920000)/,
                cardType: 'PC',
            },
            {
                reg: /^(620040|620531|620513|921000|620038)/,
                cardType: 'PC',
            },
        ],
    },
    citic: {
        bankName: '中信银行',
        bankCode: 'CITIC',
        patterns: [
            {
                reg: /^(433670|433680|442729|442730|620082|622690|622691|622692|622696|622698|622998|622999|433671|968807|968808|968809|621771|621767|621768|621770|621772|621773|622453|622456)/,
                cardType: 'DC',
            },
            {
                reg: /^(622459)/,
                cardType: 'DC',
            },
            {
                reg: /^(376968|376969|376966)/,
                cardType: 'CC',
            },
            {
                reg: /^(400360|403391|403392|404158|404159|404171|404172|404173|404174|404157|433667|433668|433669|514906|403393|520108|433666|558916|622678|622679|622680|622688|622689|628206|556617|628209|518212|628208|356390|356391|356392|622916|622918|622919)/,
                cardType: 'CC',
            },
        ],
    },
    ccb: {
        bankName: '中国建设银行',
        bankCode: 'CCB',
        patterns: [
            {
                reg: /^(621284|436742|589970|620060|621081|621467|621598|621621|621700|622280|622700|623211|623668)/,
                cardType: 'DC',
            },
            {
                reg: /^(421349|434061|434062|524094|526410|552245|621080|621082|621466|621488|621499|622966|622988|622382|621487|621083|621084|620107)/,
                cardType: 'DC',
            },
            {
                reg: /^(436742193|622280193)/,
                cardType: 'DC',
            },
            {
                reg: /^(553242)/,
                cardType: 'CC',
            },
            {
                reg: /^(625362|625363|628316|628317|356896|356899|356895|436718|436738|436745|436748|489592|531693|532450|532458|544887|552801|557080|558895|559051|622166|622168|622708|625964|625965|625966|628266|628366|622381|622675|622676|622677)/,
                cardType: 'CC',
            },
            {
                reg: /^(5453242|5491031|5544033)/,
                cardType: 'CC',
            },
            {
                reg: /^(622725|622728|436728|453242|491031|544033|622707|625955|625956)/,
                cardType: 'SCC',
            },
            {
                reg: /^(53242|53243)/,
                cardType: 'SCC',
            },
        ],
    },
    spabank: {
        bankName: '深发/平安银行',
        bankCode: 'SPABANK',
        patterns: [
            {
                reg: /^(621626|623058)/,
                cardType: 'DC',
            },
            {
                reg: /^(602907|622986|622989|622298|627069|627068|627066|627067|412963|415752|415753|622535|622536|622538|622539|998800|412962|622983)/,
                cardType: 'DC',
            },
            {
                reg: /^(531659|622157|528020|622155|622156|526855|356869|356868|625360|625361|628296|435744|435745|483536|622525|622526|998801|998802)/,
                cardType: 'CC',
            },
            {
                reg: /^(620010)/,
                cardType: 'PC',
            },
        ],
    },
    cmbc: {
        bankName: '中国民生银行',
        bankCode: 'CMBC',
        patterns: [
            {
                reg: /^(622615|622616|622618|622622|622617|622619|415599|421393|421865|427570|427571|472067|472068|622620)/,
                cardType: 'DC',
            },
            {
                reg: /^(545392|545393|545431|545447|356859|356857|407405|421869|421870|421871|512466|356856|528948|552288|622600|622601|622602|517636|622621|628258|556610|622603|464580|464581|523952|545217|553161|356858|622623|625912|625913|625911)/,
                cardType: 'CC',
            },
            {
                reg: /^(377155|377152|377153|377158)/,
                cardType: 'CC',
            },
        ],
    },
    gdb: {
        bankName: '广发银行',
        bankCode: 'GDB',
        patterns: [
            {
                reg: /^(622516|622517|622518|622521|622522|622523|984301|984303|621352|621793|621795|621796|621351|621390|621792|621791)/,
                cardType: 'DC',
            },
            {
                reg: /^(622568|6858001|6858009|621462)/,
                cardType: 'DC',
            },
            {
                reg: /^(9111)/,
                cardType: 'DC',
            },
            {
                reg: /^(406365|406366|428911|436768|436769|436770|487013|491032|491033|491034|491035|491036|491037|491038|436771|518364|520152|520382|541709|541710|548844|552794|493427|622555|622556|622557|622558|622559|622560|528931|558894|625072|625071|628260|628259|625805|625806|625807|625808|625809|625810)/,
                cardType: 'CC',
            },
            {
                reg: /^(685800|6858000)/,
                cardType: 'CC',
            },
        ],
    },
    cmb: {
        bankName: '招商银行',
        bankCode: 'CMB',
        patterns: [
            {
                reg: /^(402658|410062|468203|512425|524011|622580|622588|622598|622609|95555|621286|621483|621485|621486|621299)/,
                cardType: 'DC',
            },
            {
                reg: /^(690755)/,
                cardType: 'DC',
            },
            {
                reg: /^(690755)/,
                cardType: 'DC',
            },
            {
                reg: /^(356885|356886|356887|356888|356890|439188|439227|479228|479229|521302|356889|545620|545621|545947|545948|552534|552587|622575|622576|622577|622578|622579|545619|622581|622582|545623|628290|439225|518710|518718|628362|439226|628262|625802|625803)/,
                cardType: 'CC',
            },
            {
                reg: /^(370285|370286|370287|370289)/,
                cardType: 'CC',
            },
            {
                reg: /^(620520)/,
                cardType: 'PC',
            },
        ],
    },
    abc: {
        bankName: '中国农业银行',
        bankCode: 'ABC',
        patterns: [
            {
                reg: /^(622841|622824|622826|622848|620059|621282|622828|622823|621336|621619|622821|622822|622825|622827|622845|622849|623018|623206|621671|622840|622843|622844|622846|622847|620501)/,
                cardType: 'DC',
            },
            {
                reg: /^(95595|95596|95597|95598|95599)/,
                cardType: 'DC',
            },
            {
                reg: /^(103)/,
                cardType: 'DC',
            },
            {
                reg: /^(403361|404117|404118|404119|404120|404121|463758|519412|519413|520082|520083|552599|558730|514027|622836|622837|628268|625996|625998|625997|622838|625336|625826|625827|544243|548478|628269)/,
                cardType: 'CC',
            },
            {
                reg: /^(622820|622830)/,
                cardType: 'SCC',
            },
        ],
    },
    visa: {
        bankName: 'Visa借记卡',
        bankCode: 'VISA',
        patterns: [
            {
                reg: /^4/,
            },
        ],
    },
    mastercard: {
        bankName: '万事达卡',
        bankCode: 'MASTERCARD',
        patterns: [
            {
                reg: /^(5[1-5]|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720)/,
            },
        ],
    },
    amex: {
        bankName: '美国运通绿卡',
        bankCode: 'AMEX',
        patterns: [
            {
                reg: /^3[47]/,
            },
        ],
    },
    maestro: {
        bankName: '万事顺卡',
        bankCode: 'MAESTRO',
        patterns: [
            {
                reg: /^(5018|5020|5038|6304|6703|6708|6759|676[1-3])/,
            },
        ],
    },
    unionpay: {
        bankName: '银联卡',
        bankCode: 'UnionPay',
        patterns: [
            {
                reg: /^62/,
            },
        ],
    },
};
var CARD_TYPES = Object.values(CARD_MAP);
export var memorize = function (fn) {
    var cache = {};
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var key = args[0];
        if (cache[key]) {
            return cache[key];
        }
        var result = fn.apply(void 0, args);
        cache[key] = result;
        return result;
    };
};
export var getCardTypeByValueRaw = function (value, cardTypes) {
    if (cardTypes === void 0) { cardTypes = []; }
    var target = CARD_TYPES.concat(cardTypes).find(function (v) {
        var regs = v.patterns;
        return regs.some(function (r) {
            return r.reg.test(value);
        });
    });
    if (target) {
        return __assign(__assign({}, target), { format: DEFAULT_CARD_FORMAT });
    }
    return {
        format: DEFAULT_CARD_FORMAT,
        bankCode: 'custom',
        bankName: '',
    };
};
export var getCardTypeByTypeRaw = function (type) {
    return CARD_TYPES.find(function (cardType) { return cardType.bankCode.toLowerCase() === type.toLowerCase(); });
};
export var getCardTypeByValue = memorize(getCardTypeByValueRaw);
export var getCardTypeByType = memorize(getCardTypeByTypeRaw);
