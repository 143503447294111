import { __assign, __extends } from "tslib";
import * as React from 'react';
import cx from 'classnames';
import { CnGrid } from '@/components/cn-grid';
import { CnIcon } from '@/components/cn-icon';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnTextEllipsis } from '@/components/cn-ellipsis';
import { normalizeCol } from './utils';
import './cn-form-item.scss';
var CnRow = CnGrid.CnRow, CnCol = CnGrid.CnCol;
var CnFormItem = /** @class */ (function (_super) {
    __extends(CnFormItem, _super);
    function CnFormItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // 显示 Label title 解释信息
        _this.renderTip = function () {
            var _a = _this.props, tip = _a.tip, cnTooltipProps = _a.cnTooltipProps;
            return (React.createElement(CnTooltip, __assign({ className: "cn-ui-form-item-explanation", trigger: React.createElement(CnIcon, { outerClassName: "cn-ui-form-item-label-help-icon", role: "cn-form-item-tip", type: "help-color", size: 14 }) }, cnTooltipProps), tip));
        };
        return _this;
    }
    CnFormItem.prototype.renderLabel = function (labelMode) {
        var _a;
        var _b;
        var _c = this.props, required = _c.required, title = _c.title, labelCol = _c.labelCol, tip = _c.tip, asterisk = _c.asterisk, readOnly = _c.readOnly;
        if (!title)
            return null;
        var labelTextAlign = (_b = this.props.labelTextAlign) !== null && _b !== void 0 ? _b : (labelMode === 'inline' ? 'right' : 'left');
        var cls = cx((_a = {
                'cn-ui-form-item-header': true,
                'cn-ui-left': labelMode === 'inline'
            },
            _a["cn-ui-form-label-align-".concat(labelTextAlign)] = true,
            _a['cn-ui-form-item-readonly'] = !!readOnly,
            _a));
        var requiredStatus = false;
        if (required || asterisk) {
            requiredStatus = true;
        }
        if (readOnly) {
            requiredStatus = false;
        }
        var ele = (React.createElement("div", { className: "cn-ui-form-item-label" },
            requiredStatus && (React.createElement("span", { className: "cn-ui-form-item-required" }, '* ')),
            React.createElement(CnTextEllipsis, { line: 1, hasTooltip: true }, title),
            tip && this.renderTip()));
        if (labelMode === 'inline') {
            return (React.createElement(CnCol, __assign({}, normalizeCol(labelCol), { className: cls }), ele));
        }
        return React.createElement("div", { className: cls }, ele);
    };
    CnFormItem.prototype.renderWrapper = function (labelMode) {
        var _a = this.props, errors = _a.errors, wrapperCol = _a.wrapperCol, extra = _a.extra, children = _a.children, _b = _a.rightExtra, rightExtra = _b === void 0 ? null : _b;
        var componentArray = [
            React.createElement("div", { key: "control", className: "cn-ui-form-item-control" },
                children,
                rightExtra),
        ];
        if (Array.isArray(errors) && errors.length > 0) {
            componentArray.push(React.createElement("div", { key: "error", className: "cn-ui-form-item-error" }, errors.map(function (errorItem, idx) { return (React.createElement("div", { className: "error-item", key: "error-".concat(idx) }, errorItem)); })));
        }
        if (extra) {
            componentArray.push(React.createElement("div", { key: "extra", className: "cn-ui-form-item-extra" },
                React.createElement("span", null, extra)));
        }
        if (labelMode === 'inline') {
            return (React.createElement(CnCol, __assign({}, normalizeCol(wrapperCol), { className: "cn-ui-form-item-footer" }), componentArray));
        }
        return React.createElement("div", { className: "cn-ui-form-item-footer" }, componentArray);
    };
    CnFormItem.prototype.render = function () {
        var _a;
        var _b = this.props, className = _b.className, wrapperCol = _b.wrapperCol, labelCol = _b.labelCol, style = _b.style, id = _b.id, size = _b.size;
        var labelMode = wrapperCol || labelCol ? 'inline' : 'block';
        var classes = cx((_a = {
                'cn-ui-form-item': true
            },
            _a["cn-ui-form-item-".concat(labelMode)] = true,
            _a["cn-ui-form-item-".concat(size)] = true,
            _a[className] = !!className,
            _a));
        var Comp = labelMode === 'inline' ? CnRow : 'div';
        return (React.createElement(Comp, { "data-name": "CnFormItem", style: style, className: classes, id: id, gutter: 0, role: "cn-form-item" },
            this.renderLabel(labelMode),
            this.renderWrapper(labelMode)));
    };
    CnFormItem.displayName = 'CnFormItem';
    CnFormItem.defaultProps = {
    // labelTextAlign: 'left',
    };
    return CnFormItem;
}(React.Component));
export { CnFormItem };
