import { __assign, __rest } from "tslib";
import * as React from 'react';
import isFunction from 'lodash/isFunction';
import CnBaseTableView from './view';
import { useTablePipeline } from './view/table/pipeline';
import { normalizeColumns } from './view/utils';
import { registeredPipelines } from './register';
import { useColumnsResize, AsyncDragLine, } from './view/pipelines/columns-resize-pipeline';
import { useTableWidthCalWhenScreenResize } from './view/hooks';
// 内置 pipeline 预加载
import './view/pipelines/plugin';
export var CnBaseTable = function (props) {
    var 
    // 用户传入的 pipeline
    customPipeline = props.pipeline, 
    // 用户自定义的 pipelines
    propsPipelines = props.pipelines, 
    // 其他属性
    otherProps = __rest(props, ["pipeline", "pipelines"]);
    var resize = useColumnsResize(otherProps);
    var _a = useTableWidthCalWhenScreenResize(otherProps.tableRef), midRef = _a.midRef, totalWidthCalByColumnRef = _a.totalWidthCalByColumnRef, tableWidth = _a.tableWidth;
    var pipeline = useTablePipeline(customPipeline)
        .input({
        dataSource: otherProps === null || otherProps === void 0 ? void 0 : otherProps.dataSource,
        // 前置转换，请后代维护者关注一下
        columns: normalizeColumns(otherProps === null || otherProps === void 0 ? void 0 : otherProps.columns),
    })
        .primaryKey(otherProps === null || otherProps === void 0 ? void 0 : otherProps.primaryKey);
    if (Array.isArray(registeredPipelines)) {
        for (var _i = 0, registeredPipelines_1 = registeredPipelines; _i < registeredPipelines_1.length; _i++) {
            var registeredPipeline = registeredPipelines_1[_i];
            if (isFunction(registeredPipeline.render)) {
                try {
                    registeredPipeline.render(otherProps, pipeline, {
                        resize: resize,
                        totalWidthCalByColumnRef: totalWidthCalByColumnRef,
                        tableWidth: tableWidth,
                    });
                }
                catch (error) {
                    console.error("[CnBaseTable] \u5185\u7F6E\u7684 ".concat(registeredPipeline.name, " pipeline \u8FD0\u884C\u62A5\u9519"), error);
                }
            }
        }
    }
    if (Array.isArray(propsPipelines)) {
        for (var _b = 0, propsPipelines_1 = propsPipelines; _b < propsPipelines_1.length; _b++) {
            var udPipeline = propsPipelines_1[_b];
            if (isFunction(udPipeline)) {
                try {
                    udPipeline(otherProps, pipeline, {
                        resize: resize,
                        totalWidthCalByColumnRef: totalWidthCalByColumnRef,
                        tableWidth: tableWidth,
                    });
                }
                catch (error) {
                    console.error('[CnBaseTable] 开发者传入的 pipelines 运行报错', error);
                }
            }
        }
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(CnBaseTableView, __assign({ ref: midRef, pipeline: pipeline }, otherProps)),
        React.createElement(AsyncDragLine, { resize: resize, tableRef: midRef })));
};
CnBaseTable.displayName = 'CnBaseTable';
