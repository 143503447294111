import React from 'react';
import { Menu, Dropdown } from '@/components/fusion';
import { CnIcon } from '@/components/cn-icon';
import { setGlobalSize, useSizeChange } from '@/components/cn-utils';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
import './index.scss';
export var CnCompactSelect = function (props) {
    var _a = props.triggerType, triggerType = _a === void 0 ? 'click' : _a, onChange = props.onChange, cookieOptions = props.cookieOptions;
    var size = useSizeChange(props).size;
    var usedLangList = [
        { value: 'small', label: '紧凑' },
        { value: 'medium', label: '宽松' },
    ];
    return (React.createElement(Dropdown, { trigger: withNativeProps(props, React.createElement("span", { className: "cn-compact-select", "data-testid": "CnCompactSelect", "aria-haspopup": "true" }, size === 'small' ? (React.createElement(CnIcon, { type: "icon-task-list" })) : (React.createElement(CnIcon, { type: "icon-density-compact" })))), triggerType: triggerType },
        React.createElement(Menu, { className: "cn-compact-select-menu" }, usedLangList.map(function (langObj) {
            return (React.createElement(Menu.Item, { onClick: function () {
                    setGlobalSize(langObj.value, cookieOptions);
                    onChange === null || onChange === void 0 ? void 0 : onChange(langObj.value);
                }, key: langObj.value }, langObj.label));
        }))));
};
export default CnCompactSelect;
