import * as React from 'react';
/**
 * 在组件更新时执行回调函数
 * @param handler
 * @param depedencies
 */
export function useWillUpdate(handler, depedencies) {
    var didMount = React.useRef(false);
    React.useMemo(function () {
        if (didMount.current) {
            handler instanceof Function && handler();
        }
    }, depedencies);
    React.useEffect(function () {
        didMount.current = true;
    }, [didMount]);
}
