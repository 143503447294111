import { __assign } from "tslib";
import isFunction from 'lodash/isFunction';
import flattenDeep from 'lodash/flattenDeep';
import cloneDeep from 'lodash/cloneDeep';
var monitorTextRule = "\u206F\u206F(.*)\u206F\u206F(.*)\u206F\u206F";
/**
 * 获取字符串字节长
 */
export function getByteLen(val) {
    var reg = new RegExp(monitorTextRule, 'u');
    if (reg.test(val)) {
        try {
            while (reg.test(val)) {
                var replaceResult = val.match(reg);
                if (Array.isArray(replaceResult) && replaceResult.length > 2) {
                    val = val.replace(reg, replaceResult[1]);
                }
            }
        }
        catch (error) { }
    }
    var len = 0;
    for (var i = 0; i < val.length; i++) {
        var a = val.charCodeAt(i);
        if (a >= 0 && a <= 128) {
            len += 1;
        }
        else {
            len += 2;
        }
    }
    return len;
}
/**
 * 截取字符串，中文占2字节，西文占1字节
 * 例如 subCharStr('测试aaa一段很bbb长字符ccc串测试一段很长字符串', 15)
 * 以下 888 真实场景是编码后的结果，为不可见字符；\u{206F}也是不可见字符
 * subCharStr("\u{206F}\u{206F}今天天气非常不错\u{206F}\u{206F}888\u{206F}\u{206F}（999）",12) ==> \u{206F}\u{206F}今天天气非常...\u{206F}\u{206F}888\u{206F}\u{206F}
 * subCharStr("\u{206F}\u{206F}今天天气非常不错\u{206F}\u{206F}888\u{206F}\u{206F}（999）",19) ==> \u{206F}\u{206F}今天天气非常不错（9...\u{206F}\u{206F}888\u{206F}\u{206F}
 * 返回 '测试aaa一段很bb...'
 */
export function subCharStr(str, n) {
    if (typeof str !== 'string')
        return str;
    var matchMonitorMode = false;
    var monitorId = null;
    var reg = new RegExp(monitorTextRule, 'u');
    if (reg.test(str)) {
        matchMonitorMode = true;
        try {
            while (reg.test(str)) {
                var replaceResult = str.match(reg);
                if (Array.isArray(replaceResult) && replaceResult.length > 2) {
                    str = str.replace(reg, replaceResult[1]);
                    if (!monitorId) {
                        monitorId = replaceResult[2];
                    }
                }
            }
        }
        catch (error) { }
    }
    var inoutStr = ''; // 新字符串
    var inputLength = 0; // 记录长度
    for (var i = 0, k = str.length; i < k; i++) {
        var countCode = str.charCodeAt(i);
        if (countCode >= 0 && countCode <= 128) {
            inputLength++;
        }
        else {
            inputLength += 2;
        }
        if (inputLength > n)
            break;
        inoutStr += str[i];
    }
    if (inoutStr.length < str.length)
        inoutStr += '...';
    if (matchMonitorMode && monitorId) {
        return "\u206F\u206F".concat(inoutStr, "\u206F\u206F").concat(monitorId, "\u206F\u206F");
    }
    return inoutStr;
}
export function safeCallFunction(fun) {
    var _a;
    var rest = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        rest[_i - 1] = arguments[_i];
    }
    if (isFunction(fun)) {
        return (_a = fun.apply(void 0, rest)) !== null && _a !== void 0 ? _a : true;
    }
    return undefined;
}
export function flattenDeepChildren(collection) {
    if (!Array.isArray(collection)) {
        return [];
    }
    var getTail = function (item) {
        if ((item === null || item === void 0 ? void 0 : item.children) && (item === null || item === void 0 ? void 0 : item.children.length) > 0) {
            return [item, item === null || item === void 0 ? void 0 : item.children.map(function (m) { return getTail(m); })];
        }
        else {
            return [item];
        }
    };
    var cloneCollection = cloneDeep(collection);
    var result = flattenDeep(cloneCollection.map(function (m) { return getTail(m); }));
    return Array.isArray(result) ? result : [];
    // return find(flattenDeepChildren, target);
}
export function isPromise(obj) {
    return (!!obj &&
        (typeof obj === 'object' || typeof obj === 'function') &&
        typeof obj.then === 'function');
}
export function applyFieldNames(fieldNames) {
    var _a = fieldNames || {}, label = _a.label, value = _a.value, children = _a.children;
    return {
        label: label || 'label',
        value: value || 'value',
        children: children || 'children',
    };
}
export function loopDataSource(dataSource, fieldNames) {
    var _a = applyFieldNames(fieldNames), fieldLabel = _a.label, fieldValue = _a.value, fieldChildren = _a.children;
    var result = [];
    dataSource.forEach(function (item) {
        if (item[fieldChildren]) {
            var children = loopDataSource(item[fieldChildren]);
            result.push(__assign(__assign({}, item), { label: item[fieldLabel], value: item[fieldValue], children: children }));
        }
        else {
            result.push(__assign(__assign({}, item), { label: item[fieldLabel], value: item[fieldValue] }));
        }
    });
    return result;
}
