import { __assign, __awaiter, __generator } from "tslib";
/**
 * 基础 request method, 基于 CNRequest 实现 (背后是 axios 实例).
 * 这里可以做一些业务的特殊定制, 比如说统一的埋点上报, 以及通用的网络错误处理.
 */
import { useRequest } from 'ahooks';
import { packRequest } from './pack-request';
import { getRequestService } from './get-request-service';
export { packRequest, getRequestService };
export function unpackRequest(options) {
    return __awaiter(this, void 0, void 0, function () {
        var _a, data, success, errorMsg;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, packRequest(options)];
                case 1:
                    _a = _b.sent(), data = _a.data, success = _a.success, errorMsg = _a.errorMsg;
                    if (!success) {
                        throw new Error(errorMsg);
                    }
                    return [2 /*return*/, data];
            }
        });
    });
}
export function useCnRequest(requestConfig, plugins) {
    var service = getRequestService(requestConfig);
    var ready = Boolean(requestConfig) &&
        Boolean(requestConfig.url || requestConfig.service);
    return useRequest(service, __assign({ ready: ready }, requestConfig), plugins);
}
export * from './types';
