import { __assign, __awaiter, __generator, __rest } from "tslib";
import React, { useImperativeHandle, useRef, useState } from 'react';
import classNames from 'classnames';
import uploadFile from './upload/request';
import attrAccept from './upload/attr-accept';
import traverseFileTree from './upload/traverse-file-tree';
import { useUploadState } from './context';
import { getFilePercentOffset } from './upload/get-file-percent-offset';
import { convertFileToObject } from './upload/convert-file-to-object';
export var CnUploader = React.forwardRef(function (props, ref) {
    var children = props.children, dragger = props.dragger, className = props.className;
    var _a = useUploadState(), onUploadInit = _a.onUploadInit, onUploadProcess = _a.onUploadProcess, onUploadSuccess = _a.onUploadSuccess, onError = _a.onError, fileValidator = _a.fileValidator, getValue = _a.getValue, limited = _a.limited, rootProps = _a.props, setUploadReq = _a.setUploadReq;
    var _b = useState(), fileInputRandomKey = _b[0], setFileInputRandomKey = _b[1];
    var beforeUpload = rootProps.beforeUpload, disabled = rootProps.disabled, limit = rootProps.limit, multiple = rootProps.multiple, accept = rootProps.accept, directory = rootProps.directory, openFileDialogOnClick = rootProps.openFileDialogOnClick, headers = rootProps.headers, ossUploadConfig = rootProps.ossUploadConfig, fetchOSSToken = rootProps.fetchOSSToken;
    // 剩余上传数量
    var remainUploadNum = limit - (getValue().length || 0);
    var fileInputRef = useRef(null);
    var onClick = function () {
        var _a;
        if (!openFileDialogOnClick || limited || disabled)
            return;
        (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    /**
     * 在将文件处理完成后，执行文件上传逻辑
     */
    var processFile = function (file, fileList) { return __awaiter(void 0, void 0, void 0, function () {
        var transformedFile, error_1, parsedData, parsedFile;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    transformedFile = file;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    fileValidator(file);
                    if (!beforeUpload) return [3 /*break*/, 3];
                    return [4 /*yield*/, beforeUpload(file, fileList)];
                case 2:
                    transformedFile = _a.sent();
                    _a.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    error_1 = _a.sent();
                    onError(convertFileToObject(file, { status: 'error', error: error_1 }));
                    // 当文件校验失败，不处理文件，并忽略上传
                    transformedFile = false;
                    return [3 /*break*/, 5];
                case 5:
                    if (transformedFile === false)
                        return [2 /*return*/, null];
                    parsedData = (typeof transformedFile === 'object' ||
                        typeof transformedFile === 'string') &&
                        transformedFile
                        ? transformedFile
                        : file;
                    if (parsedData instanceof File) {
                        parsedFile = parsedData;
                    }
                    else {
                        parsedFile = new File([parsedData], file.name, { type: file.type });
                    }
                    return [2 /*return*/, parsedFile];
            }
        });
    }); };
    var post = function (_a) { return __awaiter(void 0, void 0, void 0, function () {
        var percent = _a.percent, _file = __rest(_a, ["percent"]);
        return __generator(this, function (_b) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    var _a = _file.response, host = _a.host, downloadUrl = _a.downloadUrl, url = _a.url, key = _a.key, data = __rest(_a, ["host", "downloadUrl", "url", "key"]);
                    var file = __assign(__assign({}, _file), { url: url, downloadUrl: downloadUrl, key: key });
                    var requestOption = {
                        action: host,
                        data: __assign(__assign({}, data), { key: key, success_action_status: 200 }),
                        file: _file.originFile,
                        headers: headers,
                        withCredentials: (ossUploadConfig === null || ossUploadConfig === void 0 ? void 0 : ossUploadConfig.withCredentials) || false,
                        onProgress: function (e) {
                            var percent = 0;
                            if (e.total && e.loaded && e.total > 0) {
                                percent = (e.loaded / e.total) * 100;
                            }
                            if (percent < getFilePercentOffset(file.originFile.size))
                                return;
                            onUploadProcess(__assign(__assign({}, file), { status: 'uploading', percent: percent }));
                        },
                        onSuccess: function () {
                            onUploadSuccess(__assign(__assign({}, file), { status: 'done' }));
                            resolve(undefined);
                        },
                        onError: reject,
                    };
                    setUploadReq(key, uploadFile(requestOption));
                })];
        });
    }); };
    /**
     * 根据组件配置可接受的文件类型过滤
     */
    var filterFiles = function (fileList, isAccepted) {
        if (isAccepted === void 0) { isAccepted = function (file) { return attrAccept(file, accept); }; }
        var files = [];
        for (var i = 0; i < fileList.length; i++) {
            if (i >= remainUploadNum)
                return files;
            var file = fileList[i];
            if (isAccepted(file))
                files.push(file);
        }
        return files;
    };
    var uploadFiles = function (files) { return __awaiter(void 0, void 0, void 0, function () {
        var _fileList, len, i, fileList, i, file, _i, fileList_1, file, ossData, _file, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setFileInputRandomKey(Date.now());
                    _fileList = [];
                    len = files.length;
                    for (i = 0; i < len; i++) {
                        _fileList.push(files[i]);
                    }
                    fileList = [];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 14, , 15]);
                    i = 0;
                    _a.label = 2;
                case 2:
                    if (!(i < len)) return [3 /*break*/, 5];
                    return [4 /*yield*/, processFile(_fileList[i], _fileList)];
                case 3:
                    file = _a.sent();
                    if (file) {
                        fileList.push(convertFileToObject(file, { status: 'idle', percent: 0 }));
                    }
                    _a.label = 4;
                case 4:
                    i++;
                    return [3 /*break*/, 2];
                case 5:
                    onUploadInit(fileList);
                    if (!rootProps.serialUpload) return [3 /*break*/, 11];
                    _i = 0, fileList_1 = fileList;
                    _a.label = 6;
                case 6:
                    if (!(_i < fileList_1.length)) return [3 /*break*/, 10];
                    file = fileList_1[_i];
                    return [4 /*yield*/, fetchOSSToken({ fileName: file.name })];
                case 7:
                    ossData = _a.sent();
                    if (!ossData)
                        return [2 /*return*/];
                    _file = __assign(__assign({}, file), { key: ossData.key, percent: getFilePercentOffset(file.size), response: ossData });
                    onUploadProcess(_file);
                    return [4 /*yield*/, post(_file)];
                case 8:
                    _a.sent();
                    _a.label = 9;
                case 9:
                    _i++;
                    return [3 /*break*/, 6];
                case 10: return [3 /*break*/, 13];
                case 11: return [4 /*yield*/, Promise.all(fileList.map(function (file) { return __awaiter(void 0, void 0, void 0, function () {
                        var ossData, _file;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, fetchOSSToken({ fileName: file.name })];
                                case 1:
                                    ossData = _a.sent();
                                    if (!ossData)
                                        return [2 /*return*/];
                                    _file = __assign(__assign({}, file), { key: ossData.key, percent: getFilePercentOffset(file.size), response: ossData });
                                    onUploadProcess(_file);
                                    return [4 /*yield*/, post(_file)];
                                case 2:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); }))];
                case 12:
                    _a.sent();
                    _a.label = 13;
                case 13: return [3 /*break*/, 15];
                case 14:
                    error_2 = _a.sent();
                    fileList.forEach(function (file) {
                        onError(__assign(__assign({}, file), { status: 'error', error: error_2 }));
                    });
                    return [3 /*break*/, 15];
                case 15: return [2 /*return*/];
            }
        });
    }); };
    useImperativeHandle(ref, function () { return ({
        uploader: {
            uploadFiles: uploadFiles,
            onClick: onClick,
        },
    }); });
    var onFileDrop = function (e) {
        if (disabled)
            return;
        if (limited)
            return;
        if (!dragger)
            return;
        e.preventDefault();
        if (e.type === 'dragover')
            return;
        if (directory) {
            traverseFileTree(Array.prototype.slice.call(e.dataTransfer.items), uploadFiles, function (_file) { return attrAccept(_file, accept); });
            return;
        }
        if (multiple === false) {
            uploadFiles([e.dataTransfer.files[0]]);
            return;
        }
        uploadFiles(filterFiles(e.dataTransfer.files));
    };
    // because input don't have directory/webkitdirectory type declaration
    var dirProps = directory
        ? { directory: 'directory', webkitdirectory: 'webkitdirectory' }
        : {};
    return (React.createElement("span", { className: classNames(className, {
            disabled: disabled || limited,
        }), onDrop: onFileDrop, onDragOver: onFileDrop, onClick: onClick, onKeyDown: function (e) { return e.key === 'Enter' && onClick(); } },
        React.createElement("input", __assign({}, dirProps, { key: fileInputRandomKey, ref: fileInputRef, style: { display: 'none' }, type: "file", accept: accept, multiple: multiple, onClick: function (e) { return e.stopPropagation(); }, onChange: function (_a) {
                var files = _a.target.files;
                if (!files)
                    return;
                uploadFiles(filterFiles(files, function (file) { return !directory || attrAccept(file, accept); }));
            } })),
        children));
});
