import * as React from 'react';
import cx from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import { Overlay } from '@fusion/lib';
import { useControllableValue } from 'ahooks';
import { CnTypography } from '@/components/cn-typography';
import './launchpad-menu.scss';
import { CnEllipsis } from '@/components/cn-ellipsis';
var BodyText1 = CnTypography.BodyText1, BodyText2 = CnTypography.BodyText2;
var isKeySelected = function (key, selected) {
    if (typeof selected === 'string') {
        return key === selected;
    }
    else if (Array.isArray(selected)) {
        return selected.indexOf(key) > -1;
    }
    return false;
};
export var LaunchpadMenu = function (props) {
    var _a;
    var popupClassName = props.popupClassName, dataSource = props.dataSource, triggerType = props.triggerType, onClick = props.onClick, onDelete = props.onDelete;
    var _b = useControllableValue(props, {
        valuePropName: 'selectedKeys',
        defaultValuePropName: 'defaultSelectedKeys',
        trigger: 'onSelect',
    }), selectedKeys = _b[0], setSelectedKeys = _b[1];
    var _c = React.useState(false), visible = _c[0], changeVisible = _c[1];
    var renderGroupFooter = function (groupItem) {
        if (!Array.isArray(groupItem.children) || !groupItem.children.length) {
            // 快捷面板的空状态
            return (React.createElement("div", { className: "launchpad-menu-group-footer-empty" }, groupItem.emptySlot));
        }
        return groupItem.children.map(function (menuItem) {
            return (React.createElement("a", { className: cx({
                    'launchpad-menu-item': true,
                    active: isKeySelected(menuItem.key, selectedKeys),
                }), key: menuItem.key, href: menuItem.link, onClick: function (e) {
                    if (onClick) {
                        e.preventDefault();
                        setSelectedKeys(menuItem.key, menuItem);
                        onClick(menuItem, function () {
                            changeVisible(false);
                        });
                    }
                } },
                React.createElement("div", { className: "launchpad-menu-item-header" },
                    React.createElement(CnIcon, { type: menuItem.icon, size: "medium" })),
                React.createElement("div", { className: cx('launchpad-menu-item-body', {
                        extend: !!groupItem.showRightDelete,
                    }) },
                    React.createElement(BodyText1, null,
                        React.createElement(CnEllipsis, null, menuItem.text))),
                groupItem.showRightDelete && (React.createElement("div", { className: "launchpad-menu-item-footer", onClick: function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        onDelete && onDelete(menuItem);
                    } },
                    React.createElement(CnIcon, { type: "delete" })))));
        });
    };
    if (!Array.isArray(dataSource)) {
        return null;
    }
    return (React.createElement("div", { className: "launchpad-slot" },
        React.createElement(Overlay.Popup, { v2: true, placement: "bl", offset: [-8, 8], trigger: React.createElement("div", { className: cx({
                    'launchpad-slot-icon': true,
                    active: visible,
                }) },
                React.createElement(CnIcon, { type: "launchpad-fill", size: "large" })), triggerType: triggerType, visible: visible, onVisibleChange: changeVisible, wrapperClassName: cx((_a = {},
                _a[popupClassName] = !!popupClassName,
                _a)) },
            React.createElement("div", { className: "cn-ui-shell-launchpad-menu" }, dataSource.map(function (groupItem) {
                return (React.createElement("div", { className: "launchpad-menu-group", key: groupItem.key },
                    React.createElement("div", { className: cx({
                            'launchpad-menu-group-header': true,
                            'narrow-margin': !groupItem.text && !groupItem.operationSlot,
                        }) },
                        React.createElement("div", { className: "title" },
                            React.createElement(BodyText2, null, groupItem.text)),
                        React.createElement("div", { className: "operations" }, groupItem.operationSlot)),
                    React.createElement("div", { className: "launchpad-menu-group-footer" }, renderGroupFooter(groupItem))));
            })))));
};
