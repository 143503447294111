import { __spreadArray } from "tslib";
import $i18n from 'panda-i18n';
import { rowIndex } from '../../global';
export var serialNumberCol = {
    name: $i18n.get({ id: 'SerialNumber', dm: '序号', ns: 'CnBaseTable' }),
    width: 45,
    lock: true,
    sizeFixed: true,
    align: 'center',
    attach: false,
    symbol: rowIndex,
    /**
     * 序号列渲染函数
     * @param _ 无用参数
     * @param record 当前行数据
     * @param rowIndex 当前行索引
     * @returns 返回当前行序号
     */
    render: function (_, record, rowIndex) {
        return rowIndex + 1;
    },
};
/**
 * 生成序号列
 * @param pipeline 表格管道
 * @returns 返回包含序号列的管道
 */
export function serialNumberPipeline(pipeline) {
    return pipeline.columns(__spreadArray([serialNumberCol], pipeline.getColumns(), true)); // 返回包含序号列的管道
}
