import { __assign } from "tslib";
// 导入classnames库
import classnames from 'classnames';
// 导入isPlainObject和isString函数
import { isPlainObject } from './common';
import isFunction from 'lodash/isFunction';
// 规范化列函数
export function normalizeColumns(columns) {
    // 定义返回数组
    var ret = [];
    // 如果没有列，返回空数组
    if (!columns)
        return ret;
    var cloneColumns = [];
    // 遍历列
    columns.forEach(function (col) {
        var _a;
        // 如果列不是普通对象，跳过
        if (!isPlainObject(col)) {
            return;
        }
        // 如果列有子列，递归规范化子列
        if (Array.isArray(col.children) && col.children.length > 0) {
            col.children = normalizeColumns(col.children);
        }
        // 如果列有key或dataIndex，且没有code，将code设置为key或dataIndex
        if ((col.key || col.dataIndex) && col.code == null) {
            col.code = (_a = col.key) !== null && _a !== void 0 ? _a : col.dataIndex;
        }
        // 如果列有sortable属性，将其添加到features中
        if ('sortable' in col) {
            col.features = __assign(__assign({}, col.features), { sortable: col.sortable });
        }
        // 兼容fusion api
        if ((col === null || col === void 0 ? void 0 : col.cell) instanceof Function) {
            var sourceRender_1 = col.cell;
            col.render = function (value, row, rowIndex) { return sourceRender_1(value, rowIndex, row); };
        }
        // 如果列有bold或ellipsis属性
        if ('bold' in col || 'ellipsis' in col) {
            var sourceCellProps_1 = col.cellProps;
            col.cellProps = function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                var customProps = isFunction(sourceCellProps_1)
                    ? sourceCellProps_1.apply(void 0, args) || {}
                    : {};
                // 返回自定义属性和类名
                return __assign(__assign({}, customProps), { className: classnames(customProps === null || customProps === void 0 ? void 0 : customProps.className, {
                        'cn-table-cell--bold': col.bold,
                        'cn-table-cell--ellipsis': col.ellipsis,
                    }) });
            };
        }
        cloneColumns.push(col);
    });
    // 返回规范化后的列数组
    return cloneColumns;
}
// 扁平映射函数
export function flatMap(array, callback) {
    // 定义结果数组
    var result = [];
    // 遍历数组
    array.forEach(function (value, index) {
        // 将回调函数的结果添加到结果数组中
        result.push.apply(result, callback(value, index, array));
    });
    // 返回结果数组
    return result;
}
