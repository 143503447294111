import * as React from 'react';
import { getDeptCacheByKey } from './request-cache';
import { CnEllipsis } from '@/components/cn-ellipsis';
export default function DepartmentValueItem(props) {
    var item = props.item, mokelay = props.mokelay, host = props.host, style = props.style;
    var _a = React.useState(item), departmentCache = _a[0], setDepartmentCache = _a[1];
    React.useEffect(function () {
        if (!item.fullName) {
            getDeptCacheByKey({ deptNo: item.value, mokelay: mokelay, host: host }).then(function (res) {
                setDepartmentCache(res);
            });
        }
        else {
            setDepartmentCache(item);
        }
    }, [item === null || item === void 0 ? void 0 : item.value]);
    return (React.createElement(CnEllipsis, { endCharCount: 6, style: style, ellipsisPosition: "middle" }, departmentCache === null || departmentCache === void 0 ? void 0 : departmentCache.fullName));
}
