import { __assign, __extends, __rest } from "tslib";
import $i18n, { withI18n } from 'panda-i18n';
import * as React from 'react';
import cx from 'classnames';
import BigNumber from 'bignumber.js';
// import numbro from 'numbro';
import NextNumberPicker from './number-picker';
import { CnMessage } from '@/components/cn-message';
import locale from '@/locales';
import './percent-input.scss';
var CnPercentInputOrigin = /** @class */ (function (_super) {
    __extends(CnPercentInputOrigin, _super);
    function CnPercentInputOrigin(props) {
        var _this = _super.call(this, props) || this;
        var value;
        if ('value' in props) {
            value = props.value;
        }
        else {
            value = props.defaultValue;
        }
        _this.state = {
            value: value === undefined || value === null ? undefined : value,
        };
        return _this;
    }
    CnPercentInputOrigin.getDerivedStateFromProps = function (nextProps, prevState) {
        if ('value' in nextProps && nextProps.value !== prevState.value) {
            var value = nextProps.value;
            return {
                value: value === undefined || value === null ? '' : value,
            };
        }
        return null;
    };
    CnPercentInputOrigin.prototype.render = function () {
        var _a;
        var _b = this.props, className = _b.className, readOnly = _b.readOnly, otherProps = __rest(_b, ["className", "readOnly"]);
        // 组件样式
        var classes = cx((_a = {
                'cn-ui-percent-input': true
            },
            _a[className] = !!className,
            _a));
        // 百分比符号样式
        var percentMarkClasses = cx({
            'cn-ui-percent-input-mark': true,
            'cn-ui-percent-input-readonly-mark': !!readOnly,
        });
        var innerAfter = React.createElement("span", { className: percentMarkClasses }, "%");
        if (readOnly) {
            var bigNumberValue = new BigNumber(this.state.value);
            var displayValue = bigNumberValue.toString();
            if (!bigNumberValue.isNaN()) {
                if (!isNaN(otherProps.precision)) {
                    displayValue = bigNumberValue.toFixed(Number(otherProps.precision));
                }
                if (otherProps.format) {
                    displayValue = otherProps.format(displayValue);
                }
            }
            else {
                displayValue = '-';
            }
            return (React.createElement("div", { className: classes },
                otherProps.innerBefore,
                otherProps.addonTextBefore,
                displayValue,
                otherProps.addonTextAfter,
                otherProps.innerAfter || innerAfter));
        }
        return (React.createElement(NextNumberPicker, __assign({ "data-name": "CnPercentInput", className: classes, innerAfter: innerAfter, prefix: "cn-next-", onCorrect: function (obj) {
                CnMessage.notice($i18n.get({
                    id: 'PleaseEnterTheCorrectNumber',
                    dm: '请输入正确的数字',
                    ns: 'CnNumberPicker',
                }));
            } }, this.props)));
    };
    CnPercentInputOrigin.displayName = 'CnPercentInput';
    CnPercentInputOrigin.defaultProps = {
        // 百分比极少出现负数，默认 min=0
        min: 0,
    };
    return CnPercentInputOrigin;
}(React.Component));
export var CnPercentInput = withI18n(CnPercentInputOrigin, {
    componentName: 'CnPercentInput',
    locale: locale,
    forwardRef: true,
});
