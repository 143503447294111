import { __assign } from "tslib";
import * as React from 'react';
import NextProgress from '@fusion/lib/progress';
export var CnProgress = React.forwardRef(function (props, ref) {
    return React.createElement(NextProgress, __assign({ "data-testid": "CnProgress", "data-name": "CnProgress" }, props, { ref: ref }));
});
CnProgress.displayName = 'CnProgress';
/**
 * @deprecated  请使用 CnProgress 替换
 */
export var Progress = NextProgress;
