import { __extends } from "tslib";
import * as React from 'react';
import classnames from 'classnames';
var CnPageSeparator = /** @class */ (function (_super) {
    __extends(CnPageSeparator, _super);
    function CnPageSeparator() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CnPageSeparator.prototype.render = function () {
        var className = this.props.className;
        var cls = classnames('cn-ui-page-separator', className);
        return React.createElement("div", { className: cls });
    };
    CnPageSeparator.displayName = 'CnPageSeparator';
    return CnPageSeparator;
}(React.Component));
export { CnPageSeparator };
