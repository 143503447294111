import { __assign } from "tslib";
export function fileToObject(file, options) {
    return __assign(__assign({}, options), { lastModified: file.lastModified, name: file.name, size: file.size, type: file.type, uid: file.uid, percent: 0, originFile: file });
}
function getMatchKey(file) {
    if (file.uid)
        return 'uid';
    if (file.key)
        return 'key';
    return 'name';
}
export function getFileIndex(file, fileList) {
    var matchKey = getMatchKey(file);
    return fileList.findIndex(function (item) { return item[matchKey] === file[matchKey]; });
}
export function removeFileItem(file, fileList) {
    var matchKey = getMatchKey(file);
    var removed = fileList.filter(function (item) { return item[matchKey] !== file[matchKey]; });
    if (removed.length === fileList.length) {
        return null;
    }
    return removed;
}
