import { __assign, __rest } from "tslib";
import React, { Fragment } from 'react';
import cls from 'classnames';
import { CnStep as Steps, StepItem as StepsItem, } from '@/components/cn-step';
import { connect, useField, observer, useFieldSchema, RecursionField, } from '@formily/react';
import { usePrefixCls } from '@/form/__builtins__';
import './cn-form-step.scss';
var parseSteps = function (schema) {
    var steps = [];
    schema.mapProperties(function (schema, name) {
        var _a;
        if ((_a = schema['x-component']) === null || _a === void 0 ? void 0 : _a.includes('CnFormStepItem')) {
            steps.push({
                name: name,
                props: schema['x-component-props'],
                schema: schema,
            });
        }
    });
    return steps;
};
export var CnFormStep = connect(observer(function (_a) {
    var _b;
    var cnFormStepInstance = _a.cnFormStepInstance, className = _a.className, props = __rest(_a, ["cnFormStepInstance", "className"]);
    var field = useField();
    var prefixCls = usePrefixCls('formily-step', props);
    var schema = useFieldSchema();
    var steps = parseSteps(schema);
    var current = props.current || (cnFormStepInstance === null || cnFormStepInstance === void 0 ? void 0 : cnFormStepInstance.current) || 0;
    (_b = cnFormStepInstance === null || cnFormStepInstance === void 0 ? void 0 : cnFormStepInstance.connect) === null || _b === void 0 ? void 0 : _b.call(cnFormStepInstance, steps, field);
    return (React.createElement("div", { className: cls(prefixCls, className) },
        React.createElement(Steps, __assign({}, props, { current: current }), steps.map(function (_a, key) {
            var props = _a.props;
            return React.createElement(StepsItem, __assign({}, props, { key: key }));
        })),
        steps.map(function (_a, key) {
            var name = _a.name, schema = _a.schema;
            if (key !== current)
                return;
            return React.createElement(RecursionField, { key: key, name: name, schema: schema });
        })));
}));
export var CnFormStepItem = function (_a) {
    var children = _a.children;
    return React.createElement(Fragment, null, children);
};
