import { __assign, __rest, __spreadArray } from "tslib";
import * as React from 'react';
import cx from 'classnames';
import $i18n, { withI18n } from 'panda-i18n';
import isFunction from 'lodash/isFunction';
import locale from '@/locales';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import omit from 'lodash/omit';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnLoading } from '@/components/cn-loading';
import NextTreeSelect from './tree-select';
import { CnCheckbox } from '@/components/cn-checkbox';
import { CnReadOnly } from '@/components/cn-read-only';
import { useValueState } from '@/components/cn-utils';
import { useCnRequest } from '@cainiaofe/cn-ui-common';
import { findDeep, getCheckAllValues } from './utils';
import './index.scss';
function handleErrorInfo(error) {
    if (!error)
        return {};
    return {
        autoWidth: false,
        dataSource: [],
        notFoundContent: (React.createElement("div", { className: "cn-tree-select-error" }, $i18n.get({
            id: 'RequestDataException',
            dm: '请求数据异常',
            ns: 'CnTreeSelect',
        }))),
    };
}
function handleLoading(loading, data) {
    var ContentLoading = function () {
        return (React.createElement("div", { className: "content-loading" },
            React.createElement(CnLoading, { size: "medium" })));
    };
    var IsContentLoading = (data === null || data === void 0 ? void 0 : data.length) === 0 && loading;
    if (IsContentLoading) {
        return {
            notFoundContent: React.createElement(ContentLoading, null),
        };
    }
    if (loading) {
        return {
            state: 'loading',
        };
    }
    return {
        state: undefined,
    };
}
export var CnTreeSelect = withI18n(React.forwardRef(function (props, ref) {
    var _a, _b, _c, _d;
    var className = props.className, readOnly = props.readOnly, readOnlyProps = props.readOnlyProps, requestConfig = props.requestConfig, treeProps = props.treeProps, showCheckAll = props.showCheckAll, popupClassNameProps = props.popupClassName, onVisibleChangeProps = props.onVisibleChange, onSearchClearProps = props.onSearchClear, onSearchProps = props.onSearch, onChangeProps = props.onChange, enableRemoteLazyLoad = props.enableRemoteLazyLoad, otherProps = __rest(props, ["className", "readOnly", "readOnlyProps", "requestConfig", "treeProps", "showCheckAll", "popupClassName", "onVisibleChange", "onSearchClear", "onSearch", "onChange", "enableRemoteLazyLoad"]);
    var _e = useValueState(props, undefined), value = _e[0], setValue = _e[1], isControlled = _e[2];
    var treeRef = React.useRef();
    var _f = React.useState([]), innerDataSource = _f[0], setInnerDataSource = _f[1];
    var _g = React.useState(false), checkAll = _g[0], setCheckAll = _g[1];
    var _h = React.useState(false), searching = _h[0], setSearching = _h[1];
    var _j = React.useState(1), forceUpdate = _j[1];
    var existValues = React.useRef();
    var checkAllValuesRef = React.useRef([]);
    var selectRef = React.useRef(null);
    var remoteLazyLoadItemRef = React.useRef();
    var insertSelectProps = {};
    var isRemoteDataSource = React.useMemo(function () {
        return !!((requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.url) || (requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.service));
    }, [requestConfig]);
    if (!requestConfig.resultFormatter && !requestConfig.formatResult) {
        requestConfig.formatResult = function (res) {
            var _a;
            if (Array.isArray(res)) {
                return res;
            }
            else if (Array.isArray(res === null || res === void 0 ? void 0 : res.data)) {
                return res.data;
            }
            else if (Array.isArray((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.dataSource)) {
                return res.data.dataSource;
            }
            return [];
        };
    }
    var cnRequestConfig = __assign({ ready: isRemoteDataSource }, requestConfig);
    // 兼容自定义service逻辑（通用hook不处理非cn-request的service）
    if (isFunction(requestConfig.service)) {
        cnRequestConfig.service = function (searchConfig) {
            var _a;
            return (_a = requestConfig.service(searchConfig)) === null || _a === void 0 ? void 0 : _a.then(function (data) {
                var dataSource = requestConfig.formatResult(data);
                return Array.isArray(dataSource) ? dataSource : [];
            });
        };
    }
    var _k = useCnRequest(cnRequestConfig), run = _k.run, runAsync = _k.runAsync, error = _k.error, data = _k.data, loading = _k.loading, mutate = _k.mutate;
    React.useImperativeHandle(ref, function () {
        var _a;
        return __assign({ mutateDataSource: mutate, sendRequest: run, 
            // 为了给外部正确使用 forceUpdate
            forceUpdate: function () {
                forceUpdate(function (s) { return (s + 1) % 32; });
            } }, ((_a = selectRef.current) !== null && _a !== void 0 ? _a : {}));
    }, [selectRef]);
    var shouldShowCheckAll = showCheckAll && (props.multiple || props.treeCheckable);
    var maxTagPlaceholder = function (selectedValues) {
        var trigger = (React.createElement("span", { className: "cn-next-select-tag-compact-inner" },
            $i18n.get({ id: 'Selected', dm: '已选择', ns: 'CnAsyncSelect' }), "".concat(selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues.length),
            $i18n.get({
                id: 'APOLLO_X.Item.import.CNTM',
                dm: '项',
                ns: 'CnAsyncSelect',
            })));
        var labels = selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues.map(function (obj) {
            if (typeof obj === 'object') {
                return obj.label;
            }
            return obj;
        });
        return (React.createElement(CnTooltip, { trigger: trigger }, labels && labels.join(', ')));
    };
    React.useEffect(function () {
        // existValues !== props.value，则清空
        if (!('value' in props))
            return;
        var ev = existValues.current;
        ev = Array.isArray(ev) ? ev.map(function (i) { return i.value; }) : ev === null || ev === void 0 ? void 0 : ev.value;
        if (!isEqual(ev, props.value)) {
            existValues.current = undefined;
        }
    }, [props.value]);
    React.useEffect(function () {
        if (otherProps === null || otherProps === void 0 ? void 0 : otherProps.children)
            return;
        if (loading)
            return;
        setInnerDataSource(function (previousDataSource) {
            var _a;
            var rst = [];
            var ev = existValues.current;
            if (enableRemoteLazyLoad) {
                if (!((_a = remoteLazyLoadItemRef.current) === null || _a === void 0 ? void 0 : _a.value)) {
                    return data;
                }
                var valueItem = findDeep(previousDataSource, 'value', remoteLazyLoadItemRef.current.value);
                if (valueItem) {
                    valueItem.children = data;
                }
                return __spreadArray([], previousDataSource, true);
            }
            if (Array.isArray(ev)) {
                ev.length > 0 &&
                    ev.map(function (i) {
                        if (!findDeep(data, 'value', i.value)) {
                            rst.push({
                                value: i.value,
                                label: i.label,
                            });
                        }
                    });
                return data.concat(rst);
            }
            if (ev && !findDeep(data, 'value', ev.value)) {
                rst.push({
                    value: ev.value,
                    label: ev.label,
                });
                return data.concat(rst);
            }
            return data;
        });
    }, [data]);
    // treenode形式兼容
    if (!(otherProps === null || otherProps === void 0 ? void 0 : otherProps.children)) {
        insertSelectProps.dataSource = innerDataSource || [];
    }
    if (isRemoteDataSource) {
        insertSelectProps.autoWidth = false;
    }
    var errorInfo = handleErrorInfo(error);
    var loadingInfo = handleLoading(loading, data);
    Object.assign(insertSelectProps, errorInfo, loadingInfo);
    // 开启远程异步加载数据时，关闭搜索功能
    if (enableRemoteLazyLoad) {
        otherProps.showSearch = false;
        otherProps.treeLoadData = function (_a) {
            var _b;
            var item = _a.props;
            remoteLazyLoadItemRef.current = item;
            return runAsync((_b = {},
                _b[requestConfig.remoteLazyLoadKey || 'value'] = item.value,
                _b)).catch(function (err) {
                console.log(err);
            });
        };
    }
    // onSearch 自动包装
    // 仅在 showSearch 且 filterLocal 为 false 情况下启用
    var enableOnSearch = otherProps.showSearch &&
        !otherProps.onSearch &&
        otherProps.filterLocal === false;
    if (enableOnSearch) {
        insertSelectProps.onSearch = function (inputValue) {
            var _a;
            setSearching(true);
            run((_a = {},
                _a[requestConfig.searchKey || 'key'] = inputValue,
                _a));
        };
    }
    var onVisibleChange = function (visible, type) {
        if (shouldShowCheckAll) {
            setTimeout(function () {
                forceUpdate(function (s) { return (s + 1) % 32; });
            });
        }
        if (typeof onVisibleChangeProps === 'function') {
            onVisibleChangeProps(visible, type, run);
        }
    };
    var onSearchClear = function (actionType) {
        if (typeof onSearchClearProps === 'function') {
            onSearchClearProps(actionType, run);
        }
    };
    var onChange = function (v, d) {
        var _a;
        if (!isControlled) {
            setValue(v);
        }
        checkAllValuesRef.current = getRealCheckAllValues();
        setCheckAll(isEqual(sortBy(v), sortBy(checkAllValuesRef.current)));
        if (isRemoteDataSource) {
            existValues.current = d;
            if (!v || !v.length) {
                run((_a = {},
                    _a[requestConfig.searchKey || 'key'] = '',
                    _a));
            }
        }
        if (typeof onChangeProps === 'function') {
            onChangeProps(v, d);
        }
    };
    var onSearch = function (searchedValue) {
        setSearching(true);
        if (typeof onSearchProps === 'function') {
            onSearchProps(searchedValue);
        }
    };
    // 获取处理后的全选值
    var getRealCheckAllValues = function () {
        var _a, _b;
        if (!shouldShowCheckAll)
            return [];
        var checkAllValues = getCheckAllValues((_a = treeRef.current) === null || _a === void 0 ? void 0 : _a.treeChildren);
        if (props.treeCheckable && !props.treeCheckStrictly) {
            checkAllValues = (_b = selectRef.current) === null || _b === void 0 ? void 0 : _b.getValueForSelect(checkAllValues);
        }
        return checkAllValues;
    };
    React.useEffect(function () {
        // onSearch 触发并 rerender 之后才能获取实际的 popup item
        if (!searching)
            return;
        setSearching(false);
        checkAllValuesRef.current = getRealCheckAllValues();
        setCheckAll(isEqual(sortBy(value), sortBy(checkAllValuesRef.current)));
    }, [searching, value]);
    var handleClickCheckAll = function (checked) {
        var _a;
        setCheckAll(checked);
        if (!shouldShowCheckAll || !treeRef.current) {
            return;
        }
        if (checked) {
            checkAllValuesRef.current = getRealCheckAllValues();
            onChange(checkAllValuesRef.current, (_a = selectRef.current) === null || _a === void 0 ? void 0 : _a.getData(checkAllValuesRef.current));
        }
        else {
            onChange([], []);
        }
    };
    var renderPreview = function (values) {
        return (React.createElement(CnReadOnly, __assign({ value: values || value, valueSeparator: " / " }, __assign(__assign({}, pick(props, [
            'addonBefore',
            'innerBefore',
            'addonTextBefore',
            'addonTextAfter',
            'innerAfter',
            'addonAfter',
        ])), readOnlyProps))));
    };
    var minWidth = ((_d = (_c = (_b = (_a = selectRef === null || selectRef === void 0 ? void 0 : selectRef.current) === null || _a === void 0 ? void 0 : _a.select) === null || _b === void 0 ? void 0 : _b._instance) === null || _c === void 0 ? void 0 : _c.selectDOM) === null || _d === void 0 ? void 0 : _d.offsetWidth) || 0;
    var cnTreeProps = __assign(__assign({}, treeProps), { ref: function (r) {
            treeRef.current = r;
        } });
    var isCheckAll = React.useMemo(function () {
        if (!shouldShowCheckAll)
            return false;
        checkAllValuesRef.current = getRealCheckAllValues();
        return isEqual(sortBy(value), sortBy(checkAllValuesRef.current));
    }, [value, treeRef.current]);
    if (shouldShowCheckAll) {
        cnTreeProps.footer = (React.createElement(CnCheckbox, { checked: isCheckAll, onChange: handleClickCheckAll, label: $i18n.get({ id: 'SelectAll', dm: '全选', ns: 'CnTreeSelect' }) }));
    }
    var popupClassName = popupClassNameProps;
    if (cnTreeProps.header) {
        popupClassName = cx(popupClassName, {
            'with-header': true,
        });
    }
    if (cnTreeProps.footer) {
        popupClassName = cx(popupClassName, {
            'with-footer': true,
        });
    }
    return (React.createElement(NextTreeSelect, __assign({ "data-name": "CnTreeSelect", className: cx({
            'cn-ui-tree-select': true,
        }, className), ref: function (r) {
            selectRef.current = r;
        }, preserveNonExistentValue: isRemoteDataSource, isPreview: readOnly, renderPreview: renderPreview, maxTagPlaceholder: maxTagPlaceholder, onVisibleChange: onVisibleChange, onChange: onChange, onSearchClear: onSearchClear, onSearch: onSearch, popupClassName: popupClassName, treeProps: cnTreeProps }, insertSelectProps, omit(otherProps, ['$i18n']), { value: value, popupStyle: {
            minWidth: minWidth,
        } })));
}), {
    componentName: 'CnTreeSelect',
    locale: locale,
    forwardRef: true,
});
CnTreeSelect.displayName = 'CnTreeSelect';
CnTreeSelect.defaultProps = {
    maxTagCount: 2,
    tagInline: true,
    requestConfig: {},
    readOnlyProps: {
        valueSeparator: ' / ',
        type: 'enum',
    },
};
