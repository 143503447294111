import { __assign } from "tslib";
import * as React from 'react';
import { useParentForm } from '@formily/react';
import { Dialog } from '@/components/fusion';
import { CnIcon } from '@/components/cn-icon';
import { sendLog } from '@/components/cn-utils';
import { CnDragTransfer } from '@/components/cn-drag-transfer';
import { defaultDragZone } from '../../const';
import { useFilterRefsContext } from '../../context';
export default (function (_a) {
    var enableSaveSelected = _a.enableSaveSelected, dataSource = _a.dataSource, updateDataSource = _a.updateDataSource, setOverlayVisible = _a.setOverlayVisible, configInited = _a.configInited, configValue = _a.configValue, overlayVisible = _a.overlayVisible, gridProps = _a.gridProps, saveSelectSpan = _a.saveSelectSpan, $i18n = _a.$i18n;
    var _b = React.useState({}), dragDataSource = _b[0], setDragDataSource = _b[1];
    var filterContext = useFilterRefsContext();
    var form = useParentForm();
    React.useEffect(function () {
        var newDefault = [];
        var newMore = [];
        if (enableSaveSelected) {
            newDefault.push({
                label: $i18n.get({
                    id: 'QueryCustomaryArea',
                    dm: '查询习惯区',
                    ns: 'CnFilter',
                }),
                span: saveSelectSpan || 1,
                disabled: true,
                className: 'cn-ui-filter-config-modal-query',
            });
        }
        dataSource.forEach(function (i) {
            if (i.visible) {
                newDefault.push(i);
            }
            else {
                newMore.push(i);
            }
        });
        setDragDataSource({
            default: newDefault,
            more: newMore,
        });
    }, [dataSource, enableSaveSelected, saveSelectSpan]);
    var handleConfigOk = function () {
        sendLog({
            id: 'cn-ui.cn-filter.clickConfigOkButton',
        });
        var newDataSource = [];
        newDataSource = defaultDragZone.reduce(function (p, _a) {
            var _b;
            var k = _a.key;
            return ((_b = dragDataSource === null || dragDataSource === void 0 ? void 0 : dragDataSource[k]) === null || _b === void 0 ? void 0 : _b.length)
                ? p.concat(dragDataSource[k].map(function (i) { return (__assign(__assign({}, i), { visible: k === 'default' })); }))
                : p;
        }, []);
        newDataSource = newDataSource.filter(function (i) { return !i.disabled; });
        updateDataSource(newDataSource);
        setOverlayVisible(false);
    };
    return (React.createElement(Dialog, { v2: true, width: "80%", className: "cn-ui-filter-config-modal", visible: overlayVisible, onClose: function () { return setOverlayVisible(false); }, title: $i18n.get({
            id: 'configPanelTitle',
            dm: '筛选设置',
        }), closeMode: ['close', 'esc', 'mask'], onOk: handleConfigOk, onCancel: function () { return setOverlayVisible(false); }, footerActions: ['ok', 'cancel'] },
        React.createElement("div", { className: "cn-ui-filter-config-modal-info" },
            React.createElement(CnIcon, { type: "icon-info-r-fill", className: "info-icon" }),
            $i18n.get({
                id: 'OptionsBlocksInTheAreasWhere_84475068',
                dm: '已添加筛选项、未添加筛选项区域内选项块可以相互拖拽，或通过添加和移除图标按钮操作，未添加筛选项将不会出现在筛选器内',
                ns: 'CnFilter',
            })),
        React.createElement(CnDragTransfer, { dataSource: dragDataSource, onChange: function (v) {
                sendLog({
                    id: 'cn-ui.cn-filter.changeConfigItem',
                });
                setDragDataSource(v);
            }, gridProps: gridProps, dragZone: defaultDragZone })));
});
