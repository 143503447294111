import { __assign, __rest } from "tslib";
import React, { useRef, useState, useCallback, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import mapKeys from 'lodash/mapKeys';
import cloneDeepWith from 'lodash/cloneDeepWith';
import get from 'lodash/get';
import { getRequest, request } from 'cn-request';
import queryString from 'query-string';
export { generateEffects } from './generateEffects';
export var cloneDeepWithReactElement = function (source) {
    return cloneDeepWith(source, function (value) {
        if (React.isValidElement(value)) {
            return value;
        }
    });
};
var SchemaMap = {
    initialValue: 'default',
    display: 'x-display',
    component: 'x-component',
    uipattern: 'x-pattern',
    dataSource: 'enum',
    reactions: 'x-reactions',
    validator: 'x-validator',
    index: 'x-index',
    decorator: 'x-decorator',
    content: 'x-content',
    visible: 'x-visible',
    hidden: 'x-hidden',
    disabled: 'x-disabled',
    editable: 'x-editable',
    readOnly: 'x-read-only',
    readPretty: 'x-read-pretty',
    data: 'x-data',
    'decorator-props': 'x-decorator-props',
    decoratorProps: 'x-decorator-props',
    xDecoratorProps: 'x-decorator-props',
    'component-props': 'x-component-props',
    componentProps: 'x-component-props',
    xComponentProps: 'x-component-props',
};
var CnCard = 'CnCard';
var CnCardSubCard = 'CnCardSubCard';
var CnFormGrid = 'CnFormGrid';
var ExtraSchema = { 'x-decorator': 'CnFormItem' };
var formattedSchemaKeys = function (obj) {
    return mapKeys(obj, function (value, key) { var _a; return (_a = SchemaMap === null || SchemaMap === void 0 ? void 0 : SchemaMap[key]) !== null && _a !== void 0 ? _a : key; });
};
var generateFormItemSchema = function (item, cols) {
    var _a, _b;
    var itemConfig = formattedSchemaKeys(item);
    var name = itemConfig.name, title = itemConfig.title, _c = itemConfig["x-component-props"], componentProps = _c === void 0 ? {} : _c, restProps = __rest(itemConfig, ["name", "title", 'x-component-props']);
    var subCardCols = componentProps.cols;
    var formGridProps = {};
    if (subCardCols) {
        formGridProps.cols = subCardCols;
    }
    else {
        formGridProps.cols = cols;
    }
    if (item.component === CnCard) {
        return __assign({ type: 'void', 'x-component': CnCard, 'x-component-props': __assign({ title: title, foldable: true, style: { marginBottom: '16px' } }, componentProps), properties: (_a = {},
                _a["".concat(name, "_").concat(CnFormGrid)] = {
                    type: 'void',
                    'x-component': CnFormGrid,
                    'x-component-props': formGridProps,
                    properties: {},
                },
                _a) }, restProps);
    }
    else if (item.component === CnCardSubCard) {
        return __assign({ type: 'void', 'x-component': CnCardSubCard, 'x-component-props': __assign({ subTitle: title }, componentProps), properties: (_b = {},
                _b["".concat(name, "_").concat(CnFormGrid)] = {
                    type: 'void',
                    'x-component': CnFormGrid,
                    'x-component-props': formGridProps,
                    properties: {},
                },
                _b) }, restProps);
    }
    else {
        return __assign(__assign({}, ExtraSchema), itemConfig);
    }
};
var list2FormilySchema = function (list, cols) {
    var _a;
    var currentParent = 'root';
    var tree = {
        root: {
            type: 'object',
            properties: (_a = {},
                _a["root_".concat(CnFormGrid)] = {
                    type: 'void',
                    'x-component': CnFormGrid,
                    'x-component-props': {
                        cols: cols,
                    },
                    properties: {},
                },
                _a),
            _component: 'root',
            _name: 'root',
        },
    };
    if (Array.isArray(list) && list.length > 0) {
        var _list = cloneDeepWithReactElement(list);
        var ifContainer_1 = function (component) {
            return component === 'CnCard' || component === 'CnCardSubCard';
        };
        _list.forEach(function (item, index) {
            var _a;
            if (item === void 0) { item = {}; }
            var component = item.component;
            var isContainer = ifContainer_1(component);
            var parent = tree[currentParent];
            if (isContainer) {
                item.name = "container_".concat(component, "_").concat(index);
            }
            var name = item.name;
            if (isContainer) {
                if (component === CnCard) {
                    tree[name] = generateFormItemSchema(item, cols);
                    tree[name]._parent = 'root';
                }
                else if (component === CnCardSubCard) {
                    if ((parent === null || parent === void 0 ? void 0 : parent._component) === CnCard || (parent === null || parent === void 0 ? void 0 : parent._component) === 'root') {
                        tree[name] = generateFormItemSchema(item, cols);
                        if (tree[name]) {
                            tree[name]._parent = currentParent;
                        }
                    }
                    else if ((parent === null || parent === void 0 ? void 0 : parent._component) === CnCardSubCard) {
                        var temp = (parent === null || parent === void 0 ? void 0 : parent._parent) || 'root';
                        tree[name] = generateFormItemSchema(item, cols);
                        if (tree[name]) {
                            tree[name]._parent = tree[temp]._name || 'root';
                        }
                    }
                }
                // 先判断是否是「容器」，是的话，parant指针指向最近的容器。那么下一个input的_parent就会指向最近的容器
                currentParent = name;
            }
            else {
                tree[name] = generateFormItemSchema(item, cols);
                if (tree[name]) {
                    tree[name]._parent = currentParent;
                }
            }
            if (tree[name]) {
                tree[name]._name = name;
                tree[name]._component = component;
            }
            if ((_a = tree[name]) === null || _a === void 0 ? void 0 : _a._parent) {
                var parentSchema = tree[tree[name]._parent];
                if (parentSchema._component === CnCardSubCard) {
                    var _name = parentSchema._name;
                    var formGrid = parentSchema.properties["".concat(_name, "_").concat(CnFormGrid)];
                    if (formGrid === null || formGrid === void 0 ? void 0 : formGrid.properties) {
                        formGrid.properties[name] = tree[name];
                    }
                }
                else if (parentSchema._component === 'root' && !isContainer) {
                    var formGrid = parentSchema.properties["root_".concat(CnFormGrid)];
                    if (formGrid === null || formGrid === void 0 ? void 0 : formGrid.properties) {
                        formGrid.properties[name] = tree[name];
                    }
                }
                else if (parentSchema._component === CnCard) {
                    if (component === CnCardSubCard) {
                        parentSchema.properties[name] = tree[name];
                    }
                    else {
                        var _name = parentSchema._name;
                        var formGrid = parentSchema.properties["".concat(_name, "_").concat(CnFormGrid)];
                        if (formGrid === null || formGrid === void 0 ? void 0 : formGrid.properties) {
                            formGrid.properties[name] = tree[name];
                        }
                    }
                }
                else {
                    parentSchema.properties[name] = tree[name];
                }
            }
        });
    }
    return tree.root;
};
export var generateSchema = function (schema, cols, onlyProperties) {
    if (onlyProperties === void 0) { onlyProperties = false; }
    if (isEmpty(schema))
        return {};
    if (!isArray(schema))
        return schema;
    var finalSchema = list2FormilySchema(schema, cols);
    if (onlyProperties) {
        return {
            type: 'void',
            colSpan: 'full',
            properties: finalSchema.properties,
        };
    }
    return finalSchema;
};
var packRequest = function (options) {
    if (!getRequest()) {
        console.error('组件使用默认实例中...请先初始化项目cn-request,切勿发布到生产环境');
    }
    return request(options).then(function (result) {
        if ('request' in result && 'config' in result) {
            return result.data;
        }
        return result;
    });
};
export var handleRequestService = function (requestConfig) {
    var _a = requestConfig || {}, url = _a.url, service = _a.service, paramsProps = _a.params, dataProps = _a.data, _b = _a.searchFormat, searchFormat = _b === void 0 ? function (data) { return data; } : _b, _c = _a.method, method = _c === void 0 ? 'GET' : _c, _d = _a.headers, headers = _d === void 0 ? {} : _d;
    if (service) {
        return service;
    }
    if (url) {
        return function (searchConfig) {
            var formValues = (searchConfig || {}).formValues;
            var realDataProps = dataProps;
            // application/x-www-form-urlencoded时，parse为对象后再进行解构；否则会是键值对字符串，导致解构出错
            if ((headers === null || headers === void 0 ? void 0 : headers['content-type']) === 'application/x-www-form-urlencoded') {
                realDataProps = queryString.parse(dataProps);
            }
            var params = (method === null || method === void 0 ? void 0 : method.toLocaleUpperCase()) === 'GET'
                ? searchFormat(__assign(__assign({}, formValues), paramsProps))
                : paramsProps;
            var data = (method === null || method === void 0 ? void 0 : method.toLocaleUpperCase()) === 'POST'
                ? searchFormat(__assign(__assign({}, formValues), realDataProps))
                : realDataProps;
            return packRequest(__assign(__assign({}, requestConfig), { params: params, data: data, url: url }));
        };
    }
    return function () { return Promise.resolve([]); };
};
export function useStateCallback(initialState) {
    var _a = useState(initialState), state = _a[0], setState = _a[1];
    var cbRef = useRef(null);
    var setStateCallback = useCallback(function (_state, cb) {
        cbRef.current = cb;
        setState(_state);
    }, []);
    useEffect(function () {
        if (cbRef.current) {
            cbRef.current(state);
            cbRef.current = null;
        }
    }, [state]);
    return [state, setStateCallback];
}
export function useIsExceedWidth(width) {
    var _a = useState(document.documentElement.clientWidth >= width), isExceed = _a[0], setIsExceed = _a[1];
    var resizeHandle = useCallback(function () {
        setIsExceed(document.documentElement.clientWidth >= width);
    }, []);
    useEffect(function () {
        window.addEventListener('resize', resizeHandle);
        return function () { return window.removeEventListener('resize', resizeHandle); };
    }, []);
    return isExceed;
}
export var getTargetArray = function (source, checkList, checkSource) {
    if (checkList === void 0) { checkList = []; }
    if (checkSource === void 0) { checkSource = true; }
    var result = [];
    if (checkSource) {
        if (isArray(source))
            return source;
    }
    for (var i = 0; i < checkList.length; i++) {
        var targetValue = get(source, checkList[i]);
        if (isArray(targetValue)) {
            result = targetValue;
            break;
        }
    }
    return result;
};
