import booleanRender from './render/boolean-render';
import enumRender from './render/enum-render';
import numberRender from './render/number-render';
import stringRender from './render/string-render';
import isEmpty from './utils/is-empty';
import getValueType from './utils/get-value-type';
export var cnReadOnlyRender = {
    booleanRender: booleanRender,
    enumRender: enumRender,
    numberRender: numberRender,
    stringRender: stringRender,
    isEmpty: isEmpty,
    getValueType: getValueType,
};
