import cnRequest from 'cn-request';
var request = cnRequest({ timeout: 5000 }, {
    // 此处为业务的 trace
    armsTrace: window.trace,
});
request.interceptors.request.use(function (config) {
    var _a;
    if (window.REQUEST_ENV === 'local' && ((_a = config.params) === null || _a === void 0 ? void 0 : _a.isCommonRequest)) {
        // isCommonRequest only use for middleware
        // delete config.params.isCommonRequest;
        // config.url = `https://pre-tars2.cainiao.com${config.url}`;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});
export default request;
