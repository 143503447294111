/* eslint-disable @typescript-eslint/no-unsafe-return */
export function isFunction(val) {
    return val instanceof Function;
}
export function isNumber(o) {
    return Object.prototype.toString.call(o) === '[object Number]';
}
export function getValueByPath(source, path, defaultValue) {
    return path.reduce(function (ret, nextKey) { var _a; return (_a = ret === null || ret === void 0 ? void 0 : ret[nextKey]) !== null && _a !== void 0 ? _a : defaultValue; }, source);
}
export var isDef = function (val) {
    return val !== undefined && val !== null;
};
export var noop = function (val) { return val; };
export var getTransform = function (source, defaultFunc) {
    if (defaultFunc === void 0) { defaultFunc = noop; }
    return isFunction(source) ? source : defaultFunc;
};
export var getArrayMap = function (source, defaultFunc) {
    if (defaultFunc === void 0) { defaultFunc = noop; }
    return Array.isArray(source)
        ? // @ts-ignore
            source.map(function (item, index, arr) { return defaultFunc(item, index, arr); })
        : [];
};
export function getColumnCode(item) {
    var _a, _b, _c;
    return (_c = (_b = (_a = item.dataIndex) !== null && _a !== void 0 ? _a : item.key) !== null && _b !== void 0 ? _b : item.code) !== null && _c !== void 0 ? _c : '';
}
export function getParent(dom, match, maxLevel) {
    if (maxLevel === void 0) { maxLevel = 5; }
    if (dom && isFunction(match) && maxLevel > 0) {
        var level = 0;
        var nowDom = dom;
        while (level < maxLevel) {
            nowDom = nowDom === null || nowDom === void 0 ? void 0 : nowDom.parentElement;
            if (match(nowDom)) {
                return nowDom;
            }
            level++;
        }
    }
    return dom;
}
