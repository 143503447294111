import { LOOPING_EXPORT_JOB, LOOPING_IMPORT_JOB, } from '@/components/cn-async-jobs-pro/constant';
import { events } from '@/components/cn-utils';
import { useEffect } from 'react';
export default function useStartPolling(props) {
    var visible = props.visible, type = props.type, startTaskLoop = props.startTaskLoop;
    useEffect(function () {
        if (type === 'export') {
            events.on(LOOPING_EXPORT_JOB, startTaskLoop);
        }
        if (type === 'import') {
            events.on(LOOPING_IMPORT_JOB, startTaskLoop);
        }
        return function () {
            if (type === 'export') {
                events.off(LOOPING_EXPORT_JOB, startTaskLoop);
            }
            if (type === 'import') {
                events.off(LOOPING_IMPORT_JOB, startTaskLoop);
            }
        };
    }, [type, startTaskLoop]);
    useEffect(function () {
        if (visible)
            startTaskLoop();
    }, [visible, startTaskLoop]);
}
