import { __assign, __spreadArray } from "tslib";
import $i18n from 'panda-i18n';
import cx from 'classnames';
import React from 'react';
import { ExpansionCell, icons, InlineFlexCell } from '../../dep';
import { treeMetaSymbol, isSysFixedCol } from '../../global';
import { mergeCellProps, safeRender, safeGetCellProps, safeRenderHeader, renderPre, } from '../../utils';
import isLeafNodeStandard from './is-leaf-node-standard';
export function treeModePipeline(opts) {
    if (opts === void 0) { opts = {}; }
    return function treeModeStep(pipeline) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        var stateKey = 'treeMode';
        var ctx = pipeline.ctx;
        // 获取 primaryKey
        var primaryKey = pipeline.ensurePrimaryKey('treeMode');
        if (typeof primaryKey !== 'string') {
            throw new Error($i18n.get({
                id: 'TreeModeOnlySupportsStringsAsPrimaryKey_49364134',
                dm: 'treeMode 仅支持字符串作为 primaryKey',
                ns: 'CnBaseTable',
            }));
        }
        // 获取展开的 keys
        var openKeys = (_c = (_b = (_a = opts.openKeys) !== null && _a !== void 0 ? _a : pipeline.getStateAtKey(stateKey)) !== null && _b !== void 0 ? _b : opts.defaultOpenKeys) !== null && _c !== void 0 ? _c : [];
        // 获取去重的 openKeys
        var openKeySet = new Set(openKeys);
        var onChangeOpenKeys = function (nextKeys, key, action, row) {
            var _a;
            (_a = opts.onChangeOpenKeys) === null || _a === void 0 ? void 0 : _a.call(opts, nextKeys, key, action, row);
            pipeline.setStateAtKey(stateKey, nextKeys, { key: key, action: action });
        };
        var keyRowMap = {};
        var toggle = function (rowKey) {
            var expanded = openKeySet.has(rowKey);
            if (expanded) {
                onChangeOpenKeys(openKeys.filter(function (key) { return key !== rowKey; }), rowKey, 'collapse', keyRowMap[rowKey]);
            }
            else {
                onChangeOpenKeys(__spreadArray(__spreadArray([], openKeys, true), [rowKey], false), rowKey, 'expand', keyRowMap[rowKey]);
            }
        };
        var isLeafNode = (_d = opts.isLeafNode) !== null && _d !== void 0 ? _d : isLeafNodeStandard;
        var clickArea = (_e = opts.clickArea) !== null && _e !== void 0 ? _e : 'cell';
        var treeMetaKey = (_f = opts.treeMetaKey) !== null && _f !== void 0 ? _f : treeMetaSymbol;
        var stopClickEventPropagation = Boolean(opts.stopClickEventPropagation);
        // indents
        var iconWidth = ctx.indents.iconWidth;
        var iconIndent = (_g = opts.iconIndent) !== null && _g !== void 0 ? _g : ctx.indents.iconIndent;
        var iconGap = (_h = opts.iconGap) !== null && _h !== void 0 ? _h : ctx.indents.iconGap;
        var indentSize = (_j = opts.indentSize) !== null && _j !== void 0 ? _j : ctx.indents.indentSize;
        return pipeline.mapDataSource(processDataSource).mapColumns(processColumns);
        function processDataSource(input) {
            var result = [];
            dfs(input, 0, '');
            function dfs(nodes, depth, parentPre) {
                var _a, _b;
                if (parentPre === void 0) { parentPre = ''; }
                if (nodes == null) {
                    return;
                }
                for (var _i = 0, nodes_1 = nodes; _i < nodes_1.length; _i++) {
                    var node = nodes_1[_i];
                    var rowKey = node[primaryKey];
                    keyRowMap[rowKey] = node;
                    var expanded = openKeySet.has(rowKey);
                    var isLeaf = isLeafNode(node, { depth: depth, expanded: expanded, rowKey: rowKey });
                    var treeMeta = { depth: depth, isLeaf: isLeaf, expanded: expanded, rowKey: rowKey };
                    result.push(__assign(__assign((_a = {}, _a[treeMetaKey] = treeMeta, _a), node), (_b = {}, _b[renderPre] = "".concat(parentPre, "-tree_level_").concat(depth), _b)));
                    if (!isLeaf && expanded) {
                        dfs(node.children, depth + 1, "".concat(parentPre, "-tree_level_").concat(depth).concat(rowKey));
                    }
                }
            }
            return result;
        }
        function processColumns(columns) {
            if (columns.length === 0) {
                return columns;
            }
            var sycFixedCols = [];
            var restCols = [];
            columns.forEach(function (col) {
                if (isSysFixedCol(col.symbol)) {
                    sycFixedCols.push(col);
                }
                else {
                    restCols.push(col);
                }
            });
            if (restCols.length === 0) {
                return restCols;
            }
            var firstCol = restCols[0], others = restCols.slice(1);
            var render = function (value, record, recordIndex) {
                var content = safeRender(firstCol, record, recordIndex);
                if (record[treeMetaKey] == null) {
                    // 没有 treeMeta 信息的话，就返回原先的渲染结果
                    return content;
                }
                var _a = record[treeMetaKey], rowKey = _a.rowKey, depth = _a.depth, isLeaf = _a.isLeaf, expanded = _a.expanded;
                var indent = iconIndent + depth * indentSize;
                if (isLeaf) {
                    // if (depth !== 0) {
                    //   return content;
                    // }
                    return (React.createElement(InlineFlexCell, { className: "expansion-cell leaf" },
                        React.createElement("span", { style: { marginLeft: indent + iconWidth + iconGap } }, content)));
                }
                var onClick = function (e) {
                    if (stopClickEventPropagation) {
                        e.stopPropagation();
                    }
                    toggle(rowKey);
                };
                var expandCls = expanded ? 'expanded' : 'collapsed';
                return (React.createElement(ExpansionCell, { className: cx('expansion-cell', expandCls), style: {
                        cursor: clickArea === 'content' ? 'pointer' : undefined,
                    }, onClick: clickArea === 'content' ? onClick : undefined },
                    React.createElement(icons.CaretRight, { className: cx('expansion-icon', expandCls), style: {
                            cursor: clickArea === 'icon' ? 'pointer' : undefined,
                            marginLeft: indent,
                            marginRight: iconGap,
                        }, onClick: clickArea === 'icon' ? onClick : undefined }),
                    content));
            };
            var getCellProps = function (value, record, rowIndex) {
                var prevProps = safeGetCellProps(firstCol, record, rowIndex);
                if (record[treeMetaKey] == null) {
                    // 没有 treeMeta 信息的话，就返回原先的 cellProps
                    return prevProps;
                }
                var _a = record[treeMetaKey], isLeaf = _a.isLeaf, rowKey = _a.rowKey;
                if (isLeaf) {
                    return prevProps;
                }
                return mergeCellProps(prevProps, {
                    onClick: function (e) {
                        if (stopClickEventPropagation) {
                            e.stopPropagation();
                        }
                        toggle(rowKey);
                    },
                    style: { cursor: 'pointer' },
                });
            };
            return __spreadArray(__spreadArray(__spreadArray([], sycFixedCols, true), [
                __assign(__assign({}, firstCol), { title: (React.createElement("span", { style: {
                            paddingLeft: iconIndent + iconWidth + iconGap,
                            display: 'flex',
                            alignItems: 'center',
                        } }, safeRenderHeader(firstCol))), render: render, getCellProps: clickArea === 'cell' ? getCellProps : firstCol.getCellProps })
            ], false), others, true);
        }
    };
}
