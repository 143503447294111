import { __assign } from "tslib";
import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import compact from 'lodash/compact';
import { cloneElementStyle, getPropsField } from './utils';
import './index.scss';
var DEFAULT_COLS = 4;
export var CnFormGrid = function (_a) {
    var style = _a.style, gridItemStyle = _a.gridItemStyle, columnGap = _a.columnGap, rowGap = _a.rowGap, slot = _a.slot, propsCols = _a.cols, area = _a.area, className = _a.className, children = _a.children, propResponsive = _a.responsive, onColumnChange = _a.onColumnChange;
    var responsive = !propsCols ? true : propResponsive;
    var _b = useState(propsCols), cols = _b[0], setCols = _b[1];
    var gridRef = useRef();
    var resizeObserverRef = useRef();
    var observerStatus = useRef({ setted: false });
    useEffect(function () {
        var _a;
        if (responsive && !observerStatus.current.setted) {
            resizeObserverRef.current = new ResizeObserver(function (enties) {
                for (var _i = 0, enties_1 = enties; _i < enties_1.length; _i++) {
                    var oGrid = enties_1[_i];
                    var width = oGrid.target.clientWidth;
                    var targetCols = DEFAULT_COLS;
                    if (width <= 428) {
                        targetCols = 1;
                    }
                    else if (width > 428 && width <= 894) {
                        targetCols = 2;
                    }
                    else if (width > 894 && width <= 1494) {
                        targetCols = 4;
                    }
                    else if (width > 1494) {
                        targetCols = 6;
                    }
                    if (targetCols !== cols) {
                        onColumnChange(targetCols);
                    }
                    setCols(targetCols);
                }
            });
            resizeObserverRef.current.observe(gridRef.current);
            observerStatus.current.setted = true;
        }
        else if (!responsive) {
            (_a = resizeObserverRef === null || resizeObserverRef === void 0 ? void 0 : resizeObserverRef.current) === null || _a === void 0 ? void 0 : _a.unobserve(gridRef.current);
            observerStatus.current.setted = false;
        }
    }, [responsive, cols]);
    useEffect(function () {
        if (!responsive) {
            setCols(propsCols);
        }
    }, [propsCols]);
    return (React.createElement("div", { "data-name": "CnFormGrid", style: __assign(__assign({ gridTemplateColumns: "repeat(".concat(cols, ", minmax(0, 1fr) [col-start])"), gridColumnGap: columnGap, gridRowGap: rowGap }, style), { visibility: cols === undefined ? 'hidden' : 'visible' }), ref: gridRef, className: cn('cn-ui-grid', className, responsive && 'responsive') },
        React.Children.map(compact(React.Children.toArray(children)), function (item, index) {
            var _a = item.props, colSpan = _a.colSpan, rowSpan = _a.rowSpan, hidden = _a.hidden;
            var areaIndexData = [].concat(area === null || area === void 0 ? void 0 : area[index]);
            var gridItemRow = rowSpan || (areaIndexData === null || areaIndexData === void 0 ? void 0 : areaIndexData[1]) || 1;
            var gridItemColumn = colSpan || (areaIndexData === null || areaIndexData === void 0 ? void 0 : areaIndexData[0]) || 1;
            var originItemStyle = getPropsField(item, 'style');
            var gridStyle = __assign(__assign(__assign({}, (originItemStyle || {})), gridItemStyle), { display: hidden ? 'none' : (originItemStyle === null || originItemStyle === void 0 ? void 0 : originItemStyle.display) || null, gridRow: "span ".concat(gridItemRow), gridColumn: "span ".concat(
                // eslint-disable-next-line no-nested-ternary
                gridItemColumn === 'full'
                    ? cols
                    : gridItemColumn > cols
                        ? cols
                        : gridItemColumn) });
            return cloneElementStyle(item, gridStyle);
        }),
        slot));
};
CnFormGrid.defaultProps = {
    columnGap: 16,
    rowGap: 0,
    responsive: false,
    onColumnChange: function () { },
};
CnFormGrid.displayName = 'Grid';
