import { __spreadArray } from "tslib";
import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import formatColumns from './utils/format-columns';
import getColumnsKey from './utils/get-columns-key';
import { ColumnsStateContext } from './context';
/**
 * 列配置上下文组件
 * @export
 * @param {{
 *   isDialog?: boolean;
 *   columns: ICnTableColumn[];
 *   setColumns: (columns: ICnTableColumn[], type?: string) => void;
 *   children: React.ReactNode;
 * }} props
 * @return {*}
 */
export function ColumnsSettingContextProvider(props) {
    var isDialog = props.isDialog, originColumns = props.columns, setOriginColumns = props.setColumns;
    /**
     * 格式化表格列配置
     * @type {[ICnTableColumn[], Dispatch<SetStateAction<ICnTableColumn[]>>]}
     */
    var _a = useState(formatColumns(originColumns)), columns = _a[0], setColumns = _a[1];
    /**
     * 已选中的列的键名
     * @type {string[]}
     */
    var checkedColumnKeys = useMemo(function () {
        return columns.reduce(function (result, item) {
            if (item.hidden)
                return result;
            return __spreadArray(__spreadArray([], result, true), [item.dataIndex], false);
        }, []);
    }, [columns]);
    /**
     * 当原始表格列配置变更时，重新格式化表格列配置
     */
    useEffect(function () {
        setColumns(formatColumns(originColumns));
    }, [originColumns]);
    /**
     * 列的键名
     * @type {string[]}
     */
    var columnKeys = useMemo(function () {
        return getColumnsKey(originColumns);
    }, [originColumns]);
    /**
     * 固定在左侧的列
     * @type {ICnTableColumn[]}
     */
    var fixLeftColumns = useMemo(function () {
        return columns.filter(function (item) { return item.lock === 'left' || item.lock === true; });
    }, [columns]);
    /**
     * 固定在右侧的列
     * @type {ICnTableColumn[]}
     */
    var fixRightColumns = useMemo(function () {
        return columns.filter(function (item) { return item.lock === 'right'; });
    }, [columns]);
    /**
     * 不固定的列
     * @type {ICnTableColumn[]}
     */
    var noFixColumns = useMemo(function () {
        return columns.filter(function (item) { return !item.lock; });
    }, [columns]);
    /**
     * 更新列配置
     */
    var updateColumns = useCallback(function () {
        setOriginColumns(columns, 'user');
    }, [columns, setOriginColumns]);
    /**
     * 取消列配置变更
     * 使用场景：当CnDialog关闭时，但dom未销毁
     */
    var cancelUpdateColumns = useCallback(function () {
        setColumns(formatColumns(originColumns));
    }, [setColumns, originColumns]);
    /**
     * 列配置上下文输出
     * @type {IColumnConfig}
     */
    var output = {
        isDialog: isDialog,
        columns: columns,
        setColumns: setColumns,
        fixLeftColumns: fixLeftColumns,
        fixRightColumns: fixRightColumns,
        noFixColumns: noFixColumns,
        columnKeys: columnKeys,
        checkedColumnKeys: checkedColumnKeys,
        checkedAll: checkedColumnKeys.length === columns.length,
        updateColumns: updateColumns,
        cancelUpdateColumns: cancelUpdateColumns,
    };
    return (React.createElement(ColumnsStateContext.Provider, { value: output }, props.children));
}
