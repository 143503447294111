import { __assign } from "tslib";
import * as React from 'react';
import { AnchorLink } from './anchor-link';
import './index.scss';
/**
 * 将原本的嵌套 dataSource 转换为扁平
 * 并直接将 focus 状态等计算好附加上
 * @param menuList 目标扁平列表，直接做修改
 * @param props 组件属性
 * @param level 锚点嵌套等级
 */
var getMenu = function (props, level) {
    var drill = function (menuList, props, level, padding) {
        if (padding === void 0) { padding = 0; }
        var onSelect = props.onSelect, dataSource = props.dataSource, value = props.value;
        dataSource.forEach(function (item) {
            var isFocus = value === item.htmlId;
            menuList.push({
                level: level,
                label: item.label,
                htmlId: item.htmlId,
                icon: item.icon,
                padding: padding,
                isFocus: isFocus,
                onSelect: onSelect,
            });
            if (Array.isArray(item.children)) {
                drill(menuList, __assign(__assign({}, props), { dataSource: item.children }), level + 1, padding + (item.icon ? 20 : 0));
            }
        });
    };
    var menu = [];
    drill(menu, props, level);
    return menu;
};
export var AnchorList = function (props) {
    var showRoot = props.showRoot;
    var _a = React.useState(getMenu(props, 1)), menuList = _a[0], setMenuList = _a[1];
    var firstFocusIdx = menuList.findIndex(function (item) { return item.isFocus; });
    React.useEffect(function () {
        setMenuList(getMenu(props, 1));
    }, [props.dataSource, props.value]);
    return (React.createElement("div", { className: "cn-ui-anchor-menu" },
        React.createElement("div", { className: "cn-ui-anchor-menu-header", style: {
                top: (firstFocusIdx + (showRoot ? 1 : 0)) * (18 + 16) + 16,
                display: firstFocusIdx === -1 ? 'none' : 'block',
            } }),
        React.createElement("div", { className: "cn-ui-anchor-menu-footer" },
            showRoot && React.createElement("div", { className: "cn-ui-anchor-menu-root" }, "\u76EE\u5F55"),
            menuList.map(function (item) { return (React.createElement(AnchorLink, __assign({ key: "".concat(item.label, "-").concat(item.htmlId) }, item))); }))));
};
