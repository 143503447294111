import { connect, mapProps } from '@formily/react';
import { CnRadio as UiCnRadio, CnRadioGroup as UiCnRadioGroup, } from '@/components/cn-radio';
import { mapSize } from '@/form/__builtins__';
export var CnRadio = connect(UiCnRadio, mapProps({
    value: 'checked',
    onInput: 'onChange',
}, mapSize));
export var CnRadioGroup = connect(UiCnRadioGroup, mapProps({
    dataSource: true,
}, mapSize));
