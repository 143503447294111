import { __assign } from "tslib";
import * as React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { useField } from '@formily/react';
export function connect(target) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    var Target = args.reduce(function (target, mapper) {
        return mapper(target);
    }, target);
    var Destination = React.forwardRef(function (props, ref) {
        var field = useField();
        return React.createElement(Target, __assign(__assign({}, props), { key: field.path.toString(), ref: ref }));
    });
    if (target)
        hoistNonReactStatics(Destination, target);
    return Destination;
}
