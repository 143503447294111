import { __assign, __spreadArray } from "tslib";
import cx from 'classnames';
import React from 'react';
import { getTreeDepth, isLeafNode } from '../../../view/utils';
import { Classes } from './styles';
function range(n) {
    var array = [];
    for (var i = 0; i < n; i++) {
        array.push(i);
    }
    return array;
}
/** 根据当前横向虚拟滚动 对 nested.center 进行过滤，结果只保留当前视野内可见的那些列配置 */
function filterNestedCenter(centerNested, hoz, leftFlatCount) {
    return dfs(centerNested, leftFlatCount).filtered;
    function dfs(cols, startColIndex) {
        var leafCount = 0;
        var filtered = [];
        for (var _i = 0, cols_1 = cols; _i < cols_1.length; _i++) {
            var col = cols_1[_i];
            var colIndex = startColIndex + leafCount;
            if (isLeafNode(col)) {
                leafCount += 1;
                if (leftFlatCount + hoz.leftIndex <= colIndex &&
                    colIndex < leftFlatCount + hoz.rightIndex) {
                    // 符合视野之内的，则拉进来
                    filtered.push({ colIndex: colIndex, col: col });
                }
            }
            else {
                var dfsRes = dfs(col.children, colIndex);
                leafCount += dfsRes.leafCount;
                if (dfsRes.filtered.length > 0) {
                    filtered.push({ colIndex: colIndex, col: col, children: dfsRes.filtered });
                }
            }
        }
        return { filtered: filtered, leafCount: leafCount };
    }
}
/** 根据输入的 nested 列配置，算出相应的 leveled & flat 配置方便渲染 */
function calculateLeveledAndFlat(inputNested, rowCount) {
    var leveled = [];
    for (var depth = 0; depth < rowCount; depth++) {
        leveled.push([]);
    }
    var flat = [];
    dfs(inputNested, 0);
    return { flat: flat, leveled: leveled };
    function dfs(input, depth) {
        var leafCount = 0;
        for (var i = 0; i < input.length; i++) {
            var indexedCol = input[i];
            if (isLeafNode(indexedCol)) {
                leafCount += 1;
                var wrapped = {
                    type: 'normal',
                    width: indexedCol.col.width,
                    col: indexedCol.col,
                    colIndex: indexedCol.colIndex,
                    colSpan: 1,
                    isLeaf: true,
                };
                leveled[depth].push(wrapped);
                flat.push(wrapped);
            }
            else {
                var dfsRes = dfs(indexedCol.children, depth + 1);
                leafCount += dfsRes.leafCount;
                if (dfsRes.leafCount > 0) {
                    leveled[depth].push({
                        type: 'normal',
                        width: indexedCol.col.width,
                        col: indexedCol.col,
                        colIndex: indexedCol.colIndex,
                        colSpan: dfsRes.leafCount,
                        isLeaf: false,
                    });
                }
            }
        }
        return { leafCount: leafCount };
    }
}
/** 包装列配置，附加上 colIndex 属性 */
function attachColIndex(inputNested, colIndexOffset) {
    return dfs(inputNested, colIndexOffset).result;
    function dfs(input, startColIndex) {
        var result = [];
        var leafCount = 0;
        for (var i = 0; i < input.length; i++) {
            var col = input[i];
            var colIndex = startColIndex + leafCount;
            if (isLeafNode(col)) {
                leafCount += 1;
                result.push({ colIndex: colIndex, col: col });
            }
            else {
                var sub = dfs(col.children, colIndex);
                leafCount += sub.leafCount;
                if (sub.leafCount > 0) {
                    result.push({ col: col, colIndex: colIndex, children: sub.result });
                }
            }
        }
        return { result: result, leafCount: leafCount };
    }
}
/** 计算用于渲染表头的数据结构 */
function calculateHeaderRenderInfo(_a, rowCount) {
    var flat = _a.flat, nested = _a.nested, hoz = _a.horizontalRenderRange, useVirtual = _a.useVirtual;
    if (useVirtual.header) {
        var leftPart_1 = calculateLeveledAndFlat(attachColIndex(nested.left, 0), rowCount);
        // 保留视野能看到的
        var filtered = filterNestedCenter(nested.center, hoz, flat.left.length);
        var centerPart_1 = calculateLeveledAndFlat(filtered, rowCount);
        var rightPart_1 = calculateLeveledAndFlat(attachColIndex(nested.right, flat.left.length + flat.center.length), rowCount);
        return {
            flat: __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], leftPart_1.flat, true), [
                { type: 'blank', width: hoz.leftBlank, blankSide: 'left' }
            ], false), centerPart_1.flat, true), [
                { type: 'blank', width: hoz.rightBlank, blankSide: 'right' }
            ], false), rightPart_1.flat, true),
            leveled: range(rowCount).map(function (depth) { return __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], leftPart_1.leveled[depth], true), [
                { type: 'blank', width: hoz.leftBlank, blankSide: 'left' }
            ], false), centerPart_1.leveled[depth], true), [
                { type: 'blank', width: hoz.rightBlank, blankSide: 'right' }
            ], false), rightPart_1.leveled[depth], true); }),
        };
    }
    return calculateLeveledAndFlat(attachColIndex(nested.full, 0), rowCount);
}
export default function TableHeader(_a) {
    var info = _a.info;
    var nested = info.nested, flat = info.flat, stickyLeftMap = info.stickyLeftMap, stickyRightMap = info.stickyRightMap;
    var rowCount = getTreeDepth(nested.full) + 1;
    // 收拾嵌套和虚拟展示逻辑
    var headerRenderInfo = calculateHeaderRenderInfo(info, rowCount);
    var fullFlatCount = flat.full.length;
    var leftFlatCount = flat.left.length;
    var rightFlatCount = flat.right.length;
    var thead = headerRenderInfo.leveled.map(function (wrappedCols, level) {
        var headerCells = wrappedCols.map(function (wrapped) {
            var _a, _b;
            if (wrapped.type === 'normal') {
                var colIndex = wrapped.colIndex, colSpan = wrapped.colSpan, isLeaf = wrapped.isLeaf, col = wrapped.col;
                var headerCellProps = (_a = col.headerCellProps) !== null && _a !== void 0 ? _a : {};
                var positionStyle = {};
                if (colIndex < leftFlatCount) {
                    positionStyle.position = 'sticky';
                    positionStyle.left = stickyLeftMap.get(colIndex);
                }
                else if (colIndex >= fullFlatCount - rightFlatCount) {
                    positionStyle.position = 'sticky';
                    positionStyle.right = stickyRightMap.get(colIndex + colSpan - 1);
                }
                return (React.createElement("th", __assign({ key: colIndex }, headerCellProps, { className: cx(Classes.tableHeaderCell, headerCellProps.className, {
                        first: colIndex === 0,
                        last: colIndex + colSpan === fullFlatCount,
                        'lock-left': colIndex < leftFlatCount,
                        'lock-right': colIndex >= fullFlatCount - rightFlatCount,
                    }), colSpan: colSpan, rowSpan: isLeaf ? rowCount - level : undefined, style: __assign(__assign({ textAlign: col.align }, headerCellProps.style), positionStyle) }), (_b = col.title) !== null && _b !== void 0 ? _b : col.name));
            }
            else if (wrapped.width > 0) {
                return React.createElement("th", { key: wrapped.blankSide });
            }
            else {
                return null;
            }
        });
        return (React.createElement("tr", { key: level, className: cx(Classes.tableHeaderRow, {
                first: level === 0,
                last: level === rowCount - 1,
            }) }, headerCells));
    });
    return (React.createElement("table", null,
        React.createElement("colgroup", null, headerRenderInfo.flat.map(function (wrapped) {
            if (wrapped.type === 'blank') {
                if (wrapped.width > 0) {
                    return (React.createElement("col", { key: wrapped.blankSide, style: { width: wrapped.width } }));
                }
                else {
                    return null;
                }
            }
            else {
                return (React.createElement("col", { key: wrapped.colIndex, style: { width: wrapped.width } }));
            }
        })),
        React.createElement("thead", null, thead)));
}
