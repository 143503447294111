import { __assign } from "tslib";
import React, { useMemo } from 'react';
import classNames from 'classnames';
import { CnCard } from '@/components/cn-card';
import { PageHeader } from './page-header';
import { useBreadcrumbDataSource } from './hooks/use-breadcrumb-data-source';
import { CnPageScrollDomId } from '@cainiaofe/cn-ui-common';
export function CnPageContent(props) {
    var noCard = props.noCard, title = props.title, children = props.children, embeddable = props.embeddable, noPadding = props.noPadding, flexDirection = props.flexDirection, action = props.action, cnBreadcrumbDataSource = props.cnBreadcrumbDataSource, _a = props.cnBreadcrumbProps, cnBreadcrumbProps = _a === void 0 ? {} : _a;
    var isChildrenHasCard = useMemo(function () {
        var _a;
        if (noCard)
            return true;
        var types = [];
        if (Array.isArray(children) && children.length > 0) {
            types = children.map(function (item) { var _a; return (_a = item === null || item === void 0 ? void 0 : item.type) === null || _a === void 0 ? void 0 : _a.displayName; });
        }
        else {
            // @ts-ignore 节点类型无法被识别
            types = [((_a = children === null || children === void 0 ? void 0 : children.type) === null || _a === void 0 ? void 0 : _a.displayName) || ''];
        }
        return types.includes('CnCard');
    }, [children, noCard]);
    var breadcrumbDataSource = useBreadcrumbDataSource({
        cnBreadcrumbDataSource: cnBreadcrumbDataSource,
        cnBreadcrumbProps: cnBreadcrumbProps,
    });
    return (React.createElement("div", { 
        // 页面内部触发的弹窗会根据 id 插入到 dom 容器内，支持弹窗内容跟随跟随滚动，切勿删除
        id: CnPageScrollDomId, className: classNames({
            'cn-ui-page-content-wrapper': true,
            'cn-ui-page-content-wrapper-no-padding': Boolean(noPadding),
            'cn-ui-page-flex-direction': flexDirection,
            'has-title': Boolean(title || (breadcrumbDataSource === null || breadcrumbDataSource === void 0 ? void 0 : breadcrumbDataSource.length)),
        }) },
        React.createElement(PageHeader, { title: title, action: action, cnBreadcrumbProps: __assign(__assign({}, cnBreadcrumbProps), { dataSource: breadcrumbDataSource }) }),
        React.createElement("div", { className: "cn-ui-page-content" }, isChildrenHasCard ? (children) : (React.createElement(CnCard, { className: classNames({
                'cn-ui-page-embeddable': Boolean(embeddable),
                'cn-ui-page-content-card-scroll': flexDirection,
            }) }, children)))));
}
