import React from 'react';
import classNames from 'classnames';
import { getFileType, getFileIconUrl } from '@cainiaofe/cn-ui-common';
export var StaticFileIcon = function (_a) {
    var file = _a.file, className = _a.className;
    var fileType = getFileType(file);
    var cdnUrl = getFileIconUrl(fileType);
    return (React.createElement("div", { className: classNames(className, 'cn-ui-oss-upload-list-file-ext') },
        React.createElement("img", { src: cdnUrl })));
};
