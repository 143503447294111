import { __assign, __awaiter, __generator } from "tslib";
import React from 'react';
import { packRequest } from '@/components/cn-utils/old-request';
import isPlainObject from 'lodash/isPlainObject';
import isFunction from 'lodash/isFunction';
import { CnDialog } from '@/components/cn-dialog';
import { FormProvider } from '@formily/react';
import { toJS } from '@formily/reactive';
import { SchemaField } from '@/form/schema-field';
import { applyMiddleware, isFn } from '@formily/shared';
import formatData from './utils/format-data';
export var RenderQuickDialogForm = function (props) {
    var dialogProps = props.props, form = props.form, loading = props.loading, schema = props.schema, components = props.components, requestConfig = props.requestConfig, confirmMiddlewares = props.confirmMiddlewares, onClose = props.onClose;
    // 定义表单提交的处理函数
    var onOk = function () {
        form
            .submit(function () { return __awaiter(void 0, void 0, void 0, function () {
            var _a, _b, err_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 5, , 6]);
                        if (!isPlainObject(requestConfig)) return [3 /*break*/, 2];
                        // eslint-disable-next-line require-atomic-updates
                        _a = form;
                        return [4 /*yield*/, packRequest(__assign(__assign({}, requestConfig), { data: formatData(requestConfig, toJS(form.values)) }))];
                    case 1:
                        // eslint-disable-next-line require-atomic-updates
                        _a.response = _c.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        if (!isFunction(requestConfig)) return [3 /*break*/, 4];
                        // 如果requestConfig是一个函数，那么直接调用这个函数处理请求
                        // eslint-disable-next-line require-atomic-updates
                        _b = form;
                        return [4 /*yield*/, requestConfig(toJS(form.values))];
                    case 3:
                        // 如果requestConfig是一个函数，那么直接调用这个函数处理请求
                        // eslint-disable-next-line require-atomic-updates
                        _b.response = _c.sent();
                        _c.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_1 = _c.sent();
                        // eslint-disable-next-line require-atomic-updates
                        form.response = err_1.data;
                        return [3 /*break*/, 6];
                    case 6: 
                    // 使用中间件处理表单提交
                    return [4 /*yield*/, applyMiddleware(form, confirmMiddlewares)];
                    case 7:
                        // 使用中间件处理表单提交
                        _c.sent();
                        // 调用onOk回调函数，并传入表单的值
                        props.onOk(toJS(form.values));
                        return [2 /*return*/];
                }
            });
        }); })
            .catch(function () { });
    };
    return (React.createElement(CnDialog, __assign({ size: "small" }, dialogProps, { visible: true, okProps: __assign({ loading: loading }, dialogProps.okProps), onOk: function (event) {
            var _a;
            if (((_a = dialogProps.onOk) === null || _a === void 0 ? void 0 : _a.call(dialogProps, event)) === false)
                return;
            onOk();
        }, onClose: function (trigger, event) { return __awaiter(void 0, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                (_a = dialogProps.onClose) === null || _a === void 0 ? void 0 : _a.call(dialogProps, trigger, event);
                onClose();
                return [2 /*return*/];
            });
        }); }, onCancel: function (event) { return __awaiter(void 0, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                if (((_a = dialogProps.onCancel) === null || _a === void 0 ? void 0 : _a.call(dialogProps, event)) === false)
                    return [2 /*return*/];
                onClose();
                return [2 /*return*/];
            });
        }); } }),
        React.createElement(FormProvider, { form: form }, isFn(schema) ? (schema(form)) : (React.createElement(SchemaField, { components: components, schema: schema })))));
};
