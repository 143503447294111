import { __assign } from "tslib";
// 第三方依赖
import isFunction from 'lodash/isFunction';
// 当前目录的相对依赖
import { useControlValue } from './use-control-value';
export function useGetTreeConfig(props, setLoading) {
    var _a;
    var treeConfig = props.treeConfig, remote = props.remote, tree = props.tree;
    var _b = useControlValue((treeConfig || {}), {
        defaultValuePropName: (_a = treeConfig === null || treeConfig === void 0 ? void 0 : treeConfig.defaultOpenKeys) !== null && _a !== void 0 ? _a : [],
        valuePropName: 'openKeys',
        changePropName: 'onChangeOpenKeys',
    }), treeKeys = _b[0], setTreeKeys = _b[1];
    return {
        treeConfig: tree || treeConfig || (remote === null || remote === void 0 ? void 0 : remote.getChildrenInTree)
            ? __assign({ treeKeys: treeKeys, setTreeKeys: setTreeKeys, openKeys: treeKeys, onChangeOpenKeys: function (keys, key, action, row) {
                    var _a;
                    if (action === 'expand' &&
                        isFunction(remote === null || remote === void 0 ? void 0 : remote.getChildrenInTree)) {
                        if ((remote === null || remote === void 0 ? void 0 : remote.treeCache) && ((_a = row === null || row === void 0 ? void 0 : row.children) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                            // 当开启了缓存的时候，有了孩子节点就不会一直去拉取
                            setTreeKeys(keys);
                            return;
                        }
                        Promise.resolve((remote === null || remote === void 0 ? void 0 : remote.getChildrenInTree)(key, row, setLoading))
                            .then(function (res) {
                            if (Array.isArray(res)) {
                                row.children = res;
                                setTreeKeys(keys);
                            }
                        })
                            .catch(function () {
                            setTreeKeys(keys);
                        });
                    }
                    else {
                        setTreeKeys(keys);
                    }
                } }, (treeConfig || {})) : null,
        treeKeys: treeKeys,
        setTreeKeys: setTreeKeys,
    };
}
