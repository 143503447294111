/**
 * 处理请求参数
 */
export default function formatData(requestConfig, values) {
    if (!requestConfig)
        return values;
    if (typeof requestConfig === 'function')
        return values;
    if (typeof requestConfig.formatData === 'function') {
        return requestConfig.formatData(values);
    }
    return values;
}
