import { __assign, __rest } from "tslib";
import React from 'react';
import cx from 'classnames';
import { UploadListItem } from '../upload-list-item';
import './index.scss';
export var UploadList = function (props) {
    var value = props.value, limit = props.limit, readOnly = props.readOnly, showRemove = props.showRemove, restProps = __rest(props, ["value", "limit", "readOnly", "showRemove"]);
    if (!Array.isArray(value))
        return null;
    if (readOnly && !value.length)
        return null;
    if (!value.length)
        return null;
    if (limit === 1) {
        return (React.createElement("div", { className: cx('cn-ui-upload-single', 'cn-ui-upload-list-normal') },
            React.createElement(UploadListItem, __assign({}, restProps, { file: value[0], showRemove: !readOnly && showRemove }))));
    }
    return (React.createElement("div", { className: cx('cn-ui-upload-list', 'cn-ui-upload-list-normal') }, value.map(function (item) { return (React.createElement(UploadListItem, __assign({ key: item.uid || item.name || item.size }, restProps, { file: item, readOnly: readOnly, showRemove: !readOnly && showRemove }))); })));
};
UploadList.defaultProps = {
    showRemove: true,
};
