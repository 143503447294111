import { __assign } from "tslib";
import $i18n, { withI18n } from 'panda-i18n';
import * as React from 'react';
import locale from '@/locales';
import { Input } from '@/components/fusion';
import { HocBaseComponents, Plugin, pluginManager, } from '@/components/cn-utils';
import { nextCompMap } from './next-comp-map';
export var CnInputTextAreaBase = nextCompMap(Input.TextArea, 'CnInputTextArea');
var CnInputTextAreaView = React.forwardRef(function (props, ref) {
    var placeholder = props.placeholder ||
        $i18n.get({ id: 'TERM.Enter', dm: '请输入', ns: 'CnInput' });
    var plugin = React.useMemo(function () {
        var _plugin = new Plugin('CnInputTextArea', pluginManager);
        _plugin.setLocalPlugin(props === null || props === void 0 ? void 0 : props.usePlugin);
        _plugin.setGlobalPlugin(pluginManager
            .getPluginsByComponentName('CnInputTextArea')
            .map(function (item) { return item.method; }));
        return _plugin;
    }, []);
    var plugins = plugin.getPlugin();
    if (plugins.length === 0) {
        return (React.createElement(CnInputTextAreaBase, __assign({}, props, { placeholder: placeholder, ref: ref })));
    }
    return HocBaseComponents(CnInputTextAreaBase, {
        props: __assign(__assign({}, props), { placeholder: placeholder }),
        plugins: plugins,
        ref: ref,
    });
});
export var CnInputTextArea = withI18n(CnInputTextAreaView, {
    locale: locale,
    componentName: 'CnInputTextArea',
});
