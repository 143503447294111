export var registeredPipelines = [];
export var registerPipeline = function (name, render) {
    var index = registeredPipelines.findIndex(function (pipeline) { return pipeline.name === name; });
    if (index !== -1) {
        registeredPipelines[index] = { name: name, render: render };
    }
    else {
        registeredPipelines.push({ name: name, render: render });
    }
};
