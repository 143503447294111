import $i18n from 'panda-i18n';
import React, { useContext } from 'react';
import { ColumnItem } from './column-item';
import { rightFixMaxNum } from './const';
import { ColumnsStateContext } from './context';
export default function ColumnsFixRight() {
    var _a = useContext(ColumnsStateContext), isDialog = _a.isDialog, fixRightColumns = _a.fixRightColumns;
    return (React.createElement(React.Fragment, null,
        isDialog || fixRightColumns.length ? (React.createElement("div", { className: "cn-table-column-setting-wrapper-title" }, $i18n.get({
            id: 'FixedOnTheRightDatasourcelanglerightFixMaxNum',
            dm: '固定在右侧({dataSourceLength}/{rightFixMaxNum})',
            ns: 'CnTable',
        }, {
            dataSourceLength: fixRightColumns.length,
            rightFixMaxNum: rightFixMaxNum,
        }))) : null,
        isDialog || fixRightColumns.length ? (React.createElement("div", { className: "cn-table-column-setting" }, fixRightColumns.map(function (dataItem, index) { return (React.createElement(ColumnItem, { key: dataItem.dataIndex, dataItem: dataItem, index: index, position: "right" })); }))) : null));
}
