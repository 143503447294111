import { __assign } from "tslib";
import * as React from 'react';
import { Overlay as NextOverlay, } from '@fusion/lib';
var NextPopup = NextOverlay.Popup;
export var CnOverlay = function (props) {
    return React.createElement(NextOverlay, __assign({ "data-name": "CnOverlay" }, props));
};
export var CnPopup = function (props) {
    return React.createElement(NextPopup, __assign({ "data-name": "CnPopup" }, props));
};
CnOverlay.displayName = 'CnOverlay';
CnPopup.displayName = 'CnPopup';
/**
 * @deprecated  请使用 CnOverlay 替换
 */
export var Overlay = NextOverlay;
